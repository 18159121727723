<div class=" bs-modal modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Edit Template</h4>
        <button data-test-id='close-modal-button' type="button" class="close" aria-label="Close" (click)="modal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngClass]="loading ? 'disabled' : ''">
        <app-simple-editor 
            [title]="'Node Template'"
            [tabs]="tabs"
            [showVisualTemplateEditor]="true"
            (saveAll)="saveAll($event)" 
            (transpileJsonToHandlebars)="transpileJsonToHandlebars($event)">
        </app-simple-editor>
    </div>
</div>