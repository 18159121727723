import { EnvironmentSecrets } from './types/secrets';

export const prodSecrets: EnvironmentSecrets = {
  testFirebaseUserUserName: '',
  testFirebaseUserPassword: '',
  domain: 'prod-serviceai.us.auth0.com',
  clientId: 'qOABSjqQyLSuy7CyWhZS7VCXtdYCsk7Z',
  loginUrl: 'https://serviceai.impel.io/login',
  cloudFunctionUrl: 'us-central1-brain-ui-v1.cloudfunctions.net',
};
