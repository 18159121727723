<ng-container *ngIf="loading">
  <div class="container-fluid">
    <div class="row align-items-center py-4 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading">
  <div class="container-fluid">
    <div class="row">
      <div *ngIf="hierarchyElement" class="col-lg-6 col-sm-12">
        <div class="card m-4">
          <div class="card-header">
            <h3>General</h3>
          </div>
          <div class="card-body">
            <app-hierarchy-element-general-info
              [writable]="true"
              [hierarchyElement]="hierarchyElement"
              [corpHierarchy]="corpHierarchy"
              (saved)="refreshUI()"
            >
            </app-hierarchy-element-general-info>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card m-4">
          <div class="card-header d-flex align-items-center">
            <h3>{{ elementTitle }} Assistants</h3>
            <button class="btn btn-outline-success btn-sm btn-circle ml-4" (click)="openAddBotModal()">
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <div class="card-body">
            <div class="table-responsive" *ngIf="corp && corpHierarchy && bots && bots.length">
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">Logo</th>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr *ngFor="let bot of bots">
                    <td>
                      <a class="avatar rounded-circle bg-white" href="javascript:void(0)">
                        <img
                          class="h-100"
                          alt="Assistant Avatar"
                          *ngIf="bot.styles && bot.styles.avatarImageUrl"
                          [src]="bot.styles.avatarImageUrl"
                        />
                      </a>
                    </td>
                    <td>
                      <h4>
                        {{ bot.label }}
                      </h4>
                    </td>
                    <td>
                      <i
                        *ngIf="corp && hierarchyElement && bot"
                        class="fas fa-cog text-info ml-3 cursor-pointer"
                        [routerLink]="[
                          '/admin/corps/' +
                            corp.id +
                            '/hierarchies/' +
                            corpHierarchy.systemName +
                            '/hierarchy-el/' +
                            hierarchyElementSystemName +
                            '/bot/' +
                            bot.code
                        ]"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit Bot"
                      ></i>
                      <i
                        class="fas fa-trash text-danger ml-3 cursor-pointer"
                        (click)="confirmAndDeleteBot(bot)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete Bot"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 *ngIf="!bots || bots.length === 0">No Assistants.</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
