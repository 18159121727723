import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/services/auth.service';
import { combineLatest, Subscription } from 'rxjs';
import dayjs from 'dayjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { InputValidationModel } from 'src/app/models/input-validation';
import { getSidebarItems } from '../utils';
import { SimpleListItem } from 'src/app/components/lists/simple-list-item/_types/SimpleListItem';
// tslint:disable-next-line:max-line-length
import { AddInputValidationModalComponent } from 'src/app/components/modals/add-input-validation-modal/add-input-validation-modal.component';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { CorpsService, InputValidationsService } from 'src/app/services/firestore';

@Component({
  selector: 'app-corp-input-validations',
  templateUrl: './corp-input-validations.component.html',
  styleUrls: ['./corp-input-validations.component.scss'],
})
export class CorpInputValidationsComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  corp: CorpModel;
  inputValidations: InputValidationModel[];
  loading: boolean;
  items: SimpleListItem[] = [];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private authService: AuthService,
    private modalService: BsModalService,
    private corpsService: CorpsService,
    private toaster: ToastrService,
    private inputValidationsService: InputValidationsService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(
      results1 => {
        const params = results1[0];
        const user = results1[1];
        const corpId = params.get('corp');
        if (!corpId || !user) {
          return;
        }
        this.loading = true;
        this.crtDataSubscription = combineLatest([
          this.corpsService.getCorpById(corpId),
          this.inputValidationsService.getInputValidationsByCorpId(corpId),
        ]).subscribe(
          ([corp, inputValidations]) => {
            if (!corp || !inputValidations) {
              return;
            }
            this.loading = false;
            this.corp = corp;
            this.inputValidations = inputValidations;
            this.items = this.inputValidations
              .filter(inputValidation => inputValidation.createdAt != null && inputValidation.updatedAt != null)
              .map(inputValidation => {
                return {
                  title: inputValidation.name,
                  dataPoints: [
                    {
                      label: 'Created At',
                      value: dayjs.unix(inputValidation.createdAt.seconds).format('MM-DD-YYYY hh:mm A'),
                      className: 'simple-list-item-data-point--sm',
                    },
                    {
                      label: 'Updated At',
                      value: dayjs.unix(inputValidation.updatedAt.seconds).format('MM-DD-YYYY hh:mm A'),
                      className: 'simple-list-item-data-point--sm',
                    },
                  ],
                  buttons: [
                    {
                      label: 'Edit',
                      link: ['/portal/corps', `${this.corp.id}`, 'input-validations', inputValidation.systemName],
                    },
                  ],
                };
              });
            this.refreshUI();
          },
          error => {
            this.loading = false;
            this.toaster.error(error);
          },
        );
      },
    );
  }

  canWriteCorpInputValidations(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_WRITE_CORP_INPUT_VALIDATIONS);
  }

  refreshUI() {
    this.headerService.setPageTitle(`${this.corp.label} Input Validations`);
    this.setBreadcrumb(this.corp);
    this.setSidebarItems(this.corp.id);
  }

  private setBreadcrumb(corp: CorpModel) {
    this.breadcrumbService.set([
      {
        label: corp.label,
        icon: corp.logo,
        route: `corps/${corp.id}`,
        testId: 'bread-crumb-corp',
      },
      {
        label: 'Settings',
        route: `corps/${corp.id}/settings`,
      },
      {
        label: 'Input Validations',
        route: `corps/${corp.id}/input-validations`,
      },
    ]);
  }

  private setSidebarItems(corpId: string) {
    this.sidebarService.set(getSidebarItems(corpId));
  }

  showAddModal() {
    this.modalService.show(AddInputValidationModalComponent, {
      ignoreBackdropClick: true,
      initialState: { corpId: this.corp.id },
    });
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
