<div class="modal-header">
  <app-action-progress></app-action-progress>
  <h4 class="modal-title">Add a New User</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (submit)="save()" #form="ngForm" novalidate>
  <div class="modal-body">
    <div class=" form-group">
      <label class=" form-control-label" for="firstName"> First Name </label>
      <input class="form-control" id="firstName" name="firstName" [(ngModel)]="user.firstName" type="text" placeholder="" required>
    </div>
    <div class=" form-group">
      <label class=" form-control-label" for="lastName"> Last Name </label>
      <input class="form-control" id="lastName" name="lastName" [(ngModel)]="user.lastName" type="text" placeholder="" required>
    </div>
    <div class=" form-group">
      <label class=" form-control-label" for="email"> Email </label>
      <input class="form-control" id="email" name="email" [(ngModel)]="user.email" type="email" placeholder="" required>
    </div>
    <div class=" form-group">
      <label class=" form-control-label" for="password"> Password </label>
      <input class="form-control" id="password" name="password" [(ngModel)]="password" type="password" placeholder="" required>
    </div>
    <div class=" form-group">
      <label class=" form-control-label" for="avatar"> Avatar </label>
      <input class="form-control" id="avatar" name="avatar" [(ngModel)]="user.avatar" type="text" placeholder="">
    </div>
    <div class=" form-group">
      <label class=" form-control-label" for="role"> Role </label>
      <select [(ngModel)]="user.role" class="form-control" id="role" name="role" required>
        <option *ngFor="let role of roles" [value]="role.systemName">{{role.name}}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-outline" type="submit" [disabled]="!form.valid || loading">SAVE</button>
  </div>
</form>