<div [attr.data-test-value]="item.title" class="simple-list-item d-flex flex-column overflow-hidden bg-white mb-3">
  <div class="simple-list-item-header w-100">
    <h4 class="font-weight-normal m-0 p-0">{{ item.title }}</h4>
  </div>
  <div class="simple-list-item-content d-flex align-items-center w-100">
    <div class="d-flex flex-grow-1">
      <div *ngFor="let data of item.dataPoints" class="simple-list-item-data-point mr-2" [ngClass]="data.className">
        <h6 class="p-0 m-0 font-weight-bold">{{ data.label }}</h6>
        <h5 class="p-0 m-0 font-weight-normal simple-list-item-data-point-value">{{ data.value }}</h5>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <button
        [attr.data-test-id] = "button.label + '-' +item.title"
        *ngFor="let button of item.buttons"
        [className]="'btn btn-sm btn-' + (button.type ? button.type : 'secondary')"
        (click)="executeButtonActions(button)"
        type="button"
        [disabled]="button.disabled"
      >
        {{ button.label }}
      </button>
      <div class="progress p-0 m-0" *ngIf="item.progress" style="width: 200px">
        <div class="progress-bar fade-loop"
          style="transition: width 2s"
          role="progressbar"
          [style.width]="item.progress.value.toString() + '%'"
        ></div>
      </div>
    </div>
  </div>
</div>
