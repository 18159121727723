<app-action-progress></app-action-progress>
<div *ngIf="!loading">
<app-flow-editor
    [canUserManage]="canManageFlowTemplate()"
    [nodes]="nodes"
    [bot]="bot"
    [corpId]="corpId"
    [allowSaveChanges]="true"
    [flowTemplate]="flowTemplate"
    source="template-flow"
>
</app-flow-editor>
</div>