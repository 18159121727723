<div class="modal-header">
  <h4 class="modal-title">Teaser Button</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div novalidate>                
        <div class="form-group row">
        <label class="form-control-label" for="title">
          Title
        </label>
        <input class="form-control" id="title" [(ngModel)]="buttonTitle" name="title" type="text" required />
      </div>

      <div class="form-group row">
        <label class="form-control-label" for="value">
          Value
        </label>
        <input class="form-control" id="value" [(ngModel)]="buttonValue" name="value" type="text" required />
      </div>
      <div class="form-group row">
        <label class="form-control-label" for="node_value">
          Select Node
        </label>
        <select class="form-control" [(ngModel)]="selectedNodeId" id="node_value" name="node_value" >
            <option *ngFor="let node of nodes" [value]="node.id">{{node.name}}</option>            
        </select>
      </div>

    <button *ngIf="teaser" (click)="deleteButton()" class="btn btn-danger btn-outline float-left mb-3 mr-2" type="button" >Delete</button>
    
    <button (click)="save()" class="btn btn-primary btn-outline float-right mb-3 mr-2" type="button" >SAVE</button>
  </div>
</div>
