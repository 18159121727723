<div class="modal-header">
  <h4 class="modal-title">Advanced Search</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="table-responsive">
      <table class="table align-items-center table-flush">
        <thead class="thead-light">
          <tr class="d-flex">
            <th class="col-3">Field</th>
            <th class="col-3">Criteria</th>
            <th class="col-3">Value</th>
            <th class="col-3"></th>
          </tr>
        </thead>

        <tbody>
          <ng-container>
            <ng-container *ngIf="searchFieldsList">
              <tr *ngFor="let field of searchFieldsList; let i = index" class="d-flex">
                <td class="text-capitalize col-3">
                  <b> {{ field.name }} </b>
                </td>
                <td class="col-3">
                  <span class="text-muted">
                    {{ field.operator.name }}
                  </span>
                </td>
                <td class="col-3">
                  <span class="text-muted"> {{ field.value }} </span>
                </td>

                <td class="col-2">
                  <span class="text-muted">
                    <i (click)="deleteCriteria(i)" class="fa fa-trash text-danger ml-3 cursor-pointer"></i>
                  </span>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row scrollable-body mt-3">
    <select [(ngModel)]="advancedSearchField" (change)="updateField($event)" class="form-control mt-2">
      <option value="">Select</option>
      <option *ngFor="let field of fields" [value]="field.id + ''">{{ field.name }}</option>
    </select>

    <select
      *ngIf="advancedSearchField"
      [(ngModel)]="advancedSearchOperator"
      (change)="updateField($event)"
      class="form-control mt-2"
      id="searchFieldOperator"
      name="searchFieldOperator"
    >
      <option value="">Select operator</option>
      <option *ngFor="let operator of operators[advancedSearchField]" [value]="operator.id">{{ operator.name }}</option>
    </select>

    <ng-container *ngIf="advancedSearchField && advancedSearchOperator">
      <select
        *ngIf="selectedField && selectedField.options"
        [(ngModel)]="advancedSearchSelectedValue"
        (change)="updateField($event)"
        class="form-control mt-2"
      >
        <option value="">Select operator</option>
        <option *ngFor="let opt of selectedField.options" [value]="opt.id">{{ opt.name }}</option>
      </select>

      <input
        *ngIf="!selectedField?.options"
        [(ngModel)]="advancedSearchValue"
        class="form-control mt-2"
        (keyup)="updateField($event)"
        placeholder="Field value"
        type="text"
      />
    </ng-container>

    <button
      (click)="addSearchCriteria()"
      *ngIf="advancedSearchField && advancedSearchOperator && (advancedSearchValue || advancedSearchSelectedValue)"
      class="mt-2 btn btn-sm"
    >
      Add Criteria
    </button>
  </div>
</div>

<div class="modal-footer">
  <button
    *ngIf="searchFieldsList && searchFieldsList.length > 0"
    (click)="applyFilter()"
    type="button"
    class="btn btn-primary btn-sm"
    aria-label="Apply"
  >
    Apply
  </button>
</div>
