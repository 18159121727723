<div class="hl-meta-data-list-wrapper">
  <div class="card">
    <div *ngIf="loading" class="m-auto">
      <app-loading-spinner></app-loading-spinner>
    </div>

    <ul *ngIf="selectedConversation && !loading" class="list-group list-group-flush">
      <li *ngIf="campaignType" class="list-group-item list-group-item-bg">
        <p class="mb-1 font-weight-bold">Conversation Type</p>
        <p class="mb-0">{{ campaignType }}</p>
      </li>
      <li *ngIf="internalUser" class="list-group-item list-group-item-bg">
        <p class="mb-1 font-weight-bold">Conversation ID</p>
        <p class="mb-0">{{ selectedConversation.firebaseDocumentId }}</p>
      </li>
      <li *ngIf="vehicle" class="list-group-item list-group-item-bg">
        <p class="mb-1 font-weight-bold">VIN</p>
        <p class="mb-0">{{ vehicle.vin }}</p>
      </li>
      <li *ngIf="vehicle" class="list-group-item list-group-item-bg">
        <p class="mb-1 font-weight-bold">Vehicle</p>
        <p class="mb-0">{{ vehicle.vehicle_year }} {{ vehicle.vehicle_make }} {{ vehicle.vehicle_model }}</p>
      </li>

      <li class="list-group-item list-group-item-bg" *ngIf="selectedConversation?.toEmailAddresses?.length">
        <p class="mb-1 font-weight-bold">Customer Email</p>
        <p class="mb-0" *ngIf="selectedConversation?.toEmailAddresses?.length">
          {{ selectedConversation.toEmailAddresses!.length > 0 ? selectedConversation.toEmailAddresses![0] : '' }}
        </p>
      </li>
      <li class="list-group-item list-group-item-bg">
        <p class="mb-1 font-weight-bold">Last Updated</p>
        <p class="mb-0">{{ selectedConversation.lastUpdated.toDate() | date : 'medium' }}</p>
      </li>
      <li class="list-group-item list-group-item-bg" *ngIf="assignments && assignments.length">
        <p class="mb-1 font-weight-bold">Service Reps</p>
        <div *ngFor="let assignment of assignments">
          <p class="mb-0">{{ assignment.agentName }}</p>
        </div>
      </li>
      <li class="list-group-item list-group-item-bg">
        <p class="mb-1 font-weight-bold">Assistant</p>
        <p *ngIf="!botUrl && !bot" class="mb-0">{{ selectedConversation?.botName }}</p>
        <a *ngIf="botUrl && !bot" class="mb-0" [routerLink]="botUrl">{{ selectedConversation?.botName }}</a>
        <p *ngIf="!botUrl && bot" class="mb-0">{{ bot.label.trim() }}</p>
        <a *ngIf="botUrl && bot" class="mb-0" [routerLink]="botUrl">{{ bot.label.trim() }}</a>
      </li>
      <li
        class="list-group-item list-group-item-bg"
        *ngIf="
          location || (selectedConversation.hierarchyElements && selectedConversation.hierarchyElements.length > 0)
        "
      >
        <p class="mb-1 font-weight-bold">Location</p>
        <p *ngIf="!location" class="mb-0">{{ selectedConversation.hierarchyElements[0] }}</p>
        <p *ngIf="location" class="mb-0">{{ location }}</p>
      </li>
    </ul>
  </div>
</div>
