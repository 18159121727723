import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/compat/firestore';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { BotModel } from 'src/app/models/bot';
import { ClientEnvironmentModel, BotEnvironment } from 'src/app/models/client-environment';
import uniq from 'lodash/fp/uniq';
import { PermissionsService } from './permissions.service';
import { NodesService } from './nodes.service';
import { EnvironmentService } from './environments.service';
import { COLLECTION_NAMES } from './constants';

@Injectable({
  providedIn: 'root',
})
export class BotsService {
  private botsCollection: AngularFirestoreCollection<BotModel>;
  private botsCollectionQuery: (ref: QueryFn) => AngularFirestoreCollection<BotModel>;

  constructor(
    private afs: AngularFirestore,
    private permissionsService: PermissionsService,
    private nodesService: NodesService,
    private environmentService: EnvironmentService,
  ) {
    this.botsCollection = afs.collection<BotModel>(COLLECTION_NAMES.BOTS);
    this.botsCollectionQuery = (ref: QueryFn) => afs.collection<BotModel>(COLLECTION_NAMES.BOTS, ref);
  }

  addBot(bot: BotModel): Promise<void> {
    const botId = bot.id; // avoiding error prone scenarios
    return this.botsCollection
      .doc(botId)
      .ref.get()
      .then(result => {
        if (result.exists) {
          throw new Error(`Assistant "${botId}" already exists`);
        } else {
          return this.botsCollection.doc(botId).set(Object.assign({}, bot));
        }
      });
  }

  updateBot(bot: BotModel): Promise<void> {
    return this.botsCollection.doc(bot.id).update(Object.assign({}, bot));
  }

  getBotById(botId: string): Observable<BotModel | null> {
    return this.botsCollection
      .doc(botId)
      .valueChanges()
      .pipe(
        map(bot => {
          return plainToClass(BotModel, bot);
        }),
      );
  }

  async getFlowTemplateImportNames(botId: string, flowTemplateId: string): Promise<string[] | null> {
    const bot = await this.getBotById(botId).pipe(take(1)).toPromise();

    const flowTemplateCount = Object.entries(bot?.flowTemplateImportsPrefixes ?? {}).find(
      ([flowTemplateId$1]) => flowTemplateId$1 === flowTemplateId,
    );
    if (flowTemplateCount) {
      return flowTemplateCount[1];
    }
    return null;
  }

  async addFlowTemplateImportNameToBot(botId: string, flowTemplateId: string, prefix: string): Promise<void> {
    const bot = await this.getBotById(botId).pipe(take(1)).toPromise();
    if (!bot) {
      return;
    }

    const flowTemplateImportsPrefixes = bot?.flowTemplateImportsPrefixes ?? {};

    const currentFlowTemplateImportsPrefixes = Object.entries(flowTemplateImportsPrefixes).find(
      ([flowTemplateId$1]) => flowTemplateId$1 === flowTemplateId,
    );

    if (currentFlowTemplateImportsPrefixes) {
      flowTemplateImportsPrefixes[flowTemplateId] = [...flowTemplateImportsPrefixes[flowTemplateId], prefix];
    } else {
      flowTemplateImportsPrefixes[flowTemplateId] = [prefix];
    }

    bot.flowTemplateImportsPrefixes = flowTemplateImportsPrefixes;

    this.updateBot(bot);
  }

  async getBotBy(
    corpId: string,
    hierarchyElementSystemName: string,
    botCode: string,
    clientEnvironment: ClientEnvironmentModel,
  ): Promise<BotModel | null> {
    const botId = `${corpId}-${hierarchyElementSystemName}-${botCode}_${clientEnvironment.selectedStage.systemName}`;
    return this.getBotById(botId).pipe(take(1)).toPromise();
  }

  getBotsByHierarchyElement(hierarchyElementSystemName: string): Observable<BotModel[]> {
    return this.botsCollectionQuery(ref => ref.where('hierarchyElementSystemName', '==', hierarchyElementSystemName))
      .valueChanges()
      .pipe(
        map(bots => {
          return bots.map(bot => plainToClass(BotModel, bot));
        }),
      );
  }

  getBotsByCorpId(corpId: string): Observable<BotModel[]> {
    return this.botsCollectionQuery(ref => ref.where('corpId', '==', corpId))
      .valueChanges()
      .pipe(
        map(bots => {
          return bots.map(bot => plainToClass(BotModel, bot));
        }),
      );
  }

  async removeBot(botId: string, corpId: string, hierarchySystemName: string) {
    const botIdArray = botId.split('-');
    const botCode = botIdArray.pop();

    const doc = await this.environmentService.listCorpEnvironments(corpId);
    // get all bot environments
    let environments: ClientEnvironmentModel | undefined;
    if (doc.exists) {
      environments = doc.data() as ClientEnvironmentModel;
    }

    let allBotIds: string[] = [];
    if (environments) {
      allBotIds = environments.stages.map(stage => {
        return `${corpId}-${hierarchySystemName}-${botCode}_${stage.systemName}`;
      });
    }

    // get all nodes associated with all of the bot's environments and delete delete them
    const nodesInBot = await this.nodesService.getNodesByBotIds(allBotIds);
    const deleteBotNodes = nodesInBot.docs.map(node => {
      return this.nodesService.deleteNodeById(node.id);
    });

    // delete from bots collection
    const deleteBots = allBotIds.map(bId => {
      return this.botsCollection.doc(bId).delete();
    });

    const permissionHierarchy = `${corpId}-${hierarchySystemName}`;
    // delete permissions
    const botPermissions = await this.permissionsService
      .getPermissionsBy(null, corpId, permissionHierarchy, botCode)
      .pipe(take(1))
      .toPromise();

    const deletePermisions = botPermissions.map(perm => {
      return this.permissionsService.removePermission(perm.id);
    });

    await Promise.all([...deletePermisions, ...deleteBotNodes, ...deleteBots]);
  }

  //
  // Input Validation
  //

  // Miscellanous
  async getUserAllowedBotsInCorps(userId: string, corpId: string): Promise<BotModel[]> {
    const [permissions, botsInCorp] = await combineLatest([
      this.permissionsService.getPermissionsBy(userId, corpId),
      this.getBotsByCorpId(corpId),
    ])
      .pipe(take(1))
      .toPromise();
    const botsIHavePermissionsTo = uniq(
      permissions
        .filter(permission => permission.corpId === corpId && permission.botCode)
        .map(permission => permission.botCode),
    );
    return botsInCorp.filter(
      ({ code, clientEnvironment }) =>
        botsIHavePermissionsTo.includes(code) && clientEnvironment === BotEnvironment.Development,
    );
  }
}
