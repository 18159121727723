import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import {
  IFilterCriteria,
  FilterField,
  FilterOperator,
  FieldOperators,
  FilterFields,
} from 'src/app/models/conversations';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-hl-conversation-search-modal',
  templateUrl: './hl-conversation-search-modal.component.html',
  styleUrls: ['./hl-conversation-search-modal.component.scss'],
})
export class HlConversationSearchModalComponent implements OnInit {
  filter: EventEmitter<IFilterCriteria[]> = new EventEmitter<IFilterCriteria[]>();
  clear: EventEmitter<void> = new EventEmitter<void>();

  selectedField: FilterField | null;

  searchFieldsList: IFilterCriteria[] = [];

  fields: FilterField[] = FilterFields;

  operators: { [key: string]: FilterOperator[] } = FieldOperators;

  advancedSearchField = '';
  advancedSearchValue = '';
  advancedSearchOperator = '';
  advancedSearchSelectedValue = '';

  constructor(
    public modal: BsModalRef,
    private toaster: ToastrService,
    private cdRef: ChangeDetectorRef,
    options: ModalOptions,
  ) {
    const { filters } = options.initialState as any;
    if (filters) {
      this.searchFieldsList = filters;
    }
  }
  ngOnInit() {}

  updateField(event) {
    if (this.advancedSearchField) {
      this.selectedField = this.fields.filter(f => f.id === this.advancedSearchField)[0];
    } else {
      this.selectedField = null;
    }
    this.cdRef.detectChanges();
  }
  addSearchCriteria() {
    if (this.advancedSearchField && this.advancedSearchOperator) {
      const field = this.fields.filter(f => f.id === this.advancedSearchField)[0];
      const operator = this.operators[this.advancedSearchField].filter(op => op.id === this.advancedSearchOperator)[0];
      if (operator.validator) {
        if (!operator.validator(this.advancedSearchValue)) {
          this.toaster.error(operator.errorMessage ?? 'Invalid value for this filter');
          return;
        }
      }
      let value = this.advancedSearchValue;
      if (this.selectedField?.options) {
        if (!this.advancedSearchSelectedValue) {
          this.toaster.error(`Select ${this.selectedField.name} option value to continue`);
          return;
        }
        value = this.advancedSearchSelectedValue;
      } else {
        if (!value) {
          this.toaster.error(`Enter a value to continue`);
          return;
        }
      }

      if (operator.firebaseFilter) {
        this.searchFieldsList.push({
          id: field.id,
          name: field.name,
          value,
          operator,
          firebaseFilter: {
            operator: operator.firebaseFilter.operator,
            field: operator.firebaseFilter.field,
            value,
          },
        });
      } else {
        this.searchFieldsList.push({
          id: field.id,
          name: field.name,
          value,
          operator,
        });
      }
    }
    this.advancedSearchField = '';
    this.advancedSearchValue = '';
    this.advancedSearchOperator = '';
    this.advancedSearchSelectedValue = '';
    this.selectedField = null;
    this.cdRef.detectChanges();
  }

  applyFilter() {
    this.filter.emit(this.searchFieldsList);
  }

  clearFilter() {
    this.clear.emit();
  }

  deleteCriteria(index) {
    if (confirm('Are you sure you want to delete this criteria?')) {
      this.searchFieldsList.splice(index, 1);
      this.cdRef.detectChanges();
    }
  }
}
