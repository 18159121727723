import {
  Component,
  Output,
  OnInit,
  AfterViewInit,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { HLTemplateModel } from 'src/app/models/conversations';
import { fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TemplateChannels } from 'src/app/models/template';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-hl-conversation-templates',
  templateUrl: './hl-conversation-templates.component.html',
  styleUrls: ['./hl-conversation-templates.component.scss'],
})
export class HlConversationTemplatesComponent implements OnInit, AfterViewInit, OnChanges {
  search: string;

  @Input()
  allTemplates: HLTemplateModel[] = [];
  searchResult: HLTemplateModel[] = [];
  searchMode = false;
  createMode = false;

  corpTags: string[];
  clickedTemplate: EventEmitter<HLTemplateModel>;
  deleteTemplate: EventEmitter<HLTemplateModel>;

  close: EventEmitter<void>;
  @Output()
  savedTemplate: EventEmitter<HLTemplateModel>;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  editorConfig: AngularEditorConfig = {
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    editable: true,
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    uploadWithCredentials: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'strikeThrough',
        'redo',
        'superscript',
        'subscript',
        'backgroundColor',
        'textColor',
        'insertVideo',
        'insertImage',
      ],
      ['fontSize', 'fontName'],
    ],
  };

  channels = [
    // { id: TemplateChannels.Facebook, name: 'SMS' },
    { id: TemplateChannels.Email, name: 'Email' },
    { id: TemplateChannels.SMS, name: 'SMS' },
  ];
  template: HLTemplateModel;

  constructor(public modal: BsModalRef, options: ModalOptions) {
    const { templates, corpTags } = options.initialState as any;
    this.allTemplates = templates ?? [];
    this.corpTags = corpTags ?? [];
    this.savedTemplate = new EventEmitter<HLTemplateModel>();
    this.clickedTemplate = new EventEmitter<HLTemplateModel>();
    this.deleteTemplate = new EventEmitter<HLTemplateModel>();
    this.close = new EventEmitter<void>();
    this.template = new HLTemplateModel();
    this.template.id = uuidv4();
    this.template.tags = [];
  }

  ngOnInit() {}
  ngOnChanges() {}
  ngAfterViewInit() {
    // search
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(300),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.searchTemplates(this.searchInput.nativeElement.value);
        }),
      )
      .subscribe();
  }

  closeModal() {
    this.close.emit();
    this.modal.hide();
  }
  createTemplate() {
    if (!this.template.name) {
      alert('Enter template name');
      return;
    }

    if (!this.template.channel) {
      alert('Enter template channel');
      return;
    }
    if (!this.template.content) {
      alert('Enter template content');
      return;
    }

    const template: HLTemplateModel = {
      id: this.template.id,
      content: this.template.content,
      channel: this.template.channel,
      createdAt: this.template.createdAt,
      createdBy: this.template.createdBy,
      name: this.template.name,
      botId: this.template.botId,
      lastUpdated: this.template.lastUpdated,
      tags: this.template.tags,
    };
    this.savedTemplate.emit(template);
    this.template.name = '';
    this.template.channel = '';
    this.template.content = '';
    this.template.id = uuidv4();
    this.template.tags = [];
    // hide create template form
    this.createMode = false;
  }

  useTemplate(template: HLTemplateModel) {
    this.clickedTemplate.emit(template);
  }

  searchTemplates(searchText: string) {
    this.searchMode = searchText.trim().length > 0;

    if (this.searchMode) {
      const searchByName = this.allTemplates.filter(template => {
        return template.name.toLowerCase().includes(searchText.toLowerCase());
      });

      if (searchByName.length === 0) {
        const searchByContent = this.allTemplates.filter(template => {
          return template.content.includes(searchText);
        });
        this.searchResult = searchByName.concat(searchByContent);
      } else {
        this.searchResult = searchByName;
      }
    } else {
      this.searchResult = [];
    }
  }

  removeTemplate(template: HLTemplateModel, event: Event) {
    event?.preventDefault();
    if (confirm(`Are you sure you want to delete this template: ${template.name}?`)) {
      this.deleteTemplate.emit(template);
    }
  }
  addTag(tag: string) {
    if (tag) {
      this.template.tags = this.template.tags.filter(t => t !== tag);
      this.template.tags.push(tag);
    }
  }
}
