<div class="modal-header">
    <h4 class="modal-title">Add a New {{ corpHierarchy.singular }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-hierarchy-element-general-info
      *ngIf="hierarchyElement"
      [hierarchyElement]="hierarchyElement"
      [corpHierarchy]="corpHierarchy"
      [parentSystemName]="parentSystemName"
      [writable]="true"
      [shouldAdd]="true"
      (saved)="modal.hide()"
    >
    </app-hierarchy-element-general-info>
  </div>