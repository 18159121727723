<div class="modal-header">
  <h4 class="modal-title">Update Photo</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="dropzone dropzone-single mb-3 dz-clickable" id="dropzone-single">
    <div class="dz-preview dz-preview-single">
      <div class=" dz-preview-cover">
        <img
          alt="..."
          class=" dz-preview-img"
          data-dz-thumbnail=""
          src="..."
        />
      </div>
    </div>
    <div class="dz-default dz-message">
      <span>Upload Photo</span>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary btn-outline" (click)="select()" [disabled]="!file">SELECT</button>
</div>
