import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-campaign-option',
  templateUrl: './campaign-option.component.html',
  styleUrls: ['./campaign-option.component.scss'],
})
export class CampaignOptionComponent {
  @Input() campaignType: string;
  @Input() selectedCampaign: string;
  @Input() label: string;
  @Input() description: string;
  @Input() imageSrc: string;
  @Output() campaignSelected = new EventEmitter<string>();

  selectCampaign() {
    this.campaignSelected.emit(this.campaignType);
  }
}
