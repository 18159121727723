<div class="modal-header">
    <app-action-progress></app-action-progress>
    <h4 class="modal-title">Edit Role for {{ role.name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (submit)="save()" #form="ngForm" novalidate>
    <div class="modal-body">
      <div class=" form-group">
        <label class=" form-control-label" for="roleName"> Name </label>
        <input class="form-control" id="roleName" name="name" [(ngModel)]="role.name" type="text" placeholder="" required>
      </div>
      <div class="form-group">
        <label class="form-control-label" for="rolePermissions"> Permissions </label>
        <ng-select
          [multiple]="true"
          [(ngModel)]="role.permissions"
          name="permissions"
          id="rolePermissions"
          (add)="newPermissionAdded($event)"
          (remove)="permissionRemoved($event)"
          [searchFn]="permissionsSearchFunction"
          >
          <ng-option *ngFor="let permission of permissions" [value]="permission.id">{{permission.text}}</ng-option>
      </ng-select>
      </div>
    </div>
    <div class="modal-footer">
      <button *ngIf="canWriteRoles" class="btn btn-primary btn-outline" type="submit" [disabled]="!form.valid || loading">SAVE</button>
    </div>
  </form>