import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserNavbarItemVM } from '../../components/layout/navbar/_types/UserNavbarItemVM';

@Injectable({
  providedIn: 'root'
})
export class UserNavbarService {
  private items: BehaviorSubject<UserNavbarItemVM[] | null>;
  items$: Observable<UserNavbarItemVM[] | null>;

  constructor() {
    this.items = new BehaviorSubject<UserNavbarItemVM[] | null>(null);
    this.items$ = this.items.asObservable();
  }

  set(items: UserNavbarItemVM[]) {
    this.items.next(items);
  }
}
