<div data-test-id="flow-container" class="flow flow-container">
  <div class="flow-toolbar position-absolute p-3 w-100">
    <button class="btn btn-primary btn-sm" type="button" (click)="center($event)">Center</button>
    <button
      data-test-id="flow-create-node-button"
      [disabled]="!canUserManage"
      class="btn btn-primary btn-sm"
      type="button"
      (click)="addNode($event)"
    >
      Create Node
    </button>
    <button
      data-test-id="flow-variables-button"
      [disabled]="!canUserManage"
      class="btn btn-primary btn-sm"
      type="button"
      (click)="toggleShowVariables()"
    >
      Variables
    </button>
    <button
      data-test-id="flow-save-changes-button"
      [disabled]="!canUserManage || !hasChanges"
      class="btn btn-primary btn-sm"
      type="button"
      *ngIf="allowSaveChanges"
      (click)="saveChanges$1()"
    >
      Save Changes <span *ngIf="source == 'bot-flow'"> To History </span>
    </button>
    <button
      class="btn btn-danger btn-sm"
      type="button"
      *ngIf="multipleSelectedNodes.size > 0"
      (click)="bulkDeleteMultipleSelectedNodes()"
    >
      ({{ multipleSelectedNodes.size }}) Bulk Delete
    </button>
    <div dropdown class="dropdown no-caret ml-auto mr-4">
      <button
        dropdownToggle
        class="btn btn-sm btn-primary dropdown-toggle"
        aria-haspopup="true"
        aria-controls="dropdown-basic"
        type="button"
      >
        <span>Filter Connections</span>
        <i class="fas fa-caret-down"></i>
      </button>
      <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
        <a
          class="dropdown-item"
          *ngFor="let flowLinesSetting of flowLinesSettings"
          href="javascript:void(0)"
          (click)="toggleFlowLineSetting(flowLinesSetting.label)"
        >
          <input type="checkbox" [checked]="flowLinesSetting.selected" />
          {{ flowLinesSetting.label }}
        </a>
      </div>
    </div>
    <button class="btn btn-primary btn-sm float-right" type="button" *ngIf="loading">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
  </div>
  <app-bot-flow-sidebar
    class="flow-sidebar-container overflow-auto position-absolute p-3 w-30 h-100"
    *ngIf="selectedNode"
    [(selectedNode)]="selectedNode"
    [nlpModel]="nlpModel"
    [specs]="specs"
    [specTemplates]="specsTemplates"
    [apiQueries]="apiQueries"
    [validations]="validations"
    [apiVariableItems]="apiVariableItems"
    [canUserManage]="canUserManage"
    [allowSaveChanges]="allowSaveChanges"
    [nodes]="nodes"
    (updateFlow)="updateFlow()"
    (remove)="removeNode($event)"
    (duplicate)="duplicateNode($event)"
  >
  </app-bot-flow-sidebar>
  <div
    data-test-id="flow-variables-container"
    *ngIf="showVariables"
    class="flow-sidebar-container overflow-auto position-absolute p-3 w-30 h-100"
  >
    <app-render-global-variables
      title="Assistant Variables"
      variableType="BOT"
      description="These variables will give the same value through out the flow and are not changeable within the flow"
      (handleChanges)="handleGlobalVariableChanges($event)"
      [items]="botVariableItems"
    >
    </app-render-global-variables>

    <app-render-global-variables
      title="User Variables"
      variableType="USER_INPUT"
      description="These variables are unique for every user and can only be set through user responses"
      (handleChanges)="handleGlobalVariableChanges($event)"
      [items]="userVariableItems"
    >
    </app-render-global-variables>

    <app-render-global-variables
      title="API Variables"
      variableType="API_DATA"
      description="These variables are also unique for every user and can only be set through API responses"
      (handleChanges)="handleGlobalVariableChanges($event)"
      [items]="apiVariableItems"
    >
    </app-render-global-variables>

    <app-render-conversation-state-variables> </app-render-conversation-state-variables>
  </div>
  <div
    id="flow-container"
    class="flow-container"
    (click)="clickContainer($event)"
    (mouseenter)="mouseEnter()"
    (mouseleave)="mouseLeave()"
  >
    <div
      *ngFor="let node of nodes"
      data-test-class="flow-node"
      [ngClass]="
        (selectedNode && selectedNode.systemName == node.systemName) || multipleSelectedNodes.has(node.id)
          ? 'node--active'
          : ''
      "
      id="{{ node.id }}"
      class="node"
      (click)="setNode($event, node)"
    >
      <div class="node__card">
        <div class="node__header d-flex justify-content-between">
          <h1>{{ node.name }}</h1>
          <div class="d-flex">
            <h1 class="pl-0" *ngIf="node.live && node.action">
              <span class="badge badge-pill badge-success"> Action </span>
            </h1>
            <h1 class="pl-0" *ngIf="node.live && node.spec">
              <span class="badge badge-pill badge-warning"> Spec </span>
            </h1>
            <h1 class="pl-0" *ngIf="!node.live">
              <span class="badge badge-pill badge-danger"> Disabled </span>
            </h1>
            <h1 class="pl-0" *ngIf="node.live && hasTemplateChannel(node, 'directline')">
              <span class="badge badge-pill badge-secondary"> Web </span>
            </h1>
            <h1 class="pl-0" *ngIf="node.live && hasTemplateChannel(node, 'facebook')">
              <span class="badge badge-pill badge-info"> Facebook </span>
            </h1>
          </div>
        </div>
        <div class="node__content">
          <div *ngIf="node.global" class="node__button bg-clr-yellow">
            <p>Global</p>
          </div>
          <div *ngIf="!node.global && node.inputData" class="node__button bg-clr-gray text-white">
            <p>Input Data</p>
          </div>
          <div *ngIf="!node.global && node.templates?.length" class="node__button bg-clr-purple">
            <p>Template</p>
          </div>
          <div *ngIf="!node.global && node.httpRequests?.length" class="node__button bg-clr-yellow">
            <p>Http Request</p>
          </div>
          <div *ngIf="!node.global && node.apiQueries?.length" class="node__button bg-clr-green">
            <p>API</p>
          </div>
          <div *ngIf="!node.global && node.validations?.length" class="node__button bg-clr-pink">
            <p>Validation</p>
          </div>
          <div *ngIf="!node.global && node.mod" class="node__button bg-clr-blue">
            <p>Module</p>
          </div>
          <div *ngIf="!node.global && node.kpis?.length" class="node__button bg-clr-blue">
            <p>KPI</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
