import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit, ViewChild } from '@angular/core';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { AnalyticsDashboardData } from 'src/app/services/api-gateway-analytics/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-rep-performance-report',
  templateUrl: './rep-performance-report.component.html',
  styleUrls: ['./rep-performance-report.component.scss'],
})
export class RepPerformanceReportComponent implements OnInit, OnChanges, AfterViewInit {
  reportDetails;
  displayTotals;
  dataSource;
  isLoading: boolean;
  reportType = 'RPR';

  @Input() awsRegion: AwsRegionEnum;
  @Input() botId: string;
  @Input() startDate: Date = new Date(2000, 0, 1);
  @Input() endDate: Date = new Date();
  @ViewChild('performanceSort') performanceSort: MatSort = new MatSort();
  @ViewChild(MatPaginator) performancePaginator: MatPaginator;
  columnsToDisplay = [
    'rep',
    'customers_assigned',
    'engagements',
    'engagement_percent',
    'appointments',
    'appointment_percent',
    'triage',
    'triage_percent',
    'messages_sent',
  ];

  constructor(
    private apiGatewayAnalyticsService: ApiGatewayAnalyticsService,
    private authService: AuthService,
    private toaster: ToastrService,
  ) {}

  async ngOnInit() {
    const user = await this.authService.currentUser;
    this.isLoading = true;
    await this.getReportDetails();
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.performanceSort;
    this.dataSource.paginator = this.performancePaginator;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.startDate.previousValue && changes.startDate.currentValue !== changes.startDate.previousValue) ||
      (changes.endDate.previousValue && changes.endDate.currentValue !== changes.endDate.previousValue)
    ) {
      this.isLoading = true;
      this.reportDetails = [];
      await this.getReportDetails();
      this.isLoading = false;
    }
  }

  onSearch(event) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  async getReportDetails() {
    try {
      const response: AnalyticsDashboardData = await this.apiGatewayAnalyticsService.getAnalyticsDashboardData(
        this.botId,
        this.startDate,
        this.endDate,
        this.reportType,
      );
      this.reportDetails = [];
      this.displayTotals = {
        rep: '* Totals *',
        customers_assigned: 0,
        appointments: 0,
        engagements: 0,
        triage: 0,
        messages_sent: 0,
        engagement_percent: 0.0,
        appointment_percent: 0.0,
        triage_percent: 0.0,
      };
      if (response.tables.report.details !== null) {
        const result = await Promise.all(
          response.tables.report.details.map(async details => {
            let key = 'engagements';
            const engagements = parseInt(details[key], 10);
            key = 'appointments';
            const appointments = parseInt(details[key], 10);
            key = 'customers_assigned';
            const customers_assigned = parseInt(details[key], 10);
            key = 'rep';
            const rep = details[key];
            key = 'messages_sent';
            const messages_sent = parseInt(details[key], 10);
            key = 'triage';
            const triage = parseInt(details[key], 10);
            const engagement_percent = (customers_assigned > 0 ? engagements / customers_assigned : 0) * 100;
            const appointment_percent = (engagements > 0 ? appointments / engagements : 0) * 100.0;
            const triage_percent = (engagements > 0 ? triage / engagements : 0) * 100.0;
            return {
              rep,
              customers_assigned,
              appointments,
              engagements,
              triage,
              messages_sent,
              engagement_percent,
              appointment_percent,
              triage_percent,
            };
          }),
        );
        this.reportDetails = result;
        result.forEach(r => {
          this.displayTotals.engagements = r.engagements;
          this.displayTotals.appointments = r.appointments;
          this.displayTotals.customers_assigned = r.customers_assigned;
          this.displayTotals.messages_sent += r.messages_sent;
          this.displayTotals.triage = r.triage;
          this.displayTotals.engagement_percent =
            (this.displayTotals.customers_assigned > 0
              ? this.displayTotals.engagements / this.displayTotals.customers_assigned
              : 0) * 100;
          this.displayTotals.appointment_percent =
            (this.displayTotals.engagements > 0
              ? this.displayTotals.appointments / this.displayTotals.engagements
              : 0) * 100.0;
          this.displayTotals.triage_percent =
            (this.displayTotals.engagements > 0 ? this.displayTotals.triage / this.displayTotals.engagements : 0) *
            100.0;
        });
      }
      this.dataSource = new MatTableDataSource(this.reportDetails);
      setTimeout(() => {
        this.dataSource.sort = this.performanceSort;
        this.dataSource.paginator = this.performancePaginator;
      }, 100);
    } catch (error) {
      console.log(error);
      this.toaster.error(error);
    }
  }
}
