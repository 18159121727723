<form (submit)="save()" #form="ngForm" *ngIf="corp" novalidate>
  <fieldset [disabled]="!writable">
    <div class="form-group">
      <label class="form-control-label" for="corpName"> Name </label>
      <input
        class="form-control"
        id="corpName"
        name="name"
        [(ngModel)]="corp.label"
        type="text"
        placeholder=""
        required
      />
    </div>
    <div class="form-group">
      <label class="form-control-label" for="corpLogo"> Logo </label>
      <app-editable-image
        [source]="corp.logo"
        [editable]="writable"
        (fileSelect)="logoFile = $event"
        (remove)="corp.logo = undefined"
      >
      </app-editable-image>
    </div>

    <div class="form-group">
      <label class="form-control-label" for="corpTags"> Tags </label>
      <div class="col p-0">
        <tag-input
          [(ngModel)]="corpTags"
          placeholder="Add a new tag"
          [modelAsStrings]="true"
          theme="regular-theme"
          name="tags"
          id="tags"
        >
        </tag-input>
      </div>
    </div>
    <div class="form-check" style="margin-bottom: 20px; margin-left: 5px">
      <input
        class="form-check-input"
        type="checkbox"
        id="inactiveCorps"
        name="inactiveCorps"
        [checked]="isCorpInactive"
        (click)="checkBoxChanged($event)"
      />
      <label class="form-check-label" for="inactiveCorps">
        Inactive?
      </label>
    </div>
  </fieldset>
  <button *ngIf="writable" class="btn btn-primary btn-outline" type="submit" [disabled]="!form.valid || loading">
    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span *ngIf="loading"> Saving...</span>
    <span *ngIf="!loading">SAVE</span>
  </button>
</form>
