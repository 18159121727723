<div class="deploy-bot-modal-wrapper">
  <div class="modal-header">
    <h4 class="modal-title">Deploy Assistant</h4>
    <button *ngIf="!showLoader" type="button" class="close" aria-label="Close" (click)="modal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!showLoader">
      <div class="form-group">
        <label for="selectEnvironment">Environment</label>
        <select data-test-id="deploy-to-stage" id="selectEnvironment" class="form-control" [(ngModel)]="selectedStage">
          <option disabled value="Select Environment" selected>Select Environment</option>
          <option
            *ngFor="let stage of clientEnvironment?.stages; index as i"
            [value]="stage.systemName"
            [attr.disabled]="i >= currentStageIndex ? null : true"
          >
            {{ stage.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="deploymentVersion">Version</label>
        <select data-test-id="deploy-version" id="deploymentVersion" class="form-control" [(ngModel)]="selectedVersion">
          <option disabled value="Select Version" selected>Select Version</option>
          <option
            *ngFor="let version of versions | filterUnique: 'versionNumber':'versionNumber':'desc'"
            [value]="version.versionNumber"
          >
            {{ version.versionNumber }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="deployNotes">Description</label>
        <textarea
          data-test-id="deploy-notes"
          [(ngModel)]="deploymentNotes"
          class="form-control"
          id="deployNotes"
          rows="3"
        ></textarea>
      </div>

      <button
        data-test-id="submit-deploy-button"
        type="button"
        class="btn btn-primary btn-block mb-3"
        (click)="handleDeploy()"
        [attr.disabled]="selectedVersion === 'Select Version' || !selectedStage ? true : null"
      >
        Deploy
      </button>
    </div>

    <div *ngIf="showLoader">
      <div class="text-center">
        <span class="spinner-border" aria-hidden="true"></span>
      </div>

      <div class="text-center">
        <span>Deploying... please wait</span>
      </div>
    </div>
  </div>
</div>
