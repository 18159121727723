<div class="modal-header">
  <div>
    <h4 class="modal-title">Select Node and Channel</h4>
    {{ header }}
  </div>
  <button [disabled]="isLoading" type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>

  <div *ngIf="!isLoading">
    <div class="row">
      <div class="input-group input-group-alternative">
        <input
          class="form-control"
          (keyup)="doSearchNodes($event)"
          [(ngModel)]="search"
          placeholder="Search nodes"
          type="text"
        />
      </div>
    </div>

    <div class="row scrollable-body mt-3">
      <ul *ngIf="searchMode === true">
        <li (click)="useNode(node)" *ngFor="let node of searchResult" class="row" style="cursor: pointer">
          <div class="col-lg-12 mb-2">
            <div class="justify-content-between align-items-center">
              <h4 class="mb-0">{{ node.name }}</h4>
              <div>
                <h6 class="text-muted">{{ node.action }}</h6>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <ul *ngIf="searchMode === false">
        <li (click)="useNode(node)" *ngFor="let node of allNodes" class="row mb-2" style="cursor: pointer">
          <div class="col-lg-12 mb-2">
            <div class="justify-content-between align-items-center">
              <h4 class="mb-0">
                {{ node.name }}
                <i *ngIf="currentNode && currentNode.id === node.id" class="fa fa-check-circle" aria-hidden="true"></i>
              </h4>
              <div>
                <h6 class="text-muted">
                  {{ node.action }}
                </h6>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    [disabled]="!currentNode || isLoading"
    (click)="done()"
    type="button"
    class="btn btn-primary btn-sm"
    aria-label="Done"
  >
    Done
  </button>
</div>
