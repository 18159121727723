import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Permissions } from '../utils/permissions/permissions';
import { UsersService } from './firestore';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild {
  adminGuard$: Promise<boolean>;

  constructor(private router: Router, private authService: AuthService, private usersService: UsersService) {
    this.adminGuard$ = this.adminGuard();
  }

  private async adminGuard(): Promise<boolean> {
    const user = await this.authService.user$.pipe(take(1)).toPromise();
    if (!user) {
      this.router.navigate(['/login']);
      return false;
    }
    const firestoreUser = this.usersService.getUserById(user.uid);

    if (!firestoreUser) {
      this.router.navigate(['/login']);
      return false;
    }

    if (!(await this.authService.hasPermission(Permissions.IS_ADMIN))) {
      this.router.navigate(['/portal/dashboard']);
      return false;
    }

    return true;
  }

  canActivate(): Promise<boolean> {
    return this.adminGuard$;
  }

  canActivateChild(): Promise<boolean> {
    return this.adminGuard$;
  }
}
