export enum Permissions {
  IS_ADMIN = 'IS_ADMIN',

  CAN_READ_ROLES = 'CAN_READ_ROLES',
  CAN_WRITE_ROLES = 'CAN_WRITE_ROLES',

  CAN_READ_USERS = 'CAN_READ_USERS',
  CAN_WRITE_USERS = 'CAN_WRITE_USERS',

  CAN_READ_CORP_ANALYTICS = 'CAN_READ_CORP_ANALYTICS',
  CAN_READ_CORP_SETTINGS = 'CAN_READ_CORP_SETTINGS',
  CAN_READ_CORP_INPUT_VALIDATIONS = 'CAN_READ_CORP_INPUT_VALIDATIONS',
  CAN_WRITE_CORP_INPUT_VALIDATIONS = 'CAN_WRITE_CORP_INPUT_VALIDATIONS',
  CAN_EDIT_CORP_SETTINGS = 'CAN_EDIT_CORP_SETTINGS',
  CAN_DELETE_CORP = 'CAN_DELETE_CORP',
  CAN_READ_CORP_ANALYTICS_CARD = 'CAN_READ_CORP_ANALYTICS_CARD',
  CAN_READ_FLOW_TEMPLATES = 'CAN_READ_FLOW_TEMPLATES',
  CAN_MANAGE_FLOW_TEMPLATES = 'CAN_MANAGE_FLOW_TEMPLATES',

  CAN_READ_FLOW_TEMPLATE_API_QUERIES = 'CAN_READ_FLOW_TEMPLATE_API_QUERIES',
  CAN_WRITE_FLOW_TEMPLATE_API_QUERIES = 'CAN_WRITE_FLOW_TEMPLATE_API_QUERIES',

  CAN_READ_HIERARCHY_ANALYTICS = 'CAN_READ_HIERARCHY_ANALYTICS',
  CAN_READ_HIERARCHY_SETTINGS = 'CAN_READ_HIERARCHY_SETTINGS',
  CAN_EDIT_HIERACHY_SETTINGS = 'CAN_EDIT_HIERACHY_SETTINGS',
  CAN_DELETE_HIERARCHY = 'CAN_DELETE_HIERARCHY',
  CAN_ADD_HIERARCHY = 'CAN_ADD_HIERARCHY',
  CAN_READ_HIERARCHY_ANALYTICS_CARD = 'CAN_READ_HIERARCHY_ANALYTICS_CARD',

  CAN_READ_BOT_DEPLOYMENTS = 'CAN_READ_BOT_DEPLOYMENTS',
  CAN_READ_BOT_FLOW = 'CAN_READ_BOT_FLOW',
  CAN_MANAGE_BOT_FLOW = 'CAN_MANAGE_BOT_FLOW',
  CAN_READ_BOT_ANALYTICS = 'CAN_READ_BOT_ANALYTICS',
  CAN_READ_BOT_CONVERSATION_LOGS = 'CAN_READ_BOT_CONVERSATION_LOGS',
  CAN_READ_BOT_SETTINGS = 'CAN_READ_BOT_SETTINGS',
  CAN_EDIT_BOT_SETTINGS = 'CAN_EDIT_BOT_SETTINGS',

  CAN_HANDLE_BOT_SUPPORT = 'CAN_HANDLE_BOT_SUPPORT',
  CAN_ADMIN_BOT_HIL = 'CAN_ADMIN_BOT_HIL',
  CAN_EXPORT_BOT = 'CAN_EXPORT_BOT',
  CAN_DELETE_BOT = 'CAN_DELETE_BOT',
  CAN_GENERATE_BOT_EMBEDDABLE_CODE = 'CAN_GENERATE_BOT_EMBEDDABLE_CODE',
  CAN_ADD_BOT = 'CAN_ADD_BOT',
  CAN_READ_BOT_API_QUERIES = 'CAN_READ_BOT_API_QUERIES',
  CAN_WRITE_BOT_API_QUERIES = 'CAN_WRITE_BOT_API_QUERIES',
  CAN_VIEW_DATA_EXPORT_TOOL = 'CAN_VIEW_DATA_EXPORT_TOOL',
  CAN_EXPORT_USING_DATA_EXPORT_TOOL = 'CAN_EXPORT_USING_DATA_EXPORT_TOOL',

  ACCESS_ALL_COMPANIES = 'ACCESS_ALL_COMPANIES',
}
// Stricter permissions First
export const DependentPermissions = [
  [Permissions.CAN_WRITE_ROLES, Permissions.CAN_READ_ROLES],
  [Permissions.CAN_WRITE_USERS, Permissions.CAN_READ_USERS],
  [Permissions.CAN_READ_CORP_ANALYTICS_CARD, Permissions.CAN_READ_CORP_ANALYTICS],
  [Permissions.CAN_WRITE_CORP_INPUT_VALIDATIONS, Permissions.CAN_READ_CORP_INPUT_VALIDATIONS],
  [Permissions.CAN_WRITE_BOT_API_QUERIES, Permissions.CAN_READ_BOT_API_QUERIES],
  [Permissions.CAN_WRITE_FLOW_TEMPLATE_API_QUERIES, Permissions.CAN_READ_FLOW_TEMPLATE_API_QUERIES],
  [Permissions.CAN_EDIT_CORP_SETTINGS, Permissions.CAN_READ_CORP_SETTINGS],
  [Permissions.CAN_DELETE_CORP, Permissions.CAN_READ_CORP_SETTINGS],
  [Permissions.CAN_MANAGE_FLOW_TEMPLATES, Permissions.CAN_READ_FLOW_TEMPLATES],
  [Permissions.CAN_EDIT_HIERACHY_SETTINGS, Permissions.CAN_READ_HIERARCHY_SETTINGS],
  [Permissions.CAN_DELETE_HIERARCHY, Permissions.CAN_READ_HIERARCHY_SETTINGS],
  [Permissions.CAN_EDIT_BOT_SETTINGS, Permissions.CAN_READ_BOT_SETTINGS, Permissions.CAN_READ_BOT_DEPLOYMENTS],
  [Permissions.CAN_EXPORT_BOT, Permissions.CAN_READ_BOT_DEPLOYMENTS],
  [Permissions.CAN_DELETE_BOT, Permissions.CAN_READ_BOT_SETTINGS, Permissions.CAN_READ_BOT_DEPLOYMENTS],
  [
    Permissions.CAN_GENERATE_BOT_EMBEDDABLE_CODE,
    Permissions.CAN_READ_BOT_SETTINGS,
    Permissions.CAN_READ_BOT_DEPLOYMENTS,
  ],
  [Permissions.CAN_READ_HIERARCHY_ANALYTICS_CARD, Permissions.CAN_READ_HIERARCHY_ANALYTICS],
  [Permissions.CAN_MANAGE_BOT_FLOW, Permissions.CAN_READ_BOT_FLOW, Permissions.CAN_READ_BOT_DEPLOYMENTS],
  [Permissions.CAN_EXPORT_USING_DATA_EXPORT_TOOL, Permissions.CAN_VIEW_DATA_EXPORT_TOOL],
];
