<div class="campaign-option" (click)="selectCampaign()">
  <label [for]="campaignType" style="cursor: pointer">
    <img [src]="imageSrc" alt="{{ label }}" style="cursor: pointer" />
    <div class="details" style="cursor: pointer">
      <h3>{{ label }}</h3>
      <p>{{ description }}</p>
    </div>
  </label>
  <input
    type="radio"
    [id]="campaignType"
    name="campaign"
    [value]="campaignType"
    [(ngModel)]="selectedCampaign"
    style="cursor: pointer"
  />
</div>
