<div class="container-fluid">
  <div *ngIf="loading">
    <div class="row align-items-center py-4 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>

  <ng-container>
    <div *ngIf="!loading">
      <div *ngIf="corp">
        <!--Unused component, remove after some months-->
        <!--<app-analytics-cards-topline-stats *ngIf="canReadCorpAnalyticsCard" [corpsId]="[corp.id]"></app-analytics-cards-topline-stats>-->
      </div>

      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7 d-flex align-items-center">
          <h2 class="h2 d-inline-block mb-0" *ngIf="highestHierarchy">{{ highestHierarchy.label }}</h2>
          <button class="ml-3 btn-sm btn-outline-primary rounded-circle" *ngIf="canAddHierarchy()">
            <i class="fa fa-plus" (click)="showAddHierarchyModal()"></i>
          </button>
        </div>
      </div>

      <div class="row" *ngIf="hierarchyElements && highestHierarchy">
        <div class="col-xl-3 col-md-6" *ngFor="let hierarchyElement of hierarchyElements">
          <div
            class="card hierarchy-card"
            [attr.data-test-id]="'hierarchy-' + hierarchyElement.label"
            (click)="navigateToHierarchy(hierarchyElement)"
          >
            <div class="card-body">
              <img
                class="img-center img-fluid rounded-circle"
                [src]="hierarchyElement.logo"
                onError="this.src='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='"
              />
              <div class="pt-4 text-center">
                <h2 class="card-title">{{ hierarchyElement.label }}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="!hierarchyElements.length">
          <h5>No {{ highestHierarchy.label }}.</h5>
        </div>
      </div>

      <!-- Corp Wide Stats -->
      <!--
      <div class=" row align-items-center py-4" *ngIf="hasReadPermission()">
        <div class=" col-lg-6 col-7">
          <h6 class=" h2 d-inline-block mb-0">
            {{ corp.label }} Corporate Key Measures
          </h6>
        </div>
      </div> -->

      <!-- Line Chart -->
      <!-- <app-charts *ngIf="hasReadPermission()"></app-charts> -->
    </div>
  </ng-container>
</div>
