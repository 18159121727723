<div class="modal-header">
  <h4 class="modal-title">Save HTTP Request</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (submit)="onSave()" #form="ngForm" novalidate>
  <div class="modal-body">
    <div class=" form-group">
      <label class=" form-control-label" for="title"> Title </label>
      <input class="form-control" id="title" name="title" [(ngModel)]="nodeHttpRequest.title" type="text" placeholder="Label for the Request" required />
    </div>
    <div class=" form-group">
      <label class=" form-control-label" for="endPoint"> Endpoint </label>
      <input class="form-control" id="endPoint" name="endPoint" [(ngModel)]="nodeHttpRequest.endPoint" type="url" placeholder="Endpoint for the Request" required />
    </div>
    <div class="form-group">
      <label class=" form-control-label" for="httpMethod"> Method </label>
      <select class="form-control" id="httpMethod" name="httpMethod" [(ngModel)]="nodeHttpRequest.httpMethod" required>
        <option value="GET">GET</option>
        <option value="POST">POST</option>
        <option value="PUT">PUT</option>
        <option value="PATCH">PATCH</option>
        <option value="DELETE">DELETE</option>
        <option value="HEAD">HEAD</option>
      </select>
    </div>
    <div class=" form-group">
      <!--TODO some JSON UI editor-->
      <label class=" form-control-label" for="httpHeader"> HTTP Header </label>
      <textarea class="form-control" id="httpHeader" name="httpHeader" [(ngModel)]="nodeHttpRequest.httpHeader" ></textarea>
    </div>
    <div class=" form-group" *ngIf="['POST', 'PUT', 'PATCH', 'DELETE'].includes(nodeHttpRequest.httpMethod)">
      <label class=" form-control-label" for="payload"> Payload </label>
      <textarea class="form-control" id="payload" name="payload" [(ngModel)]="nodeHttpRequest.payload" ></textarea>
    </div>
    <div class=" form-group">
      <label class=" form-control-label" for="responseStorageVariable"> Response Storage Variable </label>
      <select class="form-control" id="responseStorageVariable" name="responseStorageVariable" [(ngModel)]="nodeHttpRequest.responseStorageVariable" required>
        <option *ngFor="let globalVariable of globalVariables" [ngValue]="globalVariable.slug" >{{ globalVariable.label }}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-outline" type="submit" [disabled]="!form.valid">Save</button>
    <button class="btn btn-primary-outline" type="button" (click)="modal.hide()">CLOSE</button>
  </div>
</form>

