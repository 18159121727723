<ng-container *ngIf="loading">
  <div class="container-fluid">
    <div class="row align-items-center py-4 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading">
  <div class="container-fluid">
    <app-action-progress></app-action-progress>
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="card m-4">
          <div class="card-header d-flex align-items-center w-100 justify-content-lg-between">
            <div class="d-flex" style="padding-left: 20px">
              <h3 style="padding-top: 5px">Users</h3>
              <button
                class="btn btn-outline-success btn-sm btn-circle ml-4"
                *ngIf="canWriteUsers()"
                (click)="openAddUserModal()"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="userSearch">Search for User</label>
                <input
                  type="text"
                  class="form-control"
                  id="userSearch"
                  placeholder="Search for User"
                  [(ngModel)]="searchString"
                  name="userSearch"
                  (ngModelChange)="searchStringChange($event)"
                />
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive" *ngIf="users && users.length">
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">Avatar</th>
                    <th scope="col" class="sortable-pointer"><a (click)="fullNameSort()">Full Name</a></th>
                    <th scope="col" class="sortable-pointer"><a (click)="emailSort()">Email</a></th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr *ngFor="let user of users">
                    <td>
                      <a class="avatar rounded-circle bg-white" href="javascript:void(0)">
                        <img class="h-100" alt="User Avatar" *ngIf="user.logo" [src]="user.avatar" />
                      </a>
                    </td>
                    <td>
                      <h4>
                        {{ user.fullName }}
                      </h4>
                    </td>
                    <td>
                      <h4>
                        {{ user.email }}
                      </h4>
                    </td>
                    <td>
                      <i
                        class="fa fa-users text-info ml-3 cursor-pointer"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Manage Association"
                        (click)="openManageUserAssociationModal(user)"
                      >
                      </i>
                      <i
                        class="fas fa-edit text-info ml-3 cursor-pointer"
                        (click)="openEditUserModal(user)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit User"
                      ></i>
                      <i
                        *ngIf="canWriteUsers()"
                        class="fas fa-trash text-danger ml-3 cursor-pointer"
                        (click)="confirmAndDeleteUser(user)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete User"
                      >
                      </i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 *ngIf="!users || users.length === 0">No users.</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
