<ng-container *ngIf="isLoading">
  <div class="d-flex mt-3 justify-content-center">
    <app-loading></app-loading>
  </div>
</ng-container>

<ng-container *ngIf="currentStep === 'SHOW_RECALL_CAMPAIGNS' && !isLoading">
  <div class="d-flex w-100 flex-column">
    <div class="table-responsive"></div>
    <div class="d-flex w-100 flex-row-reverse">
      <button type="button" class="btn btn-primary" (click)="addNewCampaign($event)" style="max-width: 200px">
        <span aria-hidden="true">Create New Campaign</span>
      </button>
    </div>
  </div>
</ng-container>

<ng-container>
  <div class="d-flex w-100 flex-column" style="margin-top: 30px">
    <div class="table-responsive" style="overflow: hidden !important">
      <div class="table-responsive" class="mat-elevation-z8">
        <table mat-table [dataSource]="recallCampaigns" matSort style="overflow: hidden">
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>createdAt</th>
            <td mat-cell *matCellDef="let element">{{ element.createdAt }}</td>
          </ng-container>
          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>userName</th>
            <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
          </ng-container>
          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>startDate</th>
            <td mat-cell *matCellDef="let element">{{ element.startDate }}</td>
          </ng-container>
          <ng-container matColumnDef="maxBatch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>maxBatch</th>
            <td mat-cell *matCellDef="let element">{{ element.maxBatch }}</td>
          </ng-container>
          <ng-container matColumnDef="fileName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>fileName</th>
            <td mat-cell *matCellDef="let element">{{ element.fileName }}</td>
          </ng-container>
          <ng-container matColumnDef="customersCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>customersCount</th>
            <td mat-cell *matCellDef="let element">{{ element.customersCount }}</td>
          </ng-container>
          <ng-container matColumnDef="numberOfUsers">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Number Of Users</th>
            <td mat-cell *matCellDef="let element">{{ element.numberOfUsers }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[5]" aria-label="Select page of users"></mat-paginator>
    </div>

    <div class="d-flex w-100 flex-row">
      <button type="button" class="btn btn-danger" (click)="goBackCampaign($event)" style="max-width: 200px">
        <span aria-hidden="true">Back</span>
      </button>
    </div>
  </div>
</ng-container>
