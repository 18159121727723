<div class=" row justify-content-center mb-4">
  <div
    class="profile-image rounded-circle border"
    (click)="editable && openAvatarPickerModal()"
    [ngClass]="{
      editable: editable,
      'bg-transparent': crtSource,
      'bg-light': !crtSource
    }"
    [style.width]="scaledAvatarWidth"
    [style.height]="scaledAvatarHeight"
  >
    <img class="h-100 w-100 rounded-circle" *ngIf="crtSource" [src]="crtSource" />
    <i *ngIf="editable" class="editable fas fa-edit text-white"></i>
    <i *ngIf="editable && crtSource" class="remove fas fa-times text-white p-2" (click)="handleRemove($event)"></i>
  </div>
</div>
