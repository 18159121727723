<div class="bs-modal modal-content">
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">      
        <div class="form-group" *ngFor="let t of specTemplates">
            <span style='font-size: 12px;' [innerHtml]="getTemplateHint(t)"></span>
            <div class="input-group">
            <textarea [ngModel]="contents[t.key]" (ngModelChange)="contents[t.key] = $event" class="form-control">{{t.content}}</textarea>                
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary btn-sm" type="button" (click)="saveSpecTemplates()">Save</button>
    </div>
</div>