import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { AuthService } from 'src/app/services/auth.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import {
  BotsService,
  CorpsService,
  NodesService,
  VersionService,
  HierarchyElementsService,
} from 'src/app/services/firestore';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { ActivatedRoute } from '@angular/router';
import { getSidebarItems, getBreadcrumbItems } from '../utils';
import { combineLatest, Subscription, Observable } from 'rxjs';
import { BotModel, GlobalVariable } from 'src/app/models/bot';
import { CorpModel } from 'src/app/models/corp';
import { NodeModel } from 'src/app/models/node';
import { HierarchyElementModel } from 'src/app/models';
import { FlowEditor } from '../../../../components/flow/flow-editor/flow-editor.types';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { ClientEnvironmentModel } from 'src/app/models/client-environment';
import { take } from 'rxjs/operators';
import { FlowEditorEventsDispatcher } from 'src/app/components/flow/flow-editor/events/flow-editor-events-dispatcher';
import { FlowEditorEvents } from 'src/app/components/flow/flow-editor/events/flow-editor.events';

@Component({
  selector: 'app-bot-flow',
  templateUrl: './bot-flow.component.html',
  styleUrls: ['./bot-flow.component.scss'],
})
export class BotFlowComponent extends FlowEditorEventsDispatcher implements OnInit, OnDestroy, FlowEditor {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  private dataSubscription: Subscription;
  private flowEditorEventsSubscription: Subscription;

  corp: CorpModel;
  hierarchyElement: HierarchyElementModel;
  clientEnvironment: ClientEnvironmentModel | null;
  bot: BotModel;
  nodes: NodeModel[] = [];
  isDevelopmentEnviroment = true;
  canUserManage: boolean;
  private hasChanges: boolean;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private corpsService: CorpsService,
    private botsService: BotsService,
    private nodesService: NodesService,
    private authService: AuthService,
    private headerService: HeaderService,
    private hierarchyElementsService: HierarchyElementsService,
    private versionService: VersionService,
    private clientEnvironmentService: ClientEnvironmentService,
    private flowEditorEvents: FlowEditorEvents,
  ) {
    super();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges;
  }

  async ngOnInit() {
    this.flowEditorEventsSubscription = this.flowEditorEvents.editorEvents$.subscribe(flowEditorEvent => {
      this.handleFlowEditorEvent(flowEditorEvent);
    });
    this.paramMapSubscription = combineLatest([
      this.route.paramMap,
      this.authService.currentUser,
      this.clientEnvironmentService.items$,
    ]).subscribe(([params, user, envs]) => {
      const corpId = params.get('corp');
      const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
      const botCode = params.get('bot');
      if (!corpId || !hierarchyElementSystemName || !botCode || !user || !envs) {
        return;
      }
      this.crtDataSubscription = combineLatest([
        this.corpsService.getCorpById(corpId),
        this.hierarchyElementsService.getHierarchyElement(`${corpId}-${hierarchyElementSystemName}`),
        this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, envs),
        this.clientEnvironmentService.items$,
      ]).subscribe(async ([corp, hierarchyElement, bot, clientEnv]) => {
        if (!corp || !hierarchyElement || !clientEnv) {
          return;
        }

        try {
          if (bot) {
            this.bot = bot;
          }
        } catch (error) {
          throw new Error('Valid bot config required');
        }

        const nodes = await this.nodesService.getNodesByBotId(this.bot.id).pipe(take(1)).toPromise();
        if (!nodes) {
          return;
        }

        this.clientEnvironment = clientEnv;
        this.isDevelopmentEnviroment = clientEnv.selectedStage.systemName === 'development';
        this.corp = corp;
        this.hierarchyElement = hierarchyElement;
        this.nodes = nodes;
        this.refreshUI();

        const isAdmin = this.authService.hasPermissionSync(Permissions.IS_ADMIN);
        if (!this.bot.apiGatewayServiceId) {
          const errorMessage = isAdmin
            ? 'Bot missing API Gateway Service ID.'
            : 'Oops...something went wrong. Please contact a Impel admin. Error Code: 000-000-001';
          this.toaster.error(errorMessage);
        }
      });
    });
  }

  createNode(nodeToCreate: NodeModel) {
    let node;

    /*
      If the node to create is provided will be used that one,
      otherwise will be created a new one
     */
    if (nodeToCreate) {
      node = nodeToCreate;
    } else {
      node = NodeModel.generateDefault();
      node.name = `Node ${node.id.slice(-4)}`;
      node.botId = this.bot.id;
      NodeModel.generateSystemName(node);
    }

    this.nodes.push(node);
    if (this.isDevelopmentEnviroment) {
      this.nodesService
        .addNode(node)
        .then(() => {
          this.toaster.success('Created Node');
        })
        .catch(error => {
          this.toaster.error(error);
        });
    } else {
      this.toaster.warning('Allowed to update only on the Development environment');
    }
  }

  editorHasChanges(hasChanges: boolean) {
    this.hasChanges = hasChanges;
  }

  updateNode(node: NodeModel) {
    if (this.isDevelopmentEnviroment) {
      this.nodesService.updateNode(node, true);
    } else {
      this.toaster.warning('Allowed to update only on the Development environment');
    }
  }

  deleteNode(node: NodeModel) {
    if (this.isDevelopmentEnviroment) {
      this.nodesService
        .removeNode(this.bot.id, node.id)
        .then(() => {
          this.toaster.success('Removed Node');
        })
        .catch(error => {
          this.toaster.error(error);
        });
    } else {
      this.toaster.warning('Allowed to update only on the Development environment');
    }
  }

  saveGlobalVariables(globalVariables: GlobalVariable[]) {
    this.bot.globalVariables = globalVariables;
    this.botsService.updateBot(this.bot);
  }

  private refreshUI() {
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot.code);
    this.headerService.setPageTitle(`${this.bot.label} Flow`);
  }

  canManageBotFlow(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_MANAGE_BOT_FLOW);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, bot, 'Flow', 'flow'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement, botCode));
  }

  async saveChanges() {
    this.toaster.success('Saved to version history.');
    this.versionService.addNewVersion(this.bot, 'Flow updated.');
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.flowEditorEventsSubscription) {
      this.flowEditorEventsSubscription.unsubscribe();
    }
  }
}
