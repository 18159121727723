import { Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import * as firebaseAuth from 'firebase/auth';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as firestore from 'firebase/firestore';
import { combineLatest, Subscription } from 'rxjs';
import dayjs from 'dayjs';
import { AuthService } from 'src/app/services/auth.service';
import { BotModel } from 'src/app/models/bot';
import { CorpModel } from 'src/app/models/corp';
import { HierarchyElementModel, InputValidationModel } from 'src/app/models';
import { BotsService, FlowTemplatesService, InputValidationsService, NodesService } from 'src/app/services/firestore';
import { FlowTemplateModel, FlowTemplateType } from 'src/app/models/flow-template';
import { SimpleListItem } from 'src/app/components/lists/simple-list-item/_types/SimpleListItem';
import { ButtonType } from 'src/app/components/common/_types/ButtonType';
import { NodeModel } from 'src/app/models/node';
import { take, flatMap, map } from 'rxjs/operators';
import { Observable, of, from } from 'rxjs';
import { DeleteFlowTemplateModalComponent } from '../delete-flow-template-modal/delete-flow-template-modal.component';
import { v4 as uuidv4 } from 'uuid';
import { UploadService } from 'src/app/services/upload.service';
import { ImportFlowTemplateModalComponent } from '../import-flow-template-modal/import-flow-template-modal.component';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';

interface ISeperatedFlow {
  label: string;
  nodes: NodeModel[];
}

const ENTIRE_FLOW_LABEL = 'Entire Flow';

@Component({
  selector: 'app-base-flow-templates',
  templateUrl: './base-flow-templates.component.html',
  styleUrls: ['./base-flow-templates.component.scss'],
})
export class BaseFlowTemplatesComponent implements OnInit, OnDestroy {
  private crtDataSubscription: Subscription;
  private user: firebaseAuth.User;
  hierarchyElement: HierarchyElementModel;
  loading: boolean;
  sourceBot = '';
  entireFlowLabel = ENTIRE_FLOW_LABEL;
  selectedCorp = '';

  BOT_TEMPLATE = FlowTemplateType.BOT_TEMPLATE;
  FLOW_TEMPLATE = FlowTemplateType.FLOW_TEMPLATE;

  _flowTemplates: FlowTemplateModel[];
  _corp: CorpModel;

  get flowTemplates(): FlowTemplateModel[] {
    return this._flowTemplates;
  }

  @Input()
  set flowTemplates(flowTemplates: FlowTemplateModel[]) {
    this._flowTemplates = flowTemplates;
    setTimeout(() => {
      this.morphFlowTemplatesIntoItems(flowTemplates);
    }, 200);
  }

  get corp(): CorpModel {
    return this._corp;
  }

  @Input()
  set corp(corp: CorpModel) {
    this._corp = corp;
    this.getBotsInCorps();
  }

  @Input()
  corps: CorpModel[] = [];

  @Input()
  isGlobal: boolean;

  flowTemplate = new FlowTemplateModel();
  bots: BotModel[] = [];
  seperatedFlows: ISeperatedFlow[];
  findFlowMethod = true;
  selectedFlowLabel: string | null = '';
  selectedFlow: ISeperatedFlow = { label: '', nodes: [] };
  selectedNode: string | null;
  imageFile: File | null;
  items: SimpleListItem[] = [];
  nodes: NodeModel[] = [];

  private uploadSubscription: Subscription;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private botsService: BotsService,
    private flowTemplatesService: FlowTemplatesService,
    private authService: AuthService,
    private modalService: BsModalService,
    private router: Router,
    private toaster: ToastrService,
    private uploadService: UploadService,
    private inputValidationsService: InputValidationsService,
    private nodesService: NodesService,
  ) {}

  ngOnInit() {
    this.crtDataSubscription = combineLatest([this.authService.currentUser]).subscribe(
      ([user]) => {
        if (!user) {
          return;
        }
        this.user = user;
      },
      error => {
        this.toaster.error(error);
      },
    );
  }

  async getNodesInBot() {
    this.nodes = await this.nodesService.getNodesByBotId(this.sourceBot).pipe(take(1)).toPromise();
    this.seperateNodesIntoFlows();
    this.selectedFlow = { label: '', nodes: [] };
    this.selectedNode = null;
    if (this.flowTemplate.templateType === FlowTemplateType.BOT_TEMPLATE) {
      this.selectAFlow();
    }
  }

  private async getBotsInCorps() {
    this.bots = await this.botsService.getUserAllowedBotsInCorps(this.user.uid, this.corp.id);
  }

  async importTemplate(flowTemplate: FlowTemplateModel) {
    const sourceBotId = flowTemplate.getBotConfig?.id;
    const bots = this.bots.filter(({ id }) => id !== sourceBotId);
    this.modalService.show(ImportFlowTemplateModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        flowTemplate,
        bots,
        corps: this.corps,
        userId: this.user.uid,
        isGlobal: this.isGlobal,
      },
    });
  }

  async saveFlow() {
    if (!this.selectedFlow.label) {
      this.toaster.error('Please Select A Flow Before Proceeding');
      return;
    }
    if (this.selectedFlow.nodes.length === 0) {
      this.toaster.error('Please Select A Flow With Nodes');
      return;
    }
    if (this.selectedFlow.nodes.some(({ flowTemplateId }) => flowTemplateId)) {
      this.toaster.error('One of your nodes has a derived flow node');
      return;
    }
    this.actionProgress.start();
    this.loading = true;
    const bot = this.bots.find(({ id }) => id === this.sourceBot);
    if (bot) {
      // POT
      this.flowTemplate.sourceBot = JSON.stringify({ ...bot, id: bot.id });
    }
    if (this.isGlobal) {
      this.flowTemplate.global = true;
    } else {
      this.flowTemplate.corpId = this.corp.id;
    }

    let validationsToExport: InputValidationModel[] = [];

    this.flowTemplate.systemName = FlowTemplateModel.generateSystemName(this.flowTemplate);
    const selectedFlowNodes = this.selectedFlow.nodes.map(node => ({ ...node, botId: this.flowTemplate.systemName }));

    const validationsIds = selectedFlowNodes
      .filter(({ validations = [] }) => validations.length > 0)
      .flatMap(({ validations = [] }) => validations.map(({ validationId }) => validationId));

    if (validationsIds.length > 0) {
      const corpValidations = await this.inputValidationsService
        .getInputValidationsByCorpId(this.corp.id)
        .pipe(take(1))
        .toPromise();
      validationsToExport = corpValidations.filter(({ id }) => validationsIds.includes(id));
    }

    this.flowTemplate.numberOfNodes = this.selectedFlow.nodes.length;
    this.flowTemplate.lastTouchedBy = this.user.uid;
    this.flowTemplate.createdAt = firestore.serverTimestamp();
    this.flowTemplate.updatedAt = firestore.serverTimestamp();
    this.flowTemplate.lastTouchedHash = uuidv4();

    const uploadObservable: Observable<void | null> = this.imageFile
      ? this.uploadService.upload('flow-template/' + this.flowTemplate.systemName + '/image', this.imageFile).pipe(
          map(downloadUrl => {
            if (!this.flowTemplate) {
              return;
            }
            this.flowTemplate.image = downloadUrl;
          }),
        )
      : of(null);

    this.uploadSubscription = uploadObservable
      .pipe(
        flatMap(async () => {
          if (!this.flowTemplate) {
            return of();
          }
          return from([
            await this.flowTemplatesService.addFlowTemplate(this.flowTemplate, selectedFlowNodes, validationsToExport),
          ]);
        }),
      )
      .subscribe(
        () => this.handleSaveFlowSuccessfullyCompleted(),
        error => {
          this.loading = false;
          this.actionProgress.complete();
          this.toaster.error(error);
        },
      );
  }

  private handleSaveFlowSuccessfullyCompleted() {
    this.loading = false;
    this.actionProgress.complete();
    this.toaster.success('Template Created Successfully');
    this.flowTemplate = new FlowTemplateModel();
    this.clearFormSelection();
  }

  private clearFormSelection() {
    this.selectedFlow = { label: '', nodes: [] };
    this.sourceBot = '';
    this.selectedFlowLabel = '';
    this.selectedNode = '';
  }

  removeTemplate(flowTemplate: FlowTemplateModel) {
    this.modalService.show(DeleteFlowTemplateModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        flowTemplate,
      },
    });
  }

  private morphFlowTemplatesIntoItems(flowTemplates: FlowTemplateModel[]) {
    this.items = flowTemplates.map(flowTemplate => ({
      title: flowTemplate.label,
      dataPoints: [
        {
          label: 'Template Type',
          value: flowTemplate.getTemplateTypeAsLabel,
          className: 'simple-list-item-data-point--sm',
        },
        {
          label: 'Number of nodes',
          value: '' + flowTemplate.numberOfNodes,
          className: 'simple-list-item-data-point--sm',
        },
        {
          label: 'Created At',
          value: dayjs.unix(flowTemplate.updatedAt.seconds).format('MM-DD-YYYY hh:mm A'),
          className: 'simple-list-item-data-point--sm',
        },
      ],
      buttons: [
        {
          label: 'Nodes',
          type: ButtonType.primary,
          action: () => {
            if (this.isGlobal) {
              this.router.navigate([`/portal/global-flow-templates/${flowTemplate.systemName}/nodes/editor`]);
            } else {
              this.router.navigate([
                `/portal/corps/${this.corp.id}/flow-templates/${flowTemplate.systemName}/nodes/editor`,
              ]);
            }
          },
        },
        {
          label: 'API Queries',
          type: ButtonType.primary,
          action: () => {
            if (this.isGlobal) {
              this.router.navigate([`/portal/global-flow-templates/${flowTemplate.systemName}/api-queries`]);
            } else {
              this.router.navigate([
                `/portal/corps/${this.corp.id}/flow-templates/${flowTemplate.systemName}/api-queries`,
              ]);
            }
          },
        },
        {
          label: 'Import',
          type: ButtonType.primary,
          action: () => {
            this.importTemplate(flowTemplate);
          },
          disabled: FlowTemplateModel.isBotTemplate(flowTemplate),
        },
        {
          label: 'Delete',
          type: ButtonType.danger,
          action: () => {
            this.removeTemplate(flowTemplate);
          },
        },
      ],
    }));
  }

  findFlowContainingNode() {
    if (!this.selectedNode) {
      // clear nodes
      this.selectedFlow.nodes = [];
      this.selectedFlow.label = '';
      return;
    }
    const selectedNode = this.selectedNode;
    const selectedFlow = this.seperatedFlows
      .filter(({ label }) => label !== ENTIRE_FLOW_LABEL)
      .find(({ nodes }) => nodes.map(({ id }) => id).includes(selectedNode));

    if (!selectedFlow) {
      // Means the algorithmn is not working :facepalm, I will like an alert
      return;
    }
    this.selectedFlow = selectedFlow;
    this.selectedFlowLabel = selectedFlow.label;
  }

  private mergeConnectedFlows() {
    this.seperatedFlows = this.seperatedFlows.reduce((seperatedFlows: ISeperatedFlow[], currentFlow) => {
      const nodeConnectionNodeIds = currentFlow.nodes.flatMap(({ connections }) => {
        if (!connections) {
          return [];
        }
        return connections.map(({ id }) => id);
      });
      const templateConnectionNodeIds = currentFlow.nodes.flatMap(({ templateNodeConnections }) => {
        if (!templateNodeConnections) {
          return [];
        }
        return templateNodeConnections;
      });
      const validationsConnectionNodeIds = currentFlow.nodes.flatMap(({ validations }) => {
        if (!validations) {
          return [];
        }
        return validations.flatMap(({ fallbackRetryNodeId, fallbackRedirectNodeId }) => {
          return [fallbackRetryNodeId, fallbackRedirectNodeId];
        });
      });

      const allConnectionNodeIds = [
        ...new Set([...validationsConnectionNodeIds, ...templateConnectionNodeIds, ...nodeConnectionNodeIds]),
      ];

      const index = seperatedFlows.findIndex(({ nodes }) => {
        const allNodeIds = nodes.map(({ id }) => id);
        return allConnectionNodeIds.some(nodeId => allNodeIds.includes(nodeId));
      });
      if (index > -1) {
        seperatedFlows[index].nodes = [...seperatedFlows[index].nodes, ...currentFlow.nodes];
        return seperatedFlows;
      }
      return [...seperatedFlows, currentFlow];
    }, []);
  }

  private seperateNodesIntoFlows() {
    this.seperatedFlows = this.nodes.reduce((seperatedFlows: ISeperatedFlow[], currentNode) => {
      const index = seperatedFlows.findIndex(({ nodes }) => {
        return nodes.some(node => {
          if (node.connections) {
            const present = node.connections.some(({ id }) => {
              return id === currentNode.id;
            });
            if (present) {
              return true;
            }
          }

          if (node.validations) {
            const present = node.validations.some(({ fallbackRetryNodeId, fallbackRedirectNodeId }) => {
              return [fallbackRetryNodeId, fallbackRedirectNodeId].includes(currentNode.id);
            });
            if (present) {
              return true;
            }
          }

          if (node.templateNodeConnections) {
            return node.templateNodeConnections.some(templateConnection => {
              return templateConnection === currentNode.id;
            });
          }

          return false;
        });
      });
      if (index > -1) {
        seperatedFlows[index].nodes.push(currentNode);
        return seperatedFlows;
      }
      return [...seperatedFlows, { label: `Flow ${seperatedFlows.length + 1}`, nodes: [currentNode] }];
    }, []);
    for (let roundTrips = 1; roundTrips < Math.floor(Math.sqrt(this.nodes.length)); roundTrips++) {
      this.mergeConnectedFlows();
    }
    this.seperatedFlows.unshift({ label: ENTIRE_FLOW_LABEL, nodes: this.nodes });
  }

  selectAFlow() {
    const selectedFlow = this.seperatedFlows.find(({ label }) => label === this.selectedFlowLabel);
    this.selectedNode = '';
    if (selectedFlow) {
      this.selectedFlow = selectedFlow;
      this.selectedNode = selectedFlow.nodes[0].id;
      if (this.selectedFlow.nodes.some(({ flowTemplateId }) => flowTemplateId)) {
        this.toaster.error('You will not be able to save this flow because it has a derived flow node');
      }
    }
  }

  onTemplateTypeChange() {
    if (this.flowTemplate.templateType === FlowTemplateType.BOT_TEMPLATE) {
      this.selectedFlowLabel = ENTIRE_FLOW_LABEL;
      this.selectAFlow();
    } else {
      if (this.selectedFlow && this.selectedFlowLabel !== this.entireFlowLabel) {
        this.selectedFlowLabel = this.selectedFlow.label;
      } else {
        this.selectedFlowLabel = '';
      }
      // reload flow templates
      this.seperateNodesIntoFlows();
      this.selectAFlow();
      this.findFlowContainingNode();
    }
  }

  onCorpSelection() {
    const corp = this.corps.find(({ id }) => id === this.selectedCorp);
    if (corp) {
      this._corp = corp;
      this.getBotsInCorps();
      this.seperatedFlows = [];
    }
    this.clearFormSelection();
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
    }
  }
}
