<div class="card mb-lg-2">
  <div class="row m-2">
    <div class="d-flex flex-grow-1 my-auto">
      <h5 class="card-title text-lowercase text-muted mb-0">{{ cardItem.text }}</h5>
    </div>

    <div class="d-flex">
      <button
        [className]="'btn btn-sm btn-' + (button.type ? button.type : 'secondary')"
        *ngFor="let button of cardItem.buttons"
        (click)="executeButtonActions(button)"
      >
        {{ button.label }}
      </button>
    </div>
  </div>
</div>
