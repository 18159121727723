import { SimpleCardItem } from 'src/app/components/cards/simple-card/_types/SimpleCardItem';
import { SimpleCardItemButton } from 'src/app/components/cards/simple-card/_types/SimpleCardItemButton';

export class FileCardItem extends SimpleCardItem {
  datasetId: string;

  constructor(id: string, datasetId: string, text: string, buttons: SimpleCardItemButton[]) {
    super(id, text, buttons);
    this.datasetId = datasetId;
  }
}
