import { CorpModel, BotModel, CorpHierarchyModel, HierarchyElementModel } from 'src/app/models';

interface IUserAssociationInformationBag {
  corp: CorpModel;
  hierarchyElement?: HierarchyElementModel | null;
  bot?: BotModel | null;
  childrenName?: string;
  corpHierarchies?: CorpHierarchyModel[];
}

export class UserAssociation {
  label: string;
  childrenName: string;
  systemName: string;
  hasChildren = true;
  corp: CorpModel;
  hierarchyElement?: HierarchyElementModel;
  bot?: BotModel;
  isSelected: boolean;
  isMember: boolean;

  constructor(
    blockAssociationIds: string[],
    { corp, hierarchyElement, bot, corpHierarchies, childrenName }: IUserAssociationInformationBag
  ) {
    this.corp = corp;
    if (bot) {
      this.bot = bot;
      this.label = bot.label;
      this.isMember = blockAssociationIds.includes(bot.code);
    } else if (hierarchyElement) {
      this.childrenName = `${childrenName}`;
      this.label = hierarchyElement.label;
      this.systemName = hierarchyElement.systemName;
      this.hierarchyElement = hierarchyElement;
      this.isMember = blockAssociationIds.includes(hierarchyElement.systemName);
    } else if (corp) {
      this.label = corp.label;
      this.systemName = corp.id;
      this.isMember = blockAssociationIds.includes(corp.id);
      if (corpHierarchies && corpHierarchies.length > 0) {
        this.childrenName = corpHierarchies[0].label;
      } else {
        this.hasChildren = false;
      }
    }
  }

  toogleAssociation() {
    this.isMember = !this.isMember;
  }
}
