<div class="container-fluid bot-human-loop-component-wrapper">
  <div *ngIf="loading">
    <div class="container-fluid">
      <div class="row align-items-center py-4 justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>

  <ng-container>
    <div *ngIf="!loading">
      <div *ngIf="mode !== 'None'">
        <div class="card-deck flex-column flex-xl-row mt-4">
          <app-hl-conversations-list
            #conversationsList
            class="card conversations-list col-lg-3 col-md-3"
            [conversationsLastMessage]="conversationsLastMessage"
            [conversations]="searchMode ? conversationSearchResult : conversations"
            (filterConversation)="filterConversation($event)"
            (clearFilter)="clearConversationFilter($event)"
            (conversationSelected)="refreshConversation($event)"
            (searchConversations)="searchConversations($event)"
            (loadMore)="loadMoreConversations($event)"
            [loadingTop]="loadingMoreTop"
            [loadingBottom]="loadingMoreBottom"
            [clearSearch]="searchMode"
            [selectedConversationId]="selectedConversationId"
          >
          </app-hl-conversations-list>

          <app-hl-conversation
            #conversationView
            [loadingMessages]="loadingMessages"
            [currentMessages]="currentMessages"
            [message]="message"
            [humanTemplates]="humanTemplates"
            [corpTags]="corpTags"
            (send)="sendMessage($event)"
            (assignUser)="assignAgent()"
            (stopBot)="stopBotMessages($event)"
            (endHandover)="endBotHandover($event)"
            (savedTemplate)="saveTemplate($event)"
            (deleteTemplate)="deleteTemplate($event)"
            (selectNode)="getBotNodesForSupport()"
            (savedTags)="saveConversationTags($event, selectedConversationId)"
            (loadMoreMessages)="loadMoreMessages($event)"
            (channelNameEmitter)="receiveSelectedUserResponseChannel($event)"
            [loadingMoreTop]="loadingMessagesTop"
            [loadingMoreBottom]="loadingMessagesBottom"
            [conversationsTags]="conversationsTags"
            [conversation]="selectedConversation"
            [selectedConversationId]="selectedConversationId"
            [conversationsLastMessage]="conversationsLastMessage"
            class="col-lg-6 col-md-6"
          >
          </app-hl-conversation>

          <app-hl-conversation-sidebar
            (removeTag)="removeConversationTag($event)"
            [selectedConversationId]="selectedConversationId"
            [conversation]="selectedConversation"
            [tags]="selectedConversation?.tags || []"
            [userName]="currentConversationUserName"
            [serviceReps]="assignedUsers"
            [botUrl]="botUrl"
            [updatedAt]="conversationUpdatedAt"
            class="card col-lg-3 col-md-3 p0"
            style="height: fit-content"
          ></app-hl-conversation-sidebar>
        </div>
      </div>
    </div>
  </ng-container>
</div>
