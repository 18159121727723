<nav
  class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-dark sidenav-bg"
  id="sidenav-main"
  (mouseover)="onMouseEnterSidenav()"
  (mouseout)="onMouseLeaveSidenav()"
>
  <div class="scrollbar-inner">
    <div class="sidenav-header d-flex align-items-center">
      <a class="navbar-brand" [routerLink]="['/portal/dashboard']">
        <!-- <img src="../../../assets/img/brand/carlabs_logo_txt.jpg" class="navbar-brand-img" alt="..." /> -->
        <div class="navbar-brand-logo">Impel.<span>ai</span></div>
      </a>
      <div class="ml-auto">
        <!-- Sidenav toggler -->
        <div
          class="sidenav-toggler d-none d-xl-block"
          data-action="sidenav-unpin"
          data-target="#sidenav-main"
          (click)="minimizeSidebar()"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="navbar-inner">
      <div class="collapse navbar-collapse" id="sidenav-collapse-main">
        <!-- Collapse header -->
        <ul class="navbar-nav" *ngIf="items">
          <li *ngFor="let menuitem of items" class="nav-item">
            <!--If is a single link-->
            <a
              [routerLink]="[menuitem.path]"
              [attr.data-test-id]="menuitem.testId"
              *ngIf="menuitem.type === 'link'"
              class="nav-link"
            >
              <i class="ni {{ menuitem.icontype }}"></i>
              <span class="nav-link-text">{{ menuitem.title }}</span>
            </a>

            <!--If it have a submenu-->
            <a
              data-toggle="collapse"
              routerLinkActive="active"
              *ngIf="menuitem.type === 'sub'"
              (click)="menuitem.isCollapsed = !menuitem.isCollapsed"
              [attr.aria-expanded]="!menuitem.isCollapsed"
              [attr.aria-controls]="menuitem.collapse"
              class="nav-link"
            >
              <i class="ni {{ menuitem.icontype }}"></i>
              <span class="nav-link-text">{{ menuitem.title }}</span>
            </a>
          </li>
        </ul>

        <!-- Divider -->
        <hr *ngIf="isAdmin()" class="my-3" />

        <!-- Navigation -->
        <ul class="navbar-nav" *ngIf="isAdmin()">
          <li class="nav-item" *ngIf="!onPortalSide">
            <a class="nav-link" href="/portal">
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text" data-test-id="portal-link">Portal</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="onPortalSide">
            <a class="nav-link" href="/admin">
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text">Admin</span>
            </a>
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-3" />

        <!-- Heading -->
        <h6 class="navbar-heading p-0 text-muted">Support</h6>

        <!-- Navigation -->
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a class="nav-link" href="https://impel.ai/" target="_blank">
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text">Impel Website</span>
            </a>
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-3" />

        <div class="d-flex justify-content-center sidenav-copyright">
          <!-- Heading -->
          <h6 class="p-0 text-muted">
            <i class="far fa-copyright"></i> {{ this.currentYear }} Impel. All rights reserved
          </h6>
        </div>
      </div>
    </div>
  </div>
</nav>
