<div class="modal-header">
  <h4 class="modal-title">Add Facebook Page</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [ngClass]="loading ? 'disabled' : ''">
  <form (submit)="addPage($event)" #form="ngForm" *ngIf="!pageAccessToken" novalidate>
    <fieldset class="container">
      <div class="form-group row">
        <label class="form-control-label" for="appId">
          App ID
        </label>
        <input
          class="form-control"
          name="appId"
          [(ngModel)]="appId"
          type="text"
          placeholder="App ID"
          required
        />
      </div>
      <div class="form-group row">
        <label class="form-control-label" for="appSecret">
          App Secret
        </label>
        <input
          class="form-control"
          name="appSecret"
          [(ngModel)]="appSecret"
          type="text"
          placeholder="App Secret"
          required
        />
      </div>
      <div class="form-group row">
        <label class="form-control-label" for="pageId">
          Page ID
        </label>
        <input
          class="form-control"
          name="pageId"
          [(ngModel)]="pageId"
          type="text"
          placeholder="Page ID"
          required
        />
      </div>
      <div class="form-group row">
        <button
          class="btn btn-sm btn-primary btn-block"
          type="submit"
          [disabled]="form.invalid"
        >
          Next
        </button>
      </div>
    </fieldset>
    <button
      class="btn btn-sm btn-primary btn-outline"
      (click)="modal.hide()"
    >
      Cancel
    </button>
  </form>
  <form (submit)="save($event)" #form="ngForm" *ngIf="pageId && pageAccessToken" novalidate>
    <fieldset class="container">
      <div class="form-group row">
        <label class="form-control-label" for="appId">
          App ID
        </label>
        <input
          class="form-control"
          name="appId"
          [ngModel]="appId"
          type="text"
          disabled
          required
        />
      </div>
      <div class="form-group row">
        <label class="form-control-label" for="appSecret">
          App Secret
        </label>
        <input
          class="form-control"
          name="appSecret"
          [ngModel]="appSecret"
          type="text"
          disabled
          required
        />
      </div>
      <div class="form-group row">
        <label class="form-control-label" for="pageId">
          Page ID
        </label>
        <input
          class="form-control"
          name="pageId"
          [ngModel]="pageId"
          type="text"
          disabled
          required
        />
      </div>
      <div class="form-group row">
        <label class="form-control-label" for="pageId">
          Access Token
        </label>
        <input
          class="form-control"
          name="pageAccessToken"
          [ngModel]="pageAccessToken"
          type="text"
          disabled
          required
        />
      </div>
      <div class="form-group row">
        <label class="form-control-label" for="pageId">
          Pass Thread Control Target App ID
        </label>
        <input
          class="form-control"
          name="passThreadControlTargetAppId"
          [ngModel]="passThreadControlTargetAppId"
          type="text"
          disabled
          required
        />
      </div>
      <div class="form-group row">
        <button
          class="btn btn-sm btn-primary btn-block"
          type="submit"
          [disabled]="form.invalid"
        >
          Save
        </button>
      </div>
    </fieldset>
    <button
      class="btn btn-sm btn-primary btn-outline"
      (click)="modal.hide()"
    >
      Cancel
    </button>
  </form>
</div>
