import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, Subscription } from 'rxjs';
// tslint:disable-next-line:max-line-length
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { SidebarService } from '../../../../services/sidebar.service';
import { CorpsService, RolesService, UsersService } from 'src/app/services/firestore';
import { CorpModel, RoleModel, UserModel } from 'src/app/models';
import { ManageUserAssociationsComponent } from '../_components/manage-user-associations-modal/manage-user-associations-modal.component';
import { AddUserModalComponent } from '../_components/add-user-modal/add-user-modal.component';
import { EditUserModalComponent } from '../_components/edit-user-modal/edit-user-modal.component';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
import { getSidebarItems } from 'src/app/pages/admin/utils';
import { AuthService } from 'src/app/services/auth.service';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-users-admin',
  templateUrl: './users-admin.component.html',
  styleUrls: ['./users-admin.component.scss'],
})
export class UsersAdminComponent implements OnInit, OnDestroy {
  loading: boolean;
  users: UserModel[];
  leadUsers: UserModel[];
  corps: CorpModel[];
  roles: RoleModel[];
  dataSubscription: Subscription;

  searchString: string;

  fullNameDirection = 1;
  emailDirection = 1;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private corpsService: CorpsService,
    private usersService: UsersService,
    private rolesService: RolesService,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private modalService: BsModalService,
    private toaster: ToastrService,
    private sidebarService: SidebarService,
    private authService: AuthService,
    private fns: AngularFireFunctions,
  ) {
    this.loading = false;
  }

  ngOnInit() {
    this.setBreadcrumb();
    this.refreshCorps();
    this.setSidebarItems();
    this.headerService.setPageTitle('Users');
  }

  private refreshCorps() {
    this.loading = true;
    this.dataSubscription = combineLatest([
      this.usersService.getUsers(),
      this.corpsService.getCorps(),
      this.rolesService.getRoles(),
    ]).subscribe(
      ([users, corps, roles]) => {
        this.leadUsers = users;
        this.users = this.leadUsers;
        this.corps = corps.filter(x => !x.inactive);
        this.roles = roles;

        this.loading = false;
      },
      error => {
        this.loading = false;
        this.toaster.error(error);
      },
    );
  }

  openManageUserAssociationModal(user: UserModel) {
    this.modalService.show(ManageUserAssociationsComponent, {
      ignoreBackdropClick: true,
      initialState: {
        user,
        corps: this.corps,
        canWriteUsers: this.canWriteUsers(),
      },
    });
  }

  setBreadcrumb() {
    this.breadcrumbService.set([]);
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  openEditUserModal(user: UserModel) {
    const modalRef = this.modalService.show(EditUserModalComponent, { ignoreBackdropClick: true });
    modalRef.content.user = user;
    modalRef.content.roles = this.roles;
    modalRef.content.canWriteUsers = this.canWriteUsers();
  }

  openAddUserModal() {
    const modalRef = this.modalService.show(AddUserModalComponent, { ignoreBackdropClick: true });
    modalRef.content.roles = this.roles;
  }

  confirmAndDeleteUser(user: UserModel) {
    const modalRef = this.modalService.show(ConfirmationModalComponent, { ignoreBackdropClick: true });
    modalRef.content.title = 'Delete User';
    modalRef.content.message = `Are you sure you want to delete user ${user.firstName}?`;
    modalRef.content.confirm.subscribe(() => {
      this.deleteUser(user);
    });
  }

  canWriteUsers(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_WRITE_USERS);
  }

  private async deleteUser(user: UserModel) {
    try {
      this.actionProgress.start();

      const callable = this.fns.httpsCallable('api/deleteUser');
      const deleteUser$ = callable({ id: user.id, email: user.email });

      await this.usersService.removeUser(user.id);
      deleteUser$.toPromise();
      this.toaster.success('User deleted');
    } catch (error) {
      this.toaster.error(error);
    }
    this.actionProgress.complete();
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }

  searchStringChange(event) {
    this.processSearchString(event.toUpperCase());
  }

  processSearchString(st) {
    this.users = [...this.leadUsers];

    if (st !== '') {
      this.users = this.users.filter(
        x =>
          x.email.toUpperCase().includes(st) ||
          x.firstName?.toUpperCase().includes(st) ||
          x.lastName?.toUpperCase().includes(st) ||
          x.fullName.toUpperCase().includes(st),
      );
    }

    return this.users;
  }

  fullNameSort() {
    this.fullNameDirection *= -1;

    this.users = this.users.sort((x, y) =>
      `${x.firstName} ${x.lastName}`.toUpperCase() > `${y.firstName} ${y.lastName}`.toUpperCase()
        ? 1 * this.fullNameDirection
        : -1 * this.fullNameDirection,
    );
  }

  emailSort() {
    this.emailDirection *= -1;

    this.users = this.users.sort((x, y) =>
      `${x.email}`.toUpperCase() > `${y.email}`.toUpperCase() ? 1 * this.emailDirection : -1 * this.emailDirection,
    );
  }
}
