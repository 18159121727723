import { Component, Input } from '@angular/core';
import { FlowTemplateModel } from 'src/app/models';

@Component({
  selector: 'app-render-template-groups',
  templateUrl: './render-template-groups.component.html',
  styleUrls: ['./render-template-groups.component.scss']
})
export class RenderTemplateGroupsComponent {
  private _presentationTemplatesGroups: Array<FlowTemplateModel | null>[] = [];

  get presentationTemplatesGroups() {
    return this._presentationTemplatesGroups;
  }

  @Input()
  set templatesGroups(botTemplates: Array<FlowTemplateModel | null>) {
    const templatesGroups: Array<FlowTemplateModel | null>[] = [];

    const chunckSize = 4;
    while (botTemplates.length > chunckSize) {
      const currentChunk = botTemplates.slice(0, chunckSize);
      templatesGroups.push(currentChunk);
      botTemplates = botTemplates.slice(chunckSize);
    }

    if (botTemplates.length > 0) {
      const emptySlots = chunckSize - botTemplates.length;
      for (let i = 0; i < emptySlots; i++) {
        botTemplates.push(null);
      }
      templatesGroups.push(botTemplates);
    }
    this._presentationTemplatesGroups = templatesGroups;
  }

  formateDate(timestamp) {
    return timestamp.toDate().toString();
  }
}
