<ng-container *ngIf="loading">
  <div class="container-fluid">
    <div class="row align-items-center py-4 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!loading">
  <div *ngIf="botExists" class="container-fluid bot-settings-container">
    <div class="row h-100">
      <div class="col-md-6 h-100 overflow-auto">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 d-inline-block mb-0">Assistant Settings</h6>
          </div>
        </div>
        <div class="card p-3 m-2 mb-4" *ngIf="bot">
          <div class="row">
            <div class="col">
              <app-bot-general-info
                *ngIf="bot"
                [writable]="canEditBot()"
                [canSaveChanges]="canSaveChanges"
                [bot]="bot"
                (saved)="processSave()"
                (canceled)="navigateToParentScreen()"
                [botNameList]="botNameList"
              >
              </app-bot-general-info>
            </div>
          </div>
          <div class="d-flex flex-column pb-3">
            <div
              class="btn btn-outline-success mt-4"
              *ngIf="canGenerateBotEmbeddableCode()"
              (click)="showScriptCodeModal()"
            >
              GENERATE EMBEDDABLE CODE
            </div>
            <div
              data-test-id="delete-bot-button"
              class="btn btn-outline-danger mt-4"
              *ngIf="canDeleteBot()"
              (click)="confirmAndDeleteBot()"
            >
              DELETE {{ bot.label }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 h-100 overflow-auto">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7"></div>
        </div>
        <app-bot-web-chat class="w-100" [bot]="bot"></app-bot-web-chat>
      </div>
    </div>
  </div>

  <div *ngIf="!botExists">
    <div class="container-fluid">
      <div class="row align-items-center py-4 justify-content-center">
        <p>No bots exist in this environment.</p>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #scriptCodeModal>
  <div class="modal-header">
    <h4 class="modal-title">Embeddable Assistant Code</h4>
    <button type="button" class="close" aria-label="Close" (click)="scriptCodeBsModal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Use this code to embed the assistant on your website.</p>
    <ngx-codemirror
      [(ngModel)]="scriptCode"
      [options]="{
        lineNumbers: true,
        theme: 'material',
        mode: 'htmlmixed',
        readOnly: true
      }"
    ></ngx-codemirror>
  </div>
  <div class="modal-footer">
    <button
      ngxClipboard
      [cbContent]="scriptCode"
      (cbOnSuccess)="processCopySuccess()"
      class="btn btn-primary btn-outline"
    >
      COPY TO CLIPBOARD
    </button>
  </div>
</ng-template>
