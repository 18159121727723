<div class="modal-header">
    <h4 class="modal-title">Delete Multiple {{ typeOfEntity }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form (submit)="deleteEntity()" #form="ngForm" novalidate>
    <div class="modal-body pt-0 pb-0">
        <label class=" form-control-label" for="name"> Type The Name Of One Of The {{ typeOfEntity }} To Confirm <br />
            <code *ngFor="let entityValue of entityValues">{{entityValue}} <br /></code>
        </label>
        <input class="form-control" id="name" name="name" [(ngModel)]="entityNameInput" type="text"
            placeholder="Any Of The Items Above" required />
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary btn-outline" type="submit"
            [disabled]="!entityValues.includes(entityNameInput)">Delete</button>
        <button class="btn btn-primary-outline" type="button" (click)="modal.hide()">CLOSE</button>
    </div>
</form>