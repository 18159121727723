<div class="modal-header">
  <div>
    <h4 class="modal-title">Update Customer Settings</h4>
    <h5 *ngIf="header1 || header2">
      {{ header1 }}
      <br *ngIf="header1 && header2" />
      {{ header2 }}
    </h5>
  </div>
  <button [disabled]="modalIsLoading" type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="modalIsLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>

  <div *ngIf="!modalIsLoading" lass="form-group" ng-form="subForm">
    <div class="row">
      <div class="column" style="width: 150px">
        <label for="firstName" style="vertical-align: bottom"> First Name: </label>
      </div>
      <div class="column">
        <input
          class="form-control"
          style="height: 30px"
          type="text"
          id="firstName"
          name="firstName"
          [(ngModel)]="customer.firstName"
          (ngModelChange)="customer.firstName = $event"
          required
          minLength="2"
          maxlength="20"
        />
        <span style="color: red" *ngIf="!customer.firstName || customer.firstName?.length < 2">
          Must be at least 2 characters.
        </span>
      </div>
    </div>

    <div class="row">
      <div class="column" style="width: 150px">
        <label for="lastName"> Last Name: </label>
      </div>
      <div class="column">
        <input
          class="form-control"
          style="height: 30px; width: 250px"
          type="text"
          id="lastName"
          name="lastName"
          [(ngModel)]="customer.lastName"
          (ngModelChange)="customer.lastName = $event"
          required
          minLength="2"
          maxlength="30"
        />
        <span style="color: red" *ngIf="!customer.lastName || customer.lastName?.length < 2">
          Must be at least 2 characters.
        </span>
      </div>
    </div>

    <div class="row">
      <div class="column" style="width: 150px">
        <label for="email"> Customer Email: </label>
      </div>
      <div class="column">
        <input
          class="form-control"
          style="height: 30px; width: 250px"
          type="email"
          id="email"
          name="email"
          [(ngModel)]="customer.email"
          (ngModelChange)="customer.email = $event"
          maxlength="50"
        />
        <span
          style="color: royalblue"
          *ngIf="(!customer.email || customer.email?.length < 7) && (!customer.sms || customer.sms?.length < 7)"
        >
          FYI - Providing an invalid email and SMS number will stop the current conversation.
        </span>
      </div>
    </div>

    <div class="row">
      <div class="column" style="width: 150px">
        <label for="phone"> Phone Number: </label>
      </div>
      <div class="column">
        <input
          class="form-control"
          style="height: 30px; width: 125px"
          type="text"
          id="phone"
          name="phone"
          [(ngModel)]="customer.phone"
          (ngModelChange)="customer.phone = $event"
          maxlength="15"
        />
      </div>
    </div>

    <div class="row">
      <div class="column" style="width: 150px">
        <label for="sms"> Mobile Number: </label>
      </div>
      <div class="column">
        <input
          class="form-control"
          style="height: 30px; width: 125px"
          type="text"
          id="sms"
          name="sms"
          [(ngModel)]="customer.sms"
          (ngModelChange)="customer.sms = $event"
          maxlength="15"
        />
      </div>
      <span
        style="color: royalblue"
        *ngIf="(!customer.email || customer.email?.length < 7) && (!customer.sms || customer.sms?.length < 7)"
      >
        FYI - Providing an invalid email and SMS number will stop the current conversation.
      </span>
    </div>
    <div class="row">
      <h6 style="color: royalblue">
        Please note: The settings above will be updated the next time data is received from your DMS or CRM application.
        You must update your DMS or CRM application to ensure these values are not overwritten
      </h6>
    </div>

    <div class="row"><br /></div>
    <div class="row">
      <div class="column" style="width: 180px">
        <label style="width: 165px">Email&nbsp;Do&nbsp;Not&nbsp;Contact&nbsp;</label>
      </div>
      <div class="column">
        <div class="row">
          <div class="column" style="width: 55px">
            <label for="doNotContact">Dealer:</label>
          </div>
          <div class="column" style="width: 25px">
            <input
              type="checkbox"
              style="height: 20px; width: 20px; vertical-align: bottom"
              id="doNotContact"
              name="doNotContact"
              [(ngModel)]="customer.doNotContact"
            />
          </div>
          <div class="column" style="width: 50px">
            <label for="doNotContactInternal">Impel:</label>
          </div>
          <div class="column" style="width: 25px">
            <input
              type="checkbox"
              style="height: 20px; width: 20px; vertical-align: bottom"
              id="doNotContactInternal"
              name="doNotContactInternal"
              [(ngModel)]="customer.doNotContactInternal"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column" style="width: 165px">
        <label style="width: 165px"> SMS&nbsp;Do&nbsp;Not&nbsp;Contact&nbsp;</label>
      </div>
      <div class="column" style="width: 55px">
        <label for="doNotContactSMS">Dealer:</label>
      </div>
      <div class="column" style="width: 25px">
        <input
          type="checkbox"
          style="height: 20px; width: 20px; vertical-align: bottom"
          id="doNotContactSMS"
          name="doNotContactSMS"
          [(ngModel)]="customer.doNotContactSMS"
        />
      </div>
      <div class="column" style="width: 50px">
        <label for="doNotContactInternalSMS">Impel:</label>
      </div>
      <div class="column" style="width: 25px">
        <input
          type="checkbox"
          style="height: 20px; width: 20px; vertical-align: bottom"
          id="doNotContactInternalSMS"
          name="doNotContactInternalSMS"
          [(ngModel)]="customer.doNotContactInternalSMS"
        />
      </div>
    </div>
    <div class="row" *ngIf="false">
      <div class="column" style="width: 152px">&nbsp;</div>
      <div class="column" style="width: 68px">
        <label for="doNotContactLitigator">Litigator:</label>
      </div>
      <div class="column" style="width: 25px">
        <input
          type="checkbox"
          style="height: 20px; width: 20px; vertical-align: bottom"
          id="doNotContactLitigator"
          name="doNotContactLitigator"
          [(ngModel)]="customer.doNotContactLitigator"
        />
      </div>

      <div class="column" style="width: 100px">
        <label for="doNotContactFederal">Federal DNC:</label>
      </div>

      <div class="column" style="width: 25px">
        <input
          type="checkbox"
          style="height: 20px; width: 20px; vertical-align: bottom"
          id="doNotContactFederal"
          name="doNotContactFederal"
          [(ngModel)]="customer.doNotContactFederal"
        />
      </div>
    </div>

    <!--
    <div class="row" *ngIf="bot.sms && bot.sms.smsNumber && bot.smsSpreadsheetId">
      <div class="column" style="width: 150px">
        <label for="channel"> Preferred Channel: </label>
      </div>
      <div class="column">
        <SELECT
          class="form-control"
          id="channel"
          name="channel"
          (change)="setChannel($event)"
          ng-model="customer.channel"
          style="height: 40px; width: 100px"
        >
          <option *ngFor="let channel of channels" [value]="channel" [selected]="customer.channel == channel">
            {{ channel }}
          </option>
        </SELECT>
      </div>
    </div>
  -->

    <div class="row"><br /></div>

    <div class="row" *ngIf="bot && vehicle">
      <div class="column">
        <label for="inactive" style="width: 265px"> Customer&nbsp;No&nbsp;Longer&nbsp;Owns&nbsp;Vehicle:&nbsp; </label>
      </div>
      <div class="column">
        <input
          type="checkbox"
          style="height: 20px; width: 20px; vertical-align: bottom"
          id="inactive"
          name="inactive"
          [(ngModel)]="customer.inactive"
        />
      </div>
    </div>
    <div class="row" *ngIf="!stopSeriesDisabled">
      <div class="column" style="width: 265px">
        <label for="stopCurrentSeries"> Stop&nbsp;This&nbsp;Conversation:&nbsp; </label>
      </div>
      <div class="column">
        <input
          type="checkbox"
          style="height: 20px; width: 20px; vertical-align: bottom"
          id="stopCurrentSeries"
          name="stopCurrentSeries"
          [(ngModel)]="customer.stopCurrentSeries"
          [readonly]="stopSeriesDisabled"
        />
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="row">{{ errors }}</div>
  <div class="row">
    <button [disabled]="modalIsLoading" (click)="save()" type="button" class="btn btn-primary btn-sm" aria-label="Save">
      Save
    </button>
  </div>
</div>
