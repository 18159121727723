
const colors = {
    'purple': '#7764E4',
    'red': '#FB6340',
    'green': '#2DCE98',
    'blue': '#11CDEF',
    'pink': '#F53C56',
    'yellow': '#FEB969'
};

const linearGradientIds = [
    'line-gradient-0',
    'line-gradient-1',
    'line-gradient-2',
    'line-gradient-3',
    'line-gradient-4',
    'line-gradient-5'
];

/**
 * @param {Object} opts - Options for configuring flow instance.
 * @param {string} opts.containerId - ID of the container element.
 * @param {Object} opts.data - Objects representing the nodes.
 * @param {string} opts.onNodeUpdate - Callback when node is updated.
 * @param {string} opts.onPaneUpdate - Callback when pane is updated.
 * @param {int} opts.startX - Pane x start position.
 * @param {int} opts.startY - Pane y start position.
 */
function Flow(opts) {
    const self = this;
    self.setOptions(opts);
    self.initElements();
    self.initControls();
    self.initNodes();
    self.initPanes();
}

Flow.prototype.update = function(opts) {
    const self = this;
    self.setOptions(opts);
    self.initNodes();
};

Flow.prototype.initElements = function() {
    const self = this;

    //
    // Initialize zoom pane
    //
    self.zoomPane = document.createElement('div');
    self.zoomPane.className = 'flow-zoom-pane';
    self.zoomPane.id = self.zoomPaneId;

    //
    // Initialize node pane
    //
    self.nodePane = document.createElement('div');
    self.nodePane.className = 'flow-node-pane';
    self.nodePane.id = self.nodePaneId;


    //
    // Initialize pane
    //
    self.pane = document.createElement('div');
    self.pane.className = 'flow-pane';
    self.pane.id = self.paneId;

    //
    // Initialize container
    //
    self.container = document.getElementById(self.opts.containerId);

    // Move all child nodes from container to pane
    while(self.container.childNodes.length != 0) {
      self.nodePane.appendChild(self.container.childNodes[0]);
    }

    self.pane.appendChild(self.nodePane);
    self.zoomPane.appendChild(self.pane);
    self.container.appendChild(self.zoomPane);

    //
    // Initialize svg
    //
    self.createSvg();
    Flow.createDefs();
    Flow.createLinearGradient(colors.purple, colors.red, 0);
    Flow.createLinearGradient(colors.red, colors.green, 1);
    Flow.createLinearGradient(colors.green, colors.red, 2);
    Flow.createLinearGradient(colors.blue, colors.green, 3);
    Flow.createLinearGradient(colors.pink, colors.blue, 4);
    Flow.createLinearGradient(colors.yellow, colors.pink, 5);
    Flow.createFilter();
};

Flow.prototype.initControls = function(opts) {
    const self = this;

    function updateSetScale(event) {
      const zoomRate = 0.002;
      const newScale = self.scale + (event.deltaY * zoomRate);
      if (newScale < 0.2) {
        return;
      }

      if (newScale > 1.3) {
        return;
      }

      self.setScale(newScale);
    }

    function dragMouseUp(e) {
        e = e || window.event;
        e.preventDefault();
        if (self.lastMouseDownNode && self.opts.onNodeReleaseUpdate != null) {
            self.opts.onNodeReleaseUpdate(self.lastMouseDownNode);
            self.lastMouseDownNode = null;
        }
    }

    self.container.addEventListener('wheel', (event) => {
      requestAnimationFrame(() => {
        updateSetScale(event);
      });
    });

    self.container.onmouseup = dragMouseUp;
};

Flow.prototype.setOptions = function(opts) {
    const self = this;
    self.opts = { ...self.opts, ...opts};
    self.scale = self.opts.scale || 1;

    self.containerId = self.opts.containerId;
    self.paneId = `${self.opts.containerId}-pane`;
    self.zoomPaneId = `${self.opts.containerId}-zoom-pane`;
    self.nodePaneId = `${self.opts.containerId}-node-pane`;

    if (opts.data) {
        self.data = opts.data;
        self.dataMap = {};
        self.data.forEach((node) => {
            self.dataMap[node.id] = node;
        });
    }
};

Flow.prototype.initNodes = function() {
    const self = this;

    if (self.lines) {
        const linesToUpdate = [];
        self.data.forEach(node => {
            if (!node.connections || !node.connections.length) {
                delete self.lines.from[node.id];
                delete self.lines.to[node.id];
                return;
            }
            node.connections.forEach(connection => {
                linesToUpdate.push(connection.id);
            });
        });

        self.data.forEach(node => {
            if (self.lines.from[node.id] && self.lines.from[node.id].length) {
                self.lines.from[node.id] = self.lines.from[node.id]
                    .filter(lineId => linesToUpdate.includes(lineId));
            }
            if (self.lines.to[node.id] && self.lines.to[node.id].length) {
                self.lines.to[node.id] = self.lines.to[node.id]
                    .filter(lineId => linesToUpdate.includes(lineId));
            }
        });

        Object.keys(self.lines.elem).forEach(lineId => {
            if (!linesToUpdate.includes(lineId)) {
                const elem = document.getElementById(lineId);
                elem.parentNode.removeChild(elem);
                delete self.lines.elem[lineId];
            }
        });
    } else {
        self.lines = {
            from: {},
            to: {},
            elem: {},
        };
    }

    self.data.forEach(node => {
        const elem = document.getElementById(node.id);
        self.initNodeElem(elem, node, self.opts.onNodeUpdate);
    });
};

Flow.prototype.initPanes = function() {
    const self = this;

    //
    // Initialize node pane
    //
    const startNodePanePos = {
        x: Math.floor(self.pane.offsetWidth / 2),
        y: Math.floor(self.pane.offsetHeight / 2)
    };
    self.setElemPosition(self.nodePane, startNodePanePos);

    //
    // Initialize pane
    //
    const startPos = {
        x: (self.opts.startX * -1 || 0) - (self.pane.offsetWidth / 2),
        y: (self.opts.startY * -1 || 0) - (self.pane.offsetHeight / 2)
    };
    self.setScale(self.opts.scale || 1);
    self.initPaneElem(self.pane, startPos, self.opts.onPaneUpdate);
};

Flow.prototype.createSvg = function(paneId) {
    const self = this;
    const pane = document.getElementById(self.paneId);
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute('id', 'lines');
    svg.setAttribute('width', `${pane.offsetWidth}px`);
    svg.setAttribute('height', `${pane.offsetHeight}px`);
    const startSvgPos = {
        x: 0,
        y: 0
    };
    self.setElemPosition(svg, startSvgPos);
    pane.appendChild(svg);
};

Flow.createDefs = function() {
    const svg = document.getElementById('lines');
    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    defs.setAttribute('id', 'lines__defs');
    svg.appendChild(defs);
};

Flow.createLinearGradient = function(startColor, endColor, index) {
    const defs = document.getElementById('lines__defs');
    const linearGradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient');
    linearGradient.setAttribute('id', `line-gradient-${index}`);
    linearGradient.setAttribute('x1', '0%');
    linearGradient.setAttribute('y1', '0%');
    linearGradient.setAttribute('x2', '100%');
    linearGradient.setAttribute('y2', '0%');
    linearGradient.innerHTML = `
        <stop offset="0%" style="stop-color:${startColor};" />
        <stop offset="100%" style="stop-color:${endColor};" />
    `;
    defs.appendChild(linearGradient);
};

Flow.createFilter = function() {
    const svg = document.getElementById('lines');
    const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
    filter.setAttribute('id', 'dropshadow');
    filter.setAttribute('height', '130%');
    filter.innerHTML = `
        <!-- stdDeviation is how much to blur -->
        <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
        <!-- how much to offset -->
        <feOffset dx="1" dy="1" result="offsetblur" />
        <feComponentTransfer>
            <!-- slope is the opacity of the shadow -->
            <feFuncA type="linear" slope="0.3" />
        </feComponentTransfer>
        <feMerge>
            <!-- this contains the offset blurred image -->
            <feMergeNode />
            <!-- this contains the element that the filter is applied to -->
            <feMergeNode in="SourceGraphic" />
        </feMerge>
    `;
    svg.appendChild(filter);
};

/**
 * @param {Object} opts - Options for configuring flow instance.
 * @param {string} opts.stroke - Color of the stroke.
 * @param {string} opts.strokeWidth - Width of the stroke.
 * @param {string} opts.strokeDashArray - Dash array.
 */
Flow.prototype.createLine = function(id, i, opts, fromPos, toPos) {
    const self = this;
    const pane = document.getElementById(self.paneId);
    const newLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    newLine.setAttribute('id', id);
    self.setLinePosition(newLine, fromPos, toPos);
    const num = Math.floor(Math.random() * Math.floor(linearGradientIds.length));
    const gradientId = `#line-gradient-${num}`;
    newLine.setAttribute('stroke', opts.stroke || `url(${gradientId})`);
    newLine.setAttribute('stroke-width', opts.strokeWidth || '4');
    if (opts.strokeDashArray) {
        newLine.setAttribute('stroke-dasharray', opts.strokeDashArray);
    }
    newLine.setAttribute('stroke-linecap', 'round');
    newLine.setAttribute('fill', 'transparent');
    newLine.setAttribute('transform', `translate(${Math.floor(pane.offsetWidth/2)}, ${Math.floor(pane.offsetHeight/2)})`);
    const svg = document.getElementById('lines');
    svg.appendChild(newLine);
    return newLine;
};

Flow.prototype.setLinePosition = function(line, fromPos, toPos) {
    const self = this;
    const pane = document.getElementById(self.paneId);
    const startFromPos = {
        x: Math.floor(fromPos.x),
        y: Math.floor(fromPos.y)
    };
    const startToPos = {
        x: Math.floor(toPos.x),
        y: Math.floor(toPos.y)
    };
    const cr = 100;
    const d = `M ${startFromPos.x} ${startFromPos.y} C ${startFromPos.x + cr} ${startFromPos.y},
      ${startToPos.x - cr} ${startToPos.y}, ${startToPos.x} ${startToPos.y}`;
    line.setAttribute('d', d);
};

Flow.prototype.setElemPosition = function(elem, pos) {
    const y = Math.floor(pos.y) + "px";
    const x = Math.floor(pos.x) + "px";
    elem.style.transform = `translate3d(${x}, ${y}, 0)`;
};

Flow.prototype.updateNodeFromLines = function(elem, pos) {
    const self = this;
    const pane = document.getElementById(self.paneId);
    const fromPos = {
        x: (pos.x + elem.offsetWidth) - 5,
        y: pos.y + (elem.offsetHeight / 2),
    };
    const fromLines = self.lines.from[elem.id];
    if (fromLines && fromLines.length) {
        fromLines.forEach((lineId) => {
            const line = self.lines.elem[lineId];
            self.lines.elem[lineId].fromPos = fromPos;
            const previousToPos = line.toPos;
            self.setLinePosition(line.elem, fromPos, previousToPos);
        });
    }
};

Flow.prototype.updateNodeToLines = function(elem, pos) {
    const self = this;
    const pane = document.getElementById(self.paneId);
    const toPos = {
        x: pos.x + 5,
        y: pos.y + (elem.offsetHeight / 2),
    };
    const toLines = self.lines.to[elem.id];
    if (toLines && toLines.length) {
        toLines.forEach((lineId) => {
            const line = self.lines.elem[lineId];
            self.lines.elem[lineId].toPos = toPos;
            const previousFromPos = line.fromPos;
            self.setLinePosition(line.elem, previousFromPos, toPos);
        });
    }
};

Flow.closeDragElement = function() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
};

Flow.prototype.setScale = function(num) {
    const self = this;
    self.scale = num;

    const container = document.getElementById(self.containerId);
    const pane = document.getElementById(self.paneId);
    const zoomPane = document.getElementById(self.zoomPaneId);

    zoomPane.style.transform = `scale(${self.scale})`;
    zoomPane.style.transformOrigin = `center`;
};

Flow.prototype.initNodeConnections = function(elem, fromNode, startPos) {
    const self = this;
    fromNode.connections.forEach((toNode, i) => {
        const fromId = fromNode.id;
        const toId = toNode.id;
        const lineId = fromId + '-' + toId;

        const toElem = document.getElementById(toId);
        if (!toElem) {
          return;
        }

        const fromPos = {
            x: (startPos.x + elem.offsetWidth) - 5,
            y: (startPos.y + (elem.offsetHeight / 2)),
        };

        const toElemRect = self.dataMap[toNode.id];
        if (!toElemRect) {
          return;
        }

        const toPos = {
            x: toElemRect.x + 5,
            y: (toElemRect.y + (toElem.offsetHeight / 2)),
        };

        const findLine = self.lines.elem[lineId];
        if (!findLine) {
            const line = self.createLine(lineId, i, toNode, fromPos, toPos);

            self.lines.elem[lineId] = {
                elem: line,
                fromPos: fromPos,
                toPos: toPos
            };

            if (!self.lines.from[fromId]) {
                self.lines.from[fromId] = [];
            }

            if (!self.lines.to[toId]) {
                self.lines.to[toId] = [];
            }

            self.lines.from[fromId].push(lineId);
            self.lines.to[toId].push(lineId);
        } else {
            self.lines.elem[lineId] = {
                elem: findLine.elem,
                fromPos: fromPos,
                toPos: toPos
            };
            self.setLinePosition(findLine.elem, fromPos, toPos);
        }
    });
};

Flow.prototype.initNodeElem = function(elmnt, data, onUpdate) {
    const self = this;

    const startPos = {
        x: data.x || 0,
        y: data.y || 0,
    };

    self.setElemPosition(elmnt, startPos);
    if (data.connections) {
        self.initNodeConnections(elmnt, data, startPos);
    }

    // initial, current, offset
    var pos1,
        pos2,
        pos3,
        pos4,
        pos5 = Math.floor(startPos.x * self.scale),
        pos6 = Math.floor(startPos.y * self.scale);
    var lastX = startPos.x, lastY = startPos.y;
    var lastScale = self.scale;

    elmnt.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        e.stopPropagation();
        self.lastMouseDownNode = elmnt;

        if (lastScale == undefined || lastScale != self.scale) {
          lastScale = self.scale;
          pos5 = Math.floor(lastX * self.scale);
          pos6 = Math.floor(lastY * self.scale);
        }

        // Get the mouse cursor position at startup:
        pos1 = e.clientX - pos5;
        pos2 = e.clientY - pos6;

        document.onmouseup = Flow.closeDragElement;

        // Call a function whenever the cursor moves:
        document.onmousemove = (event) => {
          window.requestAnimationFrame(() => {
            elementDrag(event);
          });
        };
    }

    function elementDrag(e) {
        e = e || window.event;

        // calculate the new cursor position:
        pos3 = e.clientX - pos1;
        pos4 = e.clientY - pos2;
        pos5 = pos3;
        pos6 = pos4;

        lastX = Math.floor(pos3 / self.scale);
        lastY = Math.floor(pos4 / self.scale);

        const pos = {
          x: lastX,
          y: lastY
        };

        // set the element's new position:
        self.setElemPosition(elmnt, pos);
        self.updateNodeFromLines(elmnt, pos);
        self.updateNodeToLines(elmnt, pos);

        if (onUpdate != null) {
            onUpdate(elmnt);
        }
    };
};

Flow.prototype.initPaneElem = function(elmnt, startPos, onUpdate) {
    const self = this;

    self.setElemPosition(elmnt, startPos);

    // initial, current, offset
    var pos1,
        pos2,
        pos3,
        pos4,
        pos5 = Math.floor(startPos.x * self.scale),
        pos6 = Math.floor(startPos.y * self.scale);
    var lastX = startPos.x, lastY = startPos.y;
    var lastScale = self.scale;

    elmnt.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        e.stopPropagation();

        if (lastScale == undefined || lastScale != self.scale) {
          lastScale = self.scale;
          pos5 = Math.floor(lastX * self.scale);
          pos6 = Math.floor(lastY * self.scale);
        }

        // Get the mouse cursor position at startup:
        pos1 = e.clientX - pos5;
        pos2 = e.clientY - pos6;

        document.onmouseup = Flow.closeDragElement;

        // call a function whenever the cursor moves:
        document.onmousemove = (event) => {
          window.requestAnimationFrame(() => {
            elementDrag(event);
          });
        };
    }

    function elementDrag(e) {
        e = e || window.event;

        // calculate the new cursor position:
        pos3 = e.clientX - pos1;
        pos4 = e.clientY - pos2;
        pos5 = pos3;
        pos6 = pos4;

        lastX = Math.floor(pos3 / self.scale);
        lastY = Math.floor(pos4 / self.scale);

        const pos = {
          x: lastX,
          y: lastY
        };

        // set the element's new position:
        self.setElemPosition(elmnt, pos);
        if (onUpdate != null) {
            onUpdate(elmnt);
        }
    };
};

exports.Flow = Flow;