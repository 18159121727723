import classNamesNormalizer from 'classnames';
import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsSupportedColors } from '../../_types/AnalyticsType';

@Component({
  selector: 'app-analytics-card-button',
  templateUrl: './analytics-card-button.component.html',
  styleUrls: ['./analytics-card-button.component.scss'],
})
export class AnalyticsCardButtonComponent implements OnInit {
  @Input() active: boolean = false;
  @Input() color: AnalyticsSupportedColors;
  @Input() difference: number;
  @Input() iconClassNames: string;
  @Input() label: string;
  @Input() timePeriod: string;
  @Input() total: number;
  @Input() percent: string | null = null;

  constructor() {}

  ngOnInit() {}

  normalizeClassNames(...classNames) {
    return classNamesNormalizer(classNames);
  }
}
