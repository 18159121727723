import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/services/header.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { getSidebarItems } from 'src/app/pages/admin/utils';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/firestore';
import { UserModel } from 'src/app/models';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})
export class AdminProfileComponent implements OnInit, OnDestroy {
  loading: boolean;
  user: UserModel | null;

  private userSubscription: Subscription;

  constructor(
    private router: Router,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private authService: AuthService,
    private usersService: UsersService,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.headerService.setPageTitle('Profile');
    this.setBreadcrumb();
    this.setSidebarItems();
    this.getCurrentUser();
  }

  private async getCurrentUser() {
    const user = await this.authService.currentUser;
    if (!user) {
      return;
    }
    this.loading = true;
    this.userSubscription = this.usersService.getUserById(user.uid).subscribe(
      userModel => {
        this.loading = false;
        this.user = userModel;
      },
      error => {
        this.toaster.error(error);
        this.loading = false;
      }
    );
  }

  navigateToHome() {
    this.router.navigate(['/admin']);
  }

  private setBreadcrumb() {
    this.breadcrumbService.set([
      {
        label: 'Profile',
        route: '/admin/profile'
      }
    ]);
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
