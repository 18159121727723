import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { BrainService } from 'src/app/services/brain.service';
import { ToastrService } from 'ngx-toastr';
import { NodeModel } from 'src/app/models/node';
import { SimpleEditorTab } from 'src/app/components/editors/simple-editor/_types/SimpleEditorTab';
import { TemplateModel } from 'src/app/models/template';
import { Subject } from 'rxjs';
import { jsonToHandlebars } from 'src/app/utils/handlebars/handlebars.utils';
import { ShopBotService } from 'src/app/services/shopbot.service';
import { NodesService } from 'src/app/services/firestore';

@Component({
  selector: 'app-edit-template-modal',
  templateUrl: './edit-template-modal.component.html',
  styleUrls: ['./edit-template-modal.component.scss'],
})
export class EditTemplateModalComponent implements OnInit {
  public onClose: Subject<boolean>;
  loading: boolean;
  node: NodeModel;
  index: string;
  template: TemplateModel;
  tabs: SimpleEditorTab[] = [
    {
      id: 'template',
      label: 'Template',
      data: '',
      language: 'handlebars',
      logs: [],
    },
    {
      id: 'example-data',
      label: 'Example Data',
      data: '',
      language: 'json',
      logs: [],
    },
  ];

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private nodesService: NodesService,
    private brainService: BrainService,
    private shopBotService: ShopBotService,
    private toaster: ToastrService,
  ) {
    const { node, index } = options.initialState as any;
    this.node = node;
    this.index = index;
    if (!this.node?.templates) {
      return;
    }
    this.template = this.node.templates[this.index];

    if (this.template?.rawTemplate?.trim().length > 0) {
      this.tabs[0].data = this.template.rawTemplate;
    } else {
      this.tabs[0].data = TemplateModel.getDecodedBlob(this.node.templates[this.index]?.blob);
    }

    if (this.template?.rawExampleData) {
      this.tabs[1].data = JSON.stringify(this.template.rawExampleData, null, 2);
    } else {
      this.tabs[1].data = TemplateModel.getDecodedBlob(this.node.templates[this.index]?.exampleDataBlob);
    }

    this.tabs[0].language = this.template.channel === 'email' ? 'html' : 'handlebars';

    this.loading = false;
  }

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  async saveAll(tabs: SimpleEditorTab[]) {
    this.loading = true;

    const templateTab = tabs[0];
    const dataTab = tabs[1];

    // TODO: Enable path validation
    // TEMP: Disabled until we figure out mechanism to determine path vs value
    /*
    // Validate template variables
    const paths = TemplateModel.generatePaths(templateTab.data);
    if (paths.length) {
      try {
        paths.forEach(path => {
          const p = TemplateModel.getPathFromString(path);
          if (this.node?.availableData) {
            const inPath = NodeModel.inPath(this.node?.availableData, p);
            if (!inPath) {
              throw new Error(`Variable not available at node: ${path}`);
            }
          }
        });
      } catch (error) {
        this.loading = false;
        this.toaster.error('Invalid Template');
        templateTab.logs = [
          {
            className: 'simple-editor-log--error',
            text: error
          }
        ];
        return;
      }
    }
    */

    // Validate json
    try {
      JSON.parse(dataTab.data);
    } catch (error) {
      this.loading = false;
      this.toaster.error('Invalid Example Data');
      dataTab.logs = [
        {
          className: 'simple-editor-log--error',
          text: error,
        },
      ];
      return;
    }

    const onSuccess = async _ => {
      if (!this.node?.templates) {
        return;
      }
      this.loading = false;
      this.toaster.success('Saved Template');
      templateTab.logs = [];
      dataTab.logs = [];
      TemplateModel.setEncodeBlob(this.node.templates[this.index], templateTab.data);
      TemplateModel.setEncodeExampleDataBlob(this.node.templates[this.index], dataTab.data);
      this.compileNodeTemplateConnections();
      const result = await this.nodesService.updateNode(this.node); // :eyes for flow templates
      this.onClose.next(true);
      this.modal.hide();
      return result;
    };

    const onError = error => {
      this.loading = false;
      this.toaster.error('Invalid Template');
      templateTab.logs = [
        {
          className: 'simple-editor-log--error',
          text: error,
        },
      ];
    };

    const script = this.getScriptFromTemplate(templateTab.data);
    if (script !== null && script.trim().length > 0) {
      await this.shopBotService
        .validateScript({ script })
        .toPromise()
        .then(res => {
          if (res.isValid) {
            return this.compileTemplate(templateTab, dataTab, onSuccess, onError);
          } else {
            onError(`Error occurred while validating script: \n${res.stackTrace}`);
          }
        })
        .catch(error => {
          onError(`Error occurred while validating script: \n ${error.message}`);
        });
    } else {
      // no script found, compile
      return this.compileTemplate(templateTab, dataTab, onSuccess, onError);
    }
  }

  private compileTemplate(templateTab, dataTab, onSuccess, onError) {
    return this.template.channel === 'email'
      ? onSuccess(null)
      : this.brainService
          .compileTemplate({
            source: templateTab.data,
            data: JSON.parse(dataTab.data),
          })
          .subscribe(onSuccess, onError);
  }

  private getScriptFromTemplate(template: string) {
    const regex = new RegExp(
      '(?:.*?){{#script(?:.*?)}}(?:[\r\n]*?)(?<script>.*?)(?:[\r\n]*?){{/script}}(?:.*?)+',
      'gs',
    );
    const matches = regex.exec(template);
    if (matches !== null) {
      if (matches.groups !== null) {
        return matches.groups?.script ?? null;
      }
    }
    return null;
  }

  private compileNodeTemplateConnections() {
    this.node.templateNodeConnections = this.node.templates?.reduce((nodeTemplateConnections, template) => {
      if (!template.rawTemplate) {
        return nodeTemplateConnections;
      }
      const uuids = template.rawTemplate.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi);
      if (uuids) {
        return [...new Set([...nodeTemplateConnections, ...uuids])];
      }
      return nodeTemplateConnections;
    }, []);
  }

  transpileJsonToHandlebars(value) {
    if (!value) {
      return;
    }
    const data = jsonToHandlebars(value);
    if (data) {
      this.tabs[0].data = data;
    }
  }
}
