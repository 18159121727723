<div class="modal-header">
  <h4 class="modal-title">Add a New Assistant</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-bot-general-info
    [writable]="true"
    [bot]="bot"
    [shouldAdd]="true"
    [canSaveChanges]="canSaveChanges"
    [botNameList]="botNameList"
    (canceled)="modal.hide()"
    (saved)="botAdded.next(bot); modal.hide()"
    (canceled)="modal.hide()"
  >
  </app-bot-general-info>
</div>
