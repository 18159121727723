<form class="bot-sidebar p-3" [formGroup]="flowSidebarForm" novalidate>
  <fieldset class="container">
    <!-- Name -->
    <div class="form-group row">
      <label class="form-control-label"> Name </label>
      <input
        data-test-id="node-name-input"
        [disabled]="!canUserManage || !allowSaveChanges"
        class="form-control"
        name="name"
        [(ngModel)]="selectedNode.name"
        (ngModelChange)="saveDebounce.next($event)"
        formControlName="name"
        class="form-control"
        type="text"
        placeholder="e.g. Intro, Request Email"
        [class.is-invalid]="!name?.valid"
        required
      />
      <div *ngIf="!name?.valid" class="invalid-feedback">
        {{ validationMessages.required }}
      </div>
    </div>

    <!-- Live -->
    <div class="form-group row">
      <div class="col-12 no-gutters p-0">
        <label class="form-control-label"> Live </label>
      </div>
      <div class="col p-0">
        <label class="custom-toggle mr-1">
          <input formControlName="live" type="checkbox" />
          <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
        </label>
      </div>
    </div>

    <!-- Human in the loop support -->
    <div class="form-group row">
      <div class="col-12 no-gutters p-0">
        <label class="form-control-label"> Available for Support Agent </label>
      </div>
      <div class="col p-0">
        <label class="custom-toggle mr-1">
          <input formControlName="availableForSupport" type="checkbox" />
          <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
        </label>
      </div>
    </div>

    <!-- Recurring -->
    <div class="form-group row">
      <div class="col-12 no-gutters p-0">
        <label class="form-control-label"> Recurring </label>
      </div>
      <div class="col p-0">
        <label class="custom-toggle mr-1">
          <input formControlName="isRecurring" type="checkbox" />
          <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
        </label>
      </div>
    </div>

    <!-- Recurring Expression -->
    <ng-container *ngIf="isRecurring?.value">
      <div class="form-group row">
        <label class="form-control-label"> Expression </label>
        <input
          formControlName="recurringExpression"
          class="form-control"
          type="text"
          placeholder="e.g. 5 4 * * *"
          [class.is-invalid]="!recurringExpression?.valid"
        />
        <div *ngIf="!recurringExpression?.valid" class="invalid-feedback">
          {{ validationMessages.required }}
        </div>
      </div>
    </ng-container>

    <!-- Global -->
    <div class="form-group row">
      <div class="col-12 no-gutters p-0">
        <label class="form-control-label"> Global </label>
      </div>
      <div class="col p-0">
        <label class="custom-toggle mr-1">
          <input formControlName="global" type="checkbox" />
          <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
        </label>
      </div>
    </div>

    <!-- Action -->
    <ng-container *ngIf="!global?.value">
      <div class="form-group row my-0" *ngIf="nlpModel?.intents?.length && (action?.value || action?.value == '')">
        <label class="form-control-label"> Action </label>
      </div>
      <div class="form-group row" *ngIf="nlpModel?.intents?.length && (action?.value || action?.value == '')">
        <!-- REMOVE -->
        <div *ngIf="canUserManage" class="col-1 p-0 m-0 mr-2">
          <button
            [disabled]="!allowSaveChanges"
            class="btn btn-clear btn-sm m-0 close h-100"
            type="button"
            (click)="removeAction()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- SELECTION -->
        <select formControlName="action" class="col-sm form-control" [class.is-invalid]="!action?.valid">
          <option value="" selected disabled>Select Action</option>
          <option *ngFor="let action of nlpModel?.intents" [disabled]="disableAction(action)" [ngValue]="action">
            {{ action }}
          </option>
        </select>
        <div *ngIf="!action?.valid" class="invalid-feedback">
          {{ validationMessages.required }}
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="
          canUserManage &&
          nlpModel?.intents?.length &&
          selectedNode.action == undefined &&
          selectedNode.spec == undefined &&
          allowSaveChanges
        "
      >
        <button class="btn btn-sm btn-primary btn-block" type="button" (click)="addAction()">Add Action</button>
      </div>
    </ng-container>

    <!-- Input Data -->
    <ng-container *ngIf="canUserManage && !global?.value">
      <div class="form-group row my-0" *ngIf="selectedNode?.inputData">
        <label class="form-control-label"> Input Data </label>
      </div>
      <div class="card row p-0" *ngIf="selectedNode?.inputData">
        <!-- EDIT -->
        <div class="form-group row p-2 m-0">
          <div class="col-sm p-0 m-0">
            <button class="btn btn-sm btn-block btn-primary m-0" type="button" (click)="editInputData()">
              Edit Input Data
            </button>
          </div>
        </div>
        <!-- REMOVE -->
        <div class="form-group row p-2 m-0">
          <div class="col-sm p-0 m-0">
            <button class="btn btn-sm btn-block btn-secondary m-0" type="button" (click)="removeInputData()">
              Remove Input Data
            </button>
          </div>
        </div>
      </div>
      <div class="form-group row" *ngIf="!selectedNode.inputData">
        <button
          class="btn btn-sm btn-primary btn-block"
          type="button"
          [disabled]="!allowSaveChanges"
          (click)="addInputData()"
        >
          Add Input Data
        </button>
      </div>
    </ng-container>

    <!-- Spec -->
    <ng-container *ngIf="!global?.value">
      <div class="form-group row my-0" *ngIf="specs?.length && (spec?.value || spec?.value == '')">
        <label class="form-control-label"> Spec </label>
      </div>
      <div class="form-group row" *ngIf="specs?.length && (spec?.value || spec?.value == '')">
        <!-- REMOVE -->
        <div *ngIf="canUserManage" class="col-1 p-0 m-0 mr-2">
          <button class="btn btn-clear btn-sm m-0 close h-100" type="button" (click)="removeSpec()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- SELECTION -->
        <select formControlName="spec" class="col-sm form-control" [class.is-invalid]="!spec?.valid">
          <option value="" selected disabled>Select Spec</option>
          <option *ngFor="let spec of specs" [disabled]="disableSpec(spec)" [ngValue]="spec">{{ spec }}</option>
        </select>
        <div *ngIf="!spec?.valid" class="invalid-feedback">
          {{ validationMessages.required }}
        </div>
      </div>
      <ng-container *ngIf="selectedNodeIsSpec">
        <div class="form-group row" *ngIf="selectedNodeIsSpec">
          <button
            class="btn btn-sm btn-primary btn-block"
            type="button"
            (click)="editSpecTemplate()"
            [disabled]="!spec?.valid"
          >
            Edit Spec Content
          </button>
        </div>
      </ng-container>
      <div
        class="form-group row"
        *ngIf="
          canUserManage &&
          allowSaveChanges &&
          specs?.length &&
          selectedNode.spec == undefined &&
          selectedNode.action == undefined &&
          spec?.value === undefined
        "
      >
        <button class="btn btn-sm btn-primary btn-block" type="button" (click)="addSpec()">Add Spec</button>
      </div>
    </ng-container>

    <!-- TEMPLATE -->
    <ng-container *ngIf="!global?.value">
      <div class="form-group row my-0" *ngIf="selectedNode?.templates?.length">
        <label class="form-control-label"> Template </label>
      </div>
      <div
        formArrayName="templateChannels"
        *ngFor="let templateChannelControl of templateChannels.controls; let i = index"
      >
        <div class="card row p-0" *ngIf="templateChannelControl?.value || templateChannelControl?.value == ''">
          <!-- EDIT -->
          <div *ngIf="canUserManage" class="form-group row p-2 m-0">
            <div class="col-sm p-0 m-0">
              <button
                data-test-id="edit-node-template-button"
                [disabled]="!allowSaveChanges || !templateChannelControl.valid"
                class="btn btn-sm btn-block btn-primary m-0"
                type="button"
                (click)="editTemplate(i)"
              >
                Edit Template
              </button>
            </div>
          </div>
          <!-- SELECTION -->
          <div class="form-group row p-2 m-0">
            <select
              data-test-id="node-template-type-select"
              [formControlName]="i"
              class="col-sm p-0 form-control"
              [class.is-invalid]="!templateChannelControl?.valid"
            >
              <option value="" selected disabled>Select Channel</option>
              <option
                *ngFor="let c of channels"
                [value]="c.id"
                [selected]="c.id === templateChannelControl.value"
                [disabled]="disableTemplateChannel(c.id)"
              >
                {{ c.name }}
              </option>
            </select>
            <div *ngIf="!templateChannelControl?.valid" class="invalid-feedback">
              {{ validationMessages.required }}
            </div>
          </div>
          <!-- REMOVE -->
          <div *ngIf="canUserManage" class="form-group row p-2 m-0">
            <div class="col-sm p-0 m-0">
              <button
                [disabled]="!allowSaveChanges"
                class="btn btn-sm btn-block btn-secondary m-0"
                type="button"
                (click)="removeTemplate(i)"
              >
                Remove Template
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="canUserManage && allowSaveChanges && selectedNode?.templates?.length != channels?.length"
      >
        <button
          data-test-id="add-node-template-button"
          class="btn btn-sm btn-primary btn-block"
          type="button"
          (click)="addTemplate()"
        >
          Add Template
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="!selectedNode.global">
      <div class="form-group row my-0" *ngIf="selectedNode?.httpRequests?.length">
        <label class="form-control-label"> HTTP Request </label>
      </div>
      <div class="card row p-0" *ngFor="let httpRequest of selectedNode.httpRequests; let i = index">
        <!-- EDIT -->
        <div *ngIf="canUserManage" class="form-group row p-2 m-0">
          <div class="col-sm p-0 m-0">
            <button
              [disabled]="!allowSaveChanges"
              class="btn btn-sm btn-block btn-primary m-0"
              type="button"
              (click)="editNodeHttpRequest(httpRequest)"
            >
              {{ httpRequest.title }}
            </button>
          </div>
        </div>
        <!-- REMOVE -->
        <div *ngIf="canUserManage" class="form-group row p-2 m-0">
          <div class="col-sm p-0 m-0">
            <button
              [disabled]="!allowSaveChanges"
              class="btn btn-sm btn-block btn-secondary m-0"
              type="button"
              (click)="removeNodeHttpRequest(i)"
            >
              Remove HTTP Request
            </button>
          </div>
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="canUserManage && selectedNode?.templates?.length != channels?.length && allowSaveChanges"
      >
        <button class="btn btn-sm btn-primary btn-block" type="button" (click)="addHttpRequest()">
          Add HTTP Request
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="!selectedNodeIsSpec">
      <!-- API QUERIES -->
      <ng-container *ngIf="selectedNode.apiQueries">
        <div class="form-group row my-0" *ngIf="selectedNode.apiQueries?.length">
          <label class="form-control-label"> API Queries </label>
        </div>
        <div class="card row p-0" formArrayName="apiQueries" *ngFor="let apiQueryControlGroup of apiQueriesArray.controls; let i = index">
          <!-- INPUT -->
          <div [formGroupName]="i">

          <div *ngIf="canUserManage" class="form-group row p-2 m-0">
            <input
              class="col-sm form-control"
              formControlName="key"
              type="text"
              placeholder="Storage Key"
              [class.is-invalid]="!apiQueryControlGroup.get('key')?.valid"
              required
            />
            <div *ngIf="!apiQueryControlGroup.get('key')?.valid" class="invalid-feedback">
              {{ validationMessages.required }}
            </div>
          </div>
          <!-- SELECTION -->
          <div class="form-group row p-2 m-0">
            <select
              formControlName="apiQueryId"
              class="col-sm p-0 form-control"
              [class.is-invalid]="!apiQueryControlGroup.get('apiQueryId')?.valid"
            >
              <option value="" disabled selected>Select API Query</option>
              <option *ngFor="let apiQueryOption of apiQueries" [ngValue]="apiQueryOption.id" [selected]="apiQueryOption.id === apiQueryControlGroup.get('apiQueryId')?.value">{{ apiQueryOption.name }}</option>
            </select>
            <div *ngIf="!apiQueryControlGroup.get('apiQueryId')?.valid" class="invalid-feedback">
              {{ validationMessages.required }}
            </div>
          </div>
          </div>
          <!-- REMOVE -->
          <div *ngIf="canUserManage" class="form-group row p-2 m-0">
            <div class="col-sm p-0 m-0">
              <button class="btn btn-sm btn-block btn-secondary m-0" type="button" (click)="removeApiQuery(i)">
                Remove API Query
              </button>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="canUserManage && apiQueries.length && allowSaveChanges">
          <button class="btn btn-sm btn-primary btn-block" type="button" (click)="addApiQuery()">Add API Query</button>
        </div>
      </ng-container>

      <!-- VALIDATION -->
      <ng-container *ngIf="!global?.value">
        <div class="form-group row my-0" *ngIf="validationArray?.length">
          <label class="form-control-label"> Validations </label>
        </div>
        <div formArrayName="validations" *ngFor="let validationControlGroup of validationArray.controls; let i = index">
          <div [formGroupName]="i">
            <!-- INPUT -->
            <div *ngIf="canUserManage" class="form-group row p-2 m-0">
              <input
                formControlName="key"
                class="col-sm form-control"
                type="text"
                placeholder="Storage Key"
                [class.is-invalid]="!validationControlGroup.get('key')?.valid"
                required
              />
              <div *ngIf="!validationControlGroup.get('key')?.valid" class="invalid-feedback">
                {{ validationMessages.required }}
              </div>
            </div>
            <!-- SELECTION -->
            <div class="form-group row p-2 m-0">
              <select
                formControlName="validationId"
                class="col-sm form-control"
                [class.is-invalid]="!validationControlGroup.get('validationId')?.valid"
              >
                <option value="" selected disabled>Select Validation</option>
                <option
                  *ngFor="let va of validations"
                  [value]="va.id"
                  [selected]="va.id === validationControlGroup.get('validationId')?.value"
                >
                  {{ va.name }}
                </option>
              </select>
              <div *ngIf="!validationControlGroup.get('validationId')?.valid" class="invalid-feedback">
                {{ validationMessages.required }}
              </div>
            </div>
            <!-- SELECTION -->
            <div class="form-group row p-2 m-0" *ngIf="validationControlGroup.get('validationId')?.value">
              <select
                formControlName="fallbackRetryNodeId"
                class="col-sm form-control"
                [class.is-invalid]="!validationControlGroup.get('fallbackRetryNodeId')?.valid"
              >
                <option value="" selected disabled>Select Fallback Retry Node</option>
                <ng-container *ngFor="let n of nodes">
                  <option
                    *ngIf="n.id != selectedNode.id"
                    [value]="n.id"
                    [selected]="n.id === validationControlGroup.get('fallbackRetryNodeId')?.value"
                  >
                    {{ n.name }}
                  </option>
                </ng-container>
              </select>
              <div *ngIf="!validationControlGroup.get('fallbackRetryNodeId')?.valid" class="invalid-feedback">
                {{ validationMessages.required }}
              </div>
            </div>
            <!-- INPUT -->
            <div
              class="form-group row p-2 m-0"
              *ngIf="canUserManage && validationControlGroup.get('fallbackRetryNodeId')?.value"
            >
              <input
                formControlName="fallbackRetryAmount"
                class="col-sm form-control"
                type="number"
                placeholder="Retry Amount"
                [class.is-invalid]="!validationControlGroup.get('fallbackRetryAmount')?.valid"
                required
              />
              <div *ngIf="!validationControlGroup.get('fallbackRetryAmount')?.valid" class="invalid-feedback">
                {{ validationMessages.required }}
              </div>
            </div>
            <!-- SELECTION -->
            <div class="form-group row p-2 m-0" *ngIf="validationControlGroup.get('validationId')?.value">
              <select
                formControlName="fallbackRedirectNodeId"
                class="col-sm form-control"
                [class.is-invalid]="!validationControlGroup.get('fallbackRedirectNodeId')?.valid"
              >
                <option value="" selected disabled>Select Fallback Redirect Node</option>
                <ng-container *ngFor="let n of nodes">
                  <option
                    *ngIf="n.id != selectedNode.id"
                    [value]="n.id"
                    [selected]="n.id === validationControlGroup.get('fallbackRedirectNodeId')?.value"
                  >
                    {{ n.name }}
                  </option>
                </ng-container>
              </select>
              <div *ngIf="!validationControlGroup.get('fallbackRedirectNodeId')?.valid" class="invalid-feedback">
                {{ validationMessages.required }}
              </div>
            </div>
            <!-- TOGGLE -->
            <div class="form-group row p-2 m-0" *ngIf="validationControlGroup.get('validationId')?.value">
              <div class="col-12 no-gutters p-0">
                <label class="form-control-label text-white"> Storable </label>
              </div>
              <div class="col p-0">
                <label class="custom-toggle mr-1">
                  <input formControlName="storable" type="checkbox" />
                  <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
                </label>
              </div>
            </div>
            <!-- SELECTION -->
            <div class="form-group row p-2 m-0" *ngIf="validationControlGroup.get('storable')?.value">
              <select
                formControlName="storableConfirmNodeId"
                class="col-sm form-control"
                [class.is-invalid]="!validationControlGroup.get('storableConfirmNodeId')?.valid"
              >
                <option value="" selected disabled>Storable Confirm Node</option>
                <ng-container *ngFor="let n of nodes">
                  <option
                    *ngIf="n.id != selectedNode.id"
                    [value]="n.id"
                    [selected]="n.id === validationControlGroup.get('storable')?.value"
                  >
                    {{ n.name }}
                  </option>
                </ng-container>
              </select>
              <div *ngIf="!validationControlGroup.get('storableConfirmNodeId')?.valid" class="invalid-feedback">
                {{ validationMessages.required }}
              </div>
            </div>

            <!-- REMOVE -->
            <div *ngIf="canUserManage" class="form-group row p-2 m-0">
              <div class="col-sm p-0 m-0">
                <button class="btn btn-sm btn-block btn-secondary m-0" type="button" (click)="removeValidation(i)">
                  Remove Validation
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="canUserManage && validations.length && allowSaveChanges">
          <button class="btn btn-sm btn-primary btn-block" type="button" (click)="addValidation()">
            Add Validation
          </button>
        </div>
      </ng-container>

      <!-- KPI's -->
      <ng-container *ngIf="!selectedNode.global && selectedNode.kpis?.length">
        <div class="config-item row border mb-2">
          <div class="col-12">
            <div class="config-item-header row">
              <label class="form-control-label"> KPIs </label>
            </div>

            <div *ngFor="let kpi of selectedNode.kpis">
              <div class="row my-1">
                <!-- REMOVE KPI-->
                <div
                  *ngIf="canUserManage && allowSaveChanges"
                  class="col-1 p-0 pt-1 m-0 ml-1 ng-star-inserted border rounded"
                >
                  <button
                    [disabled]="!allowSaveChanges"
                    class="btn btn-clear btn-sm m-0 close h-100"
                    type="button"
                    (click)="removeKpi(kpi.systemName)"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="ml-1">{{ kpi.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="form-group row" *ngIf="canUserManage && allowSaveChanges">
        <button class="btn btn-sm btn-primary btn-block" type="button" (click)="addKpi()">Add KPI</button>
      </div>

      <!-- CONNECTIONS -->
      <div class="form-group row my-0" *ngIf="connectionArray?.length">
        <label class="form-control-label"> Connections </label>
      </div>
      <div
        class="card row p-0"
        formArrayName="connections"
        *ngFor="let connectionControlGroup of connectionArray.controls; let i = index"
      >
        <div [formGroupName]="i">
          <!-- CONNECTION CONDITIONALS -->
          <div
            formArrayName="conditionals"
            *ngFor="let conditional of connectionControlGroup.get('conditionals')['controls']; let j = index"
          >
            <div [formGroupName]="j" class="form-group row p-2 m-0">
              <!-- REMOVE -->
              <div
                class="col-1 p-0 m-0 mr-2"
                *ngIf="
                  canUserManage &&
                  conditional.get('operator').value != 'and' &&
                  conditional.get('operator').value != 'or'
                "
              >
                <button class="btn btn-sm btn-clear m-0 close h-100" type="button" (click)="removeConditional(i, j)">
                  <span class="text-black" aria-hidden="true">&times;</span>
                </button>
              </div>

              <!-- EQ, LTE LTE, GT, GTE -->
              <div
                class="col-sm p-0"
                *ngIf="conditional.get('operator').value != 'and' && conditional.get('operator').value != 'or'"
              >
                <input
                  formControlName="left"
                  class="form-control"
                  type="text"
                  placeholder="Path"
                  placement="top"
                  [class.is-invalid]="!conditional.get('left')?.valid"
                />
                <div *ngIf="!conditional.get('left')?.valid" class="invalid-feedback">
                  {{ validationMessages.required }}
                </div>
              </div>

              <div
                class="col-sm px-2"
                *ngIf="conditional.get('operator').value != 'and' && conditional.get('operator').value != 'or'"
              >
                <select
                  formControlName="operator"
                  class="form-control"
                  [class.is-invalid]="!conditional.get('operator')?.valid"
                >
                  <option value="" selected disabled>Operator</option>
                  <option *ngFor="let op of ops" [ngValue]="op.value">{{ op.label }}</option>
                </select>
                <div *ngIf="!conditional.get('operator')?.valid" class="invalid-feedback">
                  {{ validationMessages.required }}
                </div>
              </div>

              <div
                class="col-sm p-0"
                *ngIf="
                  conditional.get('operator').value != 'and' &&
                  conditional.get('operator').value != 'or' &&
                  conditional.get('operator').value != 'truthy' &&
                  conditional.get('operator').value != 'falsy'
                "
              >
                <input
                  formControlName="right"
                  class="form-control"
                  type="text"
                  placeholder="Path"
                  [class.is-invalid]="!conditional.get('right')?.valid"
                />
                <div *ngIf="!conditional.get('right')?.valid" class="invalid-feedback">
                  {{ validationMessages.required }}
                </div>
              </div>

              <!-- AND, OR -->
              <div
                class="col-sm p-0"
                *ngIf="conditional.get('operator').value == 'and' || conditional.get('operator').value == 'or'"
              >
                <select
                  formControlName="operator"
                  class="form-control"
                  (ngModelChange)="saveDebounce.next($event)"
                  required
                >
                  <option *ngFor="let op of andOrOps" [ngValue]="op.value">{{ op.label }}</option>
                </select>
              </div>
            </div>
          </div>

          <!-- CONNECTION ADD CONDITIONAL -->
          <div *ngIf="canUserManage" class="form-group row p-2 m-0">
            <div class="col-sm p-0 m-0">
              <button
                data-test-id="add-node-condition-button"
                style="border-color: #607aa6"
                class="btn btn-sm bg-clr-navy-light text-white btn-block btn-secondary m-0"
                type="button"
                (click)="addConditional(connectionControlGroup, i)"
              >
                Add Condition
              </button>
            </div>
          </div>

          <!-- CONNECTION ACTION -->
          <div class="form-group row p-2 m-0">
            <div class="col-sm p-0 m-0">
              <select
                data-test-id="select-node-connection"
                [disabled]="!canUserManage || !allowSaveChanges"
                formControlName="id"
                class="form-control"
                [class.is-invalid]="!connectionControlGroup.get('id')?.valid"
              >
                <option value="" selected disabled>Select Node</option>
                <ng-container *ngFor="let n of nodes">
                  <option
                    *ngIf="n.id != selectedNode.id"
                    [value]="n.id"
                    [selected]="n.id === connectionControlGroup.get('id')?.value"
                  >
                    {{ n.name }}
                  </option>
                </ng-container>
              </select>
              <div *ngIf="!connectionControlGroup.get('id')?.valid" class="invalid-feedback">
                {{ validationMessages.required }}
              </div>
            </div>
          </div>

          <!-- CONNECTION ADD CONDITIONAL -->
          <div *ngIf="canUserManage" class="form-group row p-2 m-0">
            <div class="col-sm p-0 m-0">
              <button
                data-test-id="remove-node-connection-button"
                class="btn btn-sm btn-block btn-secondary m-0"
                type="button"
                (click)="removeConnection(i)"
              >
                Remove Connection
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row" *ngIf="canUserManage && nodes.length > 1 && allowSaveChanges">
        <button
          data-test-id="add-node-connection-button"
          class="btn btn-sm btn-primary btn-block"
          type="button"
          (click)="addConnection()"
        >
          Add Connection
        </button>
      </div>
    </ng-container>
  </fieldset>

  <!-- Available Data -->
  <div class="card py-0 px-2 row no-gutters mb-4" *ngIf="selectedNode.availableData">
    <app-graphql-docs [schema]="selectedNode.availableData"> </app-graphql-docs>
  </div>

  <!-- METADATA -->
  <div class="row no-gutters mb-2">
    <label class="form-control-label"> Metadata </label>
  </div>
  <div class="row no-gutters d-flex">
    <div class="col-sm no-gutters">
      <div class="d-flex justify-content-between">
        <h5>ID</h5>
        <h3>
          <span class="badge badge-secondary">{{ selectedNode.id }}</span>
          <button
            ngxClipboard
            [cbContent]="selectedNode.id"
            (cbOnSuccess)="processCopySuccess()"
            class="btn btn-sm btn-primary btn-outline"
          >
            COPY TO CLIPBOARD
          </button>
        </h3>
      </div>
      <div class="d-flex justify-content-between">
        <h5>Parent Nodes</h5>
        <h3>
          <span class="badge badge-secondary">{{ selectedNode.numParentNodes }}</span>
        </h3>
      </div>
      <div class="d-flex justify-content-between">
        <h5>Child Nodes</h5>
        <h3>
          <span class="badge badge-secondary">{{ selectedNode.numChildNodes }}</span>
        </h3>
      </div>
      <div class="d-flex justify-content-between">
        <h5>Created At</h5>
        <h3>
          <span class="badge badge-secondary">{{ formatDate(selectedNode.createdAt.seconds) }}</span>
        </h3>
      </div>
      <div class="d-flex justify-content-between">
        <h5>Updated At</h5>
        <h3>
          <span class="badge badge-secondary">{{ formatDate(selectedNode.updatedAt.seconds) }}</span>
        </h3>
      </div>
      <div class="form-group">
        <h5>Notes</h5>
        <textarea formControlName="notes" class="form-control col-12 px-1" rows="3"></textarea>
      </div>
    </div>
  </div>

  <!-- Duplicate -->
  <div *ngIf="canUserManage" class="row no-gutters mt-4">
    <button
      class="btn btn-sm btn-primary btn-block"
      type="button"
      [disabled]="!flowSidebarForm.valid"
      (click)="duplicateNode()"
    >
      Duplicate
    </button>
  </div>

  <!-- DELETE -->
  <div *ngIf="canUserManage" class="row no-gutters mt-4 mb-4">
    <button
      data-test-id="delete-node-button"
      [disabled]="!allowSaveChanges"
      class="btn btn-sm btn-danger btn-block"
      type="button"
      (click)="removeNode()"
    >
      Delete
    </button>
  </div>
</form>
