<ng-container *ngIf="loading">
  <div class="container-fluid">
    <div class="row align-items-center py-4 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="card m-4">
          <div class="card-header d-flex align-items-center">
            <h3>Export Assistant Details</h3>
            <button class="btn btn-outline-primary ml-4 pull-right" (click)="exportNodes()">Export</button>
          </div>
          <div class="card-body">
            <label for="exportGlobalVariables" *ngIf="bot?.globalVariables && bot.globalVariables.length > 0">
              <input type="checkbox" id="exportGlobalVariables" [(ngModel)]="exportGlobalVariables" />
              Export Global Variables
            </label>
            <br />
            <label for="exportApiQueries" *ngIf="apiQueries.length > 0">
              <input type="checkbox" id="exportApiQueries" [(ngModel)]="exportApiQueries" />
              Export API Queries
            </label>
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th *ngFor="let column of exportAbleColumnsAsArray" scope="col">
                      <label [for]="column.key">
                        <input
                          [checked]="selectedColumns.includes(column.key)"
                          type="checkbox"
                          [id]="column.key"
                          (change)="toggleColumnExport(column.key)"
                        />
                        {{ column.label }}
                      </label>
                    </th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr *ngFor="let node of nodes">
                    <td *ngFor="let column of exportAbleColumnsAsArray" scope="col">
                      {{ node[column.key] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 *ngIf="!nodes || nodes.length === 0">No Nodes.</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
