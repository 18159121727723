<div class="card mt-2">
    <div class="card-header pb-2 pt-2 d-flex align-items-center">
        <h5 class="d-inline-bloc h3 mb-0">{{title}}
          <a
          [tooltip]="description"
          placement="top"
          href="javascript:void(0)"
        >
          <i class="fas fa-info-circle"> </i>
        </a>
        
        </h5>
    </div>

    <div class=" card-body">
        <ul class=" list-group list-group-flush list my--3">
            <li class=" list-group-item p-0 pt-2" *ngFor="let item of items">
                <div class=" row align-items-center">
                    <div class="col">
                        <h4 class=" mb-0 "> {{ item.label }}
                        <span
                            ngxClipboard
                            [cbContent]="'{{' + item.slug + '}\}'"
                            (cbOnSuccess)="processCopySuccess()"
                            [tooltip]="item.description"
                            placement="top"
                            class=" text-danger c-pointer"> <span>{{</span>{{item.slug}}<span>}}</span></span>
                        </h4>
                        <p> {{ item.type }}: {{ item.description }} &nbsp; </p>
                    </div>
                    <div class=" col-auto">                                          
                    
                    </div>
                </div>
            </li>
        </ul>
        <h5 class="text-center mb-2 mt-2" *ngIf="items.length === 0">No Variables</h5>
    </div>
</div>
