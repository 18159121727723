import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/internal/operators/take';
import { NodeKpiModel, NodeModel } from 'src/app/models/node';
import { NodesService } from 'src/app/services/firestore';
import { HumanInLoopService } from 'src/app/services/firestore/human-in-loop.service';

@Component({
  selector: 'app-analytics-filter-modal',
  templateUrl: './analytics-filter-modal.component.html',
  styleUrls: ['./analytics-filter-modal.component.scss'],
})
export class AnalyticsFilterModalComponent implements OnInit {
  botId: string;
  corpId: string;
  kpis: NodeKpiModel[];
  tags: string[];
  maxDate = new Date();
  bsValue = new Date();
  bsRangeValue: Date[];

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private nodesService: NodesService,
    private humanInLoopService: HumanInLoopService,
  ) {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsRangeValue = [this.bsValue, this.maxDate];

    if (!options.initialState) {
      return;
    }
    const { botId, corpId } = options.initialState as any;
    this.botId = botId;
    this.corpId = corpId;
  }

  async ngOnInit() {
    const nodes: NodeModel[] = await this.nodesService.getNodesByBotId(this.botId).pipe(take(1)).toPromise();

    const nodesWithKpis: NodeKpiModel[] = [];
    nodes.filter(node => {
      if (node.kpis && node.kpis.length) {
        node.kpis.map(kpi => {
          nodesWithKpis.push(kpi);
        });
      }
    });
    this.kpis = nodesWithKpis;

    const conversationTags = await this.humanInLoopService.getConversationTagsByCorpId(this.corpId);
    if (conversationTags) {
      this.tags = conversationTags;
    }
  }
}
