import { DependentPermissions, Permissions } from 'src/app/utils/permissions/permissions';
import uniq from 'lodash/fp/uniq';

const permissionsToAddOrRemove = (permission: Permissions, add: boolean): Permissions[] => {
  return DependentPermissions.reduce((permissionsToAdd, groupPermission) => {
    const permissionIndex = groupPermission.findIndex(permission$1 => permission$1 === permission);
    if (permissionIndex === -1) {
      return permissionsToAdd;
    }
    let thingsToAddOrRemove: Permissions[] = [];
    if (add) {
      thingsToAddOrRemove = [...groupPermission].splice(permissionIndex);
    } else {
      thingsToAddOrRemove = [...groupPermission].splice(0, permissionIndex);
    }
    return [...permissionsToAdd, ...thingsToAddOrRemove];
  }, []);
};

export const getPermissionsToAdd = (myPermissions: Permissions[], newPermission: Permissions) => {
  const permissionToAdd = permissionsToAddOrRemove(newPermission, true);
  return uniq([...myPermissions, ...permissionToAdd]);
};

export const getPermissionsToRemove = (myPermissions: Permissions[], permissionToRemove: Permissions) => {
  const permissionsToRemove = permissionsToAddOrRemove(permissionToRemove, false);
  return myPermissions.filter(permission => !permissionsToRemove.includes(permission));
};
