<ng-container *ngIf="loading">
  <div class=" container-fluid">
    <div class=" row align-items-center py-3 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>
<div class="container-fluid conversation-container bg-white" *ngIf="!loading">
  <div class="row h-100">
    <div class="col-5 h-100">
      <app-conversations-messages-search
        *ngIf="bot"
        [awsRegion]="awsRegion"
        [bot]="bot"
        [defaultBotLogo]="defaultBotLogo"
        [defaultBotName]="defaultBotName"
        [filter]="filter"
        (selectConversation)="selectedConversation = $event"
      >
      </app-conversations-messages-search>
    </div>
    <div class="col-7 h-100 d-flex flex-column" *ngIf="selectedConversation && bot">
      <h3 class="h3 py-3">
        Messages of conversation: <i>{{ selectedConversation.conversationId }}</i>
      </h3>
      <div class="border mb-2 h-100 overflow-auto">
        <app-conversation-messages
          *ngIf="selectedConversation && bot"
          [awsRegion]="awsRegion"
          [bot]="bot"
          [defaultBotLogo]="defaultBotLogo"
          [defaultBotName]="defaultBotName"
          [conversation]="selectedConversation"
        >
        </app-conversation-messages>
      </div>
    </div>
  </div>
</div>
