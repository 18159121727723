<div class="modal-header">
  <h4 class="modal-title">Rename NLP Dataset File</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
  <fieldset class="container">    
    <div class="form-group row">
      <label class="form-control-label" for="name">
        Name
      </label>
      <input
        class="form-control"
        id="name"
        name="name"        
        type="text"
        [(ngModel)]="newName"
        [placeholder]="newName"
        required
      />
    </div>
  </fieldset>
  <button    
    class="btn btn-primary btn-outline"
    type="submit"
    (click)="update()"
  >
    SAVE
  </button>
</div>

</div>
