import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  Query,
  OnChanges,
} from '@angular/core';
import * as firestore from 'firebase/firestore';
import { Subscription } from 'rxjs';
import { BotModel } from 'src/app/models/bot';
import {
  ConversationMessageModel,
  HLConversationModel,
  HLTemplateModel,
  IFilterCriteria,
  FilterFunctions,
  buildFirebaseQuery,
  HLCorp,
  HLUserResponseChannels,
} from 'src/app/models/conversations';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HlConversationComponent } from '../hl-conversation/hl-conversation.component';
import { ToastrService } from 'ngx-toastr';
import { HlConversationSearchModalComponent } from 'src/app/components/modals/hl-conversation-search-modal/hl-conversation-search-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HlConversationAssignUserModalComponent } from '../hl-conversation-assign-user-modal/hl-conversation-assign-user-modal.component';
import { UserModel, RoleModel } from 'src/app/models';
import { HumanInLoopService } from 'src/app/services/firestore/human-in-loop.service';
import { map, take } from 'rxjs/operators';
import { HlSelectNodeModalComponent } from '../hl-select-node-modal/hl-select-node-modal.component';
import { HlConversationsListComponent } from '../hl-conversations-list/hl-conversations-list.component';
import { HILMode } from 'src/app/pages/portal/human-in-the-loop/human-in-the-loop.component';
import { ClientEnvironmentModel, StageModel } from 'src/app/models/client-environment';
import { NodesService } from 'src/app/services/firestore';
import firebase from 'firebase/compat';
import QuerySnapshot = firebase.firestore.QuerySnapshot;

@Component({
  selector: 'app-human-in-the-loop-render',
  templateUrl: './human-in-the-loop-render.component.html',
  styleUrls: ['./human-in-the-loop-render.component.scss'],
})
export class HumanInTheLoopRenderComponent implements OnInit, OnDestroy, OnChanges {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100',
    maxHeight: '150',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'strikeThrough',
        'redo',
        'superscript',
        'subscript',
        'backgroundColor',
        'textColor',
        'insertVideo',
        'insertImage',
      ],
      ['fontSize', 'fontName'],
    ],
  };

  message = '';
  focus: boolean;
  loading: boolean;
  loadingMoreTop = false;
  loadingMoreBottom = false;
  loadingMessages = true;
  selectedConversationId: string | null;

  @Input()
  bot: BotModel;

  @Input()
  envs: ClientEnvironmentModel;

  @Input()
  botCode: string;

  @Input()
  botFullId: string;

  @Input()
  corpId: string;

  @Input()
  hierarchy: string;

  @Input()
  mode: HILMode;

  @Input()
  conversationId: string | null;

  @Output()
  refreshUI: EventEmitter<void>;

  messagesSubscription: Subscription;
  conversationsSubscription: Subscription;
  conversationsIdsSubscription: Subscription;
  botHumanTemplatesSubscription: Subscription;
  botUsersSubscription: Subscription;
  selectNodeSubscription: Subscription;
  envSubscription: Subscription;
  tagsSubscription: Subscription;
  hilSupportRoleSubscription: Subscription;

  humanTemplates: HLTemplateModel[];
  conversationIds: string[];
  conversations: HLConversationModel[];
  conversationSearchResult: HLConversationModel[] = [];
  selectedConversation: HLConversationModel | null = null;
  currentMessages: ConversationMessageModel[];
  messageIds: string[];
  conversationsLastMessage: Record<string, any> = {};
  conversationMessageIds: Map<string, string[]> = new Map();
  conversationMessage: Map<string, ConversationMessageModel[]> = new Map();
  conversationsTags = {};

  conversationUpdatedAt = '';
  serviceRep = '';
  currentConversationUserName = '';
  shopBotUrl: string;
  botUrl: string | null;

  searchMode = false;
  filterFunctions = FilterFunctions;

  lastFilterCriteria: IFilterCriteria[] = [];

  botUsers: UserModel[] = [];
  rolesWithHILSupport: RoleModel[] = [];

  assignedUsers: UserModel[] = [];

  selectedUserResponseChannel: HLUserResponseChannels;

  currentUser: UserModel | null;
  conversationsLoaded = false;
  loadingMessagesTop = false;
  loadingMessagesBottom = false;
  loadingConversations = false;

  corpTags: string[];
  hilSupportRoles: RoleModel[];

  lastDoc: any;
  firstDoc: any;
  lastMessageDoc: firestore.QueryDocumentSnapshot<firestore.DocumentData>;
  firstMessageDoc: firestore.QueryDocumentSnapshot<firestore.DocumentData>;

  @ViewChildren('conversationView') conversationView: QueryList<HlConversationComponent>;

  @ViewChildren('conversationsList') conversationsList: QueryList<HlConversationsListComponent>;

  private HANDOVER_ENDED = 'Handover ended!';

  queryListener;
  messagesQueryListener;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private toaster: ToastrService,
    private modalService: BsModalService,
    private humanInLoopService: HumanInLoopService,
    private nodesService: NodesService,
    private route: ActivatedRoute,
  ) {
    this.shopBotUrl = environment.shopbot.url;
    this.refreshUI = new EventEmitter<void>();
  }

  async ngOnChanges() {
    this.commonInit();
  }

  receiveSelectedUserResponseChannel($channelName: HLUserResponseChannels) {
    this.selectedUserResponseChannel = $channelName;
  }

  async commonInit() {
    this.route.params.subscribe(async p => {
      const { corp, conversationId, hierarchyElementSystemName } = p as any;
      this.corpId = corp;
      this.conversationId = conversationId;
      this.hierarchy = hierarchyElementSystemName;
      this.currentUser = await this.authService.getCurrentUserProfile();
      this.loadConversations();

      if (this.botHumanTemplatesSubscription) {
        this.botHumanTemplatesSubscription.unsubscribe();
      }
      this.botHumanTemplatesSubscription = this.humanInLoopService
        .getCorpHLTemplates(this.corpId)
        .subscribe(templates => {
          this.humanTemplates = templates;
          if (this.conversationView.length > 0) {
            this.conversationView.first.updateTemplates(this.humanTemplates);
          }
        });

      if (this.tagsSubscription) {
        this.tagsSubscription.unsubscribe();
      }
      this.tagsSubscription = this.humanInLoopService.getCorpTags(this.corpId).subscribe(doc => {
        if (doc) {
          this.corpTags = doc.conversation_tags;
        }
      });
      const corpId = this.corpId;
      const botCode = this.botCode;
      const userIds = await this.humanInLoopService.getBotUsersIds(corpId, botCode);

      if (this.hilSupportRoleSubscription) {
        this.hilSupportRoleSubscription.unsubscribe();
      }
      this.hilSupportRoleSubscription = this.humanInLoopService.getHILSupportedRoles().subscribe(roles => {
        this.hilSupportRoles = roles;
      });

      if (this.botUsersSubscription) {
        this.botUsersSubscription.unsubscribe();
      }

      this.botUsersSubscription = this.humanInLoopService.getBotUsersCollectionByUserIds(userIds).subscribe(users => {
        this.botUsers = users;
      });
      this.refreshUI.emit();
    });
  }
  async ngOnInit() {
    this.commonInit();
  }

  public loadMoreConversationTop() {
    if (this.loadingMoreTop) {
      return;
    }
    this.loadingMoreTop = true;

    let ref = this.getConversationsRef();
    if (!ref) {
      this.loadingMoreTop = false;
      return;
    }
    ref = ref.orderBy('lastUpdated', 'desc');
    if (this.firstDoc) {
      ref = ref.endBefore(this.firstDoc);
    } else {
      // limit to only first 20
      ref = ref.limit(20);
    }
    ref.get().then(snapshot => {
      this.firstDoc = snapshot.docs[0];

      const result = this.getConversationsFromSnapshot(snapshot);
      this.updateConversations(result, true);
      this.loadingMoreTop = false;
    });
  }

  public loadMoreConversationBottom() {
    if (this.loadingMoreBottom) {
      return;
    }
    this.loadingMoreBottom = true;

    let ref = this.getConversationsRef();
    if (!ref) {
      this.loadingMoreBottom = false;
      return;
    }
    ref = ref.orderBy('lastUpdated', 'desc');
    if (this.lastDoc) {
      ref = ref.startAfter(this.lastDoc);
    }
    ref = ref.limit(20);
    ref.get().then(snapshot => {
      this.lastDoc = snapshot.docs[snapshot.docs.length - 1];
      const result = this.getConversationsFromSnapshot(snapshot);
      this.updateConversations(result, false);
      this.loadingMoreBottom = true;
    });
  }

  updateConversations(result: HLConversationModel[], addToTop: boolean) {
    if (!this.conversations) {
      this.conversations = result;
      this.conversationIds = result.map(item => item.id);
    } else {
      if (!this.conversationIds || this.conversationIds.length === 0) {
        this.conversations = result;
        this.conversationIds = result.map(item => item.id);
        return;
      }
      const newItems = result.filter(item => !this.conversationIds.includes(item.id));
      const updatedItems = new Map(
        result.filter(item => this.conversationIds.includes(item.id)).map(item => [item.id, item]),
      );

      let all: HLConversationModel[] = this.conversations.map(item => {
        if (updatedItems.has(item.id)) {
          const found = updatedItems.get(item.id);
          if (found) {
            return found;
          }
        }
        return item;
      });

      if (addToTop) {
        all = newItems.concat(all);
      } else {
        all = all.concat(newItems);
      }

      // update existing conversations
      this.conversations = all;
      this.conversationIds = all.map(item => item.id);
    }
  }

  getConversationsFromSnapshot(snapshot: QuerySnapshot<HLConversationModel>) {
    return snapshot.docs.map(doc => {
      return this.getConversationFromDoc(doc);
    });
  }

  getConversationFromDoc(doc) {
    const conversation = doc.data() as HLConversationModel;

    conversation.id = doc.id;
    Object.defineProperty(conversation, 'lastUpdatedString', {
      get() {
        return moment(this.lastUpdated.toDate()).fromNow();
      },
      set(v) {
        // lastUpdatedString read-only
      },
    });

    if (conversation.tags) {
      const tagCount = conversation.tags.length;
      if (tagCount === 1) {
        conversation.tagToolTipText = conversation.tags[0];
      } else if (tagCount === 2) {
        conversation.tagToolTipText = `${conversation.tags[0]} and ${conversation.tags[1]}`;
      } else if (tagCount > 2) {
        conversation.tagToolTipText = `${conversation.tags[0]}, ${conversation.tags[1]} + ${tagCount - 2} more.`;
      } else {
        conversation.tagToolTipText = 'Tags';
      }
    } else {
      conversation.tagToolTipText = 'Tags';
    }

    return conversation;
  }

  getConversationsRef() {
    // show only conversation that active past 20 days
    const ts = Date.now() - 20 * 24 * 60 * 60000;

    const date = new Date(ts);
    if (this.mode === HILMode.Bot) {
      const refCol = this.humanInLoopService.getCorpBotConversationsCollection(this.corpId, this.botFullId).ref;
      const ref = refCol.where('lastUpdated', '>=', date).orderBy('lastUpdated', 'asc');
      return ref;
    } else if (this.mode === HILMode.Corp) {
      const refCol = this.humanInLoopService.getCorpConversationsCollection(this.corpId).ref;
      const ref = refCol.where('lastUpdated', '>=', date).orderBy('lastUpdated', 'asc');
      return refCol;
    } else if (this.mode === HILMode.Hierarchy) {
      const refCol = this.humanInLoopService.getHierarchyConversationsCollection(this.corpId, this.hierarchy).ref;
      const ref = refCol.where('lastUpdated', '>=', date).orderBy('lastUpdated', 'asc');
      return ref;
    }
  }
  shouldAddConversation(doc: HLConversationModel) {
    if (doc.lastMessage) {
      if (doc.lastMessage === this.HANDOVER_ENDED) {
        return false;
      }
    }
    if (this.mode === HILMode.Bot) {
      if (doc.botId === this.botFullId) {
        return true;
      }
    } else if (this.mode === HILMode.Hierarchy) {
      if (doc.hierarchyElements && doc.hierarchyElements.includes(this.hierarchy)) {
        return true;
      }
    } else if (this.mode === HILMode.Corp) {
      return true;
    }
    return false;
  }

  async loadConversations() {
    this.loadingConversations = true;
    let preloadConversation: HLConversationModel;
    let preloaded = false;
    if (this.conversationId) {
      const doc = await this.humanInLoopService
        .getCorpConversationsCollection(this.corpId)
        .doc(this.conversationId)
        .get()
        .toPromise();
      if (doc) {
        preloadConversation = this.getConversationFromDoc(doc);
        let all: HLConversationModel[] = this.conversations ?? [];
        all = all.filter(x => x.id !== preloadConversation.id);
        if (this.shouldAddConversation(preloadConversation)) {
          all.push(preloadConversation);
          preloaded = true;
        }
        this.conversations = all;
        if (preloaded) {
          this.refreshConversation(preloadConversation);
          setTimeout(() => {
            this.conversationsList.first.scrollToConversation(preloadConversation.id);
          }, 500);
        }
      }
    }

    const ref = this.getConversationsRef();
    const ref2 = this.getConversationsRef();

    if (this.queryListener) {
      this.queryListener();
    }
    if (ref) {
      ref
        .limit(50) // preload first 50 conversations
        .get()
        .then(snapshots => {
          let all: HLConversationModel[] = this.conversations ? [...this.conversations.reverse()] : [];
          const result = this.getConversationsFromSnapshot(snapshots);
          result.forEach(doc => {
            all = all.filter(x => x.id !== doc.id);
            if (this.shouldAddConversation(doc)) {
              all.push(doc);
            }
          });
          this.conversations = all.sort((a, b) => {
            return b.lastUpdated.toDate() - a.lastUpdated.toDate();
          });
          if (!preloaded) {
            if (this.conversations.length > 0) {
              this.refreshConversation(this.conversations[0]);
            }
          }
          const start = snapshots.docs[snapshots.docs.length - 1];

          // create subscription for new conversations
          if (ref2) {
            this.queryListener = ref2
              .startAfter(start)
              .limitToLast(50)
              .onSnapshot(
                snapshot => {
                  let latestConversations: HLConversationModel[] = this.conversations
                    ? [...this.conversations.reverse()]
                    : [];
                  const newResult: HLConversationModel[] = [];
                  snapshot
                    .docChanges()
                    .filter(f => f.type === 'added' || f.type === 'modified')
                    .forEach(doc => {
                      newResult.push(this.getConversationFromDoc(doc.doc));
                    });
                  newResult.forEach(doc => {
                    latestConversations = latestConversations.filter(x => x.id !== doc.id);
                    if (this.shouldAddConversation(doc)) {
                      latestConversations.push(doc);
                    }
                  });
                  if (!this.loadingConversations && latestConversations.length > this.conversations.length) {
                    setTimeout(() => {
                      this.toaster.success('New conversations started');
                    }, 500);
                  }
                  this.conversations = latestConversations.sort((a, b) => {
                    return b.lastUpdated.toDate() - a.lastUpdated.toDate();
                  });
                  this.loadingConversations = false;
                },
                error => {
                  console.log('Error: ', error);
                  this.loadingConversations = false;
                },
              );
          }
          this.loading = false;
        });
    }
  }

  getBotCodeFromBotFullId(botFullId: string) {
    const parts = botFullId.split('-');
    let botCode = parts[parts.length - 1];
    if (this.envs && this.envs.stages) {
      const env = this.envs.stages.filter(e => {
        const suffix = `_${e.systemName}`;
        return botCode.endsWith(suffix);
      });

      if (env.length > 0) {
        const suffix = `_${env[0].systemName}`;
        botCode = botCode.slice(0, botCode.length - suffix.length);
      }
    }
    return botCode;
  }

  async refreshConversation(conversation: HLConversationModel) {
    this.currentConversationUserName = '';
    this.conversationUpdatedAt = moment(conversation.lastUpdated.toDate()).fromNow();
    this.botUrl = null;
    if (conversation.hierarchyElements && conversation.hierarchyElements.length > 0) {
      const lastHierarchy = conversation.hierarchyElements[conversation.hierarchyElements.length - 1];
      let botCode = this.getBotCodeFromBotFullId(conversation.botId);
      this.botUrl = `/portal/corps/${this.corpId}/hierarchy-el/${lastHierarchy}/bots/${botCode}`;
    }
    const currentUser = await this.authService.getCurrentUserProfile();
    this.serviceRep = currentUser?.fullName || '';
    this.selectedConversation = conversation;
    this.selectedConversationId = conversation.id;
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }

    const conversationId = conversation.id;
    this.assignedUsers = await this.humanInLoopService.getConversationAgents(conversation.id);
    this.currentConversationUserName = this.selectedConversation.user
      ? this.selectedConversation.user.firstName
      : 'Guest';
    this.currentMessages = [];

    this.loadConversationMessages(conversation.id);
  }

  async loadConversationMessages(conversationId: string) {
    this.loadingMessages = true;
    this.currentMessages = [];
    if (this.messagesQueryListener) {
      // stop receiving update
      this.messagesQueryListener();
    }
    const conversationsRef = this.humanInLoopService.getCorpConversationsCollection(this.corpId);
    const messagesRef = conversationsRef.doc(conversationId).collection<ConversationMessageModel>('messages'); // :eyes
    const ref = messagesRef.ref;
    ref
      .orderBy('createdAt', 'asc')
      .limit(50) // preload first 50 messages
      .get()
      .then(snapshots => {
        let all = this.currentMessages;
        snapshots.docs.forEach(doc => {
          const m = this.buildMessageFromDoc(doc);
          if (!ConversationMessageModel.isSystemMessage(m)) {
            all = all.filter(x => x.id !== doc.id);
            all.push(m);
          }
        });
        this.currentMessages = all;
        const start = snapshots.docs[snapshots.docs.length - 1];

        // create subscription for new messages
        this.messagesQueryListener = ref
          .orderBy('createdAt', 'asc')
          .startAt(start)
          .onSnapshot(
            snapshot => {
              let existingMessages = this.currentMessages;
              snapshot.forEach(doc => {
                const m = this.buildMessageFromDoc(doc);
                existingMessages = existingMessages.filter(x => x.id !== doc.id);
                existingMessages.push(m);
              });
              this.currentMessages = existingMessages;
            },
            error => {
              // nothing
            },
          );
        this.loadingMessages = false;
      })
      .catch(error => {
        this.loadingMessages = false;
      });
  }

  buildMessageFromDoc(doc) {
    const m = doc.data() as ConversationMessageModel;
    Object.defineProperty(m, 'formattedTime', {
      get() {
        return moment(this.createdAt.toDate()).fromNow();
      },
      set(v) {
        // ignore, id is read-only
      },
    });
    const localResponseMessage = ConversationMessageModel.getLocalResponseMessage(m.message);
    if (localResponseMessage) {
      m.message = `Local Response Button: ${localResponseMessage}`;
    }
    return m;
  }

  setConversationLastMessage() {
    if (this.currentMessages && this.currentMessages.length > 0) {
      const lastMessage = this.currentMessages[this.currentMessages.length - 1];
      if (this.selectedConversationId) {
        this.conversationsLastMessage[this.selectedConversationId] = lastMessage;
      }
      if (this.selectedConversation) {
        this.selectedConversation.channel = lastMessage.channel as HLUserResponseChannels;
      }
    }
  }
  updateConversationMessages(conversationId: string, messages: ConversationMessageModel[], addToTop: boolean) {
    if (!this.currentMessages) {
      this.currentMessages = messages;
      this.conversationMessageIds[conversationId] = messages.map(item => item.id);
      this.setConversationLastMessage();
    } else {
      const messagesIds = this.conversationMessageIds[conversationId];
      if (!messagesIds || messagesIds.length === 0) {
        this.currentMessages = messages;
        this.conversationMessageIds[conversationId] = messages.map(item => item.id);
        this.setConversationLastMessage();
        return;
      }
      const newItems = messages.filter(item => !messagesIds.includes(item.id));
      const updatedItems = new Map(messages.filter(item => messagesIds.includes(item.id)).map(item => [item.id, item]));

      let all: ConversationMessageModel[] = this.currentMessages.map(item => {
        if (updatedItems.has(item.id)) {
          const found = updatedItems.get(item.id);
          if (found) {
            return found;
          }
        }
        return item;
      });

      if (addToTop) {
        all = newItems.concat(all);
      } else {
        all = all.concat(newItems);
      }

      // update existing conversations
      this.currentMessages = all;
      this.conversationMessageIds[conversationId] = all.map(item => item.id);
      this.setConversationLastMessage();
    }
  }

  ngOnDestroy() {
    if (this.queryListener) {
      this.queryListener();
    }
    if (this.messagesQueryListener) {
      this.messagesQueryListener();
    }
    if (this.tagsSubscription) {
      this.tagsSubscription.unsubscribe();
    }
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
    if (this.conversationsSubscription) {
      this.conversationsSubscription.unsubscribe();
    }
    if (this.conversationsIdsSubscription) {
      this.conversationsIdsSubscription.unsubscribe();
    }
    if (this.botHumanTemplatesSubscription) {
      this.botHumanTemplatesSubscription.unsubscribe();
    }
    if (this.botUsersSubscription) {
      this.botUsersSubscription.unsubscribe();
    }
    if (this.selectNodeSubscription) {
      this.selectNodeSubscription.unsubscribe();
    }

    if (this.selectNodeSubscription) {
      this.selectNodeSubscription.unsubscribe();
    }
    if (this.envSubscription) {
      this.envSubscription.unsubscribe();
    }
    if (this.hilSupportRoleSubscription) {
      this.hilSupportRoleSubscription.unsubscribe();
    }
  }

  async stopBotMessages(conversation) {
    // /api/handover/carlabs/initialize
    // initializing handover, so the bot wont respond anymore
    if (conversation) {
      const url = this.shopBotUrl + '/api/handover/carlabs/initialize';
      const body = {
        botFullId: conversation.botId,
        conversationId: conversation.id,
      };

      const res = await this.http
        .post(url, body)
        .toPromise()
        .catch(err => {
          this.toaster.error(`Could not complete operation: ${err}`);
        })
        .then(response => {
          this.toaster.success('Conversation initialized successfully.');
        });
    }
  }

  async endBotHandover(conversation) {
    // /api/handover/carlabs/endhandover
    // ending handover, so the bot can respond again
    if (conversation) {
      const url = this.shopBotUrl + '/api/handover/carlabs/endhandover';
      const body = {
        botFullId: conversation.botId,
        conversationId: conversation.id,
      };

      const res = await this.http
        .post(url, body)
        .toPromise()
        .catch(err => {
          this.toaster.error(`Could not complete operation: ${err}`);
        })
        .then(response => {
          this.toaster.success('Ended handover successfully.');
        });
    }
  }

  async sendMessage(message) {
    if (this.currentUser) {
      this.message = message;
      if (this.selectedConversation) {
        // TODO move this to shopbot.service
        const url = this.shopBotUrl + '/api/handover/carlabs';
        const body = {
          conversationId: this.selectedConversation.id,
          channel: this.selectedUserResponseChannel,
          message: this.message,
          agentId: this.currentUser.id,
          botFullId: this.selectedConversation.botId,
        };
        await this.humanInLoopService.touchConversation(this.corpId, this.selectedConversation.id);
        await this.http
          .post(url, body)
          .toPromise()
          .catch(_error => {
            this.toaster.error('Could not send message. Try again.');
          })
          .then(_response => {
            if (this.conversationView && this.conversationView.length > 0) {
              this.conversationView.first.scrollToBottom();
            }
            this.message = '';
          });
      }
    }
  }

  async saveTemplate(template: HLTemplateModel) {
    await this.humanInLoopService
      .createCorpHLTemplate(this.corpId, template)
      .then(_res => {
        this.toaster.success('Template saved successfully.');
      })
      .catch(_err => {
        this.toaster.error('Could not save template. Try again.');
      });
  }

  async deleteTemplate(template: HLTemplateModel) {
    await this.humanInLoopService
      .deleteCorpHLTemplate(this.corpId, template)
      .then(_res => {
        this.toaster.success('Template deleted successfully.');
      })
      .catch(_err => {
        this.toaster.error('Could not delete template. Try again.');
      });
  }

  async saveConversationTags(tags, conversationId) {
    try {
      if (!this.selectedConversation) {
        return;
      }
      this.conversationsTags[conversationId] = tags;
      this.selectedConversation.tags = tags;
      // move this to shopbot.service

      // api/handover/carlabs/tag

      // move this to shopbot.service

      const url = this.shopBotUrl + '/api/handover/carlabs/tags';
      const body = {
        operation: 'add',
        tagNames: tags,
        conversationId: this.selectedConversation.id,
        botFullId: this.selectedConversation.botId,
      };
      await this.humanInLoopService.touchConversation(this.corpId, this.selectedConversation.id);

      await this.http.post(url, body).toPromise();

      this.toaster.success('Conversation tags saved successfully.');
    } catch (error) {
      this.toaster.error('Could not save conversation tags. Try again.');
    }
  }

  async assignAgent() {
    if (this.selectedConversationId && this.selectedConversation) {
      const conversationBotId = this.selectedConversation.botId;
      const conversationId = this.selectedConversationId;
      const botCode = this.getBotCodeFromBotFullId(conversationBotId);
      this.humanInLoopService
        .getBotUsers(this.corpId, botCode, this.currentUser)
        .then(conversationBotUsers => {
          const users = conversationBotUsers.filter(u => {
            // check user has HIL Support Role
            if (this.hilSupportRoles) {
              return this.hilSupportRoles.filter(role => role.systemName == u.role).length > 0;
            }
            return true;
          });

          this.humanInLoopService.getConversationAgents(conversationId).then(assignedUsers => {
            this.assignedUsers = assignedUsers;
            const modalRef = this.modalService.show(HlConversationAssignUserModalComponent, {
              initialState: {
                users,
                assignedUsers,
              },
            });

            modalRef.content.save.pipe(take(1)).subscribe(async (user: UserModel) => {
              modalRef.hide();
              const url = this.shopBotUrl + '/api/handover/carlabs/assign';

              let email: string | null = null;
              if (user.notificationSettings) {
                if (user.notificationSettings.email) {
                  email = user.email;
                }
              } else {
                // if user has not notification settings
                // default to sending them email
                email = user.email;
              }

              const body = {
                agentId: user.id,
                notificationEmail: email,
                conversationId,
                botFullId: conversationBotId,
                assignedBy:
                  this.currentUser != null ? `${this.currentUser.firstName} ${this.currentUser.lastName}` : '',
                operation: 'add',
              };
              try {
                await this.http.post(url, body).toPromise();
                if (this.selectedConversationId) {
                  this.assignedUsers = await this.humanInLoopService.getConversationAgents(this.selectedConversationId);
                }
                this.toaster.success('Conversation assigned successfully.');
              } catch (error) {
                this.toaster.error('Could not assign conversation to user.');
              }
            });

            modalRef.content.removeUser.pipe(take(1)).subscribe(async (user: UserModel) => {
              modalRef.hide();

              const url = this.shopBotUrl + '/api/handover/carlabs/assign';
              let email: string | null = null;
              if (user.notificationSettings) {
                if (user.notificationSettings.email) {
                  email = user.email;
                }
              }

              const body = {
                agentId: user.id,
                notificationEmail: email,
                conversationId,
                botFullId: conversationBotId,
                assignedBy:
                  this.currentUser != null ? `${this.currentUser.firstName} ${this.currentUser.lastName}` : '',
                operation: 'remove',
              };

              try {
                await this.http.post(url, body).toPromise();
                if (this.selectedConversationId) {
                  this.assignedUsers = await this.humanInLoopService.getConversationAgents(this.selectedConversationId);
                }
                this.toaster.success('Agent removed from conversation.');
              } catch (error) {
                this.toaster.error('Could not remove agent from conversation.');
              }
            });
          });
        })
        .catch(error => {
          console.log('Error: ', error);
        });
    }
  }

  updateConversationUI() {
    if (this.conversationSearchResult.length === 0) {
      if (this.conversationView && this.conversationView.length > 0) {
        this.conversationView.first.selectedConversationId = '';
        this.selectedConversation = null;
        this.selectedConversationId = null;
        return;
      }
    }
    if (this.conversationSearchResult.length > 0) {
      if (this.conversationSearchResult.filter(f => f.id === this.selectedConversationId).length === 0) {
        if (this.conversationView && this.conversationView.length > 0) {
          this.conversationView.first.selectedConversationId = null;
          this.selectedConversation = null;
          this.selectedConversationId = null;
        }
      }
    }
  }
  searchConversations(query: string) {
    const isNeedleInHaystack = (needle: string) => haystack => {
      return haystack && haystack.toLowerCase().includes(needle.toLowerCase());
    };

    if (query.trim().length > 0) {
      const isQueryPresent = isNeedleInHaystack(query);
      this.conversationSearchResult = this.conversations.filter(convo => {
        if (convo.userData) {
          const { fullName, lastName, firstName } = convo.userData;
          return isQueryPresent(fullName) || isQueryPresent(lastName) || isQueryPresent(firstName);
        }
        return false;
      });
      if (this.conversationSearchResult.length === 0) {
        // search with id
        this.conversationSearchResult = this.conversations.filter(c => {
          return c.id.trim().toLowerCase() === query.trim().toLowerCase();
        });
      }
      this.searchMode = true;
      this.updateConversationUI();
      return;
    }
    this.searchMode = false;
  }
  async applyConversationFilters(criteria: IFilterCriteria[]) {
    const conversationRef = this.getConversationsRef();
    if (conversationRef) {
      // look for firebase supported filters
      const firebaseFilters = criteria.filter(item => {
        if (item.firebaseFilter) {
          return true;
        }
        return false;
      });
      if (firebaseFilters.length > 0) {
        // apply firebase queries
        const ref = buildFirebaseQuery(conversationRef, firebaseFilters);
        ref
          .get()
          .then(snapshot => {
            let result = snapshot.docs
              .map(doc => {
                const conversation = doc.data() as HLConversationModel;
                conversation.id = doc.id;
                conversation.lastUpdatedString = moment(conversation.lastUpdated.toDate()).fromNow();
                return conversation;
              })
              .filter(conversation => {
                return this.shouldAddConversation(conversation);
              });
            // apply other filters
            criteria.forEach(c => {
              result = result.filter(convo => {
                return this.applyFilter(convo, c);
              });
            });
            this.lastFilterCriteria = criteria;
            this.conversationSearchResult = result;
            this.searchMode = true;
            this.updateConversationUI();
          })
          .catch(error => {
            console.log('Error: ', error);
            if (conversationRef) {
              this.filterAllConversations(conversationRef, criteria);
            }
          });
      } else {
        // default to filter on all conversations
        this.filterAllConversations(conversationRef, criteria);
      }
    }
  }

  filterAllConversations(conversationRef, criteria: IFilterCriteria[]) {
    conversationRef.get().then(snapshot => {
      let result = snapshot.docs
        .map(doc => {
          const conversation = doc.data() as HLConversationModel;
          conversation.id = doc.id;
          conversation.lastUpdatedString = moment(conversation.lastUpdated.toDate()).fromNow();
          return conversation;
        })
        .filter(conversation => {
          return this.shouldAddConversation(conversation);
        });
      criteria.forEach(c => {
        result = result.filter(convo => {
          return this.applyFilter(convo, c);
        });
      });
      this.lastFilterCriteria = criteria;
      this.conversationSearchResult = result;
      this.searchMode = true;
      this.updateConversationUI();
    });
  }
  applyFirebaseFilter(query: firebase.firestore.Query, criteria: IFilterCriteria[]) {
    const ref = buildFirebaseQuery(query, criteria);
    return ref;
  }
  applyFilter(conversation: HLConversationModel, criteria: IFilterCriteria) {
    const filterFunctionGroup = this.filterFunctions[criteria.id];
    if (!filterFunctionGroup) {
      return true;
    }
    const filterFunction = filterFunctionGroup[criteria.operator.id];
    if (!filterFunction) {
      return true;
    }
    return filterFunction(conversation, criteria);
  }
  async filterConversation(event) {
    const modalRef = this.modalService.show(HlConversationSearchModalComponent, {
      initialState: {
        filters: this.lastFilterCriteria,
      },
      ignoreBackdropClick: true,
    });

    modalRef.content.filter.pipe(take(1)).subscribe(criteria => {
      modalRef.hide();
      this.applyConversationFilters(criteria);
    });

    modalRef.content.clear.pipe(take(1)).subscribe(() => {
      modalRef.hide();
      this.clearConversationFilter(null);
    });
  }

  async clearConversationFilter(event) {
    this.searchMode = false;
    this.lastFilterCriteria = [];
    if (this.selectedConversationId) {
      const conversationId = this.selectedConversationId;
      setTimeout(() => {
        if (conversationId) {
          this.conversationsList.first?.scrollToConversation(conversationId);
        }
      });
    }
  }

  async removeConversationTag(tag) {
    if (!this.selectedConversation) {
      return;
    }
    const tags = this.selectedConversation.tags;
    const index = tags.indexOf(tag);
    tags.splice(index, 1);
    this.selectedConversation.tags = tags;
    const url = this.shopBotUrl + '/api/handover/carlabs/tags';
    const body = {
      operation: 'remove',
      tagNames: [tag],
      conversationId: this.selectedConversation.id,
      botFullId: this.selectedConversation.botId,
    };
    try {
      await this.http.post(url, body).toPromise();
      this.toaster.success('Tag removed from conversation.');
    } catch (error) {
      this.toaster.error('Could not remove conversation tag. Try again.');
    }
  }

  async getBotNodesForSupport() {
    if (!this.selectedConversation) {
      return;
    }
    const nodes = await this.nodesService.getSupportNodesForBot(this.selectedConversation.botId);
    const modalRef = this.modalService.show(HlSelectNodeModalComponent, {
      initialState: {
        nodes,
      },
    });

    if (this.selectNodeSubscription) {
      this.selectNodeSubscription.unsubscribe();
    }
    this.selectNodeSubscription = modalRef.content.selectedNode.subscribe(node => {
      modalRef.hide();
      this.selectNode(node);
    });
  }

  async selectNode({ node, channel }) {
    if (this.selectedConversationId) {
      const conversationId = this.selectedConversationId;
      const url = this.shopBotUrl + '/api/handover/carlabs/setnode';
      const body = {
        nodeId: node.id,
        conversationId,
        botFullId: this.selectedConversation?.botId,
        channel,
      };
      await this.humanInLoopService.touchConversation(this.corpId, this.selectedConversationId);
      try {
        await this.http.post(url, body).toPromise();
        this.toaster.success('Conversation node set successfully.');
      } catch (error) {
        this.toaster.error('Could not set conversation node.');
      }
    }
  }
}
