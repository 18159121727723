import { Component, OnInit } from '@angular/core';
import { Router, Event } from '@angular/router';
import { SidebarItemVM } from './_types/SidebarItemVM';
import { SidebarService } from 'src/app/services/sidebar.service';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { AuthService } from 'src/app/services/auth.service';
import { Permissions } from 'src/app/utils/permissions/permissions';

const misc: any = {
  sidebar_mini_active: true
};

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public items: SidebarItemVM[] | null;
  public isCollapsed = true;
  public currentYear: number;
  onPortalSide: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private sidebarService: SidebarService
  ) {
    this.items = [];
    this.router.events.subscribe((event: Event) => {
      const path = get(event, ['url']);
      if (path) {
        this.processOnPortalSide(path);
        this.isCollapsed = true;
      }
    });
  }

  ngOnInit() {
    this.sidebarService.items$.subscribe(newItems => {
      this.items = newItems;
    });

    this.currentYear = dayjs().year();
  }

  processOnPortalSide(path: string) {
    if (path.includes('portal')) {
      this.onPortalSide = true;
    } else {
      this.onPortalSide = false;
    }
  }

  isAdmin(): boolean {
    return this.authService.hasPermissionSync(Permissions.IS_ADMIN);
  }

  onMouseEnterSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName('sidenav-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('g-sidenav-pinned')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('g-sidenav-pinned');
      body.classList.add('g-sidenav-hidden');
      sidenavToggler.classList.remove('active');
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add('g-sidenav-pinned');
      body.classList.remove('g-sidenav-hidden');
      sidenavToggler.classList.add('active');
      misc.sidebar_mini_active = true;
    }
  }
}
