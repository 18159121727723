import { Component, Input, Output, OnInit, EventEmitter, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NlpModel } from 'src/app/models/nlp/nlp-model/nlp-model';
import {
  NodeModel,
  NodeConnectionModel,
  NodeConditionalModel,
  NodeConditionalOperator,
  NodeApiQueryModel,
  NodeValidationModel,
  NodeHttpRequest,
} from 'src/app/models/node';
import { v4 as uuidv4 } from 'uuid';
import { TemplateModel, TemplateChannelOptions } from 'src/app/models/template';
import { InputValidationModel } from 'src/app/models/input-validation';
import { ApiQueryModel } from 'src/app/models/api-query';
import { EditTemplateModalComponent } from './_components/edit-template-modal/edit-template-modal.component';
import { EditInputDataModalComponent } from './_components/edit-input-data-modal/edit-input-data-modal.component';
import { EditSpecTemplateModalComponent } from './_components/edit-spec-template-modal/edit-spec-template-modal.component';
import { UpsertHttpRequestModalComponent } from './_components/upsert-http-request-modal/upsert-http-request-modal.component';
import dayjs from 'dayjs';
import { SpecTemplateModel } from 'src/app/models/spec_template';
import { AddNodeKpiModalComponent } from '../../modals/add-node-kpi-modal/add-node-kpi-modal.component';
import { getBotUniqueKpis } from './utils';
import { FlowEditorEvents } from '../flow-editor/events/flow-editor.events';
import { GlobalVariable } from 'src/app/models/bot';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { undefinedOrHasValue, validOperand } from 'src/app/utils/validation/validation';
import { validationMessages } from 'src/app/utils/validation/validation-messages';
import { get } from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bot-flow-sidebar',
  templateUrl: './bot-flow-sidebar.component.html',
  styleUrls: ['./bot-flow-sidebar.component.scss'],
})
export class BotFlowSidebarComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  selectedNode: NodeModel;

  @Input()
  nlpModel: NlpModel;

  @Input()
  specs: string[];

  @Input()
  specTemplates: { [key: string]: SpecTemplateModel[] } = {};

  @Input()
  apiQueries: ApiQueryModel[];

  @Input()
  canUserManage: boolean;

  @Input()
  validations: InputValidationModel[] = [];

  @Input()
  apiVariableItems: GlobalVariable[] = [];

  @Input()
  nodes: NodeModel[] = [];

  @Input()
  allowSaveChanges: boolean;

  @Output()
  updateFlow: EventEmitter<void>;

  @Output()
  duplicate: EventEmitter<NodeModel>;

  @Output()
  remove: EventEmitter<NodeModel>;

  selectedUsedChannelIds: string[] | undefined = [];

  saveDebounce = new Subject<string>();

  subscriptions = new Subscription();
  connectionSubscriptions = new Subscription();
  validationSubscriptions = new Subscription();
  apiQueriesSubscriptions = new Subscription();

  flowSidebarForm: FormGroup;

  channels = TemplateChannelOptions;

  ops = [
    { label: 'EQ', value: NodeConditionalOperator.Eq },
    { label: 'NEQ', value: NodeConditionalOperator.Neq },
    { label: 'LT', value: NodeConditionalOperator.Lt },
    { label: 'LTE', value: NodeConditionalOperator.Lte },
    { label: 'GT', value: NodeConditionalOperator.Gt },
    { label: 'GTE', value: NodeConditionalOperator.Gte },
    { label: 'TRUTHY', value: NodeConditionalOperator.Truthy },
    { label: 'FALSY', value: NodeConditionalOperator.Falsy },
  ];

  andOrOps = [
    { label: 'AND', value: NodeConditionalOperator.And },
    { label: 'OR', value: NodeConditionalOperator.Or },
  ];

  validationMessages = validationMessages;

  constructor(
    private modalService: BsModalService,
    private flowEditorEvents: FlowEditorEvents,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
  ) {
    this.updateFlow = new EventEmitter();
    this.duplicate = new EventEmitter<NodeModel>();
    this.remove = new EventEmitter<NodeModel>();
  }

  ngOnInit() {
    this.flowSidebarForm = this.formBuilder.group({
      name: [this.basicControllerValue('name'), [Validators.required]],
      live: [this.basicControllerValue('live'), []],
      isRecurring: [this.basicControllerValue('isRecurring'), []],
      availableForSupport: [this.basicControllerValue('availableForSupport'), []],
      recurringExpression: [this.basicControllerValue('recurringExpression'), []],
      global: [this.basicControllerValue('global'), []],
      action: [this.basicControllerValue('action'), [undefinedOrHasValue]],
      spec: [{ value: this.selectedNode.spec, disabled: !this.canUserManage }, [undefinedOrHasValue]],
      templateChannels: this.formBuilder.array(
        this.channels.map((_, i) =>
          this.createTemplateChannelField(get(this.selectedNode, ['templates', i, 'channel'], undefined)),
        ),
      ),
      apiQueries: this.formBuilder.array([]),
      validations: this.formBuilder.array([]),
      connections: this.formBuilder.array([]),
      notes: [this.basicControllerValue('notes'), []],
    });

    this.setRecurringExpressionValidator();

    // Feedbacks to selectedNode
    // FIXIT: updateValueAndValidity calls are temporary fixes. See: https://github.com/angular/angular/issues/13920
    this.subscriptions.add(
      this.flowSidebarForm.get('name')?.valueChanges.subscribe(newValue => {
        this.flowSidebarForm.updateValueAndValidity();
        if (this.flowSidebarForm.valid) {
          this.selectedNode.name = newValue;
        }
      }),
    );
    this.subscriptions.add(
      this.flowSidebarForm.get('live')?.valueChanges.subscribe(newValue => (this.selectedNode.live = newValue)),
    );
    this.subscriptions.add(
      this.flowSidebarForm
        .get('isRecurring')
        ?.valueChanges.subscribe(newValue => (this.selectedNode.isRecurring = newValue)),
    );

    this.subscriptions.add(
      this.flowSidebarForm.get('availableForSupport')?.valueChanges.subscribe(newValue => {
        this.selectedNode.availableForSupport = newValue;
      }),
    );

    this.subscriptions.add(
      this.flowSidebarForm
        .get('recurringExpression')
        ?.valueChanges.subscribe(newValue => (this.selectedNode.recurringExpression = newValue)),
    );
    this.subscriptions.add(
      this.flowSidebarForm.get('global')?.valueChanges.subscribe(newValue => (this.selectedNode.global = newValue)),
    );
    this.subscriptions.add(
      this.flowSidebarForm.get('action')?.valueChanges.subscribe(newValue => {
        this.flowSidebarForm.updateValueAndValidity();
        if (this.flowSidebarForm.valid) {
          this.selectedNode.action = newValue;
        }
      }),
    );
    this.subscriptions.add(
      this.flowSidebarForm.get('spec')?.valueChanges.subscribe(newValue => {
        this.flowSidebarForm.updateValueAndValidity();
        if (this.flowSidebarForm.valid) {
          this.selectedNode.spec = newValue;
        }
      }),
    );
    this.templateChannels.controls.forEach((channelControl, i) => {
      this.subscriptions.add(
        channelControl.valueChanges.subscribe(newValue => {
          this.templateChannels.updateValueAndValidity();
          if (channelControl.valid && this.selectedNode?.templates && channelControl.value !== undefined) {
            if (this.selectedNode.templates.length <= i) {
              const template = TemplateModel.generateDefault();
              template.channel = newValue;
              this.selectedNode.templates.push(template);
            } else {
              this.selectedNode.templates[i].channel = newValue;
            }
          }
        }),
      );
    });
    this.subscriptions.add(
      this.flowSidebarForm.get('notes')?.valueChanges.subscribe(newValue => {
        this.flowSidebarForm.updateValueAndValidity();
        if (this.flowSidebarForm.valid) {
          this.selectedNode.notes = newValue;
        }
      }),
    );

    // Update events
    this.subscriptions.add(
      this.flowSidebarForm.valueChanges.subscribe(() => {
        if (this.flowSidebarForm.valid) {
          this.saveDebounce.next('');
        }
      }),
    );
    this.subscriptions.add(
      this.flowSidebarForm.get('templateChannels')?.valueChanges.subscribe(() => {
        if (this.flowSidebarForm.get('templateChannels')?.valid) {
          this.updateAvailableTemplateChannels();
          this.saveDebounce.next('');
        }
      }),
    );
    this.subscriptions.add(
      this.flowSidebarForm.get('connections')?.valueChanges.subscribe(() => {
        if (this.flowSidebarForm.get('connections')?.valid) {
          this.saveDebounce.next('');
        }
      }),
    );

    this.subscriptions.add(
      this.flowSidebarForm.get('apiQueries')?.valueChanges.subscribe(() => {
        if (this.flowSidebarForm.get('apiQueries')?.valid) {
          this.saveDebounce.next('');
        }
      }),
    );

    // The actual update
    this.subscriptions.add(
      this.saveDebounce.pipe(debounceTime(500)).subscribe(() => {
        this.flowEditorEvents.emitUpdateNode(this.selectedNode);
      }),
    );

    this.initializeSidebarForm();
  }

  private setRecurringExpressionValidator() {
    const isRecurringControl = this.flowSidebarForm.get('isRecurring');
    const recurringExpressionControl = this.flowSidebarForm.get('recurringExpression');

    isRecurringControl?.valueChanges.subscribe(isRecurring => {
      recurringExpressionControl?.setValidators(isRecurring ? [Validators.required] : []);
      recurringExpressionControl?.setValue(recurringExpressionControl?.value); // hackingly trying to re-trigger the validator
    });
  }

  private basicControllerValue(field: keyof NodeModel) {
    return { value: this.selectedNode[field], disabled: !this.canUserManage || !this.allowSaveChanges };
  }

  private initializeValidations() {
    if (!this.flowSidebarForm) {
      return;
    }
    this.validationArray.clear();
    this.validationSubscriptions.unsubscribe();
    this.validationSubscriptions = new Subscription();
    this.selectedNode.validations?.forEach(validation => {
      this.addValidationControlGroup(validation);
    });
  }

  private initializeApiQueries() {
    if (!this.flowSidebarForm) {
      return;
    }
    this.apiQueriesArray.clear();
    this.apiQueriesSubscriptions.unsubscribe();
    this.apiQueriesSubscriptions = new Subscription();
    this.selectedNode.apiQueries?.forEach(apiQuery => {
      this.addApiQueryControlGroup(apiQuery);
    });
  }

  private initializeConnections() {
    if (!this.flowSidebarForm) {
      return;
    }
    this.connectionArray.clear();
    this.connectionSubscriptions.unsubscribe();
    this.connectionSubscriptions = new Subscription();
    this.selectedNode.connections?.forEach(connection => {
      this.addConnectionControlGroup(connection);
    });
  }

  createTemplateChannelField(channel: string | undefined): FormControl {
    return this.formBuilder.control(
      { value: channel, disabled: !this.canUserManage || !this.allowSaveChanges },
      undefinedOrHasValue,
    );
  }

  private addValidationControlGroup(validation: NodeValidationModel) {
    const controlGroup = this.formBuilder.group({
      key: [{ value: validation.key, disabled: !this.canUserManage || !this.allowSaveChanges }, [Validators.required]],
      validationId: [
        { value: validation.validationId, disabled: !this.canUserManage || !this.allowSaveChanges },
        [undefinedOrHasValue],
      ],
      fallbackRetryNodeId: [
        { value: validation.fallbackRetryNodeId, disabled: !this.canUserManage || !this.allowSaveChanges },
        [undefinedOrHasValue],
      ],
      fallbackRetryAmount: [
        { value: validation.fallbackRetryAmount, disabled: !this.canUserManage || !this.allowSaveChanges },
        [Validators.required],
      ],
      fallbackRedirectNodeId: [
        { value: validation.fallbackRedirectNodeId, disabled: !this.canUserManage || !this.allowSaveChanges },
        [undefinedOrHasValue],
      ],
      storable: [{ value: validation.storable, disabled: !this.canUserManage || !this.allowSaveChanges }, []],
      storableConfirmNodeId: [
        { value: validation.storableConfirmNodeId, disabled: !this.canUserManage || !this.allowSaveChanges },
        [undefinedOrHasValue],
      ],
    });
    this.validationArray.push(controlGroup);
    const indexOfValidationControl = this.validationArray.length - 1;
    const validationIdControl = controlGroup.get('validationId');
    const fallbackRetryNodeIdControl = controlGroup.get('fallbackRetryNodeId');
    const fallbackRedirectNodeIdControl = controlGroup.get('fallbackRedirectNodeId');
    const storableConfirmNodeIdControl = controlGroup.get('storableConfirmNodeId');
    this.validationSubscriptions.add(
      controlGroup?.valueChanges.subscribe(newValue => {
        this.validationArray.updateValueAndValidity();
        if (!this.selectedNode.validations?.length) {
          this.selectedNode.validations = [];
        }
        if (
          controlGroup.valid &&
          ![
            validationIdControl?.value,
            fallbackRetryNodeIdControl?.value,
            fallbackRedirectNodeIdControl?.value,
          ].includes(undefined)
        ) {
          if (this.selectedNode.validations?.length <= indexOfValidationControl) {
            const validationModel = new NodeValidationModel();
            validationModel.key = newValue.key;
            validationModel.validationId = newValue.validationId;
            validationModel.fallbackRetryNodeId = newValue.fallbackRetryNodeId;
            validationModel.fallbackRetryAmount = newValue.fallbackRetryAmount;
            validationModel.fallbackRedirectNodeId = newValue.fallbackRedirectNodeId;
            if (newValue.storable && !validationModel.storable && storableConfirmNodeIdControl?.value === undefined) {
              storableConfirmNodeIdControl?.patchValue('');
            } else {
              validationModel.storableConfirmNodeId = newValue.storableConfirmNodeId;
            }
            validationModel.storable = newValue.storable;
            this.selectedNode.validations.push(validationModel);
            this.updateFlow.emit();
          } else {
            if (this.selectedNode.validations) {
              const selectedValidation = this.selectedNode.validations[indexOfValidationControl];
              selectedValidation.key = newValue.key;
              selectedValidation.validationId = newValue.validationId;
              selectedValidation.fallbackRetryNodeId = newValue.fallbackRetryNodeId;
              selectedValidation.fallbackRetryAmount = newValue.fallbackRetryAmount;
              selectedValidation.fallbackRedirectNodeId = newValue.fallbackRedirectNodeId;
              if (
                newValue.storable &&
                !selectedValidation.storable &&
                storableConfirmNodeIdControl?.value === undefined
              ) {
                storableConfirmNodeIdControl?.patchValue('');
              } else {
                selectedValidation.storableConfirmNodeId = newValue.storableConfirmNodeId;
              }
              selectedValidation.storable = newValue.storable;
              this.updateFlow.emit();
            }
          }
        }
      }),
    );
  }

  private addConnectionControlGroup(connection: NodeConnectionModel) {
    const controlGroup = this.formBuilder.group({
      id: [{ value: connection.id, disabled: !this.canUserManage || !this.allowSaveChanges }, [undefinedOrHasValue]],
      conditionals: this.formBuilder.array([]),
    });
    this.connectionArray.push(controlGroup);
    const indexOfConnectionControl = this.connectionArray.length - 1;
    const idControl = controlGroup.get('id');
    this.connectionSubscriptions.add(
      idControl?.valueChanges.subscribe(newValue => {
        this.connectionArray.updateValueAndValidity();
        if (!this.selectedNode.connections?.length) {
          this.selectedNode.connections = [];
        }
        if (idControl.valid && idControl.value !== undefined) {
          if (this.selectedNode.connections.length <= indexOfConnectionControl) {
            const connectionModel = new NodeConnectionModel();
            connectionModel.id = newValue;
            this.selectedNode.connections.push(connectionModel);
            this.updateFlow.emit();
          } else {
            const con = this.selectedNode.connections[indexOfConnectionControl];
            con.id = newValue;
            this.updateFlow.emit();
          }
        }
      }),
    );
    connection.conditionals?.forEach((conditional, i) => {
      this.addConditionalControlGroup(controlGroup, conditional, indexOfConnectionControl, i);
    });
  }

  private addApiQueryControlGroup(apiQuery: NodeApiQueryModel) {
    const controlGroup = this.formBuilder.group({
      apiQueryId: [
        { value: apiQuery.apiQueryId, disabled: !this.canUserManage || !this.allowSaveChanges },
        [undefinedOrHasValue],
      ],
      key: [{ value: apiQuery.key, disabled: !this.canUserManage || !this.allowSaveChanges }, [undefinedOrHasValue]],
    });
    this.apiQueriesArray.push(controlGroup);
    const indexOfApiQueryControl = this.apiQueriesArray.length - 1;
    const apiQueryIdControl = controlGroup.get('apiQueryId');
    const keyControl = controlGroup.get('key');
    this.apiQueriesSubscriptions.add(
      controlGroup?.valueChanges.subscribe((newValue: NodeApiQueryModel) => {
        this.apiQueriesArray.updateValueAndValidity();
        if (!this.selectedNode.apiQueries?.length) {
          this.selectedNode.apiQueries = [];
        }
        if (controlGroup.valid && ![apiQueryIdControl?.value, keyControl?.value].includes(undefined)) {
          if (this.selectedNode.apiQueries.length <= indexOfApiQueryControl) {
            const apiQueryModel = new NodeApiQueryModel();
            apiQueryModel.apiQueryId = newValue.apiQueryId;
            apiQueryModel.key = newValue.key;
            this.selectedNode.apiQueries.push(apiQueryModel);
            this.updateFlow.emit();
          } else {
            const apiQuery$1 = this.selectedNode.apiQueries[indexOfApiQueryControl];
            apiQuery$1.apiQueryId = newValue.apiQueryId;
            apiQuery$1.key = newValue.key;
            this.updateFlow.emit();
          }
        }
      }),
    );
  }

  private addConditionalControlGroup(
    connectionControlGroup: FormGroup,
    conditional: NodeConditionalModel,
    indexOfConnection: number,
    indexOfConditional: number,
  ) {
    const conditionals = connectionControlGroup.get('conditionals') as FormArray;
    const conditionalControlGroup = this.formBuilder.group({
      operator: [
        { value: conditional.operator, disabled: !this.canUserManage || !this.allowSaveChanges },
        [undefinedOrHasValue],
      ],
      left: [{ value: conditional.left, disabled: !this.canUserManage || !this.allowSaveChanges }, [validOperand]],
      right: [{ value: conditional.right, disabled: !this.canUserManage || !this.allowSaveChanges }, [validOperand]],
    });
    // FIXIT: If angular developers ever decide to merge the fix for this stop emitting event on these
    // See: https://github.com/angular/angular/issues/23336
    conditionals.push(conditionalControlGroup);
    const operatorControl = conditionalControlGroup.get('operator');
    this.connectionSubscriptions.add(
      conditionalControlGroup?.valueChanges.subscribe(newValue => {
        conditionals.updateValueAndValidity();
        if (this.selectedNode.connections && !this.selectedNode.connections[indexOfConnection]?.conditionals?.length) {
          this.selectedNode.connections[indexOfConnection].conditionals = [];
        }
        if (conditionalControlGroup.valid && operatorControl?.value !== undefined && this.selectedNode?.connections) {
          const conditionsLength: number = this.selectedNode.connections[indexOfConnection]?.conditionals
            ?.length as number;
          if (conditionsLength <= indexOfConditional) {
            const conditionModel = new NodeConditionalModel();
            if (conditionsLength % 2 !== 0) {
              conditionals.controls[indexOfConditional - 1].updateValueAndValidity();
            }
            conditionModel.left = newValue.left;
            conditionModel.operator = newValue.operator;
            conditionModel.right = newValue.right;
            this.selectedNode.connections[indexOfConnection]?.conditionals?.push(conditionModel);
          } else {
            if (this.selectedNode.connections[indexOfConnection].conditionals) {
              const condition = (
                this.selectedNode.connections[indexOfConnection].conditionals as NodeConditionalModel[]
              )[indexOfConditional];
              condition.left = newValue.left;
              condition.operator = newValue.operator;
              condition.right = newValue.right;
            }
          }
        }
      }),
    );
  }

  private initializeSidebarForm() {
    this.flowSidebarForm?.patchValue({
      name: this.selectedNode.name,
      live: this.selectedNode.live,
      isRecurring: this.selectedNode.isRecurring,
      availableForSupport: this.selectedNode.availableForSupport,
      recurringExpression: this.selectedNode.recurringExpression,
      global: this.selectedNode.global,
      action: this.selectedNode.action,
      spec: this.selectedNode.spec,
      notes: this.selectedNode.notes,
    });

    this.channels.forEach((_, i) => {
      if (!this.templateChannels?.controls[i]) {
        return;
      }
      if (this.selectedNode.templates && this.selectedNode.templates[i]) {
        this.templateChannels.controls[i].patchValue(this.selectedNode.templates[i].channel);
      } else {
        this.templateChannels.controls[i].patchValue(undefined);
      }
    });

    this.updateAvailableTemplateChannels();
    this.initializeConnections();
    this.initializeValidations();
    this.initializeApiQueries();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initializeSidebarForm();
  }

  get name() {
    return this.flowSidebarForm?.get('name');
  }
  get live() {
    return this.flowSidebarForm?.get('live');
  }
  get isRecurring() {
    return this.flowSidebarForm?.get('isRecurring');
  }
  get recurringExpression() {
    return this.flowSidebarForm?.get('recurringExpression');
  }
  get availableForSupport() {
    return this.flowSidebarForm?.get('availableForSupport');
  }
  get global() {
    return this.flowSidebarForm?.get('global');
  }
  get action() {
    return this.flowSidebarForm?.get('action');
  }
  get spec() {
    return this.flowSidebarForm?.get('spec');
  }
  get templateChannels() {
    return this.flowSidebarForm?.get('templateChannels') as FormArray;
  }
  get validationArray() {
    return this.flowSidebarForm?.get('validations') as FormArray;
  }
  get connectionArray() {
    return this.flowSidebarForm?.get('connections') as FormArray;
  }
  get apiQueriesArray() {
    return this.flowSidebarForm?.get('apiQueries') as FormArray;
  }
  get notes() {
    return this.flowSidebarForm?.get('notes');
  }

  get selectedNodeIsSpec() {
    return this.specs?.length && (this.selectedNode.spec || this.selectedNode.spec === '');
  }

  get currentSpecTemplates() {
    const spec = this.selectedNode.spec;
    if (spec) {
      let nodeSpecTemplates = {};
      if (this.selectedNode.specTemplates) {
        nodeSpecTemplates = this.selectedNode.specTemplates;
      }

      if (this.specTemplates[spec]) {
        return this.specTemplates[spec].map(specTemplate => {
          if (nodeSpecTemplates) {
            if (nodeSpecTemplates[specTemplate.key]) {
              specTemplate.content = nodeSpecTemplates[specTemplate.key].content;
            }
          }
          return specTemplate;
        });
      }
    }

    return [];
  }

  processCopySuccess() {
    this.toaster.success('Successfully copied to clipboard');
  }

  duplicateNode() {
    this.duplicate.emit(this.selectedNode);
  }

  removeNode() {
    this.remove.emit(this.selectedNode);
  }

  formatDate(value: number) {
    return dayjs(value * 1000).format('MM-DD-YYYY hh:mm A');
  }

  addAction() {
    if (!this.selectedNode) {
      return;
    }
    this.flowSidebarForm.patchValue({ action: '' });
  }

  removeAction() {
    if (!this.selectedNode) {
      return;
    }
    if (this.selectedNode.action) {
      delete this.selectedNode.action;
      this.saveDebounce.next('');
    }
    this.flowSidebarForm.patchValue({ action: undefined });
  }

  disableAction(action: string) {
    if (!this.nodes) {
      return;
    }
    const usedActions = this.nodes.map(node => node.action);
    return usedActions.includes(action);
  }

  addSpec() {
    if (!this.selectedNode) {
      return;
    }
    this.flowSidebarForm.patchValue({ spec: '' });
  }

  removeSpec() {
    if (!this.selectedNode) {
      return;
    }
    if (this.selectedNode.spec) {
      delete this.selectedNode.spec;
      this.saveDebounce.next('');
    }
    this.flowSidebarForm.patchValue({ spec: undefined });
  }

  disableSpec(spec: string) {
    if (!this.nodes) {
      return;
    }
    const usedSpecs = this.nodes.map(node => node.spec);
    return usedSpecs.includes(spec);
  }

  addInputData() {
    if (!this.selectedNode) {
      return;
    }
    this.selectedNode.inputData = '{}';
    this.selectedNode.inputDataExampleData = '{}';
    this.saveDebounce.next('');
  }

  editInputData() {
    if (!this.selectedNode) {
      return;
    }
    this.modalService.show(EditInputDataModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        node: this.selectedNode,
      },
      class: 'modal-lg',
    });
  }

  removeInputData() {
    if (!this.selectedNode?.inputData) {
      return;
    }
    delete this.selectedNode.inputData;
    this.saveDebounce.next('');
  }

  updateAvailableTemplateChannels() {
    if (!this.selectedNode?.templates) {
      return;
    }
    this.selectedUsedChannelIds = this.selectedNode?.templates
      .filter(template => template.channel !== '')
      .map(template => template.channel);
  }

  disableTemplateChannel(id: string) {
    return this.selectedUsedChannelIds?.includes(id);
  }

  setTemplateChannel(channel: string, index: number) {
    if (!this.selectedNode?.templates) {
      return;
    }
    this.selectedNode.templates[index].channel = channel;
    this.updateAvailableTemplateChannels();
  }

  editSpecTemplate() {
    if (!this.selectedNode) {
      return;
    }
    const specTemplates = this.currentSpecTemplates;
    const node = this.selectedNode;
    this.modalService.show(EditSpecTemplateModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        node,
        specTemplates,
      },
      class: 'modal-lg',
    });
  }

  addTemplate() {
    if (!this.selectedNode?.templates) {
      return;
    }
    this.templateChannels.controls[this.selectedNode.templates.length].patchValue({ value: '' });
  }

  editTemplate(index: number) {
    if (!this.selectedNode?.templates) {
      return;
    }
    if (!this.selectedNode.templates[index]) {
      const template = TemplateModel.generateDefault();
      template.channel = this.templateChannels.controls[index].value;
      this.selectedNode.templates.push(template);
    }
    const modalRef = this.modalService.show(EditTemplateModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        node: this.selectedNode,
        index,
      },
      class: 'modal-lg',
    });
    modalRef.content.onClose.subscribe(result => {
      this.saveDebounce.next('');
    });
  }

  removeTemplate(index: number) {
    if (!this.selectedNode?.templates) {
      return;
    }
    this.selectedNode.templates.splice(index, 1);
    this.updateAvailableTemplateChannels();
    this.initializeSidebarForm();
    this.saveDebounce.next('');
  }

  addHttpRequest() {
    this.openUpsertNodeHttpRequestModal({
      id: '',
      title: '',
      endPoint: '',
      payload: '',
      httpMethod: 'GET',
      httpHeader: '',
      responseStorageVariable: '',
    });
  }

  openUpsertNodeHttpRequestModal(nodeHttpRequest: NodeHttpRequest) {
    this.modalService.show(UpsertHttpRequestModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        nodeHttpRequest,
        globalVariables: this.apiVariableItems,
        onNodeHttpRequestSave: (nodeHttpRequest$1: NodeHttpRequest) => {
          this.upsertNodeHttpRequest(nodeHttpRequest$1);
        },
      },
    });
  }

  upsertNodeHttpRequest(nodeHttpRequest: NodeHttpRequest) {
    if (!this.selectedNode) {
      return;
    }

    if (!this.selectedNode?.httpRequests) {
      this.selectedNode.httpRequests = [];
    }

    if (nodeHttpRequest.id) {
      const index = this.selectedNode.httpRequests.findIndex(({ id }) => id === nodeHttpRequest.id);
      this.selectedNode.httpRequests[index] = nodeHttpRequest;
      return;
    }
    nodeHttpRequest.id = uuidv4();
    this.selectedNode.httpRequests.push(nodeHttpRequest);
  }

  editNodeHttpRequest(httpRequest: NodeHttpRequest) {
    if (!this.selectedNode) {
      return;
    }
    this.openUpsertNodeHttpRequestModal(httpRequest);
  }

  removeNodeHttpRequest(index: number) {
    if (!this.selectedNode?.httpRequests) {
      return;
    }
    this.selectedNode.httpRequests.splice(index, 1);
    this.saveDebounce.next('');
  }

  addValidation() {
    if (!this.selectedNode) {
      return;
    }
    this.addValidationControlGroup(new NodeValidationModel());
  }

  addKpi() {
    if (!this.selectedNode) {
      return;
    }
    if (!this.selectedNode.kpis?.length) {
      this.selectedNode.kpis = [];
    }

    this.modalService.show(AddNodeKpiModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        node: this.selectedNode,
        botUniqueKpis: getBotUniqueKpis(this.nodes),
      },
      class: 'modal-sm',
    });
  }

  removeKpi(kpiSystemName: string) {
    const kpiIndex = this.selectedNode.kpis?.findIndex(kpi => kpi.systemName === kpiSystemName);
    if (kpiIndex !== undefined && kpiIndex > -1) {
      this.selectedNode.kpis?.splice(kpiIndex, 1);
    }

    this.saveDebounce.next('');
  }

  removeValidation(index: number) {
    if (!this.selectedNode?.validations?.length) {
      return;
    }
    this.selectedNode.validations.splice(index, 1);
    this.saveDebounce.next('');
    this.initializeValidations();
    this.updateFlow.emit();
  }

  addApiQuery() {
    if (!this.selectedNode) {
      return;
    }
    this.addApiQueryControlGroup(new NodeApiQueryModel());
  }

  removeApiQuery(index: number) {
    if (!this.selectedNode?.apiQueries?.length) {
      return;
    }
    this.selectedNode.apiQueries.splice(index, 1);
    this.saveDebounce.next('');
    this.initializeApiQueries();
    this.updateFlow.emit();
  }

  addConnection() {
    if (!this.selectedNode) {
      return;
    }
    this.addConnectionControlGroup(new NodeConnectionModel());
  }

  removeConnection(index: number) {
    if (!this.selectedNode?.connections?.length) {
      return;
    }
    this.selectedNode.connections.splice(index, 1);
    this.saveDebounce.next('');
    this.initializeConnections();
    this.updateFlow.emit();
  }

  addConditional(connection: FormGroup, indexOfConnection: number) {
    const conditionals = connection.get('conditionals') as FormArray;
    let indexOfConditional = conditionals.length;

    const blankConditional = new NodeConditionalModel();
    blankConditional.operator = NodeConditionalOperator.Default;

    const conditionSeparator = new NodeConditionalModel();
    conditionSeparator.operator = NodeConditionalOperator.And;

    // If odd we add slot for combination operators
    if (conditionals.length % 2 !== 0) {
      this.addConditionalControlGroup(connection, conditionSeparator, indexOfConnection, indexOfConditional);
      indexOfConditional++;
    }
    this.addConditionalControlGroup(connection, blankConditional, indexOfConnection, indexOfConditional);
  }

  removeConditional(connectionIndex: number, conditionalIndex: number) {
    const conditionalslength: number = (this.selectedNode.connections &&
      this.selectedNode.connections[connectionIndex].conditionals?.length) as number;
    const connections: NodeConnectionModel[] = this.selectedNode.connections as NodeConnectionModel[];
    if (this.selectedNode.connections && !conditionalslength) {
      return;
    }
    if (conditionalslength > 1) {
      connections[connectionIndex].conditionals?.splice(conditionalIndex - 1, 2);
    } else {
      connections[connectionIndex].conditionals?.splice(conditionalIndex, 1);
    }
    if (!this.selectedNode) {
      return;
    }
    this.saveDebounce.next('');
    this.initializeConnections();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.connectionSubscriptions.unsubscribe();
    this.validationSubscriptions.unsubscribe();
    this.apiQueriesSubscriptions.unsubscribe();
  }
}
