<div class="environment-management-modal-wrapper">
  <div class="modal-header">
    <h4 class="modal-title">Add/Remove Environments </h4>
    <div class="ml-auto">
      <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
      <button type="button" class="close tooltip c-default" tooltip="Drag and drop to rearrange the order of bot promotion in your environments." placement="bottom">
        <i class="fas fa-question fa-xs"></i>
      </button>
    </div>
  </div>

  <div class="modal-body">

    <div id="draggable-items">
      <div *ngFor="let stage of clientEnvironment.stages; first as isFirst"
        [className]="'card bg-gradient-default border-0' + (isFirst ? '' : ' is-draggable')"
        attr.data-stage-system-name="{{stage.systemName}}">
        <div class="card-body">
          <div class="row">
            <div class="col-8">
              <h3 class="card-title text-uppercase text-muted mb-0 text-white">
                {{ stage.name }}
              </h3>
            </div>
            <div class="col-4 text-right" *ngIf="!isFirst">
              <a *ngIf="stage.systemName !== clientEnvironment.selectedStage.systemName" class="mb-0 text-white"
                href="javascript:void(0)" (click)=deleteEnvironment(stage)>Delete</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="mb-3">

    <label for="addEnvironment">Add New Environment</label>
    <div class="input-group mb-3">
      <input [(ngModel)]="newEnvironmentName" id="addEnvironment" type="text" class="form-control"
        placeholder="Environment Name" aria-label="Environment Name" aria-describedby="button-addon2">
      <div class="input-group-append">
        <button class="btn btn-outline-primary" [disabled]="!newEnvironmentName || loading" type="button"
          id="button-addon2" (click)="addNewEnvironment()">Add Environment
        </button>
      </div>
    </div>

    <hr class="mb-3">

    <button type="button" class="btn btn-primary btn-block mb-3" (click)=saveClientEnvironment() [disabled]="loading">
      <span *ngIf="!loading">Save Environments</span>
      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
    </button>
  </div>
</div>