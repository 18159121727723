import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageEvent } from '../conversation-message/_types/MessageEvent';
import { BotModel } from '../../../../models';
import { ConversationSummary } from '../conversation-summary/_type/ConversationSummary';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';

@Component({
  selector: 'app-conversation-messages',
  templateUrl: './conversation-messages.component.html',
  styleUrls: ['./conversation-messages.component.scss'],
})
export class ConversationMessagesComponent implements OnInit {
  loading: boolean;
  messages: MessageEvent[];

  @Input()
  bot?: BotModel;

  /**
   * If not provided, will be used the default defined region {@link AwsRegionEnum.US_EAST_1}
   */
  @Input()
  awsRegion: AwsRegionEnum;

  @Input()
  defaultBotLogo: string;

  @Input()
  defaultBotName: string;

  @Input()
  set conversation(conversation: ConversationSummary) {
    this.getMessages(conversation);
  }

  constructor(private apiGatewayAnalyticsService: ApiGatewayAnalyticsService, private toaster: ToastrService) {}

  ngOnInit() {}

  private async getMessages(conversation: ConversationSummary) {
    this.loading = true;
    try {
      const messages = await this.apiGatewayAnalyticsService.getConversationMessages(conversation.conversationId);
      this.messages = messages.map(({ userMessage, botResponse, timestamp }) => {
        return {
          messageReceived: {
            text: userMessage,
            timestamp: new Date(timestamp),
            platformPayload: {
              vendorName: this.getVendorName(),
              vendorIcon: this.getVendorIcon(),
            },
          },
          messagesSent: JSON.parse(botResponse),
        };
      });
    } catch (error) {
      this.toaster.error(error);
    } finally {
      this.loading = false;
    }
  }

  getVendorName(): string {
    return this.bot?.label || this.defaultBotName;
  }

  getVendorIcon(): string {
    return this.bot?.styles.avatarImageUrl || this.defaultBotLogo;
  }
}
