<div class="modal-header">
    <h4 class="modal-title">Add a new Dataset</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
      <span aria-hidden="true">x</span>
    </button>
  </div>

  <div class="modal-body">
    <form #form="ngForm" novalidate>
      <div class=" form-group">
        <label class=" form-control-label" for="datasetName">Name</label>
        <input
          class="form-control"
          id="datasetName"
          name="datasetName"
          [(ngModel)]="datasetName"
          type="text"
          placeholder="Dataset Name"
          required
        />
      </div>

      <button
        class="btn btn-primary btn-outline"
        type="submit"
        [disabled]="!form.valid || loading"
        (click)="createDataset()"
      >
        SAVE
      </button>
    </form>
  </div>
