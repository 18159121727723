<div class="modal-header">
  <h4 class="modal-title">Assign Conversations</h4>

  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="table-responsive">
      <table class="table align-items-center table-flush">
        <thead class="thead-light">
          <tr class="d-flex">
            <th class="col-5">Name</th>
            <th class="col-5">Email</th>
            <th class="col-2">Remove</th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngIf="assignedUsers">
            <tr *ngFor="let user of assignedUsers; let i = index" class="d-flex">
              <td class="text-capitalize col-5">
                <b> {{ user.firstName }} {{ user.lastName }}</b>
              </td>
              <td class="col-5">
                <span class="text-muted">
                  {{ user.email }}
                </span>
              </td>

              <td class="col-2">
                <span class="text-muted">
                  <i (click)="removeFromConversation(i)" class="fa fa-trash text-danger ml-3 cursor-pointer"></i>
                </span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row scrollable-body mt-3">
    <select [(ngModel)]="userId" class="form-control mt-2">
      <option value="">Select</option>
      <option [disabled]="u.disabled" *ngFor="let u of allUser" value="{{ u.id }}">
        {{ u.firstName }} {{ u.lastName }}
      </option>
    </select>
  </div>
</div>

<div class="modal-footer">
  <button (click)="assignUser()" type="button" class="btn btn-primary btn-sm" aria-label="Assign User">
    Assign User
  </button>
</div>
