<ng-container *ngIf="loading">
  <div class=" container-fluid">
    <div class=" row align-items-center py-4 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading">
  <div class=" container-fluid">
    <div class=" row">
      <div class=" col-lg-12 col-sm-12">
        <div class=" card m-4">
          <div class=" card-header d-flex align-items-center">
            <h3>Corporations</h3>
            <button class="btn btn-outline-success btn-sm btn-circle ml-4" (click)="openAddCorpModal()">
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <div class=" card-body">
            <div class=" table-responsive" *ngIf="corps && corps.length">
              <table class=" table align-items-center table-flush">
                <thead class=" thead-light">
                  <tr>
                    <th scope="col">Logo</th>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody class=" list">
                  <tr *ngFor="let corp of corps">
                    <td>
                      <a class=" avatar rounded-circle bg-white" href="javascript:void(0)">
                        <img class=" h-100" alt="Corporation Logo" *ngIf="corp.logo" [src]="corp.logo" />
                      </a>
                    </td>
                    <td>
                      <h4>
                        {{ corp.label }}
                      </h4>
                    </td>
                    <td>
                      <i
                        class=" fa fa-cog text-info ml-3 cursor-pointer"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Corporation Settings"
                        [routerLink]="['/admin/corps/' + corp.id + '/settings']"
                      >
                      </i>
                      <i
                        class=" fas fa-trash text-danger ml-3 cursor-pointer"
                        (click)="confirmAndDeleteCorp(corp)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete Corporation"
                      >
                      </i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 *ngIf="!corps || corps.length === 0">No Organizations.</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
