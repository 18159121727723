import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataExportFilter } from '../_types/DataExportFilter';
import { FieldsSelectionService } from '../data-export-search/service/fields-selection.service';
import {
  executeSearch,
  getIndexes,
  index$,
  setFilter,
  setSearchAfter,
  setSize,
  setSort,
  setSource
} from '../../../utils/es-reactive/es-reactive';
import { getDatesAsString } from '../../../utils/date/date-utils';
import { EsClientService } from '../../../services/es-client.service';
import { AwsRegionEnum } from '../../../models/countries/AwsRegionEnum';
import { ToastrService } from 'ngx-toastr';
import { map, take } from 'rxjs/operators';
import { ActionProgressComponent } from '../../action-progress/action-progress.component';
import { downloadCsvFile, getFilter } from '../utils';
import { Permissions } from '../../../utils/permissions/permissions';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.scss']
})
export class DataExportComponent implements OnInit {
  loading = false;
  filter: DataExportFilter;

  @Input()
  botId: string;

  /**
   * If not provided, will be used the default defined region {@link AwsRegionEnum.US_EAST_1}
   */
  @Input()
  awsRegion: AwsRegionEnum;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;

  constructor(
    private fieldsSelectionService: FieldsSelectionService,
    private esClientService: EsClientService,
    private toaster: ToastrService,
    private authService: AuthService
  ) {
    this.filter = new DataExportFilter();
  }

  ngOnInit() {}

  filterUpdated(exportFilterParam: DataExportFilter) {
    this.filter = exportFilterParam;
  }

  removeField(fieldFullPath: string) {
    const node = this.filter.includedFieldToNodeMap.get(fieldFullPath);
    if (node) {
      this.filter = this.fieldsSelectionService.toggleNode(this.filter, node);
    }
  }

  async doAsyncDataCollection() {
    if (!this.validateFilterBeforeExecution()) {
      return;
    }

    this.toaster.warning('Starting the messages collection.');
    this.actionProgress.start();
    this.loading = true;

    const mergedResult: any[] = [];
    let continueLoop = true;
    let searchAfter = this.filter.sortByTimeOrder === 'asc' ? 0 : new Date().setDate(new Date().getDate() + 1);
    while (continueLoop) {
      const searchResult: any = await this.getLogsForExport(searchAfter);

      if (searchResult?.hits?.length > 0) {
        mergedResult.push(...searchResult?.hits);
        searchAfter = searchResult.nextSearchAfter;
        continueLoop = true;
      } else {
        continueLoop = false;
      }
    }

    this.toaster.success('Found ' + mergedResult.length + ' valid messages to be exported.');
    this.executeCsvExport(mergedResult);

    return mergedResult;
  }

  private executeCsvExport(jsonData: any[]) {
    this.toaster.warning('Starting the data conversion to CSV.');

    const converter = require('json-2-csv');
    const options = {
      expandArrayObjects: true,
      unwindArrays: false,
      sortHeader: true,
      excelBOM: true,
      keys: null
    };

    converter
      .json2csvAsync(jsonData, options)
      .then(csvResult => {
        /*
          Cleaning the memory, since the array could be very big.
         */
        jsonData.length = 0;

        downloadCsvFile(csvResult);

        this.toaster.success('Process finished successfully!');
        this.actionProgress.complete();
        this.loading = false;
      })
      .catch(err => {
        this.toaster.error(err);
      });
  }

  private getLogsForExport(searchAfter: number): Promise<any> {
    this.loading = true;

    const includedFields = this.filter.getRootsOfIncludedFields();
    const excludedFields = this.filter.excludedFields;

    const bucketSize = this.filter.includedFields.length < 100 ? 500 : 100;
    const indexes: string | string[] =
      this.filter && this.filter.dateRange
        ? getIndexes(getDatesAsString(this.filter.dateRange[0], this.filter.dateRange[1]))
        : 'messages-*';

    return index$(indexes)
      .pipe(
        setFilter(getFilter.bind(this)()),
        setSource({
          _source: {
            includes: includedFields,
            excludes: excludedFields
          }
        }),
        setSort([{ timestamp: this.filter.sortByTimeOrder }]),
        setSearchAfter(searchAfter),
        setSize(bucketSize),
        executeSearch(this.esClientService.getClient(this.awsRegion)),
        map(searchResponse => {
          const totalHitsCount = searchResponse.hits?.total?.value;
          const hitsResult = searchResponse.hits?.hits
            ?.filter(hit => {
              return Object.keys(hit._source).length !== 0;
            })
            .map(hit => hit._source);

          let lastHitSort;
          if ((searchResponse?.hits?.hits as [])?.length > 0) {
            lastHitSort = searchResponse.hits.hits[(searchResponse.hits.hits as []).length - 1].sort[0];
          }

          return {
            totalCount: totalHitsCount,
            hits: hitsResult,
            nextSearchAfter: lastHitSort
          };
        })
      )
      .pipe(take(1))
      .toPromise();
  }

  private validateFilterBeforeExecution(): boolean {
    if (this.filter.includedFields.length > 1000 || this.filter.excludedFields.length > 1000) {
      this.toaster.error('Complexity issue! Too many fields were selected');
      return false;
    }

    return true;
  }

  canExportData(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_EXPORT_USING_DATA_EXPORT_TOOL);
  }
}
