<div class="modal-header">
  <h4 class="modal-title">Add Event Trigger</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [ngClass]="loading ? 'disabled' : ''">
  <!-- Validation Summary -->
  <div *ngIf="addTriggerForm.pending">Validating...</div>

  <form (submit)="addTrigger($event)" [formGroup]="addTriggerForm" novalidate>
    <fieldset class="container">
      <div class="form-group row">
        <label class="form-control-label" for="eventTrigger">
          Event Trigger
        </label>
        <select class="form-control" id="eventTrigger" formControlName="eventTriggerType">
          <option value="" disabled selected>Select Event Trigger</option>
          <option value="page-url">Page URL</option>
        </select>
      </div>

      <!-- Page URL Event Triggers -->
      <div *ngIf="addTriggerForm.controls.eventTriggerType.value === 'page-url'" class="form-group row">
        <div class="col pl-0">
          <label class="form-control-label" for="pageUrl">
            Page URL
          </label>
          <input
            class="form-control"
            [class.is-invalid]="
              !addTriggerForm.controls.pageUrl.valid &&
              (addTriggerForm.controls.pageUrl.dirty ||
              addTriggerForm.controls.pageUrl.touched)
            "
            id="pageUrl"
            type="url"
            placeholder="URL"
            formControlName="pageUrl"
          />
          <div
            *ngIf="
              (addTriggerForm.controls.pageUrl.dirty ||
              addTriggerForm.controls.pageUrl.touched) &&
              addTriggerForm.controls.pageUrl.errors?.urlTaken
            "
            class="invalid-feedback"
            required
          >
            {{ validationMessages.urlTaken }}
          </div>
        </div>
        <div class="col pr-0">
          <label class="form-control-label" for="nodeToUse">
            Node to Use
          </label>
          <select class="form-control" id="nodeToUse" formControlName="nodeId">
            <option value="" disabled selected>Select Node</option>
            <option *ngFor="let n of nodes" [ngValue]="n.id">{{ n.name }}</option>
          </select>
        </div>
      </div>
    </fieldset>
    <button class="btn btn-primary btn-outline" type="button" (click)="modal.hide()">
      Cancel
    </button>
    <button class="btn btn-primary btn-outline" type="submit" [disabled]="!addTriggerForm.valid">
      Save Trigger
    </button>
  </form>
</div>
