import {
  Component,
  OnInit,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  OnChanges,
  ViewChildren,
  QueryList,
  OnDestroy,
} from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HlConversationTagsComponent } from '../hl-conversation-tags/hl-conversation-tags.component';
import { AddNodeKpiModalComponent } from 'src/app/components/modals/add-node-kpi-modal/add-node-kpi-modal.component';
import {
  HLTemplateModel,
  HLConversationModel,
  ConversationMessageModel,
  HLUserResponseChannels,
} from 'src/app/models/conversations';
import { HlConversationTemplatesComponent } from '../hl-conversation-templates/hl-conversation-templates.component';
import { Subscription } from 'rxjs';
import { TemplateChannels } from 'src/app/models/template';
@Component({
  selector: 'app-hl-conversation',
  templateUrl: './hl-conversation.component.html',
  styleUrls: ['./hl-conversation.component.scss'],
})
export class HlConversationComponent implements OnInit, OnChanges, OnDestroy {
  editorConfig: AngularEditorConfig = {
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    editable: true,
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    uploadWithCredentials: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'strikeThrough',
        'redo',
        'superscript',
        'subscript',
        'backgroundColor',
        'textColor',
        'insertVideo',
        'insertImage',
      ],
      ['fontSize', 'fontName'],
    ],
  };

  @Input()
  corpTags: string[];

  @Input()
  selectedConversationId: string | null;

  @Input()
  conversation: HLConversationModel;

  @Input()
  conversationsLastMessage = {};

  @Input()
  conversationsTags = {};

  @Input()
  loadingMessages = false;

  @Input()
  loadingMoreTop = true;

  @Input()
  loadingMoreBottom = true;

  @Input()
  currentMessages: ConversationMessageModel[] = [];

  @Input()
  message = '';

  @Input()
  humanTemplates: HLTemplateModel[];

  inputMessage = '';

  @Output()
  send: EventEmitter<string>;

  @Output()
  stopBot: EventEmitter<HLConversationModel>;

  @Output()
  endHandover: EventEmitter<HLConversationModel>;

  @Output()
  savedTemplate: EventEmitter<HLTemplateModel>;

  @Output()
  savedTags: EventEmitter<string[]>;

  @Output()
  assignUser: EventEmitter<void>;

  @Output()
  selectNode: EventEmitter<void>;

  @Output()
  deleteTemplate: EventEmitter<HLTemplateModel>;

  @Output()
  loadMoreMessages: EventEmitter<boolean>;

  @Output()
  channelNameEmitter: EventEmitter<HLUserResponseChannels>;

  lastMessage: ConversationMessageModel | null;
  channelName: HLUserResponseChannels = 'email';
  clickedTemplateSubscription: Subscription;
  deleteTemplateSubscription: Subscription;
  saveConversationTagSubscription: Subscription;
  saveTemplateSubscription: Subscription;
  closeTemplatesSubscription: Subscription;
  templatesModalRef: BsModalRef | null;

  @ViewChildren('chatContainer') private chatMessages: QueryList<ElementRef<HTMLDivElement>>;

  constructor(private modalService: BsModalService) {
    this.send = new EventEmitter<string>();
    this.savedTags = new EventEmitter<string[]>();
    this.savedTemplate = new EventEmitter<HLTemplateModel>();
    this.stopBot = new EventEmitter<HLConversationModel>();
    this.endHandover = new EventEmitter<HLConversationModel>();
    this.assignUser = new EventEmitter<void>();
    this.selectNode = new EventEmitter<void>();
    this.deleteTemplate = new EventEmitter<HLTemplateModel>();
    this.loadMoreMessages = new EventEmitter<boolean>();
    this.channelNameEmitter = new EventEmitter<HLUserResponseChannels>();
  }

  ngOnInit() {
    this.inputMessage = `${this.message}`;
    this.scrollToBottom();
  }

  ngOnChanges() {
    this.inputMessage = `${this.message}`;
    this.scrollToBottom();
    this.channelName = 'email'; // default to email for now
    this.lastMessage = null;
    if (this.currentMessages && this.currentMessages.length > 0) {
      this.lastMessage = this.currentMessages[this.currentMessages.length - 1];
      const lastReceivedChannel = this.lastMessage.channel === 'web' ? 'directline' : this.lastMessage.channel;
      if (lastReceivedChannel === TemplateChannels.SMS) {
        this.channelName = 'sms';
      } else if (lastReceivedChannel === TemplateChannels.Email) {
        this.channelName = 'email';
      }
    }
    this.channelNameEmitter.emit(this.channelName);
  }
  ngOnDestroy() {
    if (this.deleteTemplateSubscription) {
      this.deleteTemplateSubscription.unsubscribe();
    }
    if (this.clickedTemplateSubscription) {
      this.clickedTemplateSubscription.unsubscribe();
    }
    if (this.saveConversationTagSubscription) {
      this.saveConversationTagSubscription.unsubscribe();
    }
    if (this.closeTemplatesSubscription) {
      this.closeTemplatesSubscription.unsubscribe();
    }
    if (this.templatesModalRef) {
      this.templatesModalRef.hide();
      this.templatesModalRef = null;
    }
  }

  updateTemplates(templates: HLTemplateModel[]) {
    this.humanTemplates = templates;
    if (this.templatesModalRef) {
      if (this.templatesModalRef.content) {
        (this.templatesModalRef.content as HlConversationTemplatesComponent).allTemplates = this.humanTemplates.filter(
          template => template.botId === this.conversation.botId,
        );
      }
    }
  }
  sendMessage(event) {
    this.send.emit(this.inputMessage);
  }

  stopBotMessages(event) {
    this.stopBot.emit(this.conversation);
  }

  endBotHandover(event) {
    this.endHandover.emit(this.conversation);
  }

  showConversationTags() {
    const modalRef = this.modalService.show(HlConversationTagsComponent, {
      initialState: {
        corpTags: this.corpTags,
        tags: this.conversation?.tags ?? [],
      },
    });

    if (this.saveConversationTagSubscription) {
      this.saveConversationTagSubscription.unsubscribe();
    }

    this.saveConversationTagSubscription = modalRef.content.save.subscribe(tags => {
      this.saveConversationTags(tags);
    });
  }

  saveConversationTags(tags: string[]) {
    this.savedTags.emit(tags);
  }

  showTemplates() {
    const modalRef = this.modalService.show(HlConversationTemplatesComponent, {
      initialState: {
        corpTags: this.corpTags,
        templates: this.humanTemplates
          ? this.humanTemplates.filter(template => template.botId === this.conversation.botId)
          : [],
      },
    });
    this.templatesModalRef = modalRef;

    if (this.saveTemplateSubscription) {
      this.saveTemplateSubscription.unsubscribe();
    }

    this.saveTemplateSubscription = (modalRef.content as HlConversationTemplatesComponent).savedTemplate.subscribe(
      (template: HLTemplateModel) => {
        template.botId = this.conversation.botId;
        // modalRef.hide();
        this.savedTemplate.emit(template);
      },
    );
    if (this.clickedTemplateSubscription) {
      this.clickedTemplateSubscription.unsubscribe();
    }
    this.clickedTemplateSubscription = modalRef.content.clickedTemplate.subscribe((template: HLTemplateModel) => {
      modalRef.hide();
      const isEmailConversation =
        this.lastMessage && this.lastMessage.messageType && this.lastMessage.messageType === 'email';

      if (isEmailConversation) {
        this.inputMessage = template.content;
      } else {
        // convert html to plain text
        const span = document.createElement('span');
        span.innerHTML = template.content;
        const plainText = span.textContent || span.innerText;
        this.inputMessage = plainText;
      }
    });

    if (this.deleteTemplateSubscription) {
      this.deleteTemplateSubscription.unsubscribe();
    }
    this.deleteTemplateSubscription = modalRef.content.deleteTemplate.subscribe((template: HLTemplateModel) => {
      this.deleteTemplate.emit(template);
    });

    if (this.closeTemplatesSubscription) {
      this.closeTemplatesSubscription.unsubscribe();
    }

    this.closeTemplatesSubscription = modalRef.content.close.subscribe(() => {
      this.templatesModalRef = null;
    });
  }

  assignConversationToUser() {
    this.assignUser.emit();
  }

  doSelectNode() {
    this.selectNode.emit();
  }

  showKpisModal(message) {
    this.modalService.show(AddNodeKpiModalComponent, {
      initialState: {
        conversation: this.conversation,
        message,
      },
    });
  }

  public scrollToBottom() {
    if (this.chatMessages) {
      if (this.loadingMoreBottom) {
        return;
      }
      setTimeout(() => {
        const el = window.document.querySelector<HTMLDivElement>('.conversation-messages-list');
        if (el) {
          el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
        }
      }, 300);
    }
  }
}
