import { Component, OnDestroy, OnInit } from '@angular/core';
import { CorpModel } from 'src/app/models/corp';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, combineLatest } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { SidebarService } from '../../../../services/sidebar.service';
import { CorpHierarchyModel } from 'src/app/models';
import { CorpHierarchiesService, CorpsService } from 'src/app/services/firestore';
import { ActivatedRoute } from '@angular/router';
import { AddCorpHierarchyModalComponent } from './_components/add-corp-hierarchy-modal/add-corp-hierarchy-modal.component';
import { EditCorpHierarchyModalComponent } from './_components/edit-corp-hierarchy-modal/edit-corp-hierarchy-modal.component';

@Component({
  selector: 'app-corp-hierarchies-admin',
  templateUrl: './corp-hierarchies-admin.component.html',
  styleUrls: ['./corp-hierarchies-admin.component.scss'],
})
export class CorpHierarchiesAdminComponent implements OnInit, OnDestroy {
  loading: boolean;
  hierarchies: CorpHierarchyModel[];
  corp: CorpModel | null;
  private paramMapSubscription: Subscription;
  private dataSubscription: Subscription;

  constructor(
    private corpHierarchiesService: CorpHierarchiesService,
    private corpsService: CorpsService,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private modalService: BsModalService,
    private toaster: ToastrService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
  ) {
    this.loading = false;
  }

  ngOnInit() {
    this.refreshCorpHierarchies();
  }

  private refreshCorpHierarchies() {
    this.paramMapSubscription = this.route.paramMap.subscribe(params => {
      const corpId = params.get('corp');
      if (!corpId) {
        return;
      }
      this.loading = true;
      this.dataSubscription = combineLatest([
        this.corpsService.getCorpById(corpId),
        this.corpHierarchiesService.getCorpHierarchies(corpId),
      ]).subscribe(
        ([corp, hierarchies]) => {
          this.corp = corp;
          this.hierarchies = hierarchies;
          this.refreshUI();
          this.setSidebarItems();
          this.loading = false;
        },
        error => {
          this.toaster.error(error);
          this.loading = false;
        },
      );
    });
  }

  openAddHierarchyModal() {
    const modalRef = this.modalService.show(AddCorpHierarchyModalComponent, { ignoreBackdropClick: true });
    modalRef.content.corpId = this.corp?.id;
    modalRef.content.corpsLength = this.hierarchies.length;
  }

  openEditHierarchyModal(hierarchy: CorpHierarchyModel) {
    this.modalService.show(EditCorpHierarchyModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        corpHierarchy: hierarchy,
        corpId: this.corp?.id,
      },
    });
  }

  setBreadcrumb(corp: CorpModel) {
    this.breadcrumbService.set([
      {
        label: 'Corporations',
        route: `admin/corps`,
      },
      {
        label: corp.label,
        icon: corp.logo,
        route: `admin/corps/${corp.id}`,
      },
    ]);
  }

  refreshUI() {
    if (this.corp) {
      this.setBreadcrumb(this.corp);
      this.headerService.setPageTitle(`${this.corp.label} Hierarchies`);
    }
  }

  ngOnDestroy() {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  confirmAndDeleteHierarchy(corpHierarchy: CorpHierarchyModel) {
    if (!this.corp) {
      return;
    }
    const modalRef = this.modalService.show(ConfirmationModalComponent, { ignoreBackdropClick: true });
    modalRef.content.title = 'Delete Hierarchy';
    modalRef.content.message = `Are you sure you want to delete hierarchy ${corpHierarchy.label}?`;
    modalRef.content.confirm.subscribe(() => {
      if (!this.corp) {
        return;
      }
      this.corpHierarchiesService.hasChildren(this.corp.id, corpHierarchy.systemName).subscribe(
        hasChildren => {
          if (hasChildren) {
            this.toaster.error(
              'This Hierarchy has children hierarchy/bot. Please delete all children before deleting the hierarchy.',
            );
            return;
          }
          this.deleteCorpHierarchy(corpHierarchy);
        },
        error => {
          this.toaster.error(error);
        },
      );
    });
  }

  private async deleteCorpHierarchy(corpHierarchy: CorpHierarchyModel) {
    if (!this.corp) {
      return;
    }
    try {
      await this.corpHierarchiesService.removeCorpHierarchy(this.corp.id, corpHierarchy.systemName);
      this.toaster.success('Corp Hierarchy Deleted');
    } catch (error) {
      this.toaster.error(error);
    }
  }

  private setSidebarItems() {
    const sidebar = [
      {
        path: `/admin/corps/${this.corp?.id}/hierarchies`,
        title: 'Hierarchies',
        type: 'link',
        icontype: 'fa fa-sitemap text-success',
      },
      {
        path: `/admin/corps/${this.corp?.id}/settings`,
        title: 'Settings',
        type: 'link',
        icontype: 'ni-settings-gear-65 text-success',
      },
    ];
    if (this.hierarchies.length > 0) {
      const hierarchy = this.hierarchies[0];
      sidebar.splice(1, 0, {
        path: `/admin/corps/${this.corp?.id}/hierarchies/${hierarchy.systemName}`,
        title: hierarchy.label,
        type: 'link',
        icontype: 'fas fa-arrow-circle-right text-gray',
      });
    }
    this.sidebarService.set(sidebar);
  }
}
