<div class="modal-header">
  <h4 class="modal-title">Add a new Model</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">x</span>
  </button>
</div>

<div class="modal-body">
  <form #form="ngForm" novalidate>
    <div class=" form-group">
      <label class=" form-control-label" for="modelName"> Name </label>
      <input
        class="form-control"
        id="modelName"
        name="modelName"
        [(ngModel)]="modelName"
        type="text"
        placeholder=""
        required
      />
      <div *ngIf="loadingChild">
        <div class=" row align-items-center py-4 justify-content-center">
          <app-loading></app-loading>
        </div>
      </div>
      <app-nlp-model-files
        [hidden]="loadingChild"
        [nlpModelFileItems]="nlpModelFileItems"
        [className]="'height-max-400'"
        (loaded)="childLoaded()"
      ></app-nlp-model-files>
    </div>

    <button
      class="btn btn-primary btn-outline"
      type="submit"
      [disabled]="!form.valid || loadingChild"
      (click)="createModel()"
    >
      SAVE
    </button>
  </form>
</div>
