<div class="modal-header">
  <h4 class="modal-title">Delete {{ typeOfEntity }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (submit)="deleteEntity()" #form="ngForm" novalidate>
  <div class="modal-body pt-0 pb-0">
      <label class=" form-control-label" for="name"> Type {{ typeOfEntity }} Name To Confirm <br />
        <code>{{entityValue}}</code>
      </label>
      <input data-test-id="confirm-delete-input" class="form-control" id="name" name="name" [(ngModel)]="entityNameInput" type="text" [placeholder]="entityValue" required />
  </div>
  <div class="modal-footer">
    <button data-test-id="confirm-delete-button" class="btn btn-primary btn-outline" type="submit" [disabled]="entityNameInput != entityValue">Delete</button>
    <button class="btn btn-primary-outline" type="button" (click)="modal.hide()">CLOSE</button>
  </div>
</form>
