import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from 'src/app/services/firestore';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ToastrService } from 'ngx-toastr';
import { RoleModel, UserModel } from 'src/app/models';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
import { UserNotificationSettingsModel } from 'src/app/models/user';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss'],
})
export class AddUserModalComponent implements OnInit {
  user: UserModel;
  loading: boolean;
  password: string;
  roles: RoleModel[];

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private usersService: UsersService,
    public modal: BsModalRef,
    private fns: AngularFireFunctions,
    private toaster: ToastrService,
  ) {
    this.user = new UserModel();
    this.user.notificationSettings = new UserNotificationSettingsModel();
    this.loading = false;
  }

  ngOnInit() {}

  async save() {
    if (this.password.length < 8) {
      this.toaster.error('The password must have at least 8 characters');
      return;
    }

    if (!/[^a-zA-Z0-9]/.test(this.password)) {
      this.toaster.error('The password must containt at least one special character (!@#$%^&*)');
      return;
    }

    if (!/[A-Z]/.test(this.password) || !/[a-z]/.test(this.password) || !/\d/.test(this.password)) {
      this.toaster.error(
        'The password must containt at least one lower case (a-z), one upper case (A-Z) and one number',
      );
      return;
    }

    this.actionProgress.start();
    this.loading = true;

    const callable = this.fns.httpsCallable('api/createUser');
    const createUser$ = callable({
      first_name: this.user.firstName,
      last_name: this.user.lastName,
      email: this.user.email,
      password: this.password,
    });

    try {
      const userRecord = await createUser$.toPromise();
      const uid = userRecord.uid;
      this.user.id = uid;
      this.user.notificationSettings.email = true;
      this.user.notificationSettings.in_app = true;
      this.user.notificationSettings.count = 0;
      await this.usersService.addUser(this.user);
      this.loading = false;
      this.modal.hide();
      this.toaster.success('User created');
    } catch (error) {
      this.loading = false;
      this.toaster.error(error.message);
    }
    this.actionProgress.complete();
  }
}
