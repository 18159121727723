<ng-container *ngIf="loading">
  <div class="container-fluid">
    <div class="row align-items-center py-4 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading">
  <app-action-progress></app-action-progress>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="card m-4">
          <div class="card-header d-flex align-items-center">
            <h3>{{ elementTitle }} - {{ corpHierarchy.label }}</h3>
            <button class="btn btn-outline-success btn-sm btn-circle ml-4" (click)="openAddHierarchyElementModal()">
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <div class="card-body">
            <div class="table-responsive" *ngIf="corp && hierarchyElements && hierarchyElements.length">
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">Logo</th>
                    <th scope="col">Label</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr *ngFor="let hierarchyElement of hierarchyElements">
                    <td>
                      <a class="avatar rounded-circle bg-white" href="javascript:void(0)">
                        <img
                          class="h-100"
                          alt="{{ corpHierarchy.singular }} Logo"
                          *ngIf="hierarchyElement.logo"
                          [src]="hierarchyElement.logo"
                        />
                      </a>
                    </td>
                    <td>
                      <h4>
                        {{ hierarchyElement.label }}
                      </h4>
                    </td>
                    <td>
                      <i
                        class="fa fa-cog text-info ml-3 cursor-pointer"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="{{ corpHierarchy.singular }} Settings"
                        (click)="openEditHierarchyElementModal(hierarchyElement)"
                      >
                      </i>
                      <i
                        class="fa fa-trash text-danger ml-3 cursor-pointer"
                        (click)="confirmAndDeleteHierarchyElement(hierarchyElement)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete {{ corpHierarchy.singular }}"
                      >
                      </i>
                      <i
                        *ngIf="nextDownLevelCorpHierarchy"
                        class="fa fa-chevron-right text-primary ml-3 cursor-pointer"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Show {{ nextDownLevelCorpHierarchy.label }}"
                        [routerLink]="[
                          '/admin/corps/' +
                            corp.id +
                            '/hierarchies/' +
                            nextDownLevelCorpHierarchy.systemName +
                            '/hierarchy-el/' +
                            hierarchyElement.systemNameForUrl
                        ]"
                      >
                      </i>
                      <i
                        *ngIf="!nextDownLevelCorpHierarchy"
                        class="fa fa-chevron-right text-primary ml-3 cursor-pointer"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Show Assistants"
                        [routerLink]="[
                          '/admin/corps/' +
                            corp.id +
                            '/hierarchies/' +
                            corpHierarchy.systemName +
                            '/hierarchy-el/' +
                            hierarchyElement.systemNameForUrl +
                            '/bots'
                        ]"
                      >
                      </i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 *ngIf="!hierarchyElements || hierarchyElements.length === 0">No {{ corpHierarchy.label }}.</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
