<div class="nlp-dataset-file-container">
  <app-simple-editor
    *ngIf="nlpDatasetFile != null"
    [title]="nlpDatasetFile.name"
    [tabs]="tabs"
    (saveAll)="saveAll($event)"
    [canRename]="true"
    (renameFile)="renameFile($event)"    
  >
  </app-simple-editor>
</div>
