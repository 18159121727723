import { Timestamp } from 'firebase-tools';
import { BotModel } from './bot';

export enum FlowTemplateType {
  FLOW_TEMPLATE = 'FLOW_TEMPLATE',
  BOT_TEMPLATE = 'BOT_TEMPLATE'
}

export class FlowTemplateModel {
  label: string;
  systemName: string;
  corpId: string;
  numberOfNodes: number;
  image: string;
  lastTouchedBy: string;
  global: boolean;
  templateType: FlowTemplateType;
  lastTouchedHash: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  sourceBot: string;

  get getTemplateTypeAsLabel() {
    return this.templateType === FlowTemplateType.BOT_TEMPLATE ? 'Bot Template' : 'Flow Template';
  }

  get getBotConfig(): BotModel | null {
    try {
      return JSON.parse(`${this.sourceBot}`);
    } catch (e) {
      return null;
    }
  }

  static isBotTemplate(template: FlowTemplateModel): boolean {
    return template.templateType === FlowTemplateType.BOT_TEMPLATE;
  }

  static isGlobalTemplate(template: FlowTemplateModel): boolean {
    return template.global;
  }

  static isFlowTemplate(template: FlowTemplateModel): boolean {
    return template.templateType === FlowTemplateType.FLOW_TEMPLATE;
  }

  static generateSystemName(flowTemplate: FlowTemplateModel): string {
    let flowTemplatePrefix = flowTemplate.corpId;
    if (flowTemplate.global) {
      flowTemplatePrefix = 'global';
    }
    flowTemplate.systemName = `${flowTemplatePrefix}-${flowTemplate.label}`
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-');
    return flowTemplate.systemName;
  }
}
