import { Component } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { InputValidationModel } from 'src/app/models/input-validation';

@Component({
  selector: 'app-add-input-validation-modal',
  templateUrl: './add-input-validation-modal.component.html',
  styleUrls: ['./add-input-validation-modal.component.scss']
})
export class AddInputValidationModalComponent {
  inputValidation: InputValidationModel;
  loading: boolean;

  constructor(public modal: BsModalRef, options: ModalOptions) {
    if (!options.initialState) {
      return;
    }
    const { corpId } = options.initialState as any;
    this.inputValidation = InputValidationModel.generateDefault();
    this.inputValidation.corpId = corpId;
  }
}
