<ng-container *ngIf="loading">
    <div class=" container-fluid">
      <div class=" row align-items-center py-4 justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <div class=" container-fluid">
      <div class=" row">
        <div class=" col-lg-12 col-sm-12">
          <div class=" card m-4">
            <div class=" card-header d-flex align-items-center">
              <h3>Hierarchies</h3>
              <button class="btn btn-outline-success btn-sm btn-circle ml-4" (click)="openAddHierarchyModal()">
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class=" card-body">
              <div class=" table-responsive" *ngIf="hierarchies && hierarchies.length">
                <table class=" table align-items-center table-flush">
                  <thead class=" thead-light">
                    <tr>
                      <th scope="col">Level</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody class=" list">
                    <tr *ngFor="let hierarchy of hierarchies">
                      <td>
                        <h4>
                          {{ hierarchy.label }}
                        </h4>
                      </td>
                      <td>
                        <i
                          class=" fa fa-cog text-info ml-3 cursor-pointer"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Hierarchy Settings"
                          (click)="openEditHierarchyModal(hierarchy)"
                        >
                        </i>
                        <i
                          class=" fa fa-trash text-danger ml-3 cursor-pointer"
                          (click)="confirmAndDeleteHierarchy(hierarchy)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete Hierarchy"
                        >
                        </i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h4 *ngIf="!hierarchies || hierarchies.length === 0">No Hierarchies.</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
