import { Component } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NlpDatasetFile } from 'src/app/models/nlp/nlp-dataset/nlp-dataset-file';

@Component({
  selector: 'app-add-nlp-dataset-modal-modal',
  templateUrl: './add-nlp-dataset-file-modal.component.html',
  styleUrls: ['./add-nlp-dataset-file-modal.component.scss']
})
export class AddNlpDatasetFileModalComponent {
  datasetSystemName: string;
  nlpDatasetFile: NlpDatasetFile;
  loading: boolean;

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
  ) {
    if (!options.initialState) {
      return;
    }
    const { datasetSystemName } = options.initialState as any;
    this.datasetSystemName = datasetSystemName;
    this.nlpDatasetFile = NlpDatasetFile.generateDefault();
  }
}
