<div class="h-100 d-flex flex-column pt-3">
  <div>
    <div class="filter-form-group row mb-2">
      <label class="col-md-3 col-form-label form-control-label" for="dateRangePicker">
        Date Range
      </label>
      <div class="col-md-5">
        <input
          type="text"
          class="form-control"
          id="dateRangePicker"
          bsDaterangepicker
          [(ngModel)]="filter.dateRange"
          [bsConfig]="{
            isAnimated: true,
            containerClass: 'theme-dark-blue',
            maxDate: dateOfToday,
            showWeekNumbers: false
          }"
          [disabled]="false"
          name="bsDaterangepicker"
          (bsValueChange)="onDateIntervalChange($event)"
        />
      </div>
    </div>
    <div class="filter-form-group row mb-2">
      <label class="col-md-3 col-form-label form-control-label"> Sort by: </label>
      <div class="col-md-5">
        <select
          class="form-control"
          name="sortBy"
          [(ngModel)]="filter.sortByTimeOrder"
          (ngModelChange)="onSortOrderChange()"
        >
          <option value="asc">Oldest</option>
          <option value="desc">Newest</option>
        </select>
      </div>

      <div class="col-md-4">
        <button class="btn btn-primary btn-outline float-right" (click)="toggleAllFields()" [disabled]="loading">
          {{ allFieldsSelected ? 'Unselect' : 'Select' }} all fields
        </button>
      </div>
    </div>
  </div>

  <div class="h-100 d-flex flex-column border mb-2 h-100 overflow-auto">
    <ng-container *ngIf="loading">
      <div class=" row align-items-center py-4 justify-content-center">
        <app-loading></app-loading>
      </div>
    </ng-container>

    <ng-container *ngIf="!loading">
      <tree-root class="mt-2" #tree [nodes]="mappingNodes" [options]="options" (activate)="toggleNode($event)">
        <ng-template #treeNodeTemplate let-node let-index="index">
          <div class="tree-node-content">
            <span class="node-title" [ngClass]="node.data.selected ? 'text-primary' : 'text-dark'">{{
              node.data.name
            }}</span>
          </div>
        </ng-template>
      </tree-root>
    </ng-container>
  </div>
</div>
