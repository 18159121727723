<app-action-progress></app-action-progress>
<div class="template-container container-fluid">
    <div class="row mt-3">
        <div class="col-7 bg-white pb-3 rounded shadow">
            <app-simple-editor *ngIf="apiQuery != null" [title]="apiQuery.name" [tabs]="tabs" [canSave]="canSaveCorpApiQuery()" (saveAll)="saveAll($event)">
            </app-simple-editor>
        </div>
        <div class="col-5">
            <app-graphql-docs *ngIf="schema" [schema]="schema">
            </app-graphql-docs>
        </div>
    </div>
</div>