import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { HumanInLoopService } from '../../../services/firestore/human-in-loop.service';
import { CustomerSettingsModel } from '../../../models/CustomerSettingsModel';
import { DumbledoreCustomerModel } from '../../../models/DumbledoreCustomerModel';
import { ToastrService } from 'ngx-toastr';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth.service';
import { DumbledoreService } from 'src/app/services/dumbledore.service';

@Component({
  selector: 'app-hl-customer-settings-modal',
  templateUrl: './hl-customer-settings-modal.component.html',
  styleUrls: ['./hl-customer-settings-modal.component.scss'],
})
export class HlCustomerSettingsModalComponent implements OnInit {
  customer: CustomerSettingsModel = new CustomerSettingsModel();
  originalCustomer: CustomerSettingsModel;
  conversation_id: string;
  vehicle: any;
  campaign: any;
  channels = ['EMAIL', 'SMS'];
  user: DumbledoreCustomerModel;
  stopSeriesDisabled: boolean = true;
  errors: string = '';
  botChannel: string;
  bot: any;
  header1 = '';
  header2 = '';
  internalUser = false;
  modalIsLoading = true;
  selectedSettings: EventEmitter<{
    newCustomerSettings: CustomerSettingsModel;
    originalCustomerSettings: CustomerSettingsModel;
    currentCustomer: {
      customer_id: number;
      legacy_customer_id: string;
      dms_customer_number: string;
      first_name: string;
      last_name: string;
      email: string;
      sms: string;
      phone: string;
      customer_type_id: number;
      creation_date: Date;
      last_updated: Date;
      active: true;
      bot_configuration_id: number;
      secondary_email: string;
    };
  }>;

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private toaster: ToastrService,
    public humanInLoopService: HumanInLoopService,
    private authService: AuthService,
    private dumbledoreService: DumbledoreService,
  ) {
    const { conversation_id, bot } = options.initialState as any;
    this.selectedSettings = new EventEmitter<{
      newCustomerSettings: CustomerSettingsModel;
      originalCustomerSettings: CustomerSettingsModel;
      currentCustomer: DumbledoreCustomerModel;
    }>();
    this.bot = bot;
    this.conversation_id = conversation_id;
  }

  ngOnInit() {
    this.initializeData();
    this.authService.currentUser.then(currentUser => {
      if (currentUser && currentUser.email) {
        this.internalUser =
          currentUser.email.toLowerCase().indexOf('@carlabs.') > 0 ||
          currentUser.email.toLowerCase().indexOf('@impel.') > 0;
        if (this.internalUser) {
          this.header2 = this.header1 ? `Conversation Id: ${this.conversation_id}` : '';
          this.header1 = this.header1 || `Conversation Id: ${this.conversation_id}`;
        }
      }
    });
  }

  async initializeData() {
    if (!this.modalIsLoading) this.modalIsLoading = true;
    const customer_result = await this.dumbledoreService.getCustomerByConversationId(this.conversation_id);
    this.user = customer_result && customer_result.user ? customer_result.user : {};
    if (this.user) {
      const customer_channels_result = await this.dumbledoreService.getCustomerChannelsByCustomerId(
        this.user.customer_id,
      );
      const email_channel =
        customer_channels_result && customer_channels_result.user
          ? customer_channels_result.user.find(c => c.code === 'EMAIL')
          : {};
      const sms_channel =
        customer_channels_result && customer_channels_result.user
          ? customer_channels_result.user.find(c => c.code === 'SMS')
          : {};
      const vehicle_result = await this.dumbledoreService.getVehicleByLegacyConversationId(this.conversation_id);
      const conversation_results = await this.dumbledoreService.getCustomerConversations(this.user.customer_id);
      const campaigns =
        conversation_results && conversation_results.user && conversation_results.user.conversations
          ? conversation_results.user.conversations
          : null;
      this.campaign = campaigns
        ? campaigns.find((conversation: any) => conversation.legacy_conversation_id === this.conversation_id)
        : {};
      this.vehicle = vehicle_result && vehicle_result.user ? vehicle_result.user : {};

      const campaignStatus = get(this.campaign, 'conversation_status_id', 0);

      this.customer.firstName = get(this.user, 'first_name', '');
      this.customer.lastName = get(this.user, 'last_name', '');
      this.customer.email = get(this.user, 'email', '');
      this.customer.phone = get(this.user, 'phone', '');
      this.customer.sms = get(this.user, 'sms', '');
      this.customer.inactive = this.vehicle ? !get(this.vehicle, 'active', true) : false;
      this.customer.channel = get(this.user, 'preferredContact', 'EMAIL');
      this.customer.doNotContact = get(email_channel, 'do_not_send', false);
      this.customer.doNotContactInternal = get(email_channel, 'do_not_send_internal', false);
      this.customer.doNotContactSMS = get(sms_channel, 'do_not_send', false);
      this.customer.doNotContactInternalSMS = get(sms_channel, 'do_not_send_internal', false);
      this.customer.doNotContactFederal = get(sms_channel, 'do_not_send_federal', false);
      this.customer.doNotContactLitigator = get(sms_channel, 'do_not_send_litigator', false);

      this.stopSeriesDisabled = campaignStatus !== 2 && campaignStatus !== 6;
      this.customer.stopCurrentSeries = false;
      this.originalCustomer = JSON.parse(JSON.stringify(this.customer));
      let vehicle = '';

      if (this.vehicle) {
        vehicle = `${this.vehicle.vehicle_year || ''} ${this.vehicle.vehicle_make || ''} ${
          this.vehicle.vehicle_model || ''
        }`;
        vehicle = `${vehicle} - ${this.vehicle.vin}`;
      }

      this.header1 = vehicle ? vehicle.trim() : '';
      this.header2 = '';
    }
    this.modalIsLoading = false;
  }

  setChannel(e: any) {
    this.customer.channel = e && e.target && e.target.value ? e.target.value : this.customer.channel;
  }

  save() {
    this.errors = '';

    if (!this.customer.firstName || this.customer.firstName.trim().length < 2) {
      this.errors = 'First name must be at least 2 characters.';
      return false;
    }

    if (!this.customer.lastName || this.customer.lastName.trim().length < 2) {
      this.errors = 'Last name must be at least 2 characters.';
      return false;
    }

    if (JSON.stringify(this.customer) !== JSON.stringify(this.originalCustomer)) {
      this.modalIsLoading = true;
      this.selectedSettings.emit({
        newCustomerSettings: this.customer,
        originalCustomerSettings: this.originalCustomer,
        currentCustomer: this.user,
      });
    } else {
      this.toaster.error('No changes found.');
    }
  }
}
