import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AwsRegionEnum } from '../../../models/countries/AwsRegionEnum';

@Component({
  selector: 'app-reports',
  templateUrl: './report-container.component.html',
  styleUrls: ['./report-container.component.scss'],
})
export class ReportContainerComponent implements OnInit {
  tabs = [
    {
      label: 'Engagement Report',
      slug: 'engagement-report',
      visible: true,
    },
    {
      label: 'Overview Report',
      slug: 'overview-report',
      visible: true,
    },
    {
      label: 'Rep Performance Report',
      slug: 'rep-performance-report',
      visible: true,
    },
    {
      label: 'Intent Report',
      slug: 'intent-report',
      visible: true,
    },
    {
      label: 'Repair Order Report',
      slug: 'repair-order-report',
      visible: true,
    },
  ];
  loading = false;
  @Input() botId: string;
  currentTab: string;
  awsRegion?: AwsRegionEnum;

  @Input() endDate: Date = new Date();
  @Input() startDate: Date = new Date(2000, 1, 1);
  reportType: string;
  @Output() reportTypeEmitter = new EventEmitter<string>();

  constructor() {
    this.loading = false;
    this.currentTab = 'engagement-report';
  }

  ngOnInit() {
    const json = this.getSessionParameters();
    if (json !== null) {
      this.startDate = new Date(json.startDate);
      this.endDate = new Date(json.endDate);
      this.reportType = this.tabs[0].visible === true ? 'OR' : 'CR';
    } else {
      this.startDate.setMonth(this.endDate.getMonth() - 6);
      this.reportType = this.tabs[0].visible === true ? 'OR' : 'CR';
    }
    this.reportTypeEmitter.emit(this.reportType);
  }

  getSessionParameters() {
    try {
      const storage = window.localStorage;
      if (storage) {
        const value = storage.getItem('dashboardParameters') || null;
        storage.removeItem('dashboardParameters');
        if (value !== null) {
          const json = JSON.parse(value);
          return json;
        }
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  setTab(tab: string) {
    this.currentTab = tab;
  }
}
