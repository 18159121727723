import { Injectable } from '@angular/core';
import { NodeKpiModel } from 'src/app/models/node';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  QueryFn,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { classToPlain, plainToClass } from 'class-transformer';
import { CorpModel } from 'src/app/models';
import { COLLECTION_NAMES } from './constants';

@Injectable({
  providedIn: 'root',
})
export class CorpsService {
  // Yes Public! the CorpHierarchiesService will be using this
  corpsCollection: AngularFirestoreCollection<CorpModel>;

  constructor(private afs: AngularFirestore) {
    this.corpsCollection = afs.collection<CorpModel>(COLLECTION_NAMES.CORPS);
  }

  addCorp(corp: CorpModel): Promise<void> {
    const corpId = corp.id; // avoiding error prone scenarios
    return this.corpsCollection
      .doc(corpId)
      .ref.get()
      .then(result => {
        if (result.exists) {
          throw new Error(`Corporation "${corpId}" already exists`);
        } else {
          return this.corpsCollection.doc(corpId).set(Object.assign({}, corp));
        }
      });
  }

  updateCorp(corp: CorpModel): Promise<void> {
    return this.corpsCollection.doc(corp.id).update(Object.assign({}, corp));
  }

  getCorpById(id: string): Observable<CorpModel | null> {
    return this.corpsCollection
      .doc(id)
      .valueChanges()
      .pipe(
        map(corp => {
          return plainToClass(CorpModel, corp);
        }),
      );
  }

  getCorps(): Observable<CorpModel[]> {
    return this.corpsCollection.valueChanges().pipe(
      map(corps => {
        return corps.map(corp => plainToClass(CorpModel, corp));
      }),
    );
  }

  removeCorp(id: string) {
    return this.corpsCollection.doc(id).delete();
  }

  updateCorpKpis(corpId: string, kpis: NodeKpiModel[]): Promise<void> {
    const plainKpis = classToPlain(kpis) as NodeKpiModel[];
    // use the untype corps collection to prevent
    // error from type mismatch between kpis and classToPlain
    return this.corpsCollection.doc(corpId).update({
      kpis: plainKpis,
    });
  }
}
