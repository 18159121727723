<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h5>
    {{message}}
  </h5>
</div>
<div class="modal-footer">
  <button  class="btn btn-primary" (click)="sendConfirmation();">CONFIRM</button>
  <button class="btn btn-primary-outline" (click)="modal.hide()">CLOSE</button>
</div>