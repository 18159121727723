<div class="container-fluid bot-dashboard-component-wrapper">
  <div *ngIf="loading">
    <div class="container-fluid">
      <div class="row align-items-center py-4 justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>

  <ng-container>
    <div *ngIf="!loading">
      <div *ngIf="bot && botExists">
        <!-- <div class=" row mt-4">
        <div class=" col-xl-3 col-md-6">
          <div class=" card bg-gradient-default border-0">
            <div class=" card-body">
              <div class=" row">
                <div class=" col">
                  <h5 class=" card-title text-uppercase text-muted mb-0 text-white">
                    Session Count
                  </h5>

                  <span class=" h2 font-weight-bold mb-0 text-white">
                    8/24
                  </span>

                  <div class=" progress progress-xs mt-3 mb-0">
                    <progressbar type="success" [value]="30"> </progressbar>
                  </div>
                </div>

                <div class=" col-auto">
                  <div dropdown class="dropdown no-caret">
                    <button
                      id="button-basic"
                      dropdownToggle
                      type="button"
                      class="btn btn-sm btn-neutral mr-0 dropdown-toggle"
                      aria-controls="dropdown-basic"
                    >
                      <i class=" fas fa-ellipsis-h"> </i>
                    </button>

                    <div
                      class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                      *dropdownMenu
                    >
                      <a class="dropdown-item" href="javascript:void(0)">
                        Action
                      </a>

                      <a class="dropdown-item" href="javascript:void(0)">
                        Another action
                      </a>

                      <a class="dropdown-item" href="javascript:void(0)">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <p class=" mt-3 mb-0 text-sm">
                <a class=" text-nowrap text-white font-weight-600" href="javascript:void(0)">
                  See details
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class=" col-xl-3 col-md-6">
          <div class=" card bg-gradient-default border-0">
            <div class=" card-body">
              <div class=" row">
                <div class=" col">
                  <h5 class=" card-title text-uppercase text-muted mb-0 text-white">
                    KPI's Hit
                  </h5>

                  <span class=" h2 font-weight-bold mb-0 text-white">
                    123/267
                  </span>

                  <div class=" progress progress-xs mt-3 mb-0">
                    <progressbar type="success" [value]="50"> </progressbar>
                  </div>
                </div>

                <div class=" col-auto">
                  <div dropdown class="dropdown no-caret">
                    <button
                      id="button-basic"
                      dropdownToggle
                      type="button"
                      class="btn btn-sm btn-neutral mr-0 dropdown-toggle"
                      aria-controls="dropdown-basic"
                    >
                      <i class=" fas fa-ellipsis-h"> </i>
                    </button>

                    <div
                      class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                      *dropdownMenu
                    >
                      <a class="dropdown-item" href="javascript:void(0)">
                        Action
                      </a>

                      <a class="dropdown-item" href="javascript:void(0)">
                        Another action
                      </a>

                      <a class="dropdown-item" href="javascript:void(0)">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <p class=" mt-3 mb-0 text-sm">
                <a class=" text-nowrap text-white font-weight-600" href="javascript:void(0)">
                  See details
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class=" col-xl-3 col-md-6">
          <div class=" card bg-gradient-default border-0">
            <div class=" card-body">
              <div class=" row">
                <div class=" col">
                  <h5 class=" card-title text-uppercase text-muted mb-0 text-white">
                    Quiz's Taken
                  </h5>

                  <span class=" h2 font-weight-bold mb-0 text-white">
                    200/300
                  </span>

                  <div class=" progress progress-xs mt-3 mb-0">
                    <progressbar type="success" [value]="80"> </progressbar>
                  </div>
                </div>

                <div class=" col-auto">
                  <div dropdown class="dropdown no-caret">
                    <button
                      id="button-basic"
                      dropdownToggle
                      type="button"
                      class="btn btn-sm btn-neutral mr-0 dropdown-toggle"
                      aria-controls="dropdown-basic"
                    >
                      <i class=" fas fa-ellipsis-h"> </i>
                    </button>

                    <div
                      class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                      *dropdownMenu
                    >
                      <a class="dropdown-item" href="javascript:void(0)">
                        Action
                      </a>

                      <a class="dropdown-item" href="javascript:void(0)">
                        Another action
                      </a>

                      <a class="dropdown-item" href="javascript:void(0)">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <p class=" mt-3 mb-0 text-sm">
                <a class=" text-nowrap text-white font-weight-600" href="javascript:void(0)">
                  See details
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class=" col-xl-3 col-md-6">
          <div class=" card bg-gradient-default border-0">
            <div class=" card-body">
              <div class=" row">
                <div class=" col">
                  <h5 class=" card-title text-uppercase text-muted mb-0 text-white">
                    Emails Captured
                  </h5>

                  <span class=" h2 font-weight-bold mb-0 text-white">
                    50/62
                  </span>

                  <div class=" progress progress-xs mt-3 mb-0">
                    <progressbar type="success" [value]="90"> </progressbar>
                  </div>
                </div>

                <div class=" col-auto">
                  <div dropdown class="dropdown no-caret">
                    <button
                      id="button-basic"
                      dropdownToggle
                      type="button"
                      class="btn btn-sm btn-neutral mr-0 dropdown-toggle"
                      aria-controls="dropdown-basic"
                    >
                      <i class=" fas fa-ellipsis-h"> </i>
                    </button>

                    <div
                      class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                      *dropdownMenu
                    >
                      <a class="dropdown-item" href="javascript:void(0)">
                        Action
                      </a>

                      <a class="dropdown-item" href="javascript:void(0)">
                        Another action
                      </a>

                      <a class="dropdown-item" href="javascript:void(0)">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <p class=" mt-3 mb-0 text-sm">
                <a class=" text-nowrap text-white font-weight-600" href="javascript:void(0)">
                  See details
                </a>
              </p>
            </div>
          </div>
        </div>
      </div> -->

        <div *ngIf="invalidBotVariables.length > 0" class="card-deck flex-column flex-xl-row mt-4">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-8">
                  <h5 class="h3 mb-0">
                    Welcome to your assistant!
                    <small>
                      <br />
                      We look forward to getting your assistant fully deployed! The required fields below are going to
                      walk you through some of the needed items in order to configure your bot. These inputs allow us to
                      automatically standup your assistant with little additional work on your part.
                    </small>
                  </h5>
                </div>
                <div class="col-4 text-right">
                  <button type="button" class="btn btn-outline-primary" (click)="saveBotVariable()">Save</button>
                </div>
              </div>
            </div>
            <div class="card-body p-0 scrollable-card">
              <ul class="list-group list-group-flush" data-toggle="checklist">
                <li
                  *ngFor="let botVariable of invalidBotVariables"
                  class="checklist-entry list-group-item flex-column align-items-start py-4 px-4"
                >
                  <div>
                    <div class="checklist-info">
                      <h5 class="checklist-title mb-0">
                        {{ botVariable.label }}
                      </h5>
                      <small>{{ botVariable.description }}</small>
                      <h5>
                        <small>
                          <input [(ngModel)]="botVariable.defaultValue" class="form-control md-6 lg-6 sm-12" />
                        </small>
                      </h5>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-deck flex-column flex-xl-row mt-4">
          <!-- <div class=" card">
          <div class=" card-header">
            <h5 class=" h3 mb-0">Approval Queue</h5>
          </div>

          <div class=" card-body">
            <ul class=" list-group list-group-flush list my--3">
              <li class=" list-group-item px-0">
                <div class=" row align-items-center">
                  <div class=" col">
                    <h4 class=" mb-0">
                      <a href="javascript:void(0)">Action Edited</a>
                    </h4>

                    <span class=" text-warning"> ● </span>

                    <small>
                      Request Details Seltos - Text description changed</small>
                  </div>

                  <div class=" col-auto">
                    <button class=" btn btn-sm btn-success mr-3" type="button">
                      Approve
                    </button>
                    <button class=" btn btn-sm btn-danger" type="button">
                      Deny
                    </button>
                  </div>
                </div>
              </li>

              <li class=" list-group-item px-0">
                <div class=" row align-items-center">
                  <div class=" col">
                    <h4 class=" mb-0">
                      <a href="javascript:void(0)">Action Edited</a>
                    </h4>

                    <span class=" text-warning"> ● </span>

                    <small>
                      Request Details Seltos - Text description changed</small>
                  </div>

                  <div class=" col-auto">
                    <button class=" btn btn-sm btn-success mr-3" type="button">
                      Approve
                    </button>
                    <button class=" btn btn-sm btn-danger" type="button">
                      Deny
                    </button>
                  </div>
                </div>
              </li>

              <li class=" list-group-item px-0">
                <div class=" row align-items-center">
                  <div class=" col">
                    <h4 class=" mb-0">
                      <a href="javascript:void(0)">Action Edited</a>
                    </h4>

                    <span class=" text-warning"> ● </span>

                    <small>
                      Request Details Seltos - Text description changed</small>
                  </div>

                  <div class=" col-auto">
                    <button class=" btn btn-sm btn-success mr-3" type="button">
                      Approve
                    </button>
                    <button class=" btn btn-sm btn-danger" type="button">
                      Deny
                    </button>
                  </div>
                </div>
              </li>

              <li class=" list-group-item px-0">
                <div class=" row align-items-center">
                  <div class=" col">
                    <h4 class=" mb-0">
                      <a href="javascript:void(0)">Action Edited</a>
                    </h4>

                    <span class=" text-warning"> ● </span>

                    <small>
                      Request Details Seltos - Text description changed</small>
                  </div>

                  <div class=" col-auto">
                    <button class=" btn btn-sm btn-success mr-3" type="button">
                      Approve
                    </button>
                    <button class=" btn btn-sm btn-danger" type="button">
                      Deny
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div> -->

          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-8">
                  <h5 class="h3 mb-0">Releases / Versions</h5>
                  <h4
                    [attr.data-test-id]="'current-version'"
                    [attr.data-test-value]="currentVersionNumber"
                    title="History: [{{ currentVersionHistory }}]"
                  >
                    Current Version: {{ currentVersionNumber }}
                  </h4>
                  <h4
                    [attr.data-test-id]="'latest-version'"
                    [attr.data-test-value]="latestVersionNumber"
                    title="History: [{{ latestVersionHistory }}]"
                  >
                    Latest Version: {{ latestVersionNumber }}
                  </h4>
                </div>
                <div class="col-4 text-right">
                  <button
                    data-test-id="deploy-button"
                    type="button"
                    class="btn btn-outline-primary bg-white"
                    (click)="handleOpenDeployModal()"
                  >
                    Deploy
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body p-0 scrollable-card">
              <ul class="list-group list-group-flush" data-toggle="checklist">
                <li
                  *ngFor="let versionItem of versions.slice().reverse()"
                  class="checklist-entry list-group-item flex-column align-items-start py-4 px-4"
                >
                  <div
                    [className]="
                      'checklist-item ' +
                      (versionItem.versionDescription.startsWith('Flow') ? 'checklist-item-success' : '')
                    "
                  >
                    <div class="checklist-info">
                      <h5
                        title="History: [{{
                          versionItem.versionHistory ? versionItem.versionHistory.join(' -> ') : ''
                        }}]"
                        class="checklist-title mb-0"
                      >
                        {{ versionItem.versionDescription }} | Version {{ versionItem.versionNumber }}
                      </h5>
                      <small>{{ versionItem.createdAt | date: 'medium' }}</small>
                      <h5>
                        <small>{{ versionItem.createdBy.userName }}</small>
                      </h5>
                    </div>

                    <!-- TODO: Add ability to roll back to specific version using this dropdown. -->
                    <!-- <div>
                      <div dropdown class="dropdown no-caret">
                        <a id="button-basic" dropdownToggle role="button"
                          class="c-pointer btn btn-sm btn-icon-only text-light dropdown-toggle"
                          aria-controls="dropdown-basic">
                          <i class=" fas fa-ellipsis-v"> </i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" *dropdownMenu>
                          <a [className]="'dropdown-item ' + (versionItem.versionNumber === currentVersionNumber ? 'disabled' : '')"
                            href="javascript:void(0)" (click)="handleRollbackClick(versionItem)">
                            Deploy this Version
                          </a>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!botExists">
        <div class="container-fluid">
          <div class="row align-items-center py-4 justify-content-center">
            <p>No bots exist in this environment.</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
