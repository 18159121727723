import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomerSettingsModel } from 'src/app/models/CustomerSettingsModel';
import { firstValueFrom } from 'rxjs';

type SharedChannelsPayload = {
  botId: string;
  conversationId: string;
  message: string;
};

type ComputedBodyAttributes = {
  dealershipCode: string;
  corporateName: string;
};

type SendEmailPayload = {
  channel: 'email';
  fromEmail: { address: string | null; name: string | null } | null;
  subject: string;
  toEmail: string | null;
};

type SendSMSPayload = {
  channel: 'sms';
  fromNumber: string;
  toNumber: string;
};
export enum DoNotContact {
  DMS = 'D',
  INTERNAL = 'I',
  FEDERAL_DNC = 'F',
  KNOWN_LITIGATOR = 'L',
}

export type SendMessagePayload = SharedChannelsPayload & (SendEmailPayload | SendSMSPayload);

type SharedChannelBody = SendMessagePayload & ComputedBodyAttributes;

const createEmailBody = (args): SharedChannelBody => {
  const { fromEmail: orgFromEmail } = args;
  const fromEmail = {
    address: orgFromEmail ? orgFromEmail.address : null,
    name: orgFromEmail ? orgFromEmail.name : null,
  };

  return { ...args, fromEmail };
};

const createSMSBody = args => {
  const { message, ...rest } = args;
  return { body: message, ...rest };
};

@Injectable({
  providedIn: 'root',
})
export class DumbledoreService {
  private dumbledoreSendMessageUrl: string;
  private dumbledoreSetDoNotContactUrl: string;
  private dumbledoreStopCampaignProgressUrl: string;
  private dumbledoreSetVehicleInactive: string;
  private dumbledoreSetPreferredChannel: string;
  private dumbledoreGetCustomerByConversationIdUrl: string;
  private dumbledoreUpdateCustomerSettingsUrl: string;
  private dumbledoreGetVehicleByLegacyConversationId: string;
  private dumbledoreGetCustomerConversations: string;
  private dumbledoreGetCustomerChannelsByCustomerId: string;
  private dumbledoreGetManifestOptions: string;

  constructor(private http: HttpClient) {
    this.dumbledoreSendMessageUrl = environment.dumbledore.url + 'send-message';
    this.dumbledoreSetDoNotContactUrl = environment.dumbledore.url + 'set-do-not-contact';
    this.dumbledoreStopCampaignProgressUrl = environment.dumbledore.url + 'stop-campaign-progress';
    this.dumbledoreSetVehicleInactive = environment.dumbledore.url + 'set-vehicle-inactive';
    this.dumbledoreGetCustomerByConversationIdUrl = environment.dumbledore.url + 'get-customer-by-conversation-id';
    this.dumbledoreUpdateCustomerSettingsUrl = environment.dumbledore.url + 'update-customer-settings';
    this.dumbledoreSetPreferredChannel = environment.dumbledore.url + 'set-preferred-channel';
    this.dumbledoreGetVehicleByLegacyConversationId =
      environment.dumbledore.url + 'get-vehicle-by-legacy-conversation-id';
    this.dumbledoreGetCustomerConversations = environment.dumbledore.url + 'get-customer-conversations';
    this.dumbledoreGetCustomerChannelsByCustomerId =
      environment.dumbledore.url + 'get-customer-channels-by-customer-id';
    this.dumbledoreGetManifestOptions = environment.dumbledore.url + 'get-manifest-options';
  }

  async sendConversationMessage(args: SendMessagePayload) {
    const { botId, channel } = args;
    const botComponents = botId.split('-');
    let dealershipCode = botComponents[0] + '-' + botComponents[1];
    if (botComponents.length === 4) {
      dealershipCode += '-' + botComponents[2];
    }
    if (botComponents.length === 5) {
      dealershipCode += '-' + botComponents[3];
    }

    const corporateName = botComponents[0];

    const computedAttributes: ComputedBodyAttributes = { corporateName, dealershipCode };

    const body =
      channel === 'email'
        ? createEmailBody({ ...args, ...computedAttributes })
        : createSMSBody({ ...args, ...computedAttributes } as SharedChannelBody);

    const result = await this.http
      .post(this.dumbledoreSendMessageUrl, body)
      .toPromise()
      .then(data => {
        return data;
      })
      .catch(error => {
        console.log(error);
      });
    return result;
  }

  async setDoNotContact(
    customerId: number,
    conversationId: string,
    doNotContact: { channelCode: string; type: DoNotContact; value: boolean }[],
  ) {
    await Promise.all(
      doNotContact.map(async dnc => {
        const body = {
          customerId,
          channelCode: dnc.channelCode,
          legacyConversationId: conversationId,
          doNotContact: dnc.type,
          value: dnc.value,
        };

        let response = await this.http
          .post(this.dumbledoreSetDoNotContactUrl, JSON.stringify(body))
          .toPromise()
          .then(data => {
            return { status: 'success', body, data };
          })
          .catch(error => {
            console.log(error);
            return null;
          });

        return dnc;
      }),
    );
  }

  async setVehicleInactive(conversationId: string, inactive: boolean) {
    const body = { legacyConversationId: conversationId, inactive };
    const response = await this.http
      .post(this.dumbledoreSetVehicleInactive, JSON.stringify(body))
      .toPromise()
      .then(() => {
        return { status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }

  async setPreferredChannel(conversationId: string, channel: string) {
    const body = { conversationId, value: channel };
    const response = await this.http
      .post(this.dumbledoreSetPreferredChannel, JSON.stringify(body))
      .toPromise()
      .then(() => {
        return { status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }

  async stopCampaignProgress(conversationId: string) {
    const body = {
      legacyConversationId: conversationId,
    };
    const response = await this.http
      .post(this.dumbledoreStopCampaignProgressUrl, JSON.stringify(body))
      .toPromise()
      .then(() => {
        return { status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }

  async getCustomerByConversationId(conversationId: string) {
    const body = { legacyConversationId: conversationId };

    const response = await this.http
      .post(this.dumbledoreGetCustomerByConversationIdUrl, JSON.stringify(body))
      .toPromise()
      .then(user => {
        return { user, status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }
  async getCustomerChannelsByCustomerId(customerId: number) {
    const body = { customerId };
    const response = await this.http
      .post(this.dumbledoreGetCustomerChannelsByCustomerId, JSON.stringify(body))
      .toPromise()
      .then(user => {
        return { user, status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }
  async getCustomerConversations(customerId: number) {
    const body = { customerId, conversationStatus: null };
    const response = await this.http
      .post(this.dumbledoreGetCustomerConversations, JSON.stringify(body))
      .toPromise()
      .then(user => {
        return { user, status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }

  async getVehicleByLegacyConversationId(conversationId: string) {
    const body = {
      legacyConversationId: conversationId,
    };
    const response = await this.http
      .post(this.dumbledoreGetVehicleByLegacyConversationId, JSON.stringify(body))
      .toPromise()
      .then(user => {
        return { user, status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }

  async updateCustomerSettings(userId: string, customerSettings: CustomerSettingsModel) {
    const body = { userId, ...customerSettings };
    if (body.channel === 'SMS') {
      body.channel = 1;
    } else {
      body.channel = 2;
    }
    const response = await this.http
      .post(this.dumbledoreUpdateCustomerSettingsUrl, JSON.stringify(body))
      .toPromise()
      .then(user => {
        return { user, status: 'success' };
      })
      .catch(error => {
        console.log(error);
        return null;
      });
    return response;
  }

  async getManifestOptions(dealershipCode: string): Promise<string[]> {
    try {
      const body = { dealershipCode };
      const response = await firstValueFrom(
        this.http.post<string[]>(this.dumbledoreGetManifestOptions, JSON.stringify(body)),
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
}
