import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ExpansionPanelComponent } from '../../common/expansion-panel/expansion-panel.component';

@Component({
  selector: 'app-campaign-option-expansion-panel',
  templateUrl: '../../common/expansion-panel/expansion-panel.component.html',
  styleUrls: ['./campaign-option-expansion-panel.component.scss'],
})
export class CampaignOptionExpansionPanelComponent extends ExpansionPanelComponent implements OnChanges {
  @Input() selectedCampaign: string;
  @Input() campaign: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.selectedCampaign?.currentValue) return;
    this.expanded = this.campaign === changes.selectedCampaign.currentValue;
  }
}
