import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacebookService } from 'src/app/services/facebook.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-facebook-page-modal',
  templateUrl: './add-facebook-page-modal.component.html',
  styleUrls: ['./add-facebook-page-modal.component.scss']
})
export class AddFacebookPageModalComponent implements OnInit {
  loading: boolean = false;
  appId: string = '';
  appSecret: string = '';
  pageId: string = '';
  pageAccessToken: string;
  passThreadControlTargetAppId: string = '263902037430900';
  public onClose: Subject<object>;

  constructor(
    public modal: BsModalRef,
    private facebookService: FacebookService,
  ) {
  }

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  async addPage(event) {
    event.preventDefault();
    this.loading = true;
    const response = await this.facebookService.login(this.appId);
    const facebookResponse = await this.facebookService.getPageAccessToken(response, this.appId, this.appSecret, this.pageId);
    this.pageId = facebookResponse['id'];
    this.pageAccessToken = facebookResponse['access_token'];
    this.loading = false;
  }

  save(event) {
    event.preventDefault();
    this.onClose.next({
      appId: this.appId,
      appSecret: this.appSecret,
      pageId: this.pageId,
      pageAccessToken: this.pageAccessToken,
      passThreadControlTargetAppId: this.passThreadControlTargetAppId,
    });
  }
}
