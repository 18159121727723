import { Injectable } from '@angular/core';
import { catchError, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from 'src/app/services/error-handler/http-error-handler.service';
import { environment } from 'src/environments/environment';
import { IAnalyticsCardData } from '../../components/analytics/analytics-cards/analytics-card/_types/AnalyticsCardData';
import { TimeIntervalType } from '../../components/analytics/_types/TimeIntervalType';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  ToplineStatisticsFields,
  IConversation,
  IConversationCount,
  IChartDataResponse,
  IKPIPerformance,
  AnalyticsDashboardData,
} from './types';
import { IChartData } from 'src/app/components/analytics/charts/types';
import { NodeKpiModel } from 'src/app/models/node';

interface IToplineStatisticResponse extends Record<ToplineStatisticsFields, IAnalyticsCardData> {}

interface IToplineStatisticRequest {
  corpsId: string[];
  botId: string;
  hierarchyElementId: string;
  interval: TimeIntervalType;
}

interface ISearchConversation {
  botId?: string;
  userId?: string;
  conversationId?: string;
  containingText?: string;
  startDate?: string;
  endDate?: string;
  toTake?: number;
  sortByTimeOrder: 'asc' | 'desc';
}

interface IConversationMessage {
  userMessage: string;
  timestamp: string;
  botResponse: string;
}

const ANALYTICS_ROUTES = {
  TOPLINE_STATISTICS: 'topline_statistics',
  RECENT_CONVERSATION: 'recent_conversation',
  BOT_CONVERSATION_COUNT: 'bot_conversation_count',
  KPI_PERFORMANCE: 'kpi_performance',
  SEARCH_CONVERSATIONS: 'search_conversations',
  GET_CONVERSATION_MESSAGES: 'get_conversation_messages',
  TOP_INTENTS: 'top_intents',
  CHANNEL_BREAKDOWN: 'channel_breakdown',
  TRAFFIC_OVERVIEW: 'traffic_overview',
  UNIQUE_USERS_OVERVIEW: 'unique_users_overview',
  SET_ANALYTICS_KPI: 'set_analytics_kpi',
  GET_ANALYTICS_DASHBOARD_DATA: 'get_analytics_dashboard_data',
};

@Injectable({
  providedIn: 'root',
})
export class ApiGatewayAnalyticsService {
  private baseUrl: string;
  private userToken: string | null | undefined;

  constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandler, private afAuth: AngularFireAuth) {
    this.baseUrl = environment.apiGatewayAnalytics.url;
    this.afAuth.idToken.subscribe(token => {
      this.userToken = token;
    });
  }

  async getToplineStatistics(toplineStatisticRequest: IToplineStatisticRequest) {
    return this.makeAnalyticsRequest<IToplineStatisticResponse>(
      [ANALYTICS_ROUTES.TOPLINE_STATISTICS],
      toplineStatisticRequest,
    );
  }

  async getRecentConversations(botId: string, paginationId?: number): Promise<IConversation[]> {
    return this.makeAnalyticsRequest<IConversation[]>([ANALYTICS_ROUTES.RECENT_CONVERSATION], { botId, paginationId });
  }

  async getBotConversationCount(botId: string): Promise<IConversationCount> {
    return this.makeAnalyticsRequest<IConversationCount>([ANALYTICS_ROUTES.BOT_CONVERSATION_COUNT], { botId });
  }

  async getKPIPerformance(botId: string, timeFilter: string): Promise<IKPIPerformance> {
    return this.makeAnalyticsRequest<IKPIPerformance>([ANALYTICS_ROUTES.KPI_PERFORMANCE], { botId, timeFilter });
  }

  async searchConversations(searchConversation: ISearchConversation): Promise<IConversation[]> {
    return this.makeAnalyticsRequest<IConversation[]>([ANALYTICS_ROUTES.SEARCH_CONVERSATIONS], searchConversation);
  }

  async getConversationMessages(conversationId: string): Promise<IConversationMessage[]> {
    return this.makeAnalyticsRequest<IConversationMessage[]>([ANALYTICS_ROUTES.GET_CONVERSATION_MESSAGES], {
      conversationId,
    });
  }

  async getTopIntents(botId: string, timeFilter: string): Promise<IChartData> {
    return this.mapChartData(
      await this.makeAnalyticsRequest<IChartDataResponse[]>([ANALYTICS_ROUTES.TOP_INTENTS], { botId, timeFilter }),
    );
  }

  async getChannelBreakdown(botId: string, timeFilter: string): Promise<IChartData> {
    return this.mapChartData(
      await this.makeAnalyticsRequest<IChartDataResponse[]>([ANALYTICS_ROUTES.CHANNEL_BREAKDOWN], {
        botId,
        timeFilter,
      }),
    );
  }

  async getTrafficOverview(botId: string, timeFilter: string, countingPeriod: string): Promise<IChartData> {
    return this.mapChartData(
      await this.makeAnalyticsRequest<IChartDataResponse[]>([ANALYTICS_ROUTES.TRAFFIC_OVERVIEW], {
        botId,
        timeFilter,
        countingPeriod,
      }),
    );
  }

  async getUniqueUsersOverview(botId: string, timeFilter: string, countingPeriod: string): Promise<IChartData> {
    return this.mapChartData(
      await this.makeAnalyticsRequest<IChartDataResponse[]>([ANALYTICS_ROUTES.UNIQUE_USERS_OVERVIEW], {
        botId,
        timeFilter,
        countingPeriod,
      }),
    );
  }

  async setAnalyticsKpi(messageId: string, kpi: NodeKpiModel): Promise<void> {
    return this.makeAnalyticsRequest([ANALYTICS_ROUTES.SET_ANALYTICS_KPI], {
      analyticsId: messageId,
      kpiId: kpi.systemName,
      kpiName: kpi.name,
    });
  }

  async getAnalyticsDashboardData(
    botId: string,
    startDate: Date,
    endDate: Date,
    detail: string = 'T',
  ): Promise<AnalyticsDashboardData> {
    return await this.makeAnalyticsRequest([ANALYTICS_ROUTES.GET_ANALYTICS_DASHBOARD_DATA], {
      botId,
      startDate,
      endDate,
      detail,
    });
  }

  private mapChartData(chartDataResponse: IChartDataResponse[]): IChartData {
    return {
      labels: chartDataResponse.map(({ label }) => label),
      data: chartDataResponse.map(({ value }) => value),
    };
  }

  private async makeAnalyticsRequest<T>(actions: string[], parameters: Record<string, any>): Promise<T> {
    const url = `${this.baseUrl}analytics`;

    /*
      For the case when you refresh a page that makes any analytics request on init,
    */
    if (!this.userToken) {
      this.userToken = await this.afAuth.idToken.pipe(take(1)).toPromise();
    }
    return this.http
      .post<T>(
        url,
        { parameters, actions },
        {
          headers: {
            authorization: `${this.userToken}`,
          },
        },
      )
      .pipe(catchError(error => this.httpErrorHandler.handleApiGateWayAnalyticsError(error)))
      .pipe(take(1))
      .toPromise();
  }
}
