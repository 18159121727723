import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private pageTitle: BehaviorSubject<string | null>;
  pageTitle$: Observable<string | null>;

  constructor() {
    this.pageTitle = new BehaviorSubject(null);
    this.pageTitle$ = this.pageTitle.asObservable();
  }

  setPageTitle(pageTitle: string) {
    this.pageTitle.next(pageTitle);
  }
}
