<div class="modal-header">
  <h4 class="modal-title">{{ modalUsage === 'hil-level' ? 'Select KPI' : 'Select or add a new KPI' }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form (submit)="save(kpiForm.value)" #kpiForm="ngForm" novalidate>
    <fieldset>
      <select
        class="col-sm form-control"
        name="kpiSelect"
        *ngIf="!addNewKpi"
        [disabled]="!nodeKpiOptions?.length"
        [(ngModel)]="selectedNodeKpiOption"
        required
      >
        <option [ngValue]="undefined" disabled>Select KPI</option>
        <option
          *ngFor="let nodeKpiOption of nodeKpiOptions"
          [ngValue]="nodeKpiOption"
          [disabled]="modalUsage === 'corp-level'"
        >
          {{ nodeKpiOption.name }}
        </option>
      </select>

      <div *ngIf="modalUsage !== 'hil-level'" class="custom-control custom-checkbox m-2 float-right">
        <input
          class="custom-control-input"
          id="addNewNodeKpi"
          type="checkbox"
          [checked]="addNewKpi"
          (change)="addNewKpi = !addNewKpi"
        />
        <label class="custom-control-label" for="addNewNodeKpi">Add new</label>
      </div>
    </fieldset>

    <fieldset class="container" *ngIf="addNewKpi">
      <div class="form-group row">
        <label class="form-control-label" for="nodeKpiName"> Name </label>
        <input
          class="form-control"
          id="nodeKpiName"
          name="name"
          [(ngModel)]="nodeKpi.name"
          type="text"
          placeholder="e.g. Kia Model Selected"
          required
          (keyup)="updateKpiSystemName()"
        />
      </div>

      <div class="form-group row">
        <label class="form-control-label" for="nodeKpiSystemName"> System Name </label>
        <input
          class="form-control"
          id="nodeKpiSystemName"
          name="systemName"
          [(ngModel)]="nodeKpi.systemName"
          type="text"
          required
          disabled
        />
      </div>

      <div class="form-group row">
        <label class="form-control-label" for="nodeKpiDataType"> Data Type </label>

        <select
          class="form-control"
          id="nodeKpiDataType"
          name="nodeKpi.dataType"
          [(ngModel)]="nodeKpi.dataType"
          (ngModelChange)="processKpiDataTypeChange($event)"
          required
        >
          <option *ngFor="let kpiType of kpyDataTypes" [value]="kpiType">{{ kpiType }}</option>
        </select>
      </div>

      <div class="form-group row">
        <label class="form-control-label" for="nodeKpiValue"> Value </label>

        <ng-container *ngIf="nodeKpi.dataType === 'text' || nodeKpi.dataType === 'number'">
          <input
            class="form-control"
            id="nodeKpiValue"
            name="dataType"
            [(ngModel)]="nodeKpi.value"
            type="{{ nodeKpi.dataType }}"
            required
          />
        </ng-container>
        <ng-container *ngIf="nodeKpi.dataType === 'boolean'">
          <select class="form-control" name="nodeKpi.value" [(ngModel)]="nodeKpi.value">
            <option value="true">true</option>
            <option value="false">false</option>
          </select>
        </ng-container>
      </div>

      <div class="form-group row">
        <label class="form-control-label" for="nodeKpiDescription"> Description </label>
        <input
          class="form-control"
          id="nodeKpiDescription"
          name="description"
          [(ngModel)]="nodeKpi.description"
          type="text"
        />
      </div>
    </fieldset>

    <button class="btn btn-primary btn-outline float-right mt-2 mb-3" type="submit" [disabled]="!kpiForm.valid">
      SAVE
    </button>
  </form>
</div>
