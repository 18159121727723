import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from 'src/app/services/error-handler/http-error-handler.service';
import { environment } from 'src/environments/environment';

export interface IBrainCompileTemplateRequest {
  source: string;
  data: object;
}

export interface IBrainCompileInputValidationRequest {
  source: string;
  data: string;
}

export interface IBrainCompileApiQueryRequest {
  source: string;
  data: object;
}

export interface IBrainErrorResponse {
  message?: string;
  error?: string;
}

export interface IBrainCompileTemplateResponse extends IBrainErrorResponse {
  value: string;
}

export interface IBrainCompileInputValidationResponse extends IBrainErrorResponse {
  value: string;
}

export interface IBrainCompileApiQueryResponse extends IBrainErrorResponse {
  value: string;
}

export interface IBrainConfig {
  google_sheet_url: string;
  config_tab_name: string;
}

export interface IBrainExportTopicsNlgRequest {
  config: IBrainConfig;
}

export interface IBrainExportTopicsNlg {
  data: object;
  template_id: string;
  topic: string;
  templates: object;
}

export interface IBrainExportTopicsNlgResponse {
  config: IBrainConfig;
  data: IBrainExportTopicsNlg[];
}

@Injectable({
  providedIn: 'root'
})
export class BrainService {
  private baseUrl: string;

  constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.baseUrl = environment.brain.url;
  }

  compileTemplate(request: IBrainCompileTemplateRequest):
    Observable<IBrainCompileTemplateResponse> {
    const url = `${this.baseUrl}/script/compile/template`;
    return this.http
      .post<IBrainCompileTemplateResponse>(url, request)
      .pipe(catchError(error => this.httpErrorHandler.handleBrainError(error)));
  }

  compileInputValidation(request: IBrainCompileInputValidationRequest):
    Observable<IBrainCompileInputValidationResponse> {
    return new Observable(subscriber => {
      const flags = request.source.replace(/.*\/([gimy]*)$/, '$1');
      const pattern = request.source.replace(new RegExp('^/(.*?)/'+flags+'$'), '$1');

      let re: RegExp | null = null;
      try {
        re = new RegExp(pattern, flags);
      } catch (e) {
        throw new Error(e.message);
        return;
      }

      const match = request.data.match(re);
      if (!match?.length) {
        throw new Error('Regex must have at least 1 match on example data.')
        return;
      }

      subscriber.next({ value: re.source });
      subscriber.complete();
    });
  }

  compileApiQuery(request: IBrainCompileApiQueryRequest):
    Observable<IBrainCompileApiQueryResponse> {
    const url = `${this.baseUrl}/script/compile/api-query`;
    return this.http
      .post<IBrainCompileApiQueryResponse>(url, request)
      .pipe(catchError(error => this.httpErrorHandler.handleBrainError(error)));
  }

  exportTopicsNlg(request: IBrainExportTopicsNlgRequest):
    Observable<IBrainExportTopicsNlgResponse> {
      const url = `${this.baseUrl}/export-topics-nlg`;
      return this.http
        .post<IBrainExportTopicsNlgResponse>(url, request)
        .pipe(catchError(error => this.httpErrorHandler.handleBrainError(error)));
  }
}
