import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Dropzone from 'dropzone';
Dropzone.autoDiscover = false;

@Component({
  selector: 'app-avatar-picker-modal',
  templateUrl: './avatar-picker-modal.component.html',
  styleUrls: ['./avatar-picker-modal.component.scss']
})
export class AvatarPickerModalComponent implements OnInit {
  file: File;

  @Output()
  fileSelect: EventEmitter<File>;
  constructor(public modal: BsModalRef) {
    this.fileSelect = new EventEmitter<File>();
  }

  ngOnInit() {
    const dropzone = new Dropzone(document.getElementById('dropzone-single'), {
      url: '/',
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        'dz-preview-single'
      )[0],
      previewTemplate: document.getElementsByClassName('dz-preview-single')[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: 'image/*'
    });
    dropzone.on('addedfile', file => {
      if (this.file) {
        dropzone.removeFile(this.file);
      }
      this.file = file;
    });
    document.getElementsByClassName('dz-preview-single')[0].innerHTML = '';
  }

  /*
  startUpload() {
    this.loading = true;
    this.
              this.loading = false;
              this.uploaded.emit(downloadUrl);
              this.modal.hide();
            });
        })
      )
      .subscribe(
        () => {},
        error => {
          this.toaster.error(error);
          this.loading = false;
        }
      );
  }
  */

  select() {
    this.fileSelect.next(this.file);
    this.modal.hide();
  }
}
