<div *ngIf="message">
  <div class=" row mb-3" *ngIf="message.messageReceived?.text" class="d-flex flex-row-reverse">
    <div class="mw-75 ">
      <div class="d-flex flex-column">
        <div class="bg-primary text-white p-2 message d-inline-block">
          {{ message.messageReceived?.text }}
        </div>
        <div class="message-metadata text-right">
          <small>{{ message.messageReceived?.timestamp | date: 'medium' }}</small>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="message.messagesSent">
    <ng-container *ngFor="let messageSent of message.messagesSent">
      <div class=" row mb-3">
        <img class="sender-icon align-self-end" [src]="message.messageReceived?.platformPayload?.vendorIcon" />

        <div class="col-9 px-0 ">
          <div *ngIf="messageSent.text" class="bg-secondary border p-2 message d-inline-block">
            {{ messageSent.text }}
          </div>

          <app-conversation-message-attachments [messageSent]="messageSent"></app-conversation-message-attachments>

          <!-- printing all quick replies buttons images -->
          <ng-container *ngIf="messageSent.quickReplies?.actions">
            <div class="row m-1">
              <ng-container *ngFor="let action of messageSent.quickReplies.actions">
                <div
                  class=" col bg-secondary text-center text-primary message border border-primary d-inline-block p-2 mx-1"
                >
                  {{ action.title }}
                </div>
              </ng-container>
            </div>
          </ng-container>

          <div class="message-metadata pb-2">
            <small>
              <b class="mr-2">{{ message.messageReceived?.platformPayload?.vendorName }}</b>
            </small>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
