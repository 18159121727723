import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit, ViewChild } from '@angular/core';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { RODashboardData } from 'src/app/services/api-gateway-analytics/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-repair-order-report',
  templateUrl: './repair-order-report.component.html',
  styleUrls: ['./repair-order-report.component.scss'],
})
export class RepairOrderReportComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('repairOrderSort') repairOrderSort: MatSort = new MatSort();
  @ViewChild(MatPaginator) repairOrderPaginator: MatPaginator;
  @Input() awsRegion: AwsRegionEnum;
  @Input() botId: string;
  @Input() startDate: Date = new Date(2000, 0, 1);
  @Input() endDate: Date = new Date();

  reportDetails;
  displayTotals;
  dataSource;
  isLoading: boolean;
  reportType = 'ROR';
  columnsToDisplay: String[] = [];
  columns: Array<any> = [];

  constructor(
    private apiGatewayAnalyticsService: ApiGatewayAnalyticsService,
    private authService: AuthService,
    private toaster: ToastrService,
  ) {}

  async ngOnInit() {
    const user = await this.authService.currentUser;
    this.isLoading = true;
    await this.getReportDetails();
    this.isLoading = false;
  }

  async ngAfterViewInit() {
    this.dataSource.sort = this.repairOrderSort;
    this.dataSource.paginator = this.repairOrderPaginator;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.startDate &&
        changes.startDate.previousValue &&
        changes.startDate.currentValue &&
        changes.startDate.currentValue !== changes.startDate.previousValue) ||
      (changes.endDate &&
        changes.endDate.previousValue &&
        changes.endDate.currentValue &&
        changes.endDate.currentValue !== changes.endDate.previousValue)
    ) {
      this.isLoading = true;
      this.reportDetails = [];
      await this.getReportDetails();
      this.isLoading = false;
    }
  }

  onSearch(event) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  formatDollarStr(s) {
    return '$' + (s.indexOf('.') === -1 ? s + '.00' : (s + '00').substring(0, s.indexOf('.') + 3));
  }

  formatReport(results) {
    const mapProcessType = new Map<string, string>([
      ['process', 'Initiative'],
      ['ro_count_influenced', 'RO Count Influenced'],
      ['ro_amount_influenced', 'RO Amount Influenced'],
      ['ro_count_engaged', 'RO Count Engaged'],
      ['ro_amount_engaged', 'RO Amount Engaged'],
    ]);

    // List of unique column keys
    var columns = results
      .reduce((columns, row) => {
        return [...columns, ...Object.keys(row)];
      }, [])
      .reduce((columns, column) => {
        return columns.includes(column) ? columns : [...columns, column];
      }, []);

    // Map column keys to their sum
    var totals = {
      process: '* Totals *',
    };
    for (var result of results) {
      for (var key in result) {
        if (key !== 'process') {
          var sum = (Number(totals[key]) || 0) + Number(result[key]);
          totals[key] = sum === null || isNaN(sum) ? '' : sum;
        }
      }
    }

    // Format strings
    totals['ro_amount_influenced'] = this.formatDollarStr(totals['ro_amount_influenced'].toLocaleString('en'));
    totals['ro_amount_engaged'] = this.formatDollarStr(totals['ro_amount_engaged'].toLocaleString('en'));
    for (var result of results) {
      for (var key in result) {
        if (key !== 'process') {
          result[key] = Number(result[key]).toLocaleString('en');
          if (key === 'ro_amount_engaged' || key === 'ro_amount_influenced') {
            result[key] = this.formatDollarStr(result[key]);
          }
        }
      }
    }

    // Array of objects mapping columnDef to report header
    this.columns = columns.map(column => {
      var columnHeader = mapProcessType.get(column.trim()) || column.trim();
      return {
        columnDef: column,
        header: columnHeader,
        cell: (element: any) => `${element[column] ? element[column] : 0}`,
        total: totals[column],
      };
    });
    // List of columnDef to display on report
    this.columnsToDisplay = this.columns.map(c => c.columnDef);
    // Array of objects representing rows mapping columnDef to row value
    this.reportDetails = results;
  }

  async getReportDetails() {
    try {
      if (!this.reportDetails || this.reportDetails.length === 0) {
        const response: RODashboardData = await this.apiGatewayAnalyticsService.getAnalyticsDashboardData(
          this.botId,
          this.startDate,
          this.endDate,
          this.reportType,
        );
        if (response.tables.report.details !== null) {
          this.formatReport(response.tables.report.details);
        }
      }
      this.dataSource = new MatTableDataSource(this.reportDetails);
      setTimeout(() => {
        this.dataSource.sort = this.repairOrderSort;
        this.dataSource.paginator = this.repairOrderPaginator;
      }, 100);
    } catch (error) {
      console.log(error);
      this.toaster.error(error);
    }
  }
}
