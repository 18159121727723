<div class="modal-header">
  <app-action-progress></app-action-progress>
  <h4 class="modal-title">Import Template</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (submit)="importTemplate()" #form="ngForm" novalidate>
  <div class="modal-body">
    <div class="form-group" *ngIf="isGlobal">
      <label class="form-control-label" for="destinationCorpId"> Select Corp </label>
      <select
        data-test-id="import-source-corp-select"
        class="form-control"
        id="destinationCorpId"
        name="destinationCorpId"
        [(ngModel)]="destinationCorpId"
        (change)="getBotsInSelectedCorp()"
      >
        <option *ngFor="let corp of corps" [ngValue]="corp.id">{{ corp.label }}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="form-control-label" for="destinationBotId"> Select Destination Assistant </label>
      <select
        data-test-id="import-destination-bot-select"
        class="form-control"
        id="destinationBotId"
        name="destinationBotId"
        [(ngModel)]="destinationBotId"
        (change)="checkIfFlowWasPreviouslyImportedToBot()"
      >
        <option *ngFor="let bot of bots" [ngValue]="bot.id">{{ bot.label }}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="importNames.length > 0">
      <label class="form-control-label" for="nodesPrefix">
        This Flow Template Was Previously Imported, Please Provide Node Prefix
      </label>
      <input
        data-test-id="import-node-prefix-input"
        class="form-control"
        id="nodesPrefix"
        name="nodesPrefix"
        [(ngModel)]="nodesPrefix"
      />
    </div>
    <!-- <div class=" form-group">
        <label class=" form-control-label" for="importStrategy"> Import Strategy </label>
        <select class="form-control" id="importStrategy" name="importStrategy" [(ngModel)]="importStrategy">
          <option [ngValue]="'LINK'">I want updates on this template to propagate to my import </option>
          <option [ngValue]="'UNLINK'">I dont want further update on the template to propagate to my bot</option>
        </select>
      </div> -->
  </div>
  <div class="modal-footer">
    <button
      data-test-id="import-submit-button"
      class="btn btn-primary btn-outline"
      [disabled]="checkingIfFlowWasPreviouslyImportedToBot"
      type="submit"
    >
      Import
    </button>
    <button data-test-id="import-close-button" class="btn btn-primary-outline" type="button" (click)="modal.hide()">
      CLOSE
    </button>
  </div>
</form>
