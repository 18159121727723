<h3 mat-dialog-title>{{ data.title }}</h3>
<mat-dialog-content>
  <p>{{ data.message }}</p>
</mat-dialog-content>
<div class="buttons">
  <button class="cancel-btn button-left" mat-flat-button (click)="onCancel()">{{ data.cancelButtonText }}</button>
  <div class="spacer"></div>
  <button class="next-btn button-right" mat-flat-button (click)="onConfirm()" cdkFocusInitial>
    {{ data.confirmButtonText }}
  </button>
</div>
