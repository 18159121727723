import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-multiple-items-delete-confirmation-modal',
  templateUrl: './multiple-items-delete-confirmation-modal.component.html',
  styleUrls: ['./multiple-items-delete-confirmation-modal.component.scss']
})
export class MultipleItemDeleteConfirmationModalComponent implements OnInit {
  entityValues: string[] = [];
  typeOfEntity: string;
  entityNameInput = '';
  onDeleteConfirmed: EventEmitter<string>;

  constructor(public modal: BsModalRef) {
    this.onDeleteConfirmed = new EventEmitter<string>();
  }

  ngOnInit() {}

  deleteEntity() {
    this.onDeleteConfirmed.emit();
    this.modal.hide();
  }
}
