import { Validator, NG_VALIDATORS, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { sluggify } from '../utils/strings/string.utils';

// Valid if the input list does not contain the control value
@Directive({
  selector: '[appUniqueBotName]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UniqueBotNameValidatorDirective, multi: true }]
})
export class UniqueBotNameValidatorDirective implements Validator {
  @Input('appUniqueBotName') list: any[];

  validate(control: AbstractControl): ValidationErrors | null {
    return this.list ? uniqueBotNameValidator(this.list)(control) : null;
  }
}

export function uniqueBotNameValidator(list: any[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const alreadyExists = list.includes(sluggify(control.value));
    return alreadyExists ? { alreadyExists: { value: control.value } } : null;
  };
}
