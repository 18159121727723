import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { VersionService } from 'src/app/services/firestore';
import { ToastrService } from 'ngx-toastr';
import { ClientEnvironmentModel, BotEnvironment } from 'src/app/models/client-environment';
import { VersionModel } from 'src/app/models/version';
import { BotModel } from 'src/app/models';

@Component({
  selector: 'app-deploy-bot-modal',
  templateUrl: './deploy-bot-modal.component.html',
  styleUrls: ['./deploy-bot-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void <=> *', animate(500)),
    ]),
  ],
})
export class DeployBotModalComponent implements OnInit, OnDestroy {
  clientEnvironment: ClientEnvironmentModel | null;
  versions: VersionModel[];
  bot: BotModel;
  interval: NodeJS.Timeout;
  progressBarPercent = 0;
  showLoader = false;
  selectedStage: BotEnvironment;
  selectedVersion = 'Select Version';
  deploymentNotes = '';
  textToShow: string[] = [];
  currentStageIndex: number;

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private clientEnvironmentService: ClientEnvironmentService,
    private versionService: VersionService,
    private toaster: ToastrService,
  ) {
    if (!options.initialState) {
      return;
    }
    const { bot } = options.initialState as any;
    this.bot = bot;
  }

  async ngOnInit() {
    this.clientEnvironmentService.items$.subscribe(clientEnv => {
      this.clientEnvironment = clientEnv;

      this.currentStageIndex =
        clientEnv?.stages.map(stage => stage.systemName).indexOf(clientEnv.selectedStage.systemName) || 0;
    });

    const versions = await this.versionService.getBotVersions(this.bot.id);
    if (versions) {
      this.versions = versions;
    }
  }

  async handleDeploy() {
    this.showLoader = true;
    // if bot exists, overwrite bot settings - reminder that editing cannot be done on any other stage but dev,
    // so we dont need to worry about them not having updates saved in staging or prod. it should all be done in
    // dev and promoted up.

    const selectedVersion = this.versions.filter(version => version.versionNumber === Number(this.selectedVersion))[0];

    try {
      await this.versionService.deployVersionToStage(
        selectedVersion,
        this.bot,
        this.selectedStage,
        this.deploymentNotes,
      );
      this.toaster.success('Assistant Successfully Deployed');
    } catch (error) {
      // (don't know if there is some failure case, just a draft)
      this.toaster.error(`Something went wrong: ${error}`);
    } finally {
      this.modal.hide();
      this.showLoader = false;
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
