import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BreadcrumbNodeVM } from 'src/app/components/breadcrumb/_types/BreadcrumbNodeVM';
import { BotModel, HierarchyElementModel } from 'src/app/models';
import { ClientEnvironmentModel, StageModel } from 'src/app/models/client-environment';
import { CorpModel } from 'src/app/models/corp';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { BotsService, CorpsService, HierarchyElementsService } from 'src/app/services/firestore';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { getSidebarItems as getBotSidebarItems } from '../bot/utils';
import { getSidebarItems as getCorpSidebarItems } from '../corp/utils';
import { getSidebarItems as getHierarchySidebarItems } from '../hierarchy-element/utils';

export enum HILMode {
  None = 'None',
  Bot = 'bot',
  Corp = 'corp',
  Hierarchy = 'hierarchy',
}
@Component({
  selector: 'app-human-in-the-loop',
  templateUrl: './human-in-the-loop.component.html',
  styleUrls: ['./human-in-the-loop.component.scss'],
})
export class HumanInTheLoopComponent implements OnInit, OnDestroy {
  hierarchyElement: HierarchyElementModel;
  corp: CorpModel;
  bot: BotModel;
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  loading: boolean;
  botExists: boolean;
  currentSelectedStage: StageModel;
  botNameList: string[];

  hilMode: HILMode = HILMode.None;
  corpId: string | null;
  botFullId: string | null;
  botCode: string | null;
  hierarchyElementSystemName: string | null;

  preloadConversationId: string | null;
  envs: ClientEnvironmentModel | null;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private clientEnvironmentService: ClientEnvironmentService,
    private corpsService: CorpsService,
    private botsService: BotsService,
    private hierarchyElementsService: HierarchyElementsService,
    private toaster: ToastrService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([
      this.route.paramMap,
      this.authService.currentUser,
      this.clientEnvironmentService.items$,
    ]).subscribe(([params, user, envs]) => {
      const corpId = params.get('corp');
      const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
      const botCode = params.get('bot');
      this.preloadConversationId = params.get('conversationId');
      this.botFullId = `${this.corpId}-${this.hierarchyElementSystemName}-${this.botCode}_${envs?.selectedStage.systemName}`;

      this.corpId = corpId;
      this.hierarchyElementSystemName = hierarchyElementSystemName;
      this.botCode = botCode;
      this.envs = envs;

      if (botCode) {
        this.hilMode = HILMode.Bot;
      } else if (hierarchyElementSystemName) {
        this.hilMode = HILMode.Hierarchy;
      } else if (corpId) {
        this.hilMode = HILMode.Corp;
      }
      if (this.hilMode === HILMode.None) {
        this.loading = false;
        return;
      }
      if (!user || !envs) {
        this.loading = false;
        return;
      }

      this.loading = true;

      if (this.hilMode === HILMode.Bot) {
        this.loadBotConversations();
      } else if (this.hilMode === HILMode.Corp) {
        this.loadCorpConversations();
      } else if (this.hilMode === HILMode.Hierarchy) {
        this.loadHierarchyConversations();
      }
    });
  }

  async loadCorpConversations() {
    if (this.corpId) {
      this.crtDataSubscription = this.corpsService.getCorpById(this.corpId).subscribe(corp => {
        if (corp) {
          this.corp = corp;
        }
        this.refreshUI();
      });
    }
  }
  loadHierarchyConversations() {
    if (this.corpId && this.hierarchyElementSystemName) {
      this.crtDataSubscription = combineLatest([
        this.corpsService.getCorpById(this.corpId),
        this.hierarchyElementsService.getHierarchyElement(`${this.corpId}-${this.hierarchyElementSystemName}`),
      ]).subscribe(([corp, hierarchyElement]) => {
        if (corp) {
          this.corp = corp;
        }
        if (hierarchyElement) {
          this.hierarchyElement = hierarchyElement;
        }
        this.refreshUI();
      });
    }
  }
  loadBotConversations() {
    if (this.corpId && this.botCode && this.hierarchyElementSystemName && this.envs) {
      this.botFullId = `${this.corpId}-${this.hierarchyElementSystemName}-${this.botCode}_${this.envs.selectedStage.systemName}`;
      this.crtDataSubscription = combineLatest([
        this.corpsService.getCorpById(this.corpId),
        this.hierarchyElementsService.getHierarchyElement(`${this.corpId}-${this.hierarchyElementSystemName}`),
        this.botsService.getBotBy(this.corpId, this.hierarchyElementSystemName, this.botCode, this.envs),
      ]).subscribe(
        ([corp, hierarchyElement, bot]) => {
          if (!corp || !hierarchyElement || !bot) {
            this.loading = false;
            return;
          }

          this.botExists = true;
          this.bot = bot;
          this.botsService
            .getBotsByHierarchyElement(`${this.corpId}-${this.hierarchyElementSystemName}`)
            .pipe(take(1))
            .toPromise()
            .then(list => {
              this.botNameList = list.map(b => b.code).filter(b => b !== this.bot.code);
            });

          if (this.envs) {
            this.currentSelectedStage = this.envs.selectedStage;
          }

          this.loading = false;
          this.corp = corp;
          this.hierarchyElement = hierarchyElement;

          this.bot = bot;

          this.refreshUI();
        },
        error => {
          this.loading = false;
          this.toaster.error(error);
        },
      );
    }
  }

  public refreshUI() {
    if (!this.corp && !this.hierarchyElement && !this.bot) {
      return;
    }
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot?.code);
    this.headerService.setPageTitle(
      `${this.bot?.label || this.hierarchyElement?.label || this.corp?.label} Human In The Loop`,
    );
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    const title = 'Human In The Loop';
    const url = 'human-in-the-loop';
    const baseCorpUrl = `portal/corps/${this.corpId}`;
    const items: BreadcrumbNodeVM[] = [
      {
        label: this.corp.label,
        icon: this.corp.logo,
        route: `portal/corps/${this.corpId}`,
        testId: 'bread-crumb-corp',
      },
    ];
    if (this.hierarchyElement) {
      items.push({
        icon: this.hierarchyElement.logo,
        label: this.hierarchyElement.label,
        route: `${baseCorpUrl}/hierarchy-el/${this.hierarchyElementSystemName}`,
        testId: 'bread-crumb-hierarchy',
      });
    }
    if (this.bot) {
      items.push({
        label: this.bot.label,
        icon: this.bot.styles.avatarImageUrl,
        route: `${baseCorpUrl}/hierarchy-el/${this.hierarchyElementSystemName}/bots/${bot.code}`,
        testId: 'bread-crumb-bot',
      });
    }

    if (this.bot) {
      items.push({
        label: title,
        route: `${baseCorpUrl}/bots/${bot.code}/${url}`,
      });
    } else if (this.hierarchyElement) {
      items.push({
        label: title,
        route: `${baseCorpUrl}/hierarchy-el/${hierarchyElement.systemNameForUrl}/${url}`,
      });
    } else if (this.corp) {
      items.push({
        label: title,
        route: `${baseCorpUrl}/${url}`,
      });
    }
    this.breadcrumbService.set(items);
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    switch (this.hilMode) {
      case HILMode.Bot:
        this.sidebarService.set(getBotSidebarItems(corpId, hierarchyElement, botCode));
        break;
      case HILMode.Hierarchy:
        this.sidebarService.set(getHierarchySidebarItems(corpId, hierarchyElement));
        break;
      case HILMode.Corp:
        this.sidebarService.set(getCorpSidebarItems(corpId));
        break;
    }
  }

  ngOnDestroy() {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
  }
}
