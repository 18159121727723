<div class="modal-header">
	<h4 class="modal-title">Add a New Organization</h4>
	<button type="button" class="close" aria-label="Close" (click)="modal.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<form (submit)="save()" #form="ngForm" novalidate>
	<div class="modal-body">
		<div class="form-group">
			<label class="form-control-label" for="corpName"> Name </label>
			<input
				class="form-control"
				id="corpName"
				name="name"
				[(ngModel)]="corp.label"
				type="text"
				placeholder=""
				required
			/>
		</div>
		<div class="form-group">
			<label class="form-control-label" for="corpLogo">
				Logo
			</label>
			<app-editable-image
				[source]="corp.logo"
				[editable]="writable"
				(fileSelect)="logoFile = $event"
				(remove)="corp.logo = undefined"
			>
			</app-editable-image>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-primary btn-outline" type="submit" [disabled]="!form.valid || loading">
			<span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
			<span *ngIf="loading"> Saving...</span>
			<span *ngIf="!loading">SAVE</span>
		</button>
	</div>
</form>
