import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AvatarPickerModalComponent } from '../../modals/avatar-picker-modal/avatar-picker-modal.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-editable-image',
  templateUrl: './editable-image.component.html',
  styleUrls: ['./editable-image.component.scss'],
})
export class EditableImageComponent implements OnInit {
  @Input()
  source: string | SafeResourceUrl | null;

  @Input()
  path: string;

  @Input()
  editable = true;

  @Input()
  avatarWidth: number;

  @Input()
  avatarHeight: number;

  // tslint:disable-next-line: no-output-native
  @Output()
  fileSelect: EventEmitter<any>;

  @Output()
  remove: EventEmitter<void>;

  reader: FileReader;

  constructor(private modalService: BsModalService, private domSanitizer: DomSanitizer) {
    this.fileSelect = new EventEmitter<any>();
    this.remove = new EventEmitter<void>();
    this.reader = new FileReader();
    this.reader.onload = event => {
      if (event.target) {
        this.source = domSanitizer.bypassSecurityTrustResourceUrl(this.reader.result as string);
      }
    };
  }

  ngOnInit() {}

  openAvatarPickerModal() {
    const modalRef = this.modalService.show(AvatarPickerModalComponent, { ignoreBackdropClick: true });
    modalRef.content.path = this.path;
    modalRef.content.fileSelect.subscribe(async file => {
      const base64Splitted: string[] = file.dataURL?.split(',');

      if (base64Splitted?.[0] === 'data:image/svg+xml;base64') {
        const sanitizedFile = await this.sanitizeSVGScripts(file);
        this.fileSelect.emit(sanitizedFile);
        this.reader.readAsDataURL(sanitizedFile);
      } else {
        this.fileSelect.emit(file);
        this.reader.readAsDataURL(file);
      }
    });
  }

  get crtSource(): string | SafeResourceUrl | null {
    return this.source;
  }

  get scaledAvatarWidth() {
    return `${this.avatarWidth}px`;
  }

  get scaledAvatarHeight() {
    return `${this.avatarHeight}px`;
  }

  handleRemove(event) {
    this.remove.next();
    this.fileSelect.next(undefined);
    this.source = null;
    event.stopPropagation();
  }

  private async sanitizeSVGScripts(file: any): Promise<File> {
    try {
      const base64Splitted: string[] = file.dataURL?.split(',');
      const decodedImage = atob(base64Splitted[1]);
      const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
      const removedScripts = decodedImage.replace(scriptRegex, '');
      const sanitizedBase64 = btoa(removedScripts);

      const res: Response = await fetch(`${base64Splitted[0]},${sanitizedBase64}`);
      const blob: Blob = await res.blob();

      return new File([blob], file.name, { type: 'image/svg+xml' });
    } catch (e) {
      return file;
    }
  }
}
