<div *ngIf="this.datasetSystemName" class="d-flex flex-column pl-1">
  <div class="d-flex flex-row" (click)="toggleFilesSection()">
    <h5 class="d-flex flex-grow-1 font-weight-bold text-uppercase text-muted my-3">
      {{ datasetName }}
    </h5>
    <i
      class="fa my-auto"
      [attr.aria-expanded]="!isCollapsed"
      [ngClass]="isCollapsed ? 'fa-chevron-down' : 'fa-chevron-left'"
    >
    </i>
  </div>

  <div [collapse]="!isCollapsed" [isAnimated]="true">
    <ng-container *ngIf="loading">
      <div class=" container-fluid">
        <div class=" row align-items-center py-4 justify-content-center">
          <app-loading></app-loading>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading">
      <div *ngIf="datasetFileCardsList && datasetFileCardsList.length > 0; else noFilesBlock">
        <app-simple-card-list [cardItemList]="datasetFileCardsList"></app-simple-card-list>
      </div>
      <ng-template #noFilesBlock>
        <div class="text-center">There is no file available.</div>
      </ng-template>
    </ng-container>
  </div>
</div>
