import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UsersService } from './firestore';
import { Permissions } from '../utils/permissions/permissions';
import { combineLatest } from 'rxjs';
import { PermissionsService } from './firestore/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  userId: string;
  hasAllCorps: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private permissionsService: PermissionsService,
    private usersService: UsersService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.getActivationPromise(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.getActivationPromise(route);
  }

  private async getActivationPromise(route: ActivatedRouteSnapshot): Promise<boolean> {
    this.userId = (await this.authService.currentUser)?.uid || '';
    this.hasAllCorps = this.userId.length > 0 && (await this.usersService.CanAccessAllCompanies(this.userId));
    return new Promise(async (resolve, reject) => {
      const permission = route.data.permission as Permissions;
      const multiPermissions = route.data.permissions;
      const currentUser = await this.authService.currentUser;
      if (currentUser == null) {
        reject();
        return;
      }
      const corpId = route.paramMap.get('corp') != null ? route.paramMap.get('corp') : undefined;
      const hierarchyElementSystemName =
        route.paramMap.get('hierarchyElementSystemName') != null
          ? route.paramMap.get('hierarchyElementSystemName')
          : undefined;
      const botCode = route.paramMap.get('bot') != null ? route.paramMap.get('bot') : undefined;
      let fullHierarchyElementSystemName: string | null = null;
      if (corpId && hierarchyElementSystemName) {
        fullHierarchyElementSystemName = `${corpId}-${hierarchyElementSystemName}`;
      }
      combineLatest([
        this.permissionsService.getPermissionsBy(currentUser.uid, corpId, fullHierarchyElementSystemName, botCode),
        this.usersService.getUserById(currentUser.uid),
        this.authService.getCurrentUserPermissions(),
      ])
        .pipe(take(1))
        .subscribe(([permissions, user, myPermissions]) => {
          if (this.hasAllCorps) {
            resolve(true);
            return;
          }

          if (!permissions || !permissions.length || !user) {
            this.router.navigate(['/portal/unauthorized']);
            reject();
            return;
          }

          if (!permission && !multiPermissions) {
            resolve(true);
            return;
          }

          if (multiPermissions) {
            for (const index in multiPermissions) {
              if (myPermissions && myPermissions.includes(multiPermissions[index])) {
                resolve(true);
                return;
              }
            }
          }

          if (!user.role) {
            this.router.navigate(['/portal/unauthorized']);
            reject();
            return;
          }

          if (!myPermissions) {
            this.router.navigate(['/portal/unauthorized']);
            reject();
            return;
          }

          if (!myPermissions.includes(permission)) {
            this.router.navigate(['/portal/unauthorized']);
            reject();
            return;
          }

          resolve(true);
        });
    });
  }
}
