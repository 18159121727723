<ng-container *ngIf="loading">
  <div class=" container-fluid">
    <div class=" row align-items-center py-4 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading">
  <div class="container-fluid d-flex flex-column h-100">
    <div class="conversation-content bg-white py-4">
      <ng-container *ngFor="let message of messages">
        <app-conversation-message [message]="message"> </app-conversation-message>
      </ng-container>
      <span class="small" *ngIf="!messages || !messages.length">No message logs.</span>
    </div>
  </div>
</ng-container>
