import { Component, Input } from '@angular/core';
import { IAnalyticsCardData } from './_types/AnalyticsCardData';

@Component({
  selector: 'app-analytics-card',
  templateUrl: './analytics-card.component.html',
  styleUrls: ['./analytics-card.component.scss']
})
export class AnalyticsCardComponent {
  @Input()
  title: string;

  @Input()
  icon: {
    bgClasses: string[];
    iconClasses: string[];
  };

  @Input()
  data: IAnalyticsCardData | null;

  @Input()
  loading: boolean;
}
