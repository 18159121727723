import { Component, Input, OnDestroy, OnInit, EventEmitter, Output, AfterViewInit, ViewChild } from '@angular/core';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { NlpModel } from 'src/app/models/nlp/nlp-model/nlp-model';
import { SimpleListItem } from 'src/app/components/lists/simple-list-item/_types/SimpleListItem';
import { Subscription } from 'rxjs';
import { ButtonType } from 'src/app/components/common/_types/ButtonType';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { ActionProgressComponent } from '../../action-progress/action-progress.component';
dayjs.extend(relativeTime);

@Component({
  selector: 'app-nlp-model-list',
  templateUrl: './nlp-model-list.component.html',
  styleUrls: ['./nlp-model-list.component.scss']
})
export class NlpModelListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() corpId: string;
  public nlpModels: NlpModel[];
  public nlpModelList: SimpleListItem[];
  private nlpGetModelsSubscription: Subscription;
  private nlpModelUpdatesSubscription: Subscription;
  @Output()
  onCreate: EventEmitter<string>;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(private nlpDojoService: NlpDojoService) {
    this.onCreate = new EventEmitter<string>();
  }

  ngOnInit() {
    this.nlpModelUpdatesSubscription = this.nlpDojoService.nlpModelUpdates.subscribe(() => {
      this.reloadNlpModels();
    });
  }

  ngAfterViewInit() {
    this.reloadNlpModels();
  }

  private reloadNlpModels() {
    this.actionProgress.start();
    this.nlpDojoService.getModels(this.corpId).subscribe(nlpModels => {
      this.actionProgress.complete();
      this.nlpModels = nlpModels;
      this.nlpModelList = this.nlpModels.map((nlpModel: NlpModel) => {

        const intentReport = nlpModel?.evaluation['intent_report'];
        const evaluation = (intentReport) ? intentReport['weighted avg'] : {};
        // Minus 3 to remove 'weighted avg', 'macro avg', 'micro avg' from intent report.
        const numIntents = (intentReport) ? Object.keys(intentReport).length - 3 : null;

        let trainingTimeElapsed: string | null = null;
        if (nlpModel.status == 'ready') {
          const trainingStarted = dayjs(nlpModel.training_started_at);
          const trainingEnded = dayjs(nlpModel.training_ended_at);
          trainingTimeElapsed = trainingStarted.to(trainingEnded, true);
        }

        return {
          title: nlpModel.name,
          dataPoints: [
            {
              label: 'F1 Score',
              value: evaluation['f1-score']?.toFixed(2)?.toString() ?? '—',
              className: 'simple-list-item-data-point--sm'
            },
            {
              label: 'Precision',
              value: evaluation['precision']?.toFixed(2)?.toString() ?? '—',
              className: 'simple-list-item-data-point--sm'
            },
            {
              label: 'Recall',
              value: evaluation['recall']?.toFixed(2)?.toString() ?? '—',
              className: 'simple-list-item-data-point--sm'
            },
            {
              label: 'Support',
              value: evaluation['support']?.toFixed(2)?.toString() ?? '—',
              className: 'simple-list-item-data-point--sm'
            },
            {
              label: 'Intents',
              value: numIntents?.toString() ?? '—',
              className: 'simple-list-item-data-point--sm'
            },
            {
              label: 'Training Time Elapsed',
              value: trainingTimeElapsed ?? '—',
              className: 'simple-list-item-data-point--sm'
            }
          ],
          buttons: [
            {
              label: nlpModel.status === 'training' ? 'Training...' : 'Edit',
              type: ButtonType.primary,
              link: ['portal', 'nlp', 'models', nlpModel.system_name]
            }
          ]
        };
      });
    });
  }

  onClickCreate() {
    this.onCreate.emit();
  }

  ngOnDestroy(): void {
    if (this.nlpModelUpdatesSubscription) {
      this.nlpModelUpdatesSubscription.unsubscribe();
    }
    if (this.nlpGetModelsSubscription) {
      this.nlpGetModelsSubscription.unsubscribe();
    }
  }
}
