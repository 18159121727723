import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { PermissionModel } from 'src/app/models/permission';
import { getSidebarItems } from '../utils';
import { Permissions } from 'src/app/utils/permissions/permissions';
import {
  CorpHierarchiesService,
  CorpsService,
  HierarchyElementsService,
  PermissionsService,
  UsersService,
} from 'src/app/services/firestore';
import { CorpHierarchyModel, HierarchyElementModel } from 'src/app/models';
import uniq from 'lodash/fp/uniq';
// tslint:disable-next-line:max-line-length
import { AddHierarchyElementModalComponent } from 'src/app/components/modals/add-hierarchy-element-modal/add-hierarchy-element-modal.component';

@Component({
  selector: 'app-corp-dashboard',
  templateUrl: './corp-dashboard.component.html',
  styleUrls: ['./corp-dashboard.component.scss'],
})
export class CorpDashboardComponent implements OnInit, OnDestroy {
  loading = false;
  canReadCorpAnalyticsCard = false;
  corp: CorpModel;
  permissions: PermissionModel[];
  user: firebase.default.User;
  highestHierarchy: CorpHierarchyModel;
  hierarchyElements: HierarchyElementModel[] = [];
  userId: string;
  hasAllCorps: boolean;

  private crtDataSubscription: Subscription;
  private paramMapSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private permissionsService: PermissionsService,
    private corpsService: CorpsService,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private router: Router,
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    private modalService: BsModalService,
    private toaster: ToastrService,
    private hierarchyElementsService: HierarchyElementsService,
    private corpHierarchiesService: CorpHierarchiesService,
    private usersService: UsersService,
  ) {
    this.loading = false;
  }

  async ngOnInit() {
    this.userId = (await this.authService.currentUser)?.uid || '';
    this.hasAllCorps = this.userId.length > 0 && (await this.usersService.CanAccessAllCompanies(this.userId));
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(
      ([params, currentUser]) => {
        const corpId = params.get('corp');
        if (!corpId || !currentUser) {
          return;
        }
        this.user = currentUser;
        this.loading = true;
        this.crtDataSubscription = combineLatest([
          this.corpsService.getCorpById(corpId),
          this.permissionsService.getPermissionsBy(this.user.uid, corpId),
          this.hierarchyElementsService.getHierarchyChildrenElements(corpId),
          this.corpHierarchiesService.getHighestCorpHierarchy(corpId),
        ]).subscribe(
          ([corp, permissions, hierarchyElements, highestHierarchy]) => {
            if (!corp || !permissions) {
              this.loading = false;
              return;
            }
            if (!highestHierarchy) {
              this.toaster.warning(`Sorry! You can't view a corp without any hierarchy`);
              this.router.navigate(['/portal/dashboard']);
              return;
            }
            this.corp = corp;
            this.highestHierarchy = highestHierarchy;
            this.permissions = permissions;
            if (!this.permissions) {
              throw new Error('No permissions');
            }
            this.refreshUI();
            const hierarchyElementsIHavePermissionsTo = uniq(
              this.permissions.map(permission => permission.hierarchyElementSystemName),
            );
            this.hierarchyElements = hierarchyElements.filter(
              ({ systemName }) => hierarchyElementsIHavePermissionsTo.includes(systemName) || this.hasAllCorps,
            );
            this.setCanReadCorpAnalyticsCard();
            this.loading = false;
          },
          error => {
            this.toaster.error(error);
            this.loading = false;
          },
        );
      },
    );
  }

  private setBreadcrumb(corp: CorpModel) {
    this.breadcrumbService.set([
      {
        label: corp.label,
        icon: corp.logo,
        route: `portal/corps/${corp.id}`,
        testId: 'bread-crumb-corp',
      },
      {
        label: 'Dashboard',
        route: `portal/corps/${corp.id}/dashboard`,
      },
    ]);
  }

  private setSidebarItems(corpId: string) {
    const sidebarItems = getSidebarItems(corpId);
    this.sidebarService.set(sidebarItems);
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }

  navigateToHierarchy(hierarchyElement: HierarchyElementModel) {
    this.router.navigate(['../', 'hierarchy-el', hierarchyElement.systemNameForUrl], {
      relativeTo: this.route,
    });
  }

  setCanReadCorpAnalyticsCard(): void {
    this.canReadCorpAnalyticsCard = this.authService.hasPermissionSync(Permissions.CAN_READ_CORP_ANALYTICS_CARD);
  }

  canAddHierarchy(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_ADD_HIERARCHY);
  }

  refreshUI() {
    this.setBreadcrumb(this.corp);
    this.setSidebarItems(this.corp.id);
    this.headerService.setPageTitle(`${this.corp.label} Dashboard`);
  }

  showAddHierarchyModal() {
    this.modalService.show(AddHierarchyElementModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        corpHierarchy: this.highestHierarchy,
        parentSystemName: this.corp.id,
      },
    });
  }
}
