<ng-template [ngIf]="loading && !showReport">
  <div class="d-flex mt-3 justify-content-center">
    <app-loading></app-loading>
  </div>
</ng-template>
<div *ngIf="!loading" class="col">
  <div class="row d-flex mb-4">
    <div class="col col-lg-3 p-0 pl-md-0 pr-md-3 d-flex flex-column">
      <app-analytics-card-button
        class="mb-2 mb-md-3"
        [color]="'yellow'"
        [difference]="0.0348"
        [iconClassNames]="'ni ni-send'"
        [label]="'Messages sent'"
        [timePeriod]="''"
        [total]="dashboardData.cards.messagesSent.count"
      ></app-analytics-card-button>

      <app-analytics-card-button
        class="mb-2 mb-md-3"
        [color]="'blue'"
        [difference]="0.0448"
        [iconClassNames]="'ni ni-active-40'"
        [label]="'Customers Messaged'"
        [timePeriod]="''"
        [total]="dashboardData.cards.totalOutreach.count"
        (click)="reportChanged('C')"
      ></app-analytics-card-button>
      <app-analytics-card-button
        class="mb-2 mb-md-3"
        [color]="'green'"
        [difference]="0.0148"
        [iconClassNames]="'ni ni-chart-pie-35'"
        [label]="'Engagements'"
        [timePeriod]="''"
        [percent]="dashboardData.cards?.engagementPercent?.count"
        [total]="dashboardData.cards.engagements.count"
        (click)="reportChanged('E')"
      ></app-analytics-card-button>
      <app-analytics-card-button
        [color]="'red'"
        [difference]="0.001"
        [iconClassNames]="'fa fa-exclamation-triangle'"
        [label]="'Triage'"
        [timePeriod]="''"
        [total]="dashboardData.cards.triage.count"
        [active]="true"
        (click)="reportChanged('T')"
      ></app-analytics-card-button>
    </div>
    <div class="col col-lg-9 p-0 pl-md-3 pr-md-0 mt-4 mt-md-0 d-flex align-items-stretch">
      <app-analytics-chart
        class="d-flex w-100"
        (reportTypeEvent)="reportChanged($event)"
        [reportType]="reportType"
        [startDate]="startDate"
        [endDate]="endDate"
        [dataset]="chartDataset"
      ></app-analytics-chart>
    </div>
  </div>
</div>
