<div class="container-fluid bot-dashboard-component-wrapper">
  <div class="card-deck flex-column flex-xl-row mt-4">
    <div class="card">
      <div class="card-header">
        <h5 class="h3 mb-0">Recent notifications</h5>
      </div>
      <div class="card-body p-0">
        <div class="list-group list-group-flush">
          <a
            style="cursor: pointer"
            *ngFor="let notification of notifications"
            class="list-group-item list-group-item-action flex-column align-items-start py-4 px-4"
            (click)="clickedNotification(notification)"
          >
            <h4 class="mt-3 mb-1">{{ notification.message }}</h4>
            <p class="text-sm mb-0">
              {{ notification.message }}
            </p>
            <div class="d-flex w-100 justify-content-between">
              <small>{{ notification.timeAgo }}</small>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
