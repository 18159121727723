import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import createError from 'http-errors';
import { ManifestConversationType } from '../models/manifest-conversation-types';
import { firstValueFrom } from 'rxjs';

export type recallCampaign = {
  fileId: string;
  fileName: string;
  dealershipCode: string;
  startDate: string;
  maxBatch: number;
  userName: string;
  id: string;
  customersCount: number;
};

type getConversationsReturn = {
  conversations: recallCampaign[];
};

const mock = false;

const urlValidate = environment.vulcan.url + 'validate';
const urlMoveToProcess = environment.vulcan.url + 'process';
const urlGetConversations = environment.vulcan.url + 'dealership';
const urlCreateNewManifest = environment.vulcan.url + 'createNewManifest';

const fileContentHEADER = 'data:text/csv;base64,';

export type validationResult = {
  success: boolean;
  errorLines: any[];

  errorMessage: string;
  numberOfErrors: number;
  fileId: string;
};
@Injectable({
  providedIn: 'root',
})
export class UploadCampaignService {
  constructor(private http: HttpClient) {}

  mockConversations = {
    conversations: [
      {
        fileId: '5985b666-1b58-4aa1-92dd-01460f45958d',
        fileName: 'sms_only_2.csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-03-22',
        maxBatch: 50,
        userName: 'Pejmon Kayvon',
        customersCount: 6,
        name: 'sms_only_2',
        uuid: '060853d8-1dfd-4a30-93fd-c5ffd1bece93',
        createdAt: 1679513137618,
      },
      {
        fileId: '6fcc3920-3c7b-4cf0-b149-dee5435a2b97',
        fileName: 'PlatVw1.100.csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-03-30',
        maxBatch: 50,
        userName: 'Dave Donovan',
        customersCount: 99,
        name: 'Plat VW Recall List(SMS Only - Phase 2 1-100',
        uuid: '22563952-8b28-4d37-9161-bdb0313538cc',
        createdAt: 1680210215822,
      },
      {
        fileId: 'f7fd87eb-56ed-4fe3-be51-a28a17499d79',
        fileName: 'SMSTest1.csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-03-30',
        maxBatch: 50,
        userName: 'Dave Donovan',
        customersCount: 1,
        name: 'Sms Only validation',
        uuid: '4b3f9cfa-3bf3-41e5-941e-b7a51ad9fb5d',
        createdAt: 1680208713937,
      },
      {
        fileId: '4d7f4ab4-2ce4-43d1-948c-f6d1add74672',
        fileName: 'fake recall.csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-10-12',
        maxBatch: 50,
        userName: 'luke McQueeney',
        customersCount: 4,
        name: 'Test Recall',
        uuid: '4fedc79c-b4e3-4eab-947d-dffad2757057',
        createdAt: 1697137963016,
      },
      {
        fileId: 'f45b6980-6d6c-4d06-bb46-9960aaa89ff3',
        fileName: 'Platinum VW Recall List (Email Only - Phase 2).csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-03-22',
        maxBatch: 30,
        userName: 'Pejmon Kayvon',
        customersCount: 133,
        name: 'Platinum VW Recall List (Email Only - Phase 2)',
        uuid: '5c5a48d3-7a45-4da1-989a-41b91035b276',
        createdAt: 1679502919776,
      },
      {
        fileId: '981e1b38-9ac8-43b1-953b-b1f7191db02e',
        fileName: 'Platinum VW Recall List (Email Only - Phase 1).csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-03-21',
        maxBatch: 50,
        userName: 'Pejmon Kayvon',
        customersCount: 5,
        name: 'Platinum VW Recall List (Email Only - Phase 1)',
        uuid: '6366836d-c0c9-4219-89a1-42685e32ed0e',
        createdAt: 1679427624144,
      },
      {
        fileId: '0e1aef6b-3720-4981-854e-90015d9ddb0a',
        fileName: 'Platinum VW Recall List (1_10_23) - FINAL UPLOAD.csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-05-05',
        maxBatch: 30,
        userName: 'Pejmon Kayvon',
        customersCount: 505,
        name: 'Final Batch (SMS + Email)',
        uuid: '6616f5cb-95d9-425c-886a-071617aef7e1',
        createdAt: 1683306283767,
      },
      {
        fileId: 'b206e40b-5bea-4d1c-96e0-cca9bf575bc4',
        fileName: 'sms_only.csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-03-22',
        maxBatch: 50,
        userName: 'Pejmon Kayvon',
        customersCount: 6,
        name: 'sms_only',
        uuid: '6eee0923-85f8-4c36-bfde-348be6aa39d5',
        createdAt: 1679498893540,
      },
      {
        fileId: 'be6eab9d-366f-490b-b556-964c5c512667',
        fileName: 'SMSTest1.csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-03-30',
        maxBatch: 50,
        userName: 'Dave Donovan',
        customersCount: 6,
        name: 'SMStest1',
        uuid: '96c2a373-7c8e-47f6-a5da-d49e9aa67db4',
        createdAt: 1680204062362,
      },
      {
        fileId: '0ce24fc5-909f-4e4c-ba92-b9664d741df7',
        fileName: 'SMSTest2.csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-03-31',
        maxBatch: 50,
        userName: 'Dave Donovan',
        customersCount: 559,
        name: 'Plat VW Recall List(SMS Only - Phase 2 101-EOL',
        uuid: 'b070e7aa-913b-465b-8f97-94bdd38efb07',
        createdAt: 1680281190522,
      },
      {
        fileId: 'f00e3717-cb93-4fdf-918c-e0df774451c3',
        fileName: 'platvwwinter.csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-10-23',
        maxBatch: 75,
        userName: 'Dave Donovan',
        customersCount: 6257,
        name: 'winter',
        uuid: 'd02b4a9f-d38f-4523-a6e4-4a8832b80936',
        createdAt: 1698072707368,
      },
      {
        fileId: 'bc81f930-6393-42a7-abfe-a702cc21d1c8',
        fileName: 'impel_test.csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-03-20',
        maxBatch: 50,
        userName: 'Pejmon Kayvon',
        customersCount: 8,
        name: 'Impel Test',
        uuid: 'd94c5e93-ed1c-41d3-95de-921d681e5b93',
        createdAt: 1679342034057,
      },
      {
        fileId: 'd1163b4f-a5c8-477e-a105-2c147bd1e25e',
        fileName: 'sms_only_1.csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-03-22',
        maxBatch: 50,
        userName: 'Pejmon Kayvon',
        customersCount: 6,
        name: 'sms_only_1',
        uuid: 'f4322b60-707e-4c65-8970-d4e7cdeeedfd',
        createdAt: 1679509318452,
      },
      {
        fileId: 'e9706008-1855-420c-a403-9ffe707d02e8',
        fileName: 'Platinum VW Recall List (Phone Only - Phase 1).csv',
        dealershipCode: 'platinum_volkswagen-platinum_volkswagen',
        startDate: '2023-03-21',
        maxBatch: 50,
        userName: 'Pejmon Kayvon',
        customersCount: 6,
        name: 'Platinum VW Recall List (Phone Only - Phase 1)',
        uuid: 'f57ef204-548f-47df-a8de-bf95bcf8041a',
        createdAt: 1679427624144,
      },
    ],
  };

  mock = false;

  async getConversations(dealershipId: string): Promise<recallCampaign[]> {
    if (mock) {
      return this.mockConversations.conversations as unknown as recallCampaign[];
    }

    try {
      const finalURL = `${urlGetConversations}/${dealershipId}/conversation`;

      const ret = await firstValueFrom(
        this.http.get<getConversationsReturn>(finalURL, {
          headers: { Authorization: 'allow', 'Content-Type': 'application/json' },
        }),
      );

      return ret.conversations;
    } catch (e) {
      return e.message;
    }
  }

  async uploadData(
    fileId: string,
    dealershipId: string,
    startDate: string,
    maxBatch: number,
    userName: string,
    conversationName: string,
    conversationType: ManifestConversationType,
    manifestName?: string,
  ) {
    try {
      const body = {
        fileId,
        dealershipId,
        startDate,
        maxBatch,
        userName,
        conversationName,
        conversationType,
        manifestName,
      };

      await firstValueFrom(
        this.http.post(urlMoveToProcess, body, {
          headers: { Authorization: 'allow', 'Content-Type': 'application/json' },
        }),
      );

      return '';
    } catch (e) {
      return e.message;
    }
  }
  async validateInputMock(
    campaignName: string,
    email: string,
    fileName: string,
    fileContents: string,
  ): Promise<validationResult> {
    if (fileName === 'mocks.csv') {
      return {
        success: true,
        errorLines: [],
        errorMessage: '',
        numberOfErrors: 0,
        fileId: '',
      } as validationResult;
    }

    if (fileName === 'mocksError.csv') {
      return {
        success: false,
        errorLines: [
          {
            firstName: 'Guilherme',
            lastName: 'Bencke',
            email: 'gbencke@gmail.com',
            vin: 12345678,
            mmy: 'Ford/Fiesta/2015',
            phone: '55-99989121',
          },
          {
            firstName: 'Juan',
            lastName: 'Costa',
            email: 'jcosta@gmail.com',
            vin: 98765544,
            mmy: 'Nissan/Frontier/2015',
            phone: '55-99567556',
          },
        ],
        numberOfErrors: 100,
      } as validationResult;
    }

    return {
      success: false,
      errorLines: [],
      errorMessage: 'Unknown error',
      numberOfErrors: 0,
      fileId: '',
    } as validationResult;
  }
  async validateInput(
    campaignName: string,
    email: string,
    fileName: string,
    fileContents: string,
    dealershipId: string,
    conversationType: ManifestConversationType,
    manifestName?: string,
  ): Promise<validationResult> {
    if (mock) {
      return await this.validateInputMock(campaignName, email, fileName, fileContents);
    }

    try {
      const body = {
        data: fileContents.replace(fileContentHEADER, ''),
        filename: fileName,
        returnEmail: email,
        dealershipId,
        conversationName: campaignName,
        conversationType,
        manifestName,
      };

      const returned: any = await firstValueFrom(
        this.http.post(urlValidate, body, { headers: { Authorization: 'allow', 'Content-Type': 'application/json' } }),
      );

      return {
        success: true,
        errorLines: [],
        errorMessage: '',
        numberOfErrors: 0,
        fileId: returned.fileId || '',
      } as validationResult;
    } catch (e) {
      return {
        success: false,
        errorLines: [],
        errorMessage: e.error?.message || `API Returned: ${e.message}`,
        numberOfErrors: 0,
        fileId: '',
      } as validationResult;
    }
  }

  async createNewManifest(
    marketingSheetName: string,
    description: string,
    dealershipCode: string,
    startDate: string,
    campaignTitle: string,
    followUpMessages: number,
  ) {
    try {
      const body = {
        marketingSheetName,
        description,
        dealershipCode,
        startDate,
        campaignTitle,
        followUpMessages,
      };

      const response = await firstValueFrom(
        this.http.post(urlCreateNewManifest, body, {
          headers: { Authorization: 'allow', 'Content-Type': 'application/json' },
        }),
      );

      return response;
    } catch (e) {
      // Check for specific HTTP error status and throw corresponding http-error
      if (e.response && e.response.status && e.response.data && e.response.data.message) {
        throw createError(e.response.status, e.response.data.message);
      } else {
        // If no specific error found, throw a generic 500 Internal Server Error
        throw createError(500, 'Internal Server Error');
      }
    }
  }
}
