<div class="container-fluid">
<app-action-progress></app-action-progress>
  <div class=" row align-items-center py-4">
    <div class=" col">
      <h6 class=" h2 d-inline-block mb-0">
        General
      </h6>
    </div>
  </div>
  <div class=" row">
    <div class=" col-lg-6 col-12">
      <div class=" card">
        <div class=" card-body">
          <app-hierarchy-element-general-info
            *ngIf="hierarchyElement"
            [writable]="canEditHierarchySettings()"
            [hierarchyElement]="hierarchyElement"
            [corpHierarchy]="corpHierarchy"
            (saved)="refreshUI()"
          >
          </app-hierarchy-element-general-info>
        </div>
      </div>
    </div>
  </div>
</div>
