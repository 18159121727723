import { TimeIntervalType } from './TimeIntervalType';
import { getDatesAsString } from 'src/app/utils/date/date-utils';
import { CountingPeriod } from './CountingPeriod';

export class TimeInterval {
  // Used to render the description to the DOM
  public readonly description: string;
  private readonly type: TimeIntervalType;

  constructor(type: TimeIntervalType) {
    this.type = type;
    this.description = this.toString();
  }

  getType(): TimeIntervalType {
    return this.type;
  }

  public getPastDate(): Date | null {
    let date: Date | null = new Date(Date.now());
    date.setHours(0, 0, 0, 0);
    switch (this.type) {
      case '24h':
      case '1d':
        break;
      case '5d':
        date.setDate(date.getDate() - 5);
        break;
      case '7d':
        date.setDate(date.getDate() - 7);
        break;
      case '30d':
        date.setDate(date.getDate() - 30);
        break;
      case '1M':
        date.setMonth(date.getMonth() - 1);
        break;
      case '3M':
        date.setMonth(date.getMonth() - 3);
        break;
      case '90d':
        date.setDate(date.getDate() - 90);
        break;
      case '6M':
        date.setMonth(date.getMonth() - 6);
        break;
      case '1y':
        date.setMonth(date.getMonth() - 12);
        break;
      case 'MTD':
        date.setDate(1);
        break;
      case 'YTD':
        date.setMonth(0);
        date.setDate(1);
        break;
      case 'MAX':
        date = null;
    }
    return date;
  }

  public getFirstDateOfInterval() {
    const dateNow = new Date();
    return dateNow.setDate(dateNow.getDate() - this.getDaysNumber());
  }

  getCountingPeriods(): CountingPeriod[] {
    switch (this.type) {
      case '24h':
      case '1d':
      case '5d':
      case '7d':
      case '90d':
        // return [new TimeInterval('1d')];
        return [new CountingPeriod('1d')];
      case '30d':
      case '1M':
        return [new CountingPeriod('1d'), new CountingPeriod('1w')];
      case '3M':
      case '6M':
      case '1y':
      case 'YTD':
      case 'MAX':
        return [new CountingPeriod('1d'), new CountingPeriod('1w'), new CountingPeriod('1M')];
      default:
        return [new CountingPeriod('1d')];
    }
  }

  public getDaysNumber(): number {
    switch (this.type) {
      case '24h':
      case '1d':
      case 'day':
        return 1;
      case '5d':
        return 5;
      case '7d':
      case '1w':
        return 7;
      case '30d':
        return 30;
      default:
        throw new Error(`Cannot give accurate days number for the interval:  {this.type}`);
    }
  }

  private toString(): string {
    switch (this.type) {
      case '24h':
        return 'Last 24h';
      case '1d':
        return 'Today';
      case '5d':
        return 'Last 5 days';
      case '7d':
        return 'Last 7 days';
      case '30d':
        return 'Last 30 days';
      case '90d':
        return 'Last 90 days';
      case '1M':
        return 'Last month';
      case '3M':
        return 'Last 3 months';
      case '6M':
        return 'Last 6 months';
      case '1y':
        return 'Last year';
      case 'MTD':
        return 'Month to Date';
      case 'YTD':
        return 'Year to Date';
      case 'MAX':
        return 'All Time';
      default:
        return 'Today';
    }
  }

  getExplanation(): string {
    return 'No explanation available';
  }

  getDatesAsString(): string[] | null {
    return getDatesAsString(this.getPastDate());
  }
}
