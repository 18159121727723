import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HierarchyElementModel } from 'src/app/models';
import { plainToClass } from 'class-transformer';
import { SequenceService } from './sequence.service';
import { COLLECTION_NAMES } from './constants';

@Injectable({
  providedIn: 'root',
})
export class HierarchyElementsService {
  private hierarchyElementsCollection: AngularFirestoreCollection<HierarchyElementModel>;
  private hierarchyElementsCollectionQuery: (ref: QueryFn) => AngularFirestoreCollection<HierarchyElementModel>;

  constructor(private afs: AngularFirestore, private sequenceService: SequenceService) {
    this.hierarchyElementsCollection = afs.collection<HierarchyElementModel>(COLLECTION_NAMES.HIERARCHY_ELEMENTS);
    this.hierarchyElementsCollectionQuery = (ref: QueryFn) =>
      afs.collection<HierarchyElementModel>(COLLECTION_NAMES.HIERARCHY_ELEMENTS, ref);
  }

  async addHierarchyElement(hierarchyElementModel: HierarchyElementModel): Promise<void> {
    const { label, systemName } = hierarchyElementModel;
    const result = await this.hierarchyElementsCollection.doc(systemName).ref.get();
    if (result.exists) {
      throw new Error(`"${label}" already exists`);
    } else {
      hierarchyElementModel.id = await this.sequenceService.getNextSequenceId();
      return this.hierarchyElementsCollection
        .doc(systemName)
        .set({ ...hierarchyElementModel } as HierarchyElementModel);
    }
  }

  updateHierarchyElement(hierarchyElementModel: HierarchyElementModel): Promise<void> {
    return this.hierarchyElementsCollection.doc(hierarchyElementModel.systemName).update({ ...hierarchyElementModel });
  }

  getHierarchyElement(hierarchySystemName: string): Observable<HierarchyElementModel | null> {
    return this.hierarchyElementsCollection
      .doc(hierarchySystemName)
      .valueChanges()
      .pipe(
        map(hierarchyElement => {
          return plainToClass(HierarchyElementModel, hierarchyElement);
        }),
      );
  }

  async hasChildren(parentSystemName: string): Promise<boolean> {
    // TODO imlplemnent has bots or has child hierarchy
    return (await this.getHierarchyChildrenElements(parentSystemName).pipe(take(1)).toPromise()).length > 0;
  }

  getHierarchyChildrenElements(parentSystemName: string): Observable<HierarchyElementModel[]> {
    return this.hierarchyElementsCollectionQuery(ref => ref.where('parent', '==', parentSystemName))
      .valueChanges()
      .pipe(
        map(hierarchyElements => {
          return hierarchyElements.map(hierarchyElement => plainToClass(HierarchyElementModel, hierarchyElement));
        }),
      );
  }

  async getHierarchyElementWithParentElements(parentSystemName: string): Promise<Array<HierarchyElementModel | null>> {
    const parentSystemNameArray = parentSystemName.split('-');
    const hierarchySystemNames: string[] = [];
    while (parentSystemNameArray.length >= 2) {
      hierarchySystemNames.push(parentSystemNameArray.join('-'));
      parentSystemNameArray.pop();
    }
    if (hierarchySystemNames.length === 0) {
      return [];
    }

    return Promise.all(
      hierarchySystemNames
        .reverse()
        .map(hierarchySystemName => this.getHierarchyElement(hierarchySystemName).pipe(take(1)).toPromise()),
    );
  }

  removeHierarchyElement(systemName: string) {
    return this.hierarchyElementsCollection.doc(systemName).delete();
  }
}
