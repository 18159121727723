<div class="col-12 h-100 d-flex flex-column overflow-auto">
  <span [hidden]="expandedMessages">
    <h3 class="py-3">Search Conversations</h3>
    <app-conversations-messages-search-filter [filter]="filter" (filterChange)="filterChanged()">
    </app-conversations-messages-search-filter>
    <button class="btn btn-primary btn-outline float-right" (click)="executeSearch()" [disabled]="loading">
      Search
    </button>
  </span>

  <ng-container *ngIf="loading || conversationsMessages">
    <div class="h-100 d-flex flex-column">
      <div class="row">
        <div class="col pt-3">
          <h3 class="m-0">Conversations</h3>
          <ng-container *ngIf="conversationsMessages">
            <small>
              Found {{ conversationsMessages.length }} result{{ conversationsMessages.length > 1 ? 's' : '' }}
            </small>
          </ng-container>
        </div>
        <i
          class="messages-expand fas px-4 py-3 m-1"
          [ngClass]="'fa-' + (expandedMessages ? 'compress-arrows-alt' : 'expand-arrows-alt')"
          (click)="expandedMessages = !expandedMessages"
        ></i>
      </div>
      <div class="conversations-list h-100 border mb-2 overflow-auto">
        <ng-container *ngIf="!loading && conversationsMessages">
          <ng-container *ngFor="let conversationSummary of conversationsMessages">
            <app-conversation-summary
              [conversationSummary]="conversationSummary"
              [forceShowConversation]="filter.conversationId"
              [selectedConversationId]="selectedConversationId"
              (conversationSelected)="selectConversation.next(conversationSummary)"
            ></app-conversation-summary>
          </ng-container>
        </ng-container>

        <span class="small" *ngIf="!loading && (!conversationsMessages || !conversationsMessages.length)">
          No conversations found.
        </span>
      </div>
    </div>
  </ng-container>
</div>
