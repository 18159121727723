import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ClientEnvironmentModel } from 'src/app/models/client-environment';
import { HLConversationAgent, HLConversationModel } from 'src/app/models/conversations';
import { BotModel } from 'src/app/models/bot';
import { HierarchyElementModel } from 'src/app/models';
import { AuthService } from 'src/app/services/auth.service';
import { DumbledoreService } from 'src/app/services/dumbledore.service';

@Component({
  selector: 'app-hl-meta-data-list',
  templateUrl: './hl-meta-data-list.component.html',
  styleUrls: ['./hl-meta-data-list.component.scss'],
})
export class HlMetaDataListComponent implements OnInit, OnChanges {
  @Input() selectedConversation: HLConversationModel;

  @Input() corpId: string;

  @Input() envs: ClientEnvironmentModel;
  @Input() bot: BotModel | null;
  botUrl: string;
  loading: boolean;
  assignments: HLConversationAgent[];
  location: string | undefined;
  campaignType: string | undefined;
  vehicle: any;
  internalUser = false;

  @Input() hierarchyElement: HierarchyElementModel | null;
  constructor(private dumbledoreService: DumbledoreService, private authService: AuthService) {}

  ngOnInit() {
    this.authService.currentUser.then(currentUser => {
      if (currentUser && currentUser.email) {
        this.internalUser =
          currentUser.email.toLowerCase().indexOf('@carlabs.') > 0 ||
          currentUser.email.toLowerCase().indexOf('@impel.') > 0;
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedConversation) {
      this.assignments = this.selectedConversation.assignments ? this.selectedConversation.assignments : [];
      this.loading = true;
      this.location = this.hierarchyElement?.label;
      this.campaignType = this.selectedConversation.campaign
        ? this.getConversationType(this.selectedConversation.campaign)
        : undefined;
      const vehicle_result = await this.dumbledoreService.getVehicleByLegacyConversationId(
        this.selectedConversation.firebaseDocumentId,
      );
      this.vehicle = vehicle_result?.user;
      this.refreshContent();
    }
  }

  private getConversationType(conversationType: string) {
    const conversationTypes = {
      AC: 'Abandoned Customer',
      DS: 'Declined Services',
      FS: 'First Service',
      FU: 'Followup',
      NS: 'Next Service',
      PS: 'Pit Stop',
      PC: 'Pre CSI',
      PF: 'Prepaid First Service',
      PN: 'Prepaid Next Service',
      MI: 'Missed Interval',
      SF: 'SpiFi',
      SL: 'Sales Lead',
      LS: 'Sales Lead With Source',
      TI: 'Sales Trade-In',
      SI: 'State Inspection',
      EW: 'Extended Warranty',
      RC: 'Recall',
      PMA: 'Product Marketing',
      NADA: 'NADA',
    };

    const description = conversationTypes[conversationType];
    return description || conversationType;
  }

  private getBotCodeFromBotFullId(botFullId: string): string {
    const parts = botFullId.split('-');
    let botCode = parts[parts.length - 1];
    if (this.envs && this.envs.stages) {
      const env = this.envs.stages.filter(e => {
        const suffix = `_${e.systemName}`;
        return botCode.endsWith(suffix);
      });

      if (env.length > 0) {
        const suffix = `_${env[0].systemName}`;
        botCode = botCode.slice(0, botCode.length - suffix.length);
      }
    }
    return botCode;
  }

  private refreshContent() {
    if (
      this.corpId &&
      this.selectedConversation?.hierarchyElements &&
      this.selectedConversation?.hierarchyElements.length
    ) {
      const lastHierarchy =
        this.selectedConversation.hierarchyElements[this.selectedConversation.hierarchyElements.length - 1];
      const botCode = this.getBotCodeFromBotFullId(this.selectedConversation.botId);
      this.botUrl = `/portal/corps/${this.corpId}/hierarchy-el/${lastHierarchy}/bots/${botCode}`;
    }
    this.loading = false;
  }
}
