import { Injectable } from '@angular/core';
import { MappingNode } from '../../_types/MappingNode';
import { DataExportFilter } from '../../_types/DataExportFilter';
import { sortDesc } from '../../../../utils/strings/string.utils';

/**
 * This service is designed to manage the fields selection to be used in the data export.
 * We need to have a list of INCLUDED and EXCLUDED fields.
 *
 * INCLUDED: to the included will reduce to the most root field
 * ex: messageProcessedEvent.node, messageProcessedEvent.node.kpis, messageProcessedEvent.node.kpis.systemName
 * will include only: messageProcessedEvent.node
 *
 * EXCLUDED: will be excluded only the leaves
 * ex: messageProcessedEvent.node, messageProcessedEvent.node.kpis, messageProcessedEvent.node.kpis.systemName
 * will include only: messageProcessedEvent.node.kpis.systemName
 *
 * Every time a node will be selected/unselected we will find if that field has a hierarchy parent selected.
 * When select a node:
 *  - will be added the node and all hierarchy children to the included fields.
 * When unselect a node:
 *  - the node and all hierarchy children will be added to the excluded fields and will be removed from the included.
 *  - if no hierarchy parent is selected, the field and the hierarchy children will be not added to the excluded fields.
 */
@Injectable({
  providedIn: 'root'
})
export class FieldsSelectionService {
  constructor() {}

  public toggleNode(filter: DataExportFilter, node: MappingNode, selectionValueParam?: boolean): DataExportFilter {
    const selectionValue = selectionValueParam !== undefined ? selectionValueParam : !node.selected;
    this.toggleNodeAndAllChildren(node, selectionValue);
    /**
     * Will provide node.selected, since the node selection was toggled in the previous method
     */
    this.addRemoveFieldAndChildrenToFilter(filter, node, node.selected, this.isHierarchyParentSelected(node));
    filter.includedFields = filter.includedFields.sort();
    filter.excludedFields = sortDesc(filter.excludedFields);

    return filter;
  }

  public toggleAllFields(
    filter: DataExportFilter,
    mappingNodes: MappingNode[],
    allFieldsSelected: boolean
  ): DataExportFilter {
    filter.includedFields = [];
    filter.includedFieldToNodeMap = new Map<string, MappingNode>();
    filter.excludedFields = [];

    mappingNodes.forEach(node => {
      this.toggleNodeAndAllChildren(node, allFieldsSelected);
      this.addRemoveFieldAndChildrenToFilter(filter, node, allFieldsSelected, false);
    });
    filter.includedFields = filter.includedFields.sort();
    filter.excludedFields = sortDesc(filter.excludedFields);

    return filter;
  }

  private toggleNodeAndAllChildren(node: MappingNode, selectionValue: boolean) {
    node.selected = selectionValue;

    if (node.children.length < 1) {
      return;
    }

    node.children.forEach(nodeChildren => {
      this.toggleNodeAndAllChildren(nodeChildren, selectionValue);
    });
  }

  private addRemoveFieldAndChildrenToFilter(
    filter: DataExportFilter,
    node: MappingNode,
    selectionValue: boolean,
    parentHierarchySelected: boolean
  ) {
    this.addOrRemoveFieldToExportList(filter, node, parentHierarchySelected);

    node.children.forEach(nodeChild => {
      this.addRemoveFieldAndChildrenToFilter(filter, nodeChild, selectionValue, parentHierarchySelected);
    });
  }

  private addOrRemoveFieldToExportList(filter: DataExportFilter, node: MappingNode, parentHierarchySelected: boolean) {
    if (node.selected) {
      this.markIncludedField(filter, node);
    } else {
      this.markExcludedField(filter, node, parentHierarchySelected);
    }
  }

  /**
   * Adding the field to the list of excluded fields.
   * The field will be marked as excluded, only if there is at least one hierarchy selected
   */
  private markExcludedField(filter: DataExportFilter, node: MappingNode, parentHierarchySelected: boolean) {
    if (!parentHierarchySelected) {
      this.removeExcludedField(filter, node);
    } else if (!filter.excludedFields?.includes(node.fullPath)) {
      filter.excludedFields.push(node.fullPath);
    }

    this.removeIncludedField(filter, node);
  }

  /**
   * Adding the field to the list of included fields.
   * If the node is marked as excluded will be removed now from the list of excluded fields.
   */
  private markIncludedField(filter: DataExportFilter, node: MappingNode) {
    if (!filter.includedFields?.includes(node.fullPath)) {
      filter.includedFields.push(node.fullPath);
      filter.includedFieldToNodeMap.set(node.fullPath, node);
    }

    this.removeExcludedField(filter, node);
  }

  private removeIncludedField(filter: DataExportFilter, node: MappingNode) {
    const objectIndex = filter.includedFields.findIndex(field => field === node.fullPath);
    if (objectIndex > -1) {
      filter.includedFields.splice(objectIndex, 1);
      filter.includedFieldToNodeMap.delete(node.fullPath);
    }
  }

  private removeExcludedField(filter: DataExportFilter, node: MappingNode) {
    const objectIndex = filter.excludedFields.findIndex(field => field === node.fullPath);
    if (objectIndex > -1) {
      filter.excludedFields.splice(objectIndex, 1);
    }
  }

  /**
   * Return if a parent of the node from any hierarchy level is selected
   */
  private isHierarchyParentSelected(node: MappingNode): boolean {
    const parentNode: MappingNode | null = node.parent;

    if (parentNode == null || parentNode?.name === 'root') {
      return false;
    }

    if (parentNode?.selected) {
      return true;
    }

    return this.isHierarchyParentSelected(parentNode);
  }
}
