<div [ngClass]="normalizeClassNames(['card card-stats mb-0', active && 'bg-' + color])">
  <div [ngClass]="{ 'card-body': true, 'card-override': label !== 'Messages sent' }">
    <div class="row">
      <div class="col">
        <h5
          [ngClass]="
            normalizeClassNames([
              'card-title text-uppercase font-weight-bolder mb-0',
              'text-' + (active ? 'white' : 'muted')
            ])
          "
        >
          {{ label }} <span *ngIf="percent != null && percent != '0.00'">/ %</span>
        </h5>
        <span [ngClass]="normalizeClassNames(['h2 font-weight-bolder mb-0', active && 'text-white'])"
          >{{ total | number }} <span *ngIf="percent != null && percent != '0.00'"> / {{ percent }}%</span></span
        >
      </div>
      <div class="col-auto">
        <div
          [ngClass]="
            normalizeClassNames([
              'icon icon-shape rounded-circle shadow',
              active ? 'bg-white text-' + color : 'text-white bg-' + color
            ])
          "
        >
          <i [ngClass]="[iconClassNames]"></i>
        </div>
      </div>
    </div>
    <!-- <p class="mt-3 mb-0 text-sm">
      <span
        [ngClass]="
          normalizeClassNames([
            'mr-2 font-weight-bold',
            'text-' + (active ? 'white' : difference > 0 ? 'success' : 'danger')
          ])
        "
        ><i [ngClass]="['fa fa-arrow-' + (difference > 0 ? 'up' : 'down')]"></i>
        {{ difference | percent: '1.2-3' }}</span
      >
      <span [ngClass]="normalizeClassNames(['text-nowrap font-weight-bold', 'text-' + (active ? 'white' : 'light')])"
        >Since last month</span
      >
    </p> -->
  </div>
</div>
