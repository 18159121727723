import { Timestamp } from 'firebase-tools';
import * as firestore from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { classToPlain } from 'class-transformer';
import { ApiQueryModel } from './api-query';
import { TemplateModel } from './template';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { KpiDataType } from '../components/modals/add-node-kpi-modal/_types/KpiDataType';

export class NodeApiQueryModel {
  key = '';
  apiQueryId = '';
}

export class NodeValidationModel {
  key = '';
  validationId = '';
  fallbackRetryNodeId = '';
  fallbackRetryAmount = 1;
  fallbackRedirectNodeId = '';
  storable = false;
  storableConfirmNodeId?: string;
}

export enum NodeConditionalOperator {
  Default = '',
  Eq = 'eq',
  Neq = 'neq',
  Lt = 'lt',
  Lte = 'lte',
  Gt = 'gt',
  Gte = 'gte',
  And = 'and',
  Or = 'or',
  Truthy = 'truthy',
  Falsy = 'falsy',
}

export class NodeConditionalModel {
  operator: NodeConditionalOperator;
  left: string | number | boolean | object = '';
  right: string | number | boolean | object = '';
}

export class NodeKpiModel {
  name: string;
  systemName: string;
  dataType: KpiDataType;
  value: string;
  description: string;
}

export class NodeConnectionModel {
  id = '';
  strokeWidth?: string;
  strokeColor?: string;
  strokeDashArray?: string;
  conditionals?: NodeConditionalModel[] = [];
}

export class NodeHttpRequest {
  id: string;
  title: string;
  endPoint: string;
  payload: string;
  httpMethod: 'GET' | 'POST' | 'PUT' | 'HEAD' | 'PATCH' | 'DELETE';
  httpHeader: string;
  responseStorageVariable?: string;
}

export class NodeModel {
  id: string = NodeModel.generateId(this);
  name: string;
  action?: string;
  spec?: string;
  specTemplates = {};
  systemName: string;
  botId: string;
  live = false;
  global = false;
  isRecurring = false;
  availableForSupport = false; // make this node available for support
  recurringExpression: string;
  x = 50;
  y = 50;
  templates?: TemplateModel[] = [];
  apiQueries?: NodeApiQueryModel[] = [];
  validations?: NodeValidationModel[] = [];
  connections?: NodeConnectionModel[] = [];
  httpRequests?: NodeHttpRequest[] = [];
  kpis?: NodeKpiModel[] = [];
  templateNodeConnections?: string[];
  inputData?: string;
  inputDataExampleData?: string;
  mod?: object; // ? I believe this is no longer in use
  flowTemplateId?: string;
  flowTemplateNodeId?: string;
  flowTemplateCustomizations?: string[];
  flowTemplateLastTouchedHash?: string;
  createdAt: Timestamp = firestore.serverTimestamp();
  updatedAt: Timestamp = firestore.serverTimestamp();
  notes: string;
  //
  // Helper State
  //

  parentNodes: NodeModel[] = [];
  childNodes: NodeModel[] = [];
  numParentNodes = 0;
  numChildNodes = 0;
  availableData?: object = {};

  static removeExcluded(nodeModel: NodeModel) {
    delete nodeModel.parentNodes;
    delete nodeModel.childNodes;
    delete nodeModel.numParentNodes;
    delete nodeModel.numChildNodes;
    delete nodeModel.availableData;
  }

  //
  //
  //

  static ifGlobalClear(nodeModel: NodeModel) {
    if (!nodeModel?.global) {
      return;
    }
    delete nodeModel.action;
    delete nodeModel.spec;
    nodeModel.templates = [];
    nodeModel.apiQueries = [];
    nodeModel.validations = [];
    delete nodeModel.mod;
    delete nodeModel.inputData;
    delete nodeModel.inputDataExampleData;
  }

  static generateDefault(): NodeModel {
    return new NodeModel();
  }

  static generateId(nodeModel: NodeModel): string {
    nodeModel.id = uuidv4();
    return nodeModel.id;
  }

  static generateUpdatedAt(nodeModel: NodeModel): string {
    nodeModel.updatedAt = firestore.serverTimestamp();
    return nodeModel.updatedAt;
  }

  static generateSystemName(nodeModel: NodeModel): string {
    nodeModel.systemName = nodeModel.name.toLowerCase().trim().replace(/\s+/g, '-');
    return nodeModel.systemName;
  }

  static validateConnections(nodeModel: NodeModel): void {
    nodeModel.connections = nodeModel.connections?.filter(connection => (connection.id ? true : false));
  }

  static toPlain(nodeModel: NodeModel): NodeModel {
    return {
      ...classToPlain(nodeModel),
      ...{ updatedAt: nodeModel.updatedAt, createdAt: nodeModel.createdAt },
    } as NodeModel;
  }

  //
  // Helper Methods
  //

  // Helper do not save return values to the document
  static getParents(nodeModels: NodeModel[], nodeModel: NodeModel): NodeModel[] {
    const getParents = (n, seen: string[] = []) =>
      nodeModels
        .filter(n1 => {
          if (!n1.connections?.length) {
            return false;
          }
          const prevNodeInCurrentConnections = n1.connections.filter(c => c.id === n.id).length > 0;
          return n.id !== n1.id && !seen.includes(n1.id) && prevNodeInCurrentConnections;
        })
        .map(n2 => {
          seen.push(n2.id);
          return { ...n2, ...{ parentNodes: getParents(n2, seen) } };
        });
    return getParents(nodeModel);
  }

  // Helper do not save return values to the document
  private static countParents(nodeWithParents: NodeModel): number {
    const countParents = (n: NodeModel): number => {
      if (!n?.parentNodes?.length) {
        return 0;
      }
      return n.parentNodes.reduce((acc, v) => acc + countParents(v), n.parentNodes.length);
    };
    return countParents(nodeWithParents);
  }

  // Helper do not save return values to the document
  private static getChildren(nodeModels: NodeModel[], nodeModel: NodeModel): NodeModel[] {
    const getChildren = (n: NodeModel, seen: string[] = []) =>
      nodeModels
        .filter(n1 => {
          if (!n.connections?.length) {
            return false;
          }
          const prevNodeInConnections = n.connections.filter(c => c.id === n1.id).length > 0;
          return n.connections.length && !seen.includes(n1.id) && prevNodeInConnections;
        })
        .map(n2 => {
          seen.push(n2.id);
          return { ...n2, ...{ childNodes: getChildren(n2, seen) } };
        });
    return getChildren(nodeModel);
  }

  // Helper do not save return values to the document
  private static countChildren(nodeWithChildren: NodeModel): number {
    const countChildren = (n: NodeModel): number => {
      if (!n?.childNodes?.length) {
        return 0;
      }
      return n.childNodes.reduce((acc, v) => acc + countChildren(v), n.childNodes.length);
    };
    return countChildren(nodeWithChildren);
  }

  // Helper do not save return values to the document
  static generateMeta(nodeModel: NodeModel, nodeModels: NodeModel[]) {
    nodeModel.parentNodes = NodeModel.getParents(nodeModels, nodeModel);
    nodeModel.childNodes = NodeModel.getChildren(nodeModels, nodeModel);
    nodeModel.numParentNodes = NodeModel.countParents(nodeModel);
    nodeModel.numChildNodes = NodeModel.countChildren(nodeModel);
  }

  // Helper do not save return values to the document
  static generateAvailableData(nodeModel: NodeModel, schema: object, apiQueries: ApiQueryModel[]) {
    const r = (nodes: NodeModel[], data: object = {}): object => {
      if (nodes == null) {
        return data;
      }

      let i = 0;
      while (i < nodes.length) {
        const node = nodes[i];

        if (node.apiQueries?.length) {
          let j = 0;
          while (j < node.apiQueries.length) {
            const aq = node.apiQueries[j];
            const apiQuery = apiQueries.find(a => a.id === aq.apiQueryId);
            if (!apiQuery) {
              j++;
              continue;
            }
            const apiQueryReturnType = get(schema, [
              'children',
              apiQuery?.queryOperation,
              'children',
              apiQuery?.queryType,
            ]);
            if (!apiQueryReturnType) {
              j++;
              continue;
            }
            data[aq.key] = {
              name: aq.key,
              kind: 'OBJECT',
              type: null,
              typeLabel: null,
              children: {
                [apiQueryReturnType.name]: apiQueryReturnType,
              },
            };
            j++;
          }
        }

        if (node.validations?.length) {
          let j = 0;
          while (j < node.validations.length) {
            const v = node.validations[j];
            data[v.key] = {
              name: v.key,
              kind: 'SCALAR',
              type: 'String',
              typeLabel: 'String',
              children: null,
            };
            j++;
          }
        }

        if (node.inputData) {
          const inputData = JSON.parse(node.inputData);
          Object.keys(inputData).forEach(key => {
            const item = inputData[key];
            let itemType = 'String';
            if (typeof item === 'string') {
              itemType = 'string';
            } else if (typeof item === 'number') {
              itemType = item % 1 === 0 ? 'Int' : 'Float';
            } else if (typeof item === 'boolean') {
              itemType = 'Boolean';
            }
            data[key] = {
              name: key,
              kind: 'SCALAR',
              type: itemType,
              typeLabel: itemType,
              children: null,
            };
          });
        }

        if (node.parentNodes?.length) {
          const d = r(node.parentNodes, data);
          data = { ...data, ...d };
        }

        i++;
      }

      // TODO: Separate connection available data from template available data.
      if (nodeModel.apiQueries) {
        nodeModel.apiQueries?.forEach(aq => {
          const apiQuery = apiQueries.find(a => a.id === aq.apiQueryId);
          if (!apiQuery) {
            return;
          }
          const apiQueryReturnType = get(schema, [
            'children',
            apiQuery?.queryOperation,
            'children',
            apiQuery?.queryType,
          ]);
          if (!apiQueryReturnType) {
            return;
          }
          data[aq.key] = {
            name: aq.key,
            kind: 'OBJECT',
            type: null,
            typeLabel: null,
            children: {
              [apiQueryReturnType.name]: apiQueryReturnType,
            },
          };
        });
      }

      // TODO: Separate connection available data from template available data.
      if (nodeModel.validations) {
        nodeModel.validations?.forEach(v => {
          data[v.key] = {
            name: v.key,
            kind: 'SCALAR',
            type: 'String',
            typeLabel: 'String',
            children: null,
          };
        });
      }

      // TODO: Separate connection available data from template available data.
      if (nodeModel.inputData) {
        const inputData = JSON.parse(nodeModel.inputData);
        Object.keys(inputData).forEach(key => {
          const item = inputData[key];
          let itemType = 'String';
          if (typeof item === 'string') {
            itemType = 'string';
          } else if (typeof item === 'number') {
            itemType = item % 1 === 0 ? 'Int' : 'Float';
          } else if (typeof item === 'boolean') {
            itemType = 'Boolean';
          }
          data[key] = {
            name: key,
            kind: 'SCALAR',
            type: itemType,
            typeLabel: itemType,
            children: null,
          };
        });
      }

      return data;
    };

    let availableData = r(nodeModel.parentNodes);

    if (Object.keys(availableData).length) {
      availableData = {
        name: 'Available Data',
        children: {
          ...availableData,
          types: get(schema, ['children', 'types']),
        },
      };
      nodeModel.availableData = availableData;
    } else {
      nodeModel.availableData = undefined;
    }
  }

  // Helper do not save return values to the document
  static inPath(data: object, path: string[]): boolean {
    const checkPath: string[] = path.reverse();
    const r = (obj: object, current: string) => {
      const currentObj = obj[current];
      if (!currentObj) {
        return false;
      }
      if (currentObj && !checkPath.length) {
        return true;
      }
      // Consume index
      if (currentObj.kind === 'LIST') {
        checkPath.pop();
      }
      const nextPath = checkPath.pop();
      if (nextPath) {
        return r(currentObj.children, nextPath);
      } else {
        return true;
      }
    };
    const startPath = checkPath.pop();
    if (startPath) {
      return r(get(data, ['children']), startPath);
    }
    return false;
  }

  // Helper do not save return values to the document
  static duplicate(node: NodeModel): NodeModel {
    const clonedNode: NodeModel = cloneDeep(node);
    const copy = NodeModel.generateDefault();
    copy.name = `${clonedNode.name} Duplicate ${copy.id.slice(-4)}`;
    if (clonedNode.templates) {
      copy.templates = cloneDeep(clonedNode.templates);
    }
    if (clonedNode.apiQueries?.length) {
      copy.apiQueries = cloneDeep(clonedNode.apiQueries);
    }
    if (clonedNode.validations?.length) {
      copy.validations = cloneDeep(clonedNode.validations);
    }
    if (clonedNode.connections?.length) {
      copy.connections = cloneDeep(clonedNode.connections);
    }
    if (clonedNode.inputData) {
      copy.inputData = clonedNode.inputData;
    }
    if (clonedNode.inputDataExampleData) {
      copy.inputDataExampleData = clonedNode.inputDataExampleData;
    }
    if (clonedNode.specTemplates) {
      copy.specTemplates = cloneDeep(clonedNode.specTemplates);
    }
    if (clonedNode.kpis) {
      copy.kpis = cloneDeep(clonedNode.kpis);
    }
    copy.botId = clonedNode.botId;
    copy.x = clonedNode.x;
    copy.y = clonedNode.y + 200;
    if (clonedNode.action) {
      copy.action = clonedNode.action;
    }
    copy.live = clonedNode.live;
    if (clonedNode.spec) {
      copy.spec = clonedNode.spec;
    }
    copy.global = clonedNode.global;
    if (clonedNode.notes) {
      copy.notes = clonedNode.notes;
    }
    copy.availableForSupport = clonedNode.availableForSupport;
    copy.createdAt = firestore.serverTimestamp();
    copy.updatedAt = firestore.serverTimestamp();
    NodeModel.generateSystemName(copy);
    return copy;
  }
}
