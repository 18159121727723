import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SidebarItemVM } from '../components/layout/sidebar/_types/SidebarItemVM';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private items: BehaviorSubject<SidebarItemVM[] | null>;
  items$: Observable<SidebarItemVM[] | null>;
  constructor(private authService: AuthService) {
    this.items = new BehaviorSubject<SidebarItemVM[] | null>(null);
    this.items$ = this.items.asObservable();
  }

  async set(sidebarItemVM: SidebarItemVM[]) {
    const userPermissions = await this.authService.getCurrentUserPermissions();
    const allowedSideBarItems = sidebarItemVM.filter(({ permission, permissions }) => {
      if (!permission && !permissions) {
        return true;
      }

      if (permissions) {
        for (let index in permissions) {
          if (userPermissions.includes(permissions[index])) {
            return true;
          }
        }
      }
      if (permission) {
        return userPermissions.includes(permission);
      }
      return false;
    });
    this.items.next(allowedSideBarItems);
  }

  clean() {
    this.items.next([]);
  }
}
