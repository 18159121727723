<ng-container *ngIf="loading">
  <div class=" container-fluid">
    <div class=" row align-items-center py-4 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading">
  <div class="m-4">
    <div class="d-flex flex-column">
      <app-simple-list-item [item]="nlpModelItem"></app-simple-list-item>
    </div>
    <div class="card container-fluid py-4" *ngIf="nlpModel.status === 'ready'">
      <div class="row px-2">
        <h3>Test model</h3>
      </div>
      <form class="p-0 m-0" (submit)="parse()" #form="ngForm" novalidate>
        <fieldset class="p-0 m-0" [ngClass]="parseLoading ? 'disabled' : ''">
          <div class="row pt-2 pb-4 px-2">
            <input
              class="col-sm form-control"
              id="text"
              name="text"
              [(ngModel)]="parseText"
              type="text"
              placeholder="Test phrase"
              required
            />
            <button class="col-1 ml-3 btn btn-primary" type="submit" [disabled]="!form.valid || loading">
              Parse
            </button>
          </div>
        </fieldset>
      </form>
      <div *ngIf="parseResponse" class="row px-2">
        <ngx-codemirror
          class="col-sm p-0 m-0 rounded overflow-hidden"
          [(ngModel)]="parseResponse"
          [options]="{
            lineNumbers: true,
            theme: 'material',
            mode: { name: 'javascript', json: true },
            readOnly: true
          }"
        ></ngx-codemirror>
      </div>
      <div class="row pt-4 px-2">
        <h3>Confusion Matrix / Hist</h3>
      </div>
      <div class="row pt-4 px-2">
        <img class="col-6" alt="" src="{{ nlpDojoS3Url }}/nlp_models/default/{{ nlpModel.id }}/confmat.png" />
        <img class="col-6" alt="" src="{{ nlpDojoS3Url }}/nlp_models/default/{{ nlpModel.id }}/hist.png" />
      </div>
      <div class="row pt-4 px-2" *ngIf="nlpModelEvaluateIntentReport">
        <h3>Intent Report</h3>
      </div>
      <div class="row pt-4 px-2" *ngIf="nlpModelEvaluateIntentReport">
        <ngx-codemirror
          class="col-sm p-0 m-0 rounded overflow-hidden"
          [(ngModel)]="nlpModelEvaluateIntentReport"
          [options]="{
            lineNumbers: true,
            theme: 'material',
            mode: { name: 'javascript', json: true },
            readOnly: true
          }"
        ></ngx-codemirror>
      </div>
      <div class="row pt-4 px-2" *ngIf="nlpModelIntents">
        <h3>Intents</h3>
      </div>
      <div class="row pt-4 px-2" *ngIf="nlpModelIntents">
        <ngx-codemirror
          class="col-sm p-0 m-0 rounded overflow-hidden"
          [(ngModel)]="nlpModelIntents"
          [options]="{
            lineNumbers: true,
            theme: 'material',
            mode: { name: 'javascript', json: true },
            readOnly: true
          }"
        ></ngx-codemirror>
      </div>
      <div class="row pt-4 px-2" *ngIf="nlpModelEntities">
        <h3>Entities</h3>
      </div>
      <div class="row pt-4 px-2" *ngIf="nlpModelEntities">
        <ngx-codemirror
          class="col-sm p-0 m-0 rounded overflow-hidden"
          [(ngModel)]="nlpModelEntities"
          [options]="{
            lineNumbers: true,
            theme: 'material',
            mode: { name: 'javascript', json: true },
            readOnly: true
          }"
        ></ngx-codemirror>
      </div>
      <div class="row pt-4 px-2" *ngIf="nlpModelIntentsEntities">
        <h3>Intents Entities</h3>
      </div>
      <div class="row pt-4 px-2" *ngIf="nlpModelIntentsEntities">
        <ngx-codemirror
          class="col-sm p-0 m-0 rounded overflow-hidden"
          [(ngModel)]="nlpModelIntentsEntities"
          [options]="{
            lineNumbers: true,
            theme: 'material',
            mode: { name: 'javascript', json: true },
            readOnly: true
          }"
        ></ngx-codemirror>
      </div>
    </div>

    <div [ngClass]="nlpModel.status === 'training' ? 'disabled' : ''">
      <app-nlp-model-files
        [nlpModelFileItems]="nlpModelFileItems"
        [initialFiles]="nlpModel.files"
        [className]="'height-max-600'"
      ></app-nlp-model-files>
    </div>
  </div>
</ng-container>
