import { SidebarItemVM } from 'src/app/components/layout/sidebar/_types/SidebarItemVM';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { BreadcrumbNodeVM } from 'src/app/components/breadcrumb/_types/BreadcrumbNodeVM';
import { CorpModel, HierarchyElementModel, BotModel } from 'src/app/models';

export const getSidebarItems = (
  corpId: string,
  hierarchyElement: HierarchyElementModel,
  botCode: string,
): SidebarItemVM[] => {
  // tslint:disable-next-line:max-line-length
  const baseUrl = `/portal/corps/${corpId}/hierarchy-el/${hierarchyElement.systemNameForUrl}/bots/${botCode}`;

  return [
    {
      path: `${baseUrl}/dashboard`,
      title: 'Dashboard',
      type: 'link',
      icontype: 'ni-chart-bar-32 text-danger',
      testId: 'bot-analytics-link',
      permission: Permissions.CAN_READ_BOT_ANALYTICS,
    },
    // Hide for now, we may remove this
    // permanently in the future.
    // {
    //   path: `${baseUrl}/conversation-logs`,
    //   title: 'Conversation Logs',
    //   type: 'link',
    //   icontype: 'ni-chat-round text-info',
    //   testId: 'bot-conversation-logs-link',
    //   permission: Permissions.CAN_READ_BOT_CONVERSATION_LOGS,
    // },
    {
      path: `${baseUrl}/api-queries`,
      title: 'API Queries',
      type: 'link',
      icontype: 'ni-atom text-success',
      testId: 'bot-api-queries-link',
      permission: Permissions.CAN_READ_BOT_API_QUERIES,
    },
    {
      path: `${baseUrl}/flow`,
      title: 'Flow',
      type: 'link',
      icontype: 'ni-vector text-info',
      testId: 'bot-flow-link',
      permission: Permissions.CAN_READ_BOT_FLOW,
    },
    {
      path: `${baseUrl}/export`,
      title: 'Export',
      type: 'link',
      icontype: 'ni-books text-primary',
      permission: Permissions.CAN_EXPORT_BOT,
    },
    {
      path: `${baseUrl}/settings`,
      title: 'Assistant Settings & Test',
      type: 'link',
      icontype: 'ni-settings-gear-65 text-grey',
      testId: 'bot-settings-link',
      permission: Permissions.CAN_READ_BOT_SETTINGS,
    },
    {
      path: `${baseUrl}/human-in-the-loop`,
      title: 'Human in the Loop',
      type: 'link',
      icontype: 'ni-support-16 text-purple',
      permissions: [Permissions.CAN_HANDLE_BOT_SUPPORT, Permissions.CAN_ADMIN_BOT_HIL],
    },
    {
      path: `${baseUrl}/deployments`,
      title: 'Deployments',
      type: 'link',
      icontype: 'ni-world-2 text-primary',
      testId: 'bot-dashboard-link',
      permission: Permissions.CAN_READ_BOT_DEPLOYMENTS,
    },
  ];
};

export const getBreadcrumbItems = (
  corp: CorpModel,
  hierarchyElement: HierarchyElementModel,
  bot: BotModel,
  title: string,
  url: string,
): BreadcrumbNodeVM[] => {
  const baseHierarchyUrl = `portal/corps/${corp.id}/hierarchy-el/${hierarchyElement.systemNameForUrl}`;
  return [
    {
      label: corp.label,
      icon: corp.logo,
      route: `portal/corps/${corp.id}`,
      testId: 'bread-crumb-corp',
    },
    {
      icon: hierarchyElement.logo,
      label: hierarchyElement.label,
      route: baseHierarchyUrl,
      testId: 'bread-crumb-hierarcy',
    },
    {
      label: bot.label,
      icon: bot.styles.avatarImageUrl,
      route: `${baseHierarchyUrl}/bots/${bot.code}`,
      testId: 'bread-crumb-bot',
    },
    {
      label: title,
      route: `${baseHierarchyUrl}/bots/${bot.code}/${url}`,
    },
  ];
};
