<app-action-progress></app-action-progress>
<form (submit)="save()" #form="ngForm" novalidate>
  <fieldset class="container" [disabled]="!writable">
    <div class="form-group row">
      <label class="form-control-label" for="apiQueryName">
        Name
      </label>
      <input
        class="form-control"
        id="apiQueryName"
        name="name"
        [(ngModel)]="apiQuery.name"
        type="text"
        placeholder="e.g. Request Details, Payments"
        required
      />
    </div>
  </fieldset>
  <button *ngIf="writable" class="btn btn-primary btn-outline" type="submit" [disabled]="!form.valid || loading">
    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span *ngIf="loading"> Saving...</span>
    <span *ngIf="!loading">SAVE</span>
  </button>
</form>
