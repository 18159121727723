<form #form="ngForm" novalidate>
<app-action-progress></app-action-progress>
    <fieldset [disabled]="!writable">
      <div class=" form-group">
        <div class=" form-group">
          <label class=" form-control-label" for="hierarchyLabel"> {{ corpHierarchy.singular }} </label>
          <input class="form-control" id="hierarchyLabel" name="label" [(ngModel)]="hierarchyElement.label" (keyup)="changeHierarcyLabelSlug()" type="text" placeholder="" required>
        </div>
      </div>
      <div class=" form-group" *ngIf="shouldAdd">
        <div class=" form-group">
          <label class=" form-control-label" for="hierarcyLabelSlug"> System Name (Only Alphanumerics And Underscore Are Allowed) </label>
          <input class="form-control" id="hierarcyLabelSlug" name="label_slug" [(ngModel)]="hierarcyLabelSlug" pattern="[a-zA-Z0-9_]+" type="text" placeholder="" required>
        </div>
      </div>
      <div class=" form-group">
        <label class=" form-control-label"> Logo </label>
        <app-editable-image
          [source]="hierarchyElement.logo"
          [editable]="writable"
          (fileSelect)="logoFile = $event"
          (remove)="hierarchyElement.logo = undefined"
        >
        </app-editable-image>
      </div>
    </fieldset>
    <button *ngIf="writable" class="btn btn-primary btn-outline" type="submit" [disabled]="!form.valid || loading" (click)="save()">
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span *ngIf="loading"> Saving...</span>
      <span *ngIf="!loading">SAVE</span>
    </button>
  </form>
