import { Component, HostListener, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SidebarService } from '../../services/sidebar.service';
import { UserNavbarService } from '../../services/navbar/user-navbar.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  isMobileResolution: boolean;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private userNavbarService: UserNavbarService
  ) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  ngOnInit() {
    this.setBreadcrumb();
    this.setUserNavbarItems();
    this.sidebarService.clean();
  }

  @HostListener('window:resize', ['$event'])
  isMobile(event) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  private setBreadcrumb() {
    this.breadcrumbService.reset();
    this.breadcrumbService.root.next({
      icon: 'wrench',
      route: 'admin/'
    });
  }

  private setUserNavbarItems() {
    this.userNavbarService.set([
      {
        label: 'My profile',
        icon: 'single-02',
        route: '/admin/profile'
      }
    ]);
  }
}
