<div class="card-header conversation-list-header pt-0 pl-0 pr-0">
  <div class="input-group input-group-alternative">
    <input
      class="form-control"
      #searchInput
      [(ngModel)]="searchText"
      placeholder="Search conversations"
      (keyup)="doSearchConversations($event)"
      type="text"
    />

    <div class="input-group-append">
      <span class="input-group-text">
        <i (click)="showAdvancedSearch()" style="cursor: pointer" class="ni ni-zoom-split-in"> </i>
      </span>
    </div>
  </div>
  <div>
    <div>
      <input
        *ngIf="false"
        [(ngModel)]="advancedSearch"
        style="display: inline-block; margin-right: 5px"
        type="checkbox"
      />

      <!-- <button (click)="showAdvancedSearch()" class="mt-2 btn btn-sm">Advanced Search</button> -->

      <button *ngIf="clearSearch" (click)="doClearFilter()" class="mt-2 btn btn-sm">Reset</button>
    </div>
  </div>

  <div>
    <h3 class="mt-3">{{ conversations.length }} Conversation{{ conversations.length == 1 ? '' : 's' }}</h3>
  </div>
</div>

<div
  #conversationsListDiv
  (scroll)="onScroll($event)"
  id="all-conversations-list"
  class="card-body conversations-list-body p-0 scrollable-card list-group list-group-chat list-group-flush"
>
  <div *ngIf="loadingTop">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; background: transparent; display: block"
      width="25px"
      height="25px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#07abcc"
        stroke-width="10"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  </div>

  <div
    [attr.id]="'convo-' + conversation.id"
    *ngFor="let conversation of conversations"
    class="list-group-item conversations-list-item"
    [class.active]="selectedConversationId === conversation.id"
    [class.bg-default]="selectedConversationId === conversation.id"
    (click)="refreshConversation(conversation)"
    style="cursor: pointer"
  >
    <div class="media-body ml-2">
      <div class="justify-content-between align-items-center">
        <p
          class="mb-0"
          [class.text-white]="selectedConversationId === conversation.id"
          [class.font-weight-boldest]="conversation.conversationIsUnread"
        >
          {{ conversation.userData && conversation.userData?.fullName ? conversation.userData?.fullName : 'Guest' }}
          <span
            *ngIf="conversation.needsAttentionInitial || conversation.needsAttentionContinue"
            style="float: right; color: red"
          >
            <i class="fa fa-circle fa-sm"></i>
          </span>

          <span
            *ngIf="conversation.tags"
            style="float: right; color: #2dbecd"
            tooltip="{{ conversation.tagToolTipText }}"
          >
            <i class="fa fa-tags fa-sm"></i>
          </span>
        </p>

        <!-- <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
          <small
            style="margin-right: 10px"
            *ngIf="conversation.lastMessage"
            class="text-muted mb-0"
            [class.text-white]="selectedConversationId === conversation.id"
          >
            {{ conversation.lastMessage }}
          </small>
        </div> -->

        <div>
          <p
            *ngIf="conversation"
            class="text-muted mb-0 time-ago"
            [class.text-white]="selectedConversationId === conversation.id"
            [class.font-weight-boldest]="conversation.conversationIsUnread"
          >
            {{ conversation.lastUpdatedString }}
          </p>
        </div>

        <ng-container *ngIf="conversation.assignments">
          <div class="assigned-users-avatars" style="float: right">
            <ng-container *ngFor="let agent of conversation.assignments">
              <span class="conversation-avatar" *ngIf="agent.agentAvatarUrl">
                <img src="{{ agent.agentAvatarUrl }}" tooltip="{{ agent.agentName }}" />
              </span>
              <span class="conversation-avatar" *ngIf="!agent.agentAvatarUrl">
                <img src="assets/img/brand/undraw_profile_pic_ic5t.png" tooltip="{{ agent.agentName }}" />
              </span>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="loadingBottom">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; background: transparent; display: block"
      width="25px"
      height="25px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#07abcc"
        stroke-width="10"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  </div>
</div>
