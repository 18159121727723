<ng-container *ngIf="loading">
    <div class=" container-fluid">
      <div class=" row align-items-center py-4 justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <div class=" container-fluid">
      <div class=" row">
        <div class=" col-lg-12 col-sm-12">
          <div class=" card m-4">
            <div class=" card-header d-flex align-items-center">
              <h3>Roles</h3>
              <button *ngIf="canWriteRoles()" class="btn btn-outline-success btn-sm btn-circle ml-4" (click)="openAddRoleModal()">
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class=" card-body">
              <div class=" table-responsive" *ngIf="roles && roles.length">
                <table class=" table align-items-center table-flush">
                  <thead class=" thead-light">
                    <tr>
                      <th scope="col">Role</th>
                      <th scope="col">Number Of Permissions</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody class=" list">
                    <tr *ngFor="let role of roles">
                      <td>
                        <h4>
                          {{ role.name }}
                        </h4>
                      </td>
                      <td>
                        <h4>
                          {{ role.permissions.length }}
                        </h4>
                      </td>
                      <td>
                        <i
                          class=" fa fa-cog text-info ml-3 cursor-pointer"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit Role"
                          (click)="openEditRoleModal(role)"
                        >
                        </i>
                        <i
                          *ngIf="canWriteRoles()"
                          class=" fas fa-trash text-danger ml-3 cursor-pointer"
                          (click)="confirmAndDeleteRole(role)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete Role"
                        >
                        </i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h4 *ngIf="!roles || roles.length === 0">No roles.</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
