import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Event, NavigationEnd, NavigationError, Router } from '@angular/router';
import { DefaultGlobalConfig, ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/firestore';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { UserModel } from 'src/app/models/user';
import { UserNavbarService } from 'src/app/services/navbar/user-navbar.service';
// tslint:disable-next-line:max-line-length
import { EnvironmentManagementModalComponent } from 'src/app/components/modals/environment-management-modal/environment-management-modal.component';
import { UserNavbarItemVM } from './_types/UserNavbarItemVM';
import { ClientEnvironmentModel, StageModel } from 'src/app/models/client-environment';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { UserNotificationsService } from 'src/app/services/firestore/user-notifications.service';
import { NotificationStatus, NotificationType, UserNotificationModel } from 'src/app/models/notifications';
import moment, { Moment } from 'moment';
import * as firestore from 'firebase/firestore';
import { Timestamp } from 'firebase-tools';
import { Permissions } from '../../../utils/permissions/permissions';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public focus;
  public user: UserModel | null;
  public sidebarItemsCount: number;
  public userNavbarItems: UserNavbarItemVM[] | null;
  public clientEnvironment: ClientEnvironmentModel | null;
  public isOnDashboard = false;
  public isOnHierarchyEl = false;
  public notifications: UserNotificationModel[] = [];
  public timeSortedNotifications: UserNotificationModel[] = [];
  public unseenNotificationCount: number;
  public unreadNotificationCount: number;
  public isAdmin = false;

  @Input()
  public backgroundColor = 'primary';

  private crtDataSubscription: Subscription;
  private userSubscription: Subscription;
  private notificationsSubscription: Subscription;
  private lastNotificationIds: string[] = [];
  private useAuth0 = environment.useAuth0 || false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private usersService: UsersService,
    private sidebarService: SidebarService,
    private toaster: ToastrService,
    private userNavbarService: UserNavbarService,
    private clientEnvironmentService: ClientEnvironmentService,
    private modalService: BsModalService,
    private notificationService: UserNotificationsService,
    private auth0Service: Auth0Service,
  ) {
    this.router.events.subscribe((event: Event) => {
      const path = get(event, ['urlAfterRedirects']);
      if (path) {
        this.setOnBotDashboard(path);
        this.setOnHierarchyEl(path);
        this.hasCorpSelected(path);
      }

      if (event instanceof NavigationEnd && window.innerWidth < 1200) {
        document.body.classList.remove('g-sidenav-pinned');
        document.body.classList.add('g-sidenav-hidden');
      }

      if (event instanceof NavigationError) {
        this.toaster.error(event.error);
      }
      const config = DefaultGlobalConfig;
      config.autoDismiss = false;
      config.maxOpened = 0;
      this.toaster.toastrConfig = config;
    });
  }

  async ngOnInit() {
    const currentUser = await this.authService.currentUser;

    this.isAdmin = await this.authService.hasPermission(Permissions.IS_ADMIN);

    if (!currentUser?.uid) {
      return;
    }

    this.crtDataSubscription = combineLatest([
      this.sidebarService.items$,
      this.userNavbarService.items$,
      this.clientEnvironmentService.items$,
      this.usersService.getUserById(currentUser.uid),
      this.notificationService.getUserNotifications(currentUser.uid),
    ]).subscribe(([sidebarItems, userNavbarItems, clientEnvironment, user, notifications]) => {
      if (!userNavbarItems || !clientEnvironment || !user || !notifications) {
        return;
      }

      this.sidebarItemsCount = sidebarItems?.length ?? 0;
      this.userNavbarItems = userNavbarItems;
      this.clientEnvironment = clientEnvironment;
      this.user = user;
      this.notifications = notifications.sort((a, b) => this.sortNotificationsDesc(a, b));

      this.unseenNotificationCount = this.user.notificationSettings.count ?? 0;

      this.unreadNotificationCount = notifications.filter(
        notification => notification.status === NotificationStatus.Unread,
      ).length;

      this.maybeShowNewNotifications();
    });
  }

  private sortNotificationsDesc(a: UserNotificationModel, b: UserNotificationModel) {
    return b.createdAt.toDate() - a.createdAt.toDate();
  }
  async maybeShowNewNotifications() {
    this.timeSortedNotifications = this.sortNotificationsByTime();
    const notificationIds = this.timeSortedNotifications.map(n => n.id);

    if (!this.user || !this.lastNotificationIds.length) {
      return;
    }

    if (!this.user.notificationSettings) {
      this.user.notificationSettings = {
        email: true,
        in_app: true,
        lastActive: firestore.serverTimestamp(),
      };
    }

    // check for new notifications and show toasts
    const lastActive: Timestamp = this.user.notificationSettings?.lastActive;

    const showNotifications = this.user.notificationSettings && this.user.notificationSettings.in_app;
    if (showNotifications) {
      const newNotifications = this.notifications.filter(n => {
        if (lastActive) {
          const notificationTime: Moment = moment(n.createdAt.toDate());
          if (notificationTime.isBefore(moment(lastActive.toDate()))) {
            return false;
          }
        }
        return !this.lastNotificationIds.includes(n.id);
      });

      newNotifications.forEach(n => {
        this.toaster
          .success(n.message, 'Notification', {
            disableTimeOut: true,
            closeButton: true,
          })
          .onTap.toPromise()
          .then(any => {
            this.clickedNotification(n);
          });
      });
    }

    this.lastNotificationIds = notificationIds;

    this.user.notificationSettings.lastActive = firestore.serverTimestamp();
    await this.usersService.updateUser(this.user);
  }

  async clickedNotification(notification: UserNotificationModel) {
    await this.notificationService.markNotificationAsRead(notification.id);

    if (notification.notificationType === NotificationType.ConversationAssigned) {
      // open conversation
      const { url } = notification.notificationData as any;

      if (url) {
        const urlString = `${url}`;
        if (urlString.startsWith('http')) {
          try {
            const u = new URL(urlString);
            this.router.navigateByUrl(u.pathname);
            return;
          } catch {
            //
          }
        }
        this.router.navigateByUrl(urlString);
      }
    }
  }

  openSidebar() {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
  }

  logout() {
    if (!this.useAuth0) {
      this.authService
        .logout()
        .then(() => {
          this.router.navigate(['/login']);
        })
        .catch(error => {
          this.toaster.error(error);
        });
    } else {
      this.auth0Service.logout().subscribe(() => {
        this.authService
          .logout()
          .then(() => {
            setTimeout(() => this.router.navigate(['/login']), 2000);
          })
          .catch(error => {
            this.toaster.error(error);
          });
      });
    }
  }

  setStage(stage: StageModel) {
    if (!this.clientEnvironment) {
      return;
    }
    const clonedClientEnvironment = cloneDeep(this.clientEnvironment);
    clonedClientEnvironment.selectedStage = stage;
    this.clientEnvironmentService.setSelectedStage(clonedClientEnvironment);
  }

  openEnvironmentManagementModal(): void {
    this.modalService.show(EnvironmentManagementModalComponent, {
      initialState: {
        clientEnvironment: this.clientEnvironment,
      },
    });
  }

  handleNotificationClick(): void {
    try {
      if (!this.user?.id) {
        throw new Error('No user ID provided.');
      }

      this.notificationService.resetUserNotificationsCount(this.user.id);
    } catch (error) {
      console.error(error);
    }
  }

  private hasCorpSelected(path: string) {
    if (path.includes('corps')) {
      const pathArray = path.split('/');
      const corpId = pathArray[pathArray.indexOf('corps') + 1];

      if (corpId) {
        this.clientEnvironmentService.setClientEnvironmentListener(corpId);
      }
    }
  }

  private setOnHierarchyEl(path: string) {
    this.isOnHierarchyEl = path.includes('hierarchy-el');
  }

  private setOnBotDashboard(path: string) {
    this.isOnDashboard = path.includes('dashboard');
  }

  private sortNotificationsByTime(): UserNotificationModel[] {
    let sortedNotifications: UserNotificationModel[] = [];

    sortedNotifications = this.notifications
      .map(n => {
        if (!n.timeAgo) {
          Object.defineProperty(n, 'timeAgo', {
            get() {
              return moment(n.createdAt.toDate()).fromNow();
            },
            set() {
              //
            },
          });
        }
        return n;
      })
      .sort((a, b) => {
        return b.createdAt.toDate() - a.createdAt.toDate();
      });

    return sortedNotifications;
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    this.clientEnvironmentService.removeClientEnvironmentListener();

    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }
  }
}
