<ng-template [ngIf]="isLoading">
  <div class="d-flex mt-3 justify-content-center">
    <app-loading></app-loading>
  </div>
</ng-template>

<div class="card card-width" *ngIf="!isLoading">
  <div class="card-header border-0">
    <div class="row">
      <div class="col-6">
        <h3 class="mb-0">Intent Report</h3>
      </div>
      <div class="col-6 search-box-align" *ngIf="reportDetails && reportDetails.length && reportDetails.length > 0">
        <input type="text" placeholder="Search..." (keyup)="onSearch($event)" #input />
      </div>
    </div>
    <div class="row" *ngIf="reportDetails && reportDetails.length && reportDetails.length > 0">
      <mat-table [dataSource]="dataSource" matSort #intentSort="matSort">
        <ng-container matColumnDef="Intent">
          <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header> Conversation </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.Intent }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.length > 0 ? displayTotals[0].Intent : '* Totals *' }}
          </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="Count">
          <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header> Responses </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.Count.toLocaleString('en-US') }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.length > 0 ? displayTotals[0].Count.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="Pct">
          <mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header> % of Total </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.Pct | number: '1.2-2' }}% </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ (displayTotals && displayTotals.length > 0 ? displayTotals[0].Pct : 0.0) | number: '1.2-2' }}%
          </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let rows; columns: columnsToDisplay"></mat-row>
        <mat-footer-row *matFooterRowDef="columnsToDisplay; sticky: true"></mat-footer-row>
      </mat-table>
    </div>
    <div class="row" *ngIf="!reportDetails || reportDetails.length === 0">No Records Found.</div>
  </div>
</div>
