import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
// tslint:disable-next-line:max-line-length
import { ConversationLogFilter } from 'src/app/components/conversations/conversations/conversations-messages-search-filter/types/ConversationLogFilter';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { BotModel } from 'src/app/models/bot';
import { CorpModel } from 'src/app/models/corp';
import { getSidebarItems, getBreadcrumbItems } from '../../../../pages/portal/bot/utils';
import { HierarchyElementModel } from 'src/app/models';
import { BotsService, CorpsService, HierarchyElementsService } from 'src/app/services/firestore';
import { ConversationSummary } from '../../conversation/conversation-summary/_type/ConversationSummary';
import { UploadService } from '../../../../services/upload.service';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';

@Component({
  selector: 'app-conversations-messages',
  templateUrl: './conversations-messages.component.html',
  styleUrls: ['./conversations-messages.component.scss'],
})
export class ConversationsMessagesComponent implements OnInit, OnDestroy {
  private readonly defaultBotLogoPath = 'bots/_default/avatar.png';

  loading: boolean;

  selectedConversation: ConversationSummary | null;
  corp: CorpModel;
  hierarchyElement: HierarchyElementModel;
  bot: BotModel;
  awsRegion?: AwsRegionEnum;
  defaultBotName: string;
  defaultBotLogo: string;
  filter: ConversationLogFilter;

  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private hierarchyElementsService: HierarchyElementsService,
    private authService: AuthService,
    private botsService: BotsService,
    private corpsService: CorpsService,
    private uploadService: UploadService,
    private clientEnvironmentService: ClientEnvironmentService,
  ) {
    this.loading = true;
    this.filter = new ConversationLogFilter(false);
  }

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(
      ([params, user]) => {
        const corpId = params.get('corp');
        const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
        const botCode = params.get('bot');
        const conversationId = params.get('conversationId');
        if (!corpId || !hierarchyElementSystemName || !botCode || !user) {
          return;
        }
        if (conversationId) {
          this.filter = new ConversationLogFilter(true, conversationId);
        }
        this.crtDataSubscription = combineLatest([
          this.corpsService.getCorpById(corpId),
          this.hierarchyElementsService.getHierarchyElement(`${corpId}-${hierarchyElementSystemName}`),
          this.uploadService.getFileByPath(this.defaultBotLogoPath),
          this.clientEnvironmentService.items$,
        ]).subscribe(async ([corp, hierarchyElement, defaultBotLogo, envs]) => {
          if (!corp || !hierarchyElement || !defaultBotLogo || !envs) {
            return;
          }

          try {
            const bot = await this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, envs);
            if (bot) {
              this.bot = bot;
              this.awsRegion = bot.config.awsRegion;
            }
          } catch (error) {
            throw new Error('Valid bot config required.');
          }

          this.corp = corp;
          this.hierarchyElement = hierarchyElement;
          this.refreshUI();
          this.defaultBotName = 'Default Assistant Name';
          this.defaultBotLogo = defaultBotLogo;

          this.loading = false;
        });
      },
    );
  }

  refreshUI() {
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot.code);
    this.headerService.setPageTitle(`${this.bot.label} Conversation Logs`);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set(
      getBreadcrumbItems(corp, hierarchyElement, bot, 'Conversation Logs', 'conversation-logs'),
    );
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement, botCode));
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
