<div class="modal-header">
  <h4 class="modal-title">Filters</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pt-1">
  <div class="form-group">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fas fa-calendar"></i>
        </span>
      </div>
      <input
        type="text"
        class="form-control"
        bsDaterangepicker
        [(ngModel)]="bsRangeValue"
        [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue' }"
        name="bsDaterangepicker"
      />
    </div>
  </div>

  <div class="mr-1 dropdown" dropdown>
    <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" dropdownToggle>
      <i class="fas fa-chart-line"></i> KPI
    </button>
    <div aria-labelledby="dropdownMenuButton" *dropdownMenu class="dropdown-menu">
      <a class="dropdown-item" *ngFor="let kpi of kpis" href="javascript:void(0)">
        {{ kpi.name }}
      </a>
    </div>
  </div>

  <div class="mr-1 dropdown" dropdown>
    <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" dropdownToggle>
      <i class="fas fa-tags"></i> Tags
    </button>
    <div aria-labelledby="dropdownMenuButton" *dropdownMenu class="dropdown-menu">
      <a class="dropdown-item" *ngFor="let tag of tags" href="javascript:void(0)">
        {{ tag }}
      </a>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary btn-outline" type="submit" (click)="modal.hide()">Cancel</button>
  <button class="btn btn-primary btn-outline" type="submit">Apply</button>
</div>
