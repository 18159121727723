<div class=" row align-items-center py-4">
    <div class=" col-lg-6 col-7">
      <h6 class=" h2 d-inline-block mb-0">
        Topline Statistics
      </h6>
    </div>
    <div dropdown class="dropdown no-caret ml-auto mr-4" *ngIf="selectedInterval">
      <button
        dropdownToggle
        class="btn btn-sm btn-outline-primary bg-white mr-0 dropdown-toggle"
        id="button-basic"
        aria-haspopup="true"
        aria-controls="dropdown-basic"
        type="button"
      >
        <span>{{ selectedInterval.description }}</span>
        <i class=" fas fa-ellipsis-h"></i>
      </button>
      <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
        <a
          class="dropdown-item"
          *ngFor="let interval of intervals"
          href="javascript:void(0)"
          (click)="selectedInterval = interval"
        >
          {{ interval.description }}
        </a>
      </div>
    </div>
  </div>

  <div class="card-deck">
    <div class=" col-xl-3 col-md-6 px-0" *ngFor="let cardSettings of cardSettingsArray">
      <app-analytics-card
        [title]="cardSettings.title"
        [icon]="cardSettings.icon"
        [data]="cardSettings.data"
        [loading]="loading"
      >
      </app-analytics-card>
    </div>
  </div>
