import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ClientEnvironmentModel, StageModel, BotEnvironment } from '../models/client-environment';
import { EnvironmentService } from './firestore/environments.service';

@Injectable({
  providedIn: 'root',
})
export class ClientEnvironmentService {
  private clientEnvironmentSubscription: Subscription;
  private items: BehaviorSubject<ClientEnvironmentModel | null>;
  items$: Observable<ClientEnvironmentModel | null>;
  corpId: string;

  shouldShowEnvironmentDropdown = new Subject<boolean>();

  constructor(private environmentService: EnvironmentService) {
    this.items = new BehaviorSubject<ClientEnvironmentModel | null>(null);
    this.items$ = this.items.asObservable();
  }

  // TODO: This service requires corpId for many of the methods.
  // Currently it needs to get corpId set using this method. In
  // the future remove this coupling by giving access to
  // router corpId param to this service.
  setClientEnvironmentListener(corpId: string): void {
    // To avoid unnecessary repetitive calls, only call if this.corpId is false
    if ((!this.corpId && corpId) || this.corpId !== corpId) {
      this.corpId = corpId;
      this.clientEnvironmentSubscription = this.environmentService
        .getEnvironments(this.corpId)
        .subscribe(res => this.items.next(res));
    }
  }

  removeClientEnvironmentListener(): void {
    if (this.clientEnvironmentSubscription) {
      this.clientEnvironmentSubscription.unsubscribe();
    }
  }

  setSelectedStage(clientEnvironment: ClientEnvironmentModel): void {
    if (!this.corpId) {
      return;
    }
    this.items.next(clientEnvironment);
  }

  setInitialEnvironment(corpId: string): Promise<void> {
    const initialEnv = this.generateInitialEnvironment();
    return this.environmentService.setInitialEnvironment(corpId, initialEnv);
  }

  updateClientEnvironment(environment: ClientEnvironmentModel): Promise<void> {
    return this.environmentService.updateClientEnvironment(this.corpId, environment);
  }

  appendNewStage(name: string, environment: ClientEnvironmentModel): ClientEnvironmentModel {
    const newEnv: StageModel = {
      name,
      systemName: this.generateSystemName(name),
    };
    environment.stages.push(newEnv);

    return environment;
  }

  generateSystemName(name: string): BotEnvironment {
    return name.toLowerCase().trim().replace(/\s+/g, '-') as BotEnvironment;
  }

  generateInitialEnvironment(): ClientEnvironmentModel {
    const development = {
      name: 'Development',
      systemName: BotEnvironment.Development,
    };
    return {
      stages: [
        development,
        {
          name: 'Staging',
          systemName: BotEnvironment.Staging,
        },
        {
          name: 'Production',
          systemName: BotEnvironment.Production,
        },
      ],
      selectedStage: development,
    };
  }
}
