<ng-container *ngIf="loading">
    <div class=" container-fluid">
        <div class=" row align-items-center py-4 justify-content-center">
            <app-loading></app-loading>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!loading">
    <div class="container-fluid bot-settings-container">
        <div class="row h-100">
            <div class="col-md-12 col-sm-12 h-100 overflow-auto">
                <div class=" row align-items-center py-4">
                    <div class=" col-lg-12 col-12">
                        <button class="btn btn-primary btn-outline" (click)="showAddBotModal()" >Start from Scratch</button>
                    </div>
                </div>
            </div>
            <div class="card col-md-12 col-sm-12">
                <div class=" card-header">
                    <h3 class=" mb-0">Choose A Template From {{ corp?.label }}</h3>
                </div>
                <div class="card-body">
                    <app-render-template-groups
                        [templatesGroups]="availableCorpTemplatesGroups"
                    >
                    </app-render-template-groups>
                    <h4 *ngIf="availableCorpTemplatesGroups.length == 0"> No Templates Yet </h4>
                </div>
            </div>

            <div class="card col-md-12 col-sm-12" *ngIf="availableGlobalTemplatesGroups.length > 0">
                <div class=" card-header">
                    <h3 class=" mb-0">Choose A Global Template</h3>
                </div>
                <div class="card-body">
                    <app-render-template-groups
                        [templatesGroups]="availableGlobalTemplatesGroups"
                    >
                    </app-render-template-groups>
                </div>
            </div>
        </div>
    </div>
</ng-container>
