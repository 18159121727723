<div class="modal-header">
    <app-action-progress></app-action-progress>
    <h4 class="modal-title">Edit Hierarchy</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (submit)="save()" #form="ngForm" novalidate>
    <div class="modal-body">
      <div class=" form-group">
        <label class=" form-control-label" for="hierarchyName"> Name </label>
        <input class="form-control" id="hierarchyName" name="label" [(ngModel)]="corpHierarchy.label" type="text" placeholder="" required>
      </div>
      <div class=" form-group">
        <label class=" form-control-label" for="hierarchySingular"> Singular Form </label>
        <input class="form-control" id="hierarchySingular" name="singular" [(ngModel)]="corpHierarchy.singular" type="text" placeholder="" required>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary btn-outline" type="submit" [disabled]="!form.valid || loading">SAVE</button>
    </div>
  </form>