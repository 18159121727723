import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/compat/firestore';
import { NotificationStatus, UserNotificationModel } from 'src/app/models/notifications';
import { UserModel } from 'src/app/models/user';
import { COLLECTION_NAMES } from './constants';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationsService {
  private notificationsCollection: AngularFirestoreCollection<UserNotificationModel>;
  private notificationsCollectionQuery: (ref: QueryFn) => AngularFirestoreCollection<UserNotificationModel>;

  constructor(private afs: AngularFirestore, private usersService: UsersService) {
    this.notificationsCollection = afs.collection<UserNotificationModel>(COLLECTION_NAMES.NOTIFICATIONS);
    this.notificationsCollectionQuery = (ref: QueryFn) =>
      afs.collection<UserNotificationModel>(COLLECTION_NAMES.NOTIFICATIONS, ref);
  }

  getUserNotifications(userId: string) {
    return this.notificationsCollectionQuery(ref => ref.where('userId', '==', userId)).valueChanges();
  }

  resetUserNotificationsCount(userId: string): Promise<void> {
    const updates: Partial<UserModel> = { notificationSettings: { count: 0 } };
    return this.usersService.updateUser({ id: userId, ...updates } as unknown as UserModel);
  }

  markNotificationAsRead(notificationId: string): Promise<void> {
    return this.notificationsCollection.doc(notificationId).update({ status: NotificationStatus.Read });
  }
}
