import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
  cancelButtonText: string;
  confirmButtonText: string;
}

@Component({
  selector: 'app-cancel-confirm-modal',
  templateUrl: './cancel-confirm-modal.component.html',
  styleUrls: ['./cancel-confirm-modal.component.scss'],
})
export class CancelConfirmModalComponent {
  constructor(
    public dialogRef: MatDialogRef<CancelConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onCancel(): void {
    this.dialogRef.close('cancel');
  }

  onConfirm(): void {
    this.dialogRef.close('confirm');
  }
}
