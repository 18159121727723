import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  title: string;
  message: string;
  confirm: EventEmitter<void>;

  constructor(public modal: BsModalRef) {
    this.confirm = new EventEmitter<void>();
  }

  sendConfirmation() {
    this.confirm.emit();
    this.modal.hide();
  }
}
