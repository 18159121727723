import { Component, EventEmitter, OnDestroy, OnChanges, OnInit, Output, Input } from '@angular/core';
import { UsersService } from 'src/app/services/firestore';
import { UserModel, UserNotificationSettingsModel } from 'src/app/models/user';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, of, Subscription } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { UploadService } from 'src/app/services/upload.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import * as firebaseAuth from 'firebase/auth';
@Component({
  selector: 'app-profile-general-info',
  templateUrl: './profile-general-info.component.html',
  styleUrls: ['./profile-general-info.component.scss'],
})
export class ProfileGeneralInfoComponent implements OnInit, OnDestroy, OnChanges {
  loading: boolean;
  makingChangePasswordRequest: boolean;

  avatarFile: File | null;

  uploadSubscription: Subscription;

  @Output()
  saved: EventEmitter<void>;

  @Input()
  user: UserModel | null;

  @Output()
  canceled: EventEmitter<void>;

  notificationSettings: UserNotificationSettingsModel;

  changePasswordForm = this.fb.group({
    oldPassword: [null, [Validators.required]],
    newPassword: [null, [Validators.required]],
    reNewPassword: [null, [Validators.required]],
  });

  constructor(
    private usersService: UsersService,
    private uploadService: UploadService,
    private authService: AuthService,
    private toaster: ToastrService,
    private fb: FormBuilder,
  ) {
    this.saved = new EventEmitter<void>();
    this.canceled = new EventEmitter<void>();
  }

  async ngOnInit() {
    if (this.user) {
      if (this.updateUserNotificationSettings()) {
        return;
      }
    }
    this.notificationSettings = { email: false, in_app: false };
  }

  ngOnChanges() {
    if (this.user) {
      this.updateUserNotificationSettings();
    }
  }

  updateUserNotificationSettings() {
    if (this.user) {
      if (this.user.notificationSettings) {
        this.notificationSettings = { email: false, in_app: false };
        this.notificationSettings.email = this.user.notificationSettings.email;
        this.notificationSettings.in_app = this.user.notificationSettings.in_app;
        return true;
      }
    }
    return false;
  }

  save() {
    this.updateUser();
  }

  onUpdatePasswordSubmit() {
    this.updatePassword();
  }

  async updatePassword() {
    const { oldPassword, newPassword, reNewPassword } = this.changePasswordForm.value;
    if (newPassword !== reNewPassword) {
      return this.toaster.error("New password doesn't match");
    }
    if (newPassword === oldPassword) {
      return this.toaster.error("You can't change your password to the current one");
    }
    if (!this.changePasswordForm.valid) {
      return this.toaster.error('All fields are required');
    }
    try {
      this.makingChangePasswordRequest = true;
      const user = await this.authService.currentUser;
      if (!user) {
        return;
      }

      const credential = firebaseAuth.EmailAuthProvider.credential(`${user.email}`, oldPassword);
      const isAuthenticated = await user.reauthenticateWithCredential(credential);
      if (!isAuthenticated) {
        return this.toaster.error('Invalid Credentials');
      }
      await user.updatePassword(newPassword);
      this.changePasswordForm.reset();
      return this.toaster.success('Password Changed Successfully');
    } catch (error) {
      this.toaster.error(error);
    } finally {
      this.makingChangePasswordRequest = false;
    }
  }

  private updateUser() {
    if (!this.user) {
      return;
    }
    this.user.notificationSettings = this.notificationSettings;
    const uploadObservable: Observable<void | null> = this.avatarFile
      ? this.uploadService.upload('users/' + this.user.id + '/avatar', this.avatarFile).pipe(
          map(downloadUrl => {
            if (!this.user) {
              return;
            }
            this.user.avatar = downloadUrl;
          }),
        )
      : of(null);
    this.loading = true;
    this.uploadSubscription = uploadObservable
      .pipe(
        flatMap(async () => {
          if (!this.user) {
            return of();
          }
          return from([await this.usersService.updateUser(this.user)]);
        }),
      )
      .subscribe(
        () => {
          this.loading = false;
          this.toaster.success('Profile updated');
          this.saved.emit();
        },
        error => {
          this.loading = false;
          this.toaster.error(error);
        },
      );
  }

  ngOnDestroy() {
    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
    }
  }
}
