<ng-container *ngIf="messageSent">
  <ng-container *ngFor="let attachment of messageSent.attachments">
    <!-- List layout -->
    <ng-container *ngIf="messageSent.attachmentLayout == attachmentLayoutList || !messageSent.attachmentLayout; else compactList">
      <ng-container *ngFor="let button of attachment.buttons">
        <div class="bg-secondary text-center message border border-primary text-primary p-2 mb-2 ">
          {{ button.title }}
        </div>
      </ng-container>

      <ng-container *ngFor="let image of attachment.images">
        <img class="col-12 border-bottom mb-1" src="{{ image.url }}" />
      </ng-container>
    </ng-container>

    <!-- Compact List layout -->
    <ng-template #compactList>
      <ng-container *ngIf="messageSent.attachmentLayout == attachmentLayoutCompactList">
        <div class="row border-bottom mx-1">
          <img class="col-5" *ngIf="attachment.images?.length > 0" src="{{ attachment.images[0].url }}" />
          <div class="col-7 pt-2">
            <strong>{{ attachment.title }}</strong>
            <div>{{ attachment.subtitle }}</div>
            <div>{{ attachment.text }}</div>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
</ng-container>
