import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, take, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from 'src/app/services/error-handler/http-error-handler.service';
import { environment } from 'src/environments/environment';
import { NlpModelTrain } from 'src/app/models/nlp/nlp-model/nlp-model-train';
import { NlpDataset } from 'src/app/models/nlp/nlp-dataset/nlp-dataset';
import { NlpDatasetFile } from 'src/app/models/nlp/nlp-dataset/nlp-dataset-file';

(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  //@ts-ignore: Facebook SDK
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

@Injectable({
  providedIn: 'root'
})
export class FacebookService {
  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandler
  ) {}

  login(appId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      //@ts-ignore: FB login
      FB.init({
        appId      : appId,
        cookie     : true,
        xfbml      : true,
        version    : 'v6.0'
      });

      //@ts-ignore: FB login
      FB.login((response) => {
        if (response.status === 'connected') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  }

  // https://developers.facebook.com/docs/pages/access-tokens/
  async getPageAccessToken(response: object, appId: string, appSecret: string, pageId: string): Promise<object> {
    const accessToken = response['authResponse']['accessToken'];
    const userId = response['authResponse']['userID'];

    const longLivedUserAccessTokenUrl = `https://graph.facebook.com/oauth/access_token?
grant_type=fb_exchange_token&client_id=${appId}&
client_secret=${appSecret}&fb_exchange_token=${accessToken}`;

    const tokenResponse = await this.http
      .get<object>(longLivedUserAccessTokenUrl)
      .pipe(take(1)).toPromise();

    const longAccessToken = tokenResponse['access_token'];

    const pageAccessTokenUrl = `https://graph.facebook.com/${pageId}?
fields=access_token&access_token=${longAccessToken}`;

    return this.http
      .get<object>(pageAccessTokenUrl)
      .pipe(take(1)).toPromise();
  }

}
