import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BreadcrumbNodeVM } from 'src/app/components/breadcrumb/_types/BreadcrumbNodeVM';
import { BreadcrumbRootVM } from '../components/breadcrumb/_types/BreadcrumbRootVM';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private nodes: BehaviorSubject<BreadcrumbNodeVM[]>;
  nodes$: Observable<BreadcrumbNodeVM[]>;
  root: BehaviorSubject<BreadcrumbRootVM>;
  root$: Observable<BreadcrumbRootVM>;

  constructor() {
    this.nodes = new BehaviorSubject<BreadcrumbNodeVM[]>([]);
    this.nodes$ = this.nodes.asObservable();
    this.root = new BehaviorSubject<BreadcrumbRootVM>({
      icon: 'home',
      route: '/'
    });
    this.root$ = this.root.asObservable();
  }

  push(node: BreadcrumbNodeVM) {
    this.nodes.getValue().push(node);
    this.nodes.next(this.nodes.getValue().slice());
  }

  pop() {
    this.nodes.getValue().pop();
    this.nodes.next(this.nodes.getValue().slice());
  }

  slice(count: number) {
    this.nodes.next(this.nodes.getValue().slice(0, count));
  }

  reset() {
    this.nodes.next([]);
  }

  set(nodes: BreadcrumbNodeVM[]) {
    this.nodes.next(nodes);
  }
}
