<div class="modal-header">
    <h4 class="modal-title">Add a New Input Validation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-input-validation-general-info
      [writable]="true"
      [inputValidation]="inputValidation"
      [shouldAdd]="true"
      (saved)="this.modal.hide()"
    >
    </app-input-validation-general-info>
  </div>