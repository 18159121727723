import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { HLConversationModel, HLTemplateModel, HLUserResponseChannels } from 'src/app/models/conversations';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HlConversationTemplatesComponent } from '../../human-in-the-loop/hl-conversation-templates/hl-conversation-templates.component';
import { Subscription } from 'rxjs';
import { HumanInLoopService } from 'src/app/services/firestore/human-in-loop.service';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HlConversationTagsComponent } from '../../human-in-the-loop/hl-conversation-tags/hl-conversation-tags.component';
import { BotConfigService } from 'src/app/services/bot-config.service';
import { ClientEnvironmentModel } from 'src/app/models/client-environment';
import { RoleModel, UserModel } from 'src/app/models';
// tslint:disable-next-line: max-line-length
import { HlConversationAssignUserModalComponent } from '../../human-in-the-loop/hl-conversation-assign-user-modal/hl-conversation-assign-user-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TemplateChannels } from 'src/app/models/template';
import { AddNodeKpiModalComponent } from 'src/app/components/modals/add-node-kpi-modal/add-node-kpi-modal.component';
import { HlCustomerSettingsModalComponent } from '../../human-in-the-loop/hl-customer-settings-modal/hl-customer-settings-modal.component';
import { CustomerSettingsModel } from 'src/app/models/CustomerSettingsModel';
import { DoNotContact, DumbledoreService } from 'src/app/services/dumbledore.service';
import { BachService, SendMessagePayload } from 'src/app/services/bach.service';
@Component({
  selector: 'app-hl-messages-list',
  templateUrl: './hl-messages-list.component.html',
  styleUrls: ['./hl-messages-list.component.scss'],
})
export class HlMessagesListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedConversation: HLConversationModel;
  @Input() selectedConversationId: string;
  @Input() corpId: string;

  @Input() corpTags: string[];

  @Input() templates: HLTemplateModel[];

  @Input() envs: ClientEnvironmentModel;

  @Input() supportRoles: RoleModel[];

  inputMessage = '';
  subjectInput = '';
  clickedTemplateSubscription: Subscription;
  deleteTemplateSubscription: Subscription;
  saveConversationTagSubscription: Subscription;
  saveTemplateSubscription: Subscription;
  closeTemplatesSubscription: Subscription;
  selectedSettingsSubscription: Subscription;
  templatesModalRef: BsModalRef | null;
  newMessageSubscription: Subscription;
  channelName: HLUserResponseChannels = 'email';
  currentUser: UserModel;
  selectedToEmail: string | null = null;
  selectedFromEmail: { name: string; address: string } | null = null;
  toEmailList: string[] | undefined;
  fromEmailList: { name: string; address: string }[] | undefined;
  bot: any;
  hideFooter: boolean = false;
  userSelectedChannel: HLUserResponseChannels = 'email'; // default to email until after mvp

  editorConfig: AngularEditorConfig = {
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    editable: true,
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    uploadWithCredentials: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'strikeThrough',
        'redo',
        'superscript',
        'subscript',
        'backgroundColor',
        'textColor',
        'insertVideo',
        'insertImage',
      ],
      ['fontSize', 'fontName'],
    ],
  };

  private latestVersion = false;

  constructor(
    public humanInLoopService: HumanInLoopService,
    private modalService: BsModalService,
    private toasterService: ToastrService,
    private authService: AuthService,
    private dumbledoreService: DumbledoreService,
    private bachService: BachService,
    private botConfigService: BotConfigService,
  ) {}

  async ngOnInit() {
    this.hideFooter = true;
    try {
      const user = await this.authService.getCurrentUserProfile();
      if (!user) {
        throw new Error('No user profile found');
      }
      this.currentUser = user;
      this.toEmailList = undefined;
      this.fromEmailList = undefined;
      this.addUserToEmailList(this.currentUser);
      if (this.selectedConversation.botId) {
        this.latestVersion = await this.botConfigService.isLastestVersion(this.selectedConversation.botId);
        this.bot = await this.botConfigService.getBotConfigById(this.selectedConversation.botId);
      } else {
        this.latestVersion = false;
      }
    } catch (error) {
      console.error('Error in HL Messages List', error);
    }
    this.hideFooter = false;
  }

  ngOnDestroy() {
    if (this.newMessageSubscription) {
      this.newMessageSubscription.unsubscribe();
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.currentUser) {
      const user = await this.authService.getCurrentUserProfile();
      if (!user) {
        throw new Error('No user profile found');
      }
      this.currentUser = user;
    }
    if (changes.selectedConversationId?.currentValue) {
      const conversationId = changes.selectedConversationId.currentValue;
      this.selectedToEmail = null;
      this.selectedFromEmail = null;
      if (this.selectedConversation.toEmailAddresses) {
        // default 'To' email to the first email
        this.selectedToEmail = this.selectedConversation.toEmailAddresses[0];
      }
      if (this.selectedConversation.fromEmails) {
        // default 'From' email to the first email
        const validEmails = this.selectedConversation.fromEmails.filter(email => email.address);
        this.selectedFromEmail = validEmails[0];
      }

      const botId = this.selectedConversation?.botId;
      const lastReceivedChannel = this.selectedConversation.lastChannel ?? TemplateChannels.Email;
      if (lastReceivedChannel) {
        this.channelName = lastReceivedChannel;
        this.userSelectedChannel = lastReceivedChannel;
      } else {
        this.channelName = 'email';
        this.userSelectedChannel = 'email';
      }

      this.corpId = changes.selectedConversation.currentValue.botId
        ? changes.selectedConversation.currentValue.botId.split('-')[0]
        : changes.selectedConversation.currentValue.corpId;

      this.humanInLoopService.clearData('messages');
      this.humanInLoopService.listenToMessages(
        this.corpId,
        changes.selectedConversation.currentValue.firebaseDocumentId,
      );

      if (this.newMessageSubscription) {
        this.newMessageSubscription.unsubscribe();
      }
      this.newMessageSubscription = this.humanInLoopService.hasNewMessages.subscribe(value => {
        if (value) {
          setTimeout(() => {
            this.scrollToBottom();
          }, 100);
        }
      });
      this.inputMessage = '';

      this.setLastMessageSubjectLine(this.corpId, conversationId);
    }
    this.toEmailList = undefined;
    this.fromEmailList = undefined;
    this.addUserToEmailList(this.currentUser);
  }

  addUserToEmailList(user: UserModel) {
    if (!this.toEmailList) {
      this.toEmailList = this.selectedConversation.toEmailAddresses;
    }
    if (!this.fromEmailList) {
      this.fromEmailList = this.selectedConversation.fromEmails;
    }
    const email = user ? (user.carLabsEmail ? user.carLabsEmail : user.email) : null;
    if (this.fromEmailList && email) {
      const index = this.fromEmailList.findIndex(j => j.address === email);
      if (index === -1) {
        this.fromEmailList?.push({ name: user.fullName, address: email });
      }
    }
    if (this.toEmailList && email) {
      const index = this.toEmailList.findIndex(j => j === email);
      if (index === -1) {
        this.toEmailList?.push(user.email);
      }
    }
  }

  showTemplate() {
    const modalRef = this.modalService.show(HlConversationTemplatesComponent, {
      initialState: {
        templates: this.templates ?? [],
        corpTags: this.corpTags,
      },
    });

    this.templatesModalRef = modalRef;

    if (this.saveTemplateSubscription) {
      this.saveTemplateSubscription.unsubscribe();
    }

    this.saveTemplateSubscription = (modalRef.content as HlConversationTemplatesComponent).savedTemplate.subscribe(
      (template: HLTemplateModel) => {
        template.botId = this.selectedConversation.botId;
        this.saveTemplate(template);
      },
    );

    if (this.clickedTemplateSubscription) {
      this.clickedTemplateSubscription.unsubscribe();
    }

    this.clickedTemplateSubscription = modalRef.content.clickedTemplate.subscribe((template: HLTemplateModel) => {
      modalRef.hide();
      const isEmailConversation =
        this.selectedConversation &&
        this.selectedConversation.lastChannel &&
        this.selectedConversation.lastChannel === 'email';

      if (isEmailConversation) {
        this.inputMessage = template.content;
      } else {
        // convert html to plain text
        const span = document.createElement('span');
        span.innerHTML = template.content;
        const plainText = span.textContent || span.innerText;
        this.inputMessage = plainText;
      }
    });

    if (this.deleteTemplateSubscription) {
      this.deleteTemplateSubscription.unsubscribe();
    }

    this.deleteTemplateSubscription = modalRef.content.deleteTemplate.subscribe((template: HLTemplateModel) => {
      this.deleteTemplate(template);
    });

    if (this.closeTemplatesSubscription) {
      this.closeTemplatesSubscription.unsubscribe();
    }

    this.closeTemplatesSubscription = modalRef.content.close.subscribe(() => {
      this.templatesModalRef = null;
    });
  }

  showTags() {
    if (this.selectedConversation) {
      const modalRef = this.modalService.show(HlConversationTagsComponent, {
        initialState: {
          corpTags: this.corpTags,
          tags: this.selectedConversation?.tags ?? [],
        },
      });

      if (this.saveConversationTagSubscription) {
        this.saveConversationTagSubscription.unsubscribe();
      }

      this.saveConversationTagSubscription = modalRef.content.save.subscribe(tags => {
        this.saveConversationTags(tags);
      });
    }
  }

  showAssignedUsers() {
    if (this.selectedConversationId && this.selectedConversation) {
      const conversationBotId = this.selectedConversation.botId;
      const botCode = this.getBotCodeFromBotFullId(conversationBotId);
      this.humanInLoopService
        .getBotUsers(this.corpId, botCode, this.currentUser)
        .then(conversationBotUsers => {
          const users = conversationBotUsers.filter(u => {
            // check user has HIL Support Role
            if (this.supportRoles) {
              return this.supportRoles.filter(role => role.systemName === u.role).length > 0;
            }
            return true;
          });
          let assignedUsersIds: string[] = [];
          if (this.selectedConversation.firebaseDocumentId) {
            // load the latest version of the selected conversation
            const conversation = this.humanInLoopService.getConversationsFromMemoryById(
              this.selectedConversation.firebaseDocumentId,
            );
            if (conversation && conversation.assignments) {
              assignedUsersIds = conversation.assignments.map(agent => {
                return agent.agentId;
              });
            } else if (this.selectedConversation.assignments) {
              assignedUsersIds = this.selectedConversation.assignments.map(agent => {
                return agent.agentId;
              });
            }
          }

          const modalRef = this.modalService.show(HlConversationAssignUserModalComponent, {
            initialState: {
              users,
              assignedUsers: users.filter(user => assignedUsersIds.includes(user.id)),
            },
          });

          modalRef.content.save.pipe(take(1)).subscribe(async (user: UserModel) => {
            modalRef.hide();
            const assignedBy =
              this.currentUser !== null ? `${this.currentUser.firstName} ${this.currentUser.lastName}` : '';
            this.assignConversationToUser(user, assignedBy);
          });

          modalRef.content.removeUser.pipe(take(1)).subscribe(async (user: UserModel) => {
            modalRef.hide();
            const assignedBy =
              this.currentUser !== null ? `${this.currentUser.firstName} ${this.currentUser.lastName}` : '';
            this.removeUser(user, assignedBy);
          });
        })
        .catch(error => {
          console.log('Error: ', error);
        });
    }
  }

  setToEmail(email: string) {
    this.selectedToEmail = email;
  }
  setFromEmail(email: string, name: string) {
    this.selectedFromEmail = { address: email, name };
  }
  /* We should have one type for channels, but since we don't this keep the UI happy */
  setSendChannel(channel: 'email' | 'sms') {
    this.userSelectedChannel = channel;
  }

  async sendMessage() {
    if (!this.currentUser) {
      await this.getAndSetUser();
    }

    const canSendMessage = this.currentUser && this.inputMessage && this.selectedConversation;

    if (canSendMessage) {
      if (this.userSelectedChannel === 'email') {
        this.channelName = 'email';
        this.sendEmail();
      }
      if (this.userSelectedChannel === 'sms') {
        this.channelName = 'sms';
        this.sendSMS();
      }
    }
  }

  /** Fetches user then sets the current user to the response */
  async getAndSetUser() {
    const user = await this.authService.getCurrentUserProfile();
    if (user) {
      this.currentUser = user;
    }
  }

  /** Gets critical info to send a message with any channel */
  parseConversation() {
    const botId = this.selectedConversation.botId;
    const userId = this.currentUser?.id;

    const conversationId = this.selectedConversation?.firebaseDocumentId;
    if (!conversationId) {
      console.log('No Conversation Id');
      return undefined;
    }

    return {
      dealer: true,
      botId,
      conversationId,
      userId,
    };
  }

  async sendWithDumbledore(args: SendMessagePayload) {
    await this.dumbledoreService
      .sendConversationMessage(args)
      .catch(_error => {
        this.toasterService.error('Could not send message. Try again.');
      })
      .then(_response => {
        this.toasterService.success('Message sent');
        this.inputMessage = '';
      });
  }

  async sendSMS() {
    this.hideFooter = true;
    const sharedAttrs = this.parseConversation();
    if (!sharedAttrs) {
      console.log('No Shared Id');
      this.hideFooter = false;
      return;
    }
    const { botId } = sharedAttrs;
    const bot: any = await this.botConfigService.getBotConfigById(botId);

    await this.humanInLoopService.touchConversation(this.corpId, this.selectedConversationId);
    this.selectedConversation.touched = true;
    const sms = await this.dumbledoreService.getCustomerByConversationId(this.selectedConversationId);
    if (bot.sms?.smsNumber && sms?.user?.sms) {
      const result = await this.bachService.sendConversationMessage({
        ...sharedAttrs,
        channel: 'sms',
        fromNumber: bot.sms.smsNumber,
        message: this.inputMessage,
        toNumber: `+1${sms.user.sms}`,
      });
      this.inputMessage = '';
      this.subjectInput = '';
      this.toasterService.success('Text message sent.');
    } else {
      if (!bot.sms || !bot.sms.smsNumber) {
        this.toasterService.error('Bot SMS Number is not available.');
        console.log('Bot SMS Number... Is Not Available');
      }
      if (!this.selectedConversation || !this.selectedConversation.userSMS) {
        this.toasterService.error('User SMS is not available.');
        console.log(`User SMS... Is Not Available for Conversation:${this.selectedConversation.id}`);
      }
    }
    this.hideFooter = false;
  }

  async sendEmail() {
    this.hideFooter = true;
    const sharedAttrs = this.parseConversation();
    if (!sharedAttrs) {
      this.hideFooter = false;
      return;
    }

    let toEmail = this.selectedToEmail;
    if (!toEmail) {
      if (this.selectedConversation.toEmailAddresses) {
        // default to the first email
        toEmail = this.selectedConversation.toEmailAddresses[0];
      }
    }
    let fromEmail = this.selectedFromEmail;
    if (!fromEmail) {
      if (this.selectedConversation.fromEmails) {
        // default to the first email
        fromEmail = this.selectedConversation.fromEmails[0];
      }
    }

    await this.humanInLoopService.touchConversation(this.corpId, this.selectedConversationId);
    this.selectedConversation.touched = true;
    this.bachService.sendConversationMessage({
      ...sharedAttrs,
      channel: 'email',
      fromEmail,
      message: this.inputMessage,
      subject: this.subjectInput,
      toEmail,
    });
    this.inputMessage = '';
    this.subjectInput = '';
    this.toasterService.success('Email message sent.');
    this.hideFooter = false;
  }

  async selectNode() {
    if (!this.selectedConversation) {
      return;
    }

    if (!this.currentUser) {
      const user = await this.authService.getCurrentUserProfile();
      if (user) {
        this.currentUser = user;
      }
    }
    if (!this.currentUser) {
      return;
    }

    if (this.selectedConversationId) {
      const modalRef = this.modalService.show(HlCustomerSettingsModalComponent, {
        initialState: {
          conversation_id: this.selectedConversationId,
          bot: this.bot,
        },
      });

      if (this.selectedSettingsSubscription) {
        this.selectedSettingsSubscription.unsubscribe();
      }

      this.selectedSettingsSubscription = modalRef.content.selectedSettings.subscribe(
        ({ newCustomerSettings, originalCustomerSettings, currentCustomer }) => {
          this.updateCustomerSettings(
            currentCustomer.customer_id,
            currentCustomer.legacy_customer_id,
            newCustomerSettings,
            originalCustomerSettings,
            modalRef,
            currentCustomer.email,
            this.selectedConversationId,
          );
        },
      );
    } else {
      this.toasterService.error('Failed to load this conversation');
    }
  }

  getDate(value): Date {
    try {
      if ('toDate' in value) {
        return value.toDate();
      }
      if ('seconds' in value && 'nanoseconds' in value) {
        const milliseconds = value.seconds * 1000 + value.nanoseconds / 1000000;
        return new Date(milliseconds);
      }
    } catch (error) {
      return new Date();
    }
  }

  async updateCustomerSettings(
    customerId: number,
    userId: string,
    newCustomerSettings: CustomerSettingsModel,
    originalCustomerSettings: CustomerSettingsModel,
    modalRef: BsModalRef,
    email: string,
    conversationId: string,
  ) {
    if (conversationId) {
      const botId = this.selectedConversation.botId;
      const corpId = botId.split('-')[0];
      const botConfig = await this.botConfigService.getBotConfigById(botId);

      let updated = false;
      try {
        const doNotContact: { channelCode: string; type: DoNotContact; value: boolean }[] = [];
        if (newCustomerSettings.doNotContact !== originalCustomerSettings.doNotContact && email) {
          doNotContact.push({ channelCode: 'EMAIL', type: DoNotContact.DMS, value: newCustomerSettings.doNotContact });
        }

        if (newCustomerSettings.doNotContactInternal !== originalCustomerSettings.doNotContactInternal && email) {
          doNotContact.push({
            channelCode: 'EMAIL',
            type: DoNotContact.INTERNAL,
            value: newCustomerSettings.doNotContactInternal,
          });
        }

        if (newCustomerSettings.doNotContactSMS !== originalCustomerSettings.doNotContactSMS) {
          doNotContact.push({ channelCode: 'SMS', type: DoNotContact.DMS, value: newCustomerSettings.doNotContactSMS });
        }

        if (newCustomerSettings.doNotContactInternalSMS !== originalCustomerSettings.doNotContactInternalSMS) {
          doNotContact.push({
            channelCode: 'SMS',
            type: DoNotContact.INTERNAL,
            value: newCustomerSettings.doNotContactInternalSMS,
          });
        }

        if (newCustomerSettings.doNotContactFederal !== originalCustomerSettings.doNotContactFederal) {
          doNotContact.push({
            channelCode: 'SMS',
            type: DoNotContact.FEDERAL_DNC,
            value: newCustomerSettings.doNotContactFederal,
          });
        }

        if (newCustomerSettings.doNotContactLitigator !== originalCustomerSettings.doNotContactLitigator) {
          doNotContact.push({
            channelCode: 'SMS',
            type: DoNotContact.KNOWN_LITIGATOR,
            value: newCustomerSettings.doNotContactLitigator,
          });
        }

        if (doNotContact?.length > 0) {
          const res = await this.dumbledoreService.setDoNotContact(customerId, conversationId, doNotContact);
          updated = true;
        }

        if (updated) {
          if (!newCustomerSettings.stopCurrentSeries && !originalCustomerSettings.stopCurrentSeries) {
            newCustomerSettings.stopCurrentSeries = true;
          }

          await this.humanInLoopService.addSetNodeMessage(
            corpId,
            conversationId,
            'DNC status was changed.',
            this.currentUser.fullName,
          );

          setTimeout(() => {
            console.log('Waiting for the DNC update to finish.');
          }, 500);
        }

        updated = false;
        let count = 0;
        if (newCustomerSettings.stopCurrentSeries && !originalCustomerSettings.stopCurrentSeries) {
          while (!updated && count < 10) {
            count++;
            try {
              const res = await this.dumbledoreService.stopCampaignProgress(conversationId);
              updated = res !== null && res.status !== undefined && res.status === '200';
            } catch {
              updated = false;
            }
          }
        }

        if (updated) {
          await this.humanInLoopService.addSetNodeMessage(
            corpId,
            conversationId,
            'stopped the current series',
            this.currentUser.fullName,
          );
          this.toasterService.success('The selected conversation was stopped.');
          setTimeout(() => {
            console.log('Waiting for the series');
          }, 500);
        }

        updated = false;
        count = 0;

        if (newCustomerSettings.inactive !== originalCustomerSettings.inactive && this.bot.botType !== 'SALES') {
          while (!updated && count < 10) {
            count++;
            try {
              const res = await this.dumbledoreService.setVehicleInactive(conversationId, newCustomerSettings.inactive);
              updated = res !== null && res.status !== undefined && res.status === '200';
            } catch {
              updated = false;
            }
          }
        }

        if (updated) {
          if (newCustomerSettings.inactive) this.toasterService.success('The selected vehicle was updated - inactive.');
          else this.toasterService.success('The selected vehicle was updated - active.');
          await this.humanInLoopService.addSetNodeMessage(
            corpId,
            conversationId,
            newCustomerSettings.inactive ? 'set the vehicle status to sold.' : 'set the vehicle status to owned.',
            this.currentUser.fullName,
          );
          setTimeout(() => {
            console.log('Waiting for the DNC update to finish.');
          }, 500);
        }

        updated = false;
        count = 0;

        if (newCustomerSettings.sms) {
          newCustomerSettings.sms = newCustomerSettings.sms.replace(' ', '').replace('-', '').replace(/\(|\)/g, '');
        }

        if (newCustomerSettings.phone) {
          newCustomerSettings.phone = newCustomerSettings.phone.replace(' ', '').replace('-', '').replace(/\(|\)/g, '');
        }

        if (
          newCustomerSettings.firstName !== originalCustomerSettings.firstName ||
          newCustomerSettings.lastName !== originalCustomerSettings.lastName ||
          newCustomerSettings.sms !== originalCustomerSettings.sms ||
          newCustomerSettings.phone !== originalCustomerSettings.phone ||
          newCustomerSettings.email !== originalCustomerSettings.email ||
          newCustomerSettings.channel !== originalCustomerSettings.channel
        ) {
          while (!updated && count < 10) {
            count++;
            try {
              const res = await this.dumbledoreService.updateCustomerSettings(userId, newCustomerSettings);

              updated = res !== null && res.status !== undefined && res.status === '200';
            } catch (error) {
              console.log(error);
              updated = false;
            }
          }

          if (updated) {
            this.toasterService.success('The customer was updated.');
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              'Updated Customer',
              this.currentUser.fullName,
            );
          }

          if (newCustomerSettings.firstName !== originalCustomerSettings.firstName)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `changed first name from ${originalCustomerSettings.firstName} to ${newCustomerSettings.firstName} .`,
              this.currentUser.fullName,
            );

          if (newCustomerSettings.lastName !== originalCustomerSettings.lastName)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `changed last name from ${originalCustomerSettings.lastName} to ${newCustomerSettings.lastName} .`,
              this.currentUser.fullName,
            );

          if (newCustomerSettings.sms !== originalCustomerSettings.sms)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `changed SMS from ${originalCustomerSettings.sms} to ${newCustomerSettings.sms} .`,
              this.currentUser.fullName,
            );

          if (newCustomerSettings.email !== originalCustomerSettings.email)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `changed email from ${originalCustomerSettings.email} to ${newCustomerSettings.email} .`,
              this.currentUser.fullName,
            );

          if (newCustomerSettings.phone !== originalCustomerSettings.phone)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `changed phone from ${originalCustomerSettings.phone} to ${newCustomerSettings.phone} .`,
              this.currentUser.fullName,
            );

          if (newCustomerSettings.channel !== originalCustomerSettings.channel)
            await this.humanInLoopService.addSetNodeMessage(
              corpId,
              conversationId,
              `changed Preferred conctact from ${originalCustomerSettings.channel} to ${newCustomerSettings.channel} .`,
              this.currentUser.fullName,
            );
        }

        await this.humanInLoopService.touchConversation(corpId, conversationId);

        modalRef.hide();
        return this.toasterService.success('Customer records were updated successfully.');
      } catch (error) {
        modalRef.hide();
        return this.toasterService.error('Could update customer records.');
      }
    }

    this.toasterService.error('Something went wrong... please try again later.');
  }

  showKpisModal(message) {
    this.modalService.show(AddNodeKpiModalComponent, {
      initialState: {
        conversation: this.selectedConversation,
        message,
        corpId: this.corpId,
      },
    });
  }

  handleScroll($scrollEvent: 'bottom' | 'top') {
    if ($scrollEvent === 'top') {
      this.humanInLoopService.loadMoreMessages(this.corpId, this.selectedConversationId);
    }
  }

  scrollToBottom() {
    const messagesDiv = document.getElementById('hil-conversation-messages-list');
    if (messagesDiv) {
      messagesDiv.scroll({ top: messagesDiv.scrollHeight, behavior: 'smooth' });
    }
  }

  private getBotCodeFromBotFullId(botFullId: string) {
    const parts = botFullId.split('-');
    let botCode = parts[parts.length - 1];
    if (this.envs && this.envs.stages) {
      const env = this.envs.stages.filter(e => {
        const suffix = `_${e.systemName}`;
        return botCode.endsWith(suffix);
      });

      if (env.length > 0) {
        const suffix = `_${env[0].systemName}`;
        botCode = botCode.slice(0, botCode.length - suffix.length);
      }
    }
    return botCode;
  }

  private async assignConversationToUser(user: UserModel, assignedBy: string) {
    if (this.selectedConversation) {
      try {
        const botId = this.selectedConversation.botId;
        if (!this.selectedConversation?.firebaseDocumentId) {
          throw new Error('No firebaseDocumentId found');
        }

        await this.humanInLoopService.addAgentToConversation(
          this.selectedConversation.botId.split('-')[0],
          user,
          this.selectedConversation.firebaseDocumentId,
        );

        await this.humanInLoopService.touchConversation(this.corpId, this.selectedConversationId);
        this.selectedConversation.touched = true;
        this.toasterService.success('Conversation assigned successfully.');
      } catch (error) {
        this.toasterService.error('Could not assign conversation to user.');
      }
    }
  }

  private async removeUser(user: UserModel, assignedBy: string) {
    if (!this.selectedConversation) {
      return;
    }
    try {
      const botId = this.selectedConversation.botId;
      const conversationId = this.selectedConversation.firebaseDocumentId;

      if (!conversationId) {
        throw new Error('No conversationId found');
      }

      await this.humanInLoopService.removerAgentFromConversation(botId.split('-')[0], user, conversationId);
      this.toasterService.success('Agent removed from conversation.');
    } catch (error) {
      this.toasterService.error('Could not remove agent from conversation.');
    }
  }

  private async saveConversationTags(tags) {
    if (!this.selectedConversation) {
      return;
    }

    try {
      if (!this.selectedConversation.firebaseDocumentId) {
        throw new Error('No firebaseDocumentId found');
      }
      this.selectedConversation.tags = tags;
      await this.humanInLoopService.saveConversationTags(
        this.corpId,
        this.selectedConversation.firebaseDocumentId,
        tags,
      );
      await this.humanInLoopService.touchConversation(this.corpId, this.selectedConversation.id);
      this.selectedConversation.touched = true;
    } catch (error) {
      this.toasterService.error('Could not save conversation tags. Try again.');
    }
  }

  private deleteTemplate(template: HLTemplateModel) {
    this.humanInLoopService
      .deleteCorpHLTemplate(this.corpId, template)
      .then(() => {
        this.toasterService.success('Template deleted successfully.');
      })
      .catch(error => {
        this.toasterService.error('Could not delete template.');
      });
  }

  private saveTemplate(template: HLTemplateModel) {
    this.humanInLoopService
      .createCorpHLTemplate(this.corpId, template)
      .then(() => {
        this.toasterService.success('Template saved successfully.');
      })
      .catch(error => {
        this.toasterService.error('Could not save template.');
      });
  }

  private async setLastMessageSubjectLine(corpId: string, conversationId: string) {
    const lastUserSentMessage = await this.humanInLoopService.getLastUserSentMessageInConversation(
      corpId,
      conversationId,
    );
    this.subjectInput = lastUserSentMessage?.emailSubject || '';
  }
}
