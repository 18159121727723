import { Component, Input, OnInit } from '@angular/core';
import { TimeInterval } from '../../_types/TimeInterval';
import { IAnalyticsCardData } from '../analytics-card/_types/AnalyticsCardData';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { AnalyticsCardInterval1D } from 'src/app/components/analytics/analytics-cards/analytics-card/_types/AnalyticsCardInterval1D';
import { AnalyticsCardInterval7D } from 'src/app/components/analytics/analytics-cards/analytics-card/_types/AnalyticsCardInterval7D';
import { AnalyticsCardInterval30D } from 'src/app/components/analytics/analytics-cards/analytics-card/_types/AnalyticsCardInterval30D';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { ToplineStatisticsFields } from 'src/app/services/api-gateway-analytics/types';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-analytics-cards-topline-stats',
  templateUrl: './analytics-cards-topline-stats.component.html',
  styleUrls: ['./analytics-cards-topline-stats.component.scss']
})
export class AnalyticsCardsToplineStatsComponent implements OnInit {
  @Input()
  corpsId: string[];

  @Input()
  botId: string;

  @Input()
  hierarchyElementId: string;

  @Input()
  awsRegion: AwsRegionEnum = AwsRegionEnum.US_EAST_1;

  cardSettingsArray: Array<{
    title: string;
    data: IAnalyticsCardData | null;
    key: ToplineStatisticsFields;
    icon: {
      bgClasses: string[];
      iconClasses: string[];
    };
  }> = [
    {
      title: 'Total Traffic',
      data: null,
      key: ToplineStatisticsFields.TotalTraffic,
      icon: {
        bgClasses: ['bg-gradient-red'],
        iconClasses: ['ni-active-40']
      }
    },
    {
      title: 'Unique Users Volume',
      data: null,
      key: ToplineStatisticsFields.UniqueUsersVolume,
      icon: {
        bgClasses: ['bg-gradient-orange'],
        iconClasses: ['ni-chart-pie-35']
      }
    },
    {
      title: "Kpi's",
      data: null,
      key: ToplineStatisticsFields.Kpis,
      icon: {
        bgClasses: ['bg-gradient-green'],
        iconClasses: ['ni-money-coins']
      }
    },
    {
      title: "Kpi's Performance",
      data: null,
      key: ToplineStatisticsFields.KpisPerformance,
      icon: {
        bgClasses: ['bg-gradient-blue'],
        iconClasses: ['ni-money-coins']
      }
    }
  ];

  loading = false;

  public intervals: TimeInterval[] = [
    new AnalyticsCardInterval1D(),
    new AnalyticsCardInterval7D(),
    new AnalyticsCardInterval30D()
  ];
  private _selectedInterval: TimeInterval;

  public set selectedInterval(interval: TimeInterval) {
    this._selectedInterval = interval;
    if (!this.cardSettingsArray) {
      return;
    }
    this.fetchAnalyticsData();
  }

  public get selectedInterval(): TimeInterval {
    return this._selectedInterval;
  }

  constructor(private apiGatewayAnalyticsService: ApiGatewayAnalyticsService, private toaster: ToastrService) {}

  ngOnInit() {
    this.selectedInterval = this.intervals[1];
    this.fetchAnalyticsData();
  }

  private async fetchAnalyticsData() {
    if (this.loading) { // Rejecting some unfindable double triggering
      return;
    }
    if (!(this.corpsId || this.botId || this.hierarchyElementId)) {
      return;
    }
    try {
      this.loading = true;
      const response = await this.apiGatewayAnalyticsService.getToplineStatistics({
        corpsId: this.corpsId,
        botId: this.botId,
        hierarchyElementId: this.hierarchyElementId,
        interval: this._selectedInterval.getType()
      });

      this.cardSettingsArray = this.cardSettingsArray.map(cardSettings => ({
        ...cardSettings,
        data: { ...response[cardSettings.key], explanation: this.selectedInterval.getExplanation() }
      }));
    } catch (error) {
      this.toaster.error(error);
    } finally {
      this.loading = false;
    }
  }
}
