import { Component, OnInit, OnChanges, SimpleChange, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiGatewayAnalyticsService as AnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { AnalyticsDashboardCards, AnalyticsDashboardData } from 'src/app/services/api-gateway-analytics/types';

const initialState: AnalyticsDashboardData = {
  cards: {
    totalOutreach: {
      count: '0',
      variation: {
        monthly: '0%',
      },
    },
    engagements: {
      count: '0',
      variation: {
        monthly: '0%',
      },
    },
    messagesSent: {
      count: '0',
      variation: {
        monthly: '0%',
      },
    },
    triage: {
      count: '0',
      variation: {
        monthly: '0%',
      },
    },
    engagementPercent: {
      count: '0',
      variation: {
        monthly: '0%',
      },
    },
  },
  chart: {},
  tables: {
    totalOutreach: {},
    productOverview: {},
    report: {
      details: [],
    },
  },
};

const tempChartInitialState = {
  totalOutreach: 0,
  engagements: 0,
  messagesSent: 0,
  triage: 0,
  engagementPercent: 0,
};

@Component({
  selector: 'app-overview-charts',
  templateUrl: './overview-charts.component.html',
  styleUrls: ['./overview-charts.component.scss'],
})
export class OverviewChartsComponent implements OnInit, OnChanges {
  @Input() botId;
  @Input() startDate: Date = new Date(2000, 0, 1);
  @Input() endDate: Date = new Date();
  @Input() reportType: string = 'T';
  @Output() reportTypeEvent = new EventEmitter<string>();
  @Input() showReport: boolean = true;

  public reportChanged(report: string) {
    const reportDays = (this.endDate.getTime() - this.startDate.getTime()) / (1000 * 3600 * 24);
    if (reportDays <= 185) {
      this.reportType = report;
      this.reportTypeEvent.emit(report);
    }
  }

  loading = false;

  dashboardData: AnalyticsDashboardData = initialState;
  chartDataset: Record<AnalyticsDashboardCards, number> = tempChartInitialState;

  constructor(private analyticsService: AnalyticsService, private toastrService: ToastrService) {}

  async ngOnInit() {
    this.setLoading(true);
    this.analyticsService
      .getAnalyticsDashboardData(this.botId, this.startDate, this.endDate)
      .then((dashboardData: AnalyticsDashboardData): void => {
        const messagesSent = Number(dashboardData.cards.totalOutreach.count);
        if (messagesSent > 0) {
          const engagements = Number(dashboardData.cards.engagements.count);
          const percent = (engagements / messagesSent) * 100;
          dashboardData.cards.engagementPercent = {
            count: `${percent.toFixed(2)}`,
            variation: { monthly: '0' },
          };
        } else {
          dashboardData.cards.engagementPercent = {
            count: '0.00',
            variation: { monthly: '0' },
          };
        }
        this.dashboardData = dashboardData;

        this.chartDataset = {
          totalOutreach: Number(dashboardData.cards.totalOutreach.count),
          engagements: Number(dashboardData.cards.engagements.count),
          messagesSent: Number(dashboardData.cards.messagesSent.count),
          triage: Number(dashboardData.cards.triage.count),
          engagementPercent: Number(dashboardData.cards.engagementPercent.count),
        };
      })
      .catch(error => {
        this.toastrService.error('Unable to fetch Analytics data. Try again later.');
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentTabChange: SimpleChange = changes.currentTab;
    if (changes.startDate || changes.endDate) {
      const reportDays = (this.endDate.getTime() - this.startDate.getTime()) / (1000 * 3600 * 24);
      if (changes.reportType) {
        this.reportTypeEvent.emit(this.reportType);
      }
      this.ngOnInit();
    }
  }

  setLoading(loadingStatus) {
    this.loading = loadingStatus;
  }
}
