<div class="container-fluid data-export-container bg-white" [ngClass]="loading ? 'disabled' : ''">
  <app-action-progress></app-action-progress>

  <div class="row h-100">
    <div class="col-5 h-100">
      <app-data-export-search
        [awsRegion]="awsRegion"
        [botId]="botId"
        (filterUpdated)="filterUpdated($event)"
      ></app-data-export-search>
    </div>
    <div class="col-7 h-100 d-flex flex-column">
      <div>
        <h3 class="h3 py-3">Fields to export</h3>
        <ng-container *ngIf="this.filter.messagesCount">
          <small> Found {{ this.filter.messagesCount }} messages </small>
        </ng-container>
      </div>
      <div class="border mb-2 h-100 overflow-auto">
        <ng-container *ngIf="filter?.includedFields">
          <div *ngFor="let field of filter.includedFields">
            <i class="field-delete fas fa-minus-circle text-danger px-2" (click)="removeField(field)"></i>
            <span>{{ field }}</span>
          </div>
        </ng-container>
      </div>

      <button
        class="btn btn-primary btn-outline mb-2"
        (click)="doAsyncDataCollection()"
        [disabled]="filter.includedFields.length < 1 || !canExportData()"
      >
        Export to CSV
      </button>
    </div>
  </div>
</div>
