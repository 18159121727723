import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { InputValidationModel } from 'src/app/models';
import { COLLECTION_NAMES } from './constants';

@Injectable({
  providedIn: 'root',
})
export class InputValidationsService {
  private inputValidationsCollection: AngularFirestoreCollection<InputValidationModel>;
  private inputValidationsCollectionQuery: (ref: QueryFn) => AngularFirestoreCollection<InputValidationModel>;

  constructor(private afs: AngularFirestore) {
    this.inputValidationsCollection = afs.collection<InputValidationModel>(COLLECTION_NAMES.INPUT_VALIDATIONS);
    this.inputValidationsCollectionQuery = (ref: QueryFn) =>
      afs.collection<InputValidationModel>(COLLECTION_NAMES.INPUT_VALIDATIONS, ref);
  }

  addInputValidation(inputValidation: InputValidationModel, throwErrorOnDuplicate = true): Promise<void> {
    return this.getInputValidationsBySystemNameAndCorpId(inputValidation.systemName, inputValidation.corpId)
      .pipe(take(1))
      .toPromise()
      .then(data => {
        if (data.length && throwErrorOnDuplicate) {
          throw new Error(`Input validation already exists: ${inputValidation.systemName}`);
        }
        return this.inputValidationsCollection.doc(inputValidation.id).set(Object.assign({}, inputValidation));
      });
  }

  getInputValidationsByCorpId(corpId: string): Observable<InputValidationModel[]> {
    return this.inputValidationsCollectionQuery(ref => ref.where('corpId', '==', corpId))
      .valueChanges({ idField: 'id' })
      .pipe(map(results => results.sort((a, b) => (a.name > b.name ? 1 : -1))));
  }

  private getInputValidationsBySystemNameAndCorpId(
    systemName: string,
    corpId: string,
  ): Observable<InputValidationModel[]> {
    return this.inputValidationsCollectionQuery(ref =>
      ref.where('systemName', '==', systemName).where('corpId', '==', corpId),
    ).valueChanges({ idField: 'id' });
  }

  getInputValidationsBySystemName(systemName: string): Observable<InputValidationModel[]> {
    return this.inputValidationsCollectionQuery(ref => ref.where('systemName', '==', systemName))
      .valueChanges({ idField: 'id' })
      .pipe(map(results => results.sort((a, b) => (a.name > b.name ? 1 : -1))));
  }

  updateInputValidation(inputValidation: InputValidationModel): Promise<void> {
    InputValidationModel.generateUpdatedAt(inputValidation);
    return this.inputValidationsCollection.doc(inputValidation.id).update(inputValidation);
  }

  removeInputValidation(id: string) {
    return this.inputValidationsCollection.doc(id).delete();
  }
}
