import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { NlpModel } from 'src/app/models/nlp/nlp-model/nlp-model';
import { ToastrService } from 'ngx-toastr';
import { FileCardItem } from '../../nlp/nlp-dataset-cards/_types/FileCardItem';
import { NlpModelTrainFile } from '../../../models/nlp/nlp-model/nlp-model-train-file';
import { NlpModelTrain } from '../../../models/nlp/nlp-model/nlp-model-train';

@Component({
  selector: 'app-nlp-model-create-modal',
  templateUrl: './nlp-model-create-modal.component.html',
  styleUrls: ['./nlp-model-create-modal.component.scss']
})
export class NlpModelCreateModalComponent implements OnInit {
  loadingChild: boolean;
  corpId: string;
  modelName: string;
  modelAdded: EventEmitter<NlpModel>;
  nlpModel: NlpModel;
  nlpModelFileItems: FileCardItem[];

  constructor(
    public modal: BsModalRef,
    options: ModalOptions,
    private nlpDojoService: NlpDojoService,
    public toaster: ToastrService
  ) {
    this.corpId = (options.initialState as any)?.corpId;
    this.modelAdded = new EventEmitter<NlpModel>();
    this.nlpModelFileItems = [];
    this.loadingChild = true;
  }

  ngOnInit() {}

  createModel() {
    if (!this.nlpModelFileItems || this.nlpModelFileItems?.length < 1) {
      this.toaster.error('There must be at least 1 file to train');
      return;
    }

    this.nlpDojoService.createModel(this.corpId, this.modelName).subscribe(createdNlpModel => {
      this.nlpModel = createdNlpModel;

      this.modal.hide();
      this.modelAdded.emit(createdNlpModel);
      this.toaster.success('Model created successfully');
      this.updateNlpModel();
    });
  }

  private updateNlpModel() {
    this.toaster.success('Model train started');

    const nlpTrainFiles: NlpModelTrainFile[] = this.nlpModelFileItems.map(nlpModelFileItem => {
      return {
        org_id: 'default',
        dataset_id: nlpModelFileItem.datasetId,
        file_id: nlpModelFileItem.id
      };
    });

    this.nlpDojoService
      .trainModel('default', this.nlpModel.id, new NlpModelTrain(nlpTrainFiles))
      .toPromise()
      .then((updatedNlpModel: NlpModel) => {
        this.nlpModel = updatedNlpModel;
      });
  }

  public childLoaded() {
    this.loadingChild = false;
  }
}
