<app-action-progress></app-action-progress>
<div class="modal-header">
  <h4 class="modal-title">Delete Template</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (submit)="deleteTemplate()" #form="ngForm" novalidate>
  <div class="modal-body">
    <div class=" form-group">
      <label class=" form-control-label" for="name"> Type Template Name To Continue </label>
      <input class="form-control" id="name" name="name" [(ngModel)]="deleteOptionName" type="text" [placeholder]="flowTemplate.label" required />
    </div>
    <!-- <div class=" form-group">
      <label class=" form-control-label" for="mode"> Delete Strategy </label>
      <select class="form-control" id="mode" name="templateType" [(ngModel)]="deleteOptionMode">
        <option [ngValue]="'LEAVE_CHILDREN'">Delete this template and leave the derived nodes</option>
        <option [ngValue]="'DELETE_DERIVED_NODES'">Delete this template and all its derived nodes</option>
      </select>
    </div> -->
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-outline" type="submit" [disabled]="deleteOptionName != flowTemplate.label">Delete</button>
    <button class="btn btn-primary-outline" type="button" (click)="modal.hide()">CLOSE</button>
  </div>
</form>
