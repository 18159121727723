import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConversationLogFilter } from '../conversations-messages-search-filter/types/ConversationLogFilter';
import { ToastrService } from 'ngx-toastr';
import { ConversationSummary } from '../../conversation/conversation-summary/_type/ConversationSummary';
import { BotModel } from '../../../../models';
import { Subscription } from 'rxjs';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';

const TO_TAKE = 50;

@Component({
  selector: 'app-conversations-messages-search',
  templateUrl: './conversations-messages-search.component.html',
  styleUrls: ['./conversations-messages-search.component.scss']
})
export class ConversationsMessagesSearchComponent implements OnInit, OnDestroy {
  conversationsMessages: ConversationSummary[] | null;
  expandedMessages = false;
  searchExecuted = false;

  loading: boolean;

  @Input()
  filter: ConversationLogFilter;

  @Input()
  bot?: BotModel;

  /**
   * If not provided, will be used the default defined region {@link AwsRegionEnum.US_EAST_1}
   */
  @Input()
  awsRegion: AwsRegionEnum;

  @Input()
  defaultBotLogo: string;

  @Input()
  defaultBotName: string;

  @Input()
  corpId: string;

  @Input()
  countryCode: string;

  @Output()
  selectConversation: EventEmitter<ConversationSummary | null>;
  selectedConversationId: string | null;

  conversationSubscription: Subscription;

  constructor(private apiGatewayAnalyticsService: ApiGatewayAnalyticsService, private toaster: ToastrService) {
    this.conversationsMessages = null;
    this.loading = false;
    this.selectConversation = new EventEmitter<ConversationSummary>();
    this.conversationSubscription = this.selectConversation.subscribe(conversation => {
      if (!conversation) {
        this.selectedConversationId = null;
        return;
      }
      this.selectedConversationId = conversation.conversationId;
    });
  }

  ngOnInit() {
    if (this.filter.conversationId) {
      this.executeSearch();
    }
  }

  filterChanged() {
    if (this.searchExecuted) {
      this.selectedConversationId = null;
      this.executeSearch();
    }
  }

  executeSearch() {
    this.searchExecuted = true;
    this.selectConversation.next(null);
    this.conversationsMessages = null;
    this.searchConversations();
  }

  ngOnDestroy(): void {
    if (this.conversationSubscription) {
      this.conversationSubscription.unsubscribe();
    }
  }

  private async searchConversations() {
    this.loading = true;
    const { userId, conversationId, containingText, anyDate, dateRange, sortByTimeOrder } = this.filter;

    const getBoundaryDate = this.getBoundaryDate(!!anyDate, dateRange);
    try {
      const response = await this.apiGatewayAnalyticsService.searchConversations({
        botId: this.bot?.id,
        userId,
        conversationId,
        containingText,
        startDate: getBoundaryDate(0),
        endDate: getBoundaryDate(1),
        toTake: TO_TAKE,
        sortByTimeOrder
      });
      this.conversationsMessages = response.map(
        ({
          userId: userId$1,
          conversationId: conversationId$1,
          beginIntent,
          lastIntent,
          noOfTurns,
          conversationDate
        }) => ({
          userId: userId$1,
          conversationId: conversationId$1,
          conversationStartDate: conversationDate,
          conversationEndDate: conversationDate,
          beginIntent,
          lastIntent,
          noOfTurns,
          botName: this.getBotName(),
          botLogo: this.getBotLogo()
        })
      );

      if (this.conversationsMessages?.length === 1) {
        this.selectConversation.next(this.conversationsMessages[0]);
      }
    } catch (error) {
      this.toaster.error(error);
    } finally {
      this.loading = false;
    }
  }

  private getBoundaryDate(anyDate: boolean, dateRange: Date[] | undefined) {
    return (position: number) => {
      return anyDate ? undefined : dateRange && dateRange[position].toISOString().split('T')[0];
    };
  }

  private getBotName(): string {
    // TODO When we really care about this we will use the reponse botID
    return this.bot?.label || this.defaultBotName;
  }

  private getBotLogo(): string {
    return this.bot?.styles?.avatarImageUrl || this.defaultBotLogo;
  }
}
