import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { PermissionModel } from 'src/app/models/permission';
import { COLLECTION_NAMES } from './constants';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private permissionsCollection: AngularFirestoreCollection<PermissionModel>;
  private permissionsCollectionQuery: (ref: QueryFn) => AngularFirestoreCollection<PermissionModel>;

  constructor(private afs: AngularFirestore, private usersService: UsersService) {
    this.permissionsCollection = afs.collection<PermissionModel>(COLLECTION_NAMES.PERMISSIONS);
    this.permissionsCollectionQuery = (ref: QueryFn) =>
      afs.collection<PermissionModel>(COLLECTION_NAMES.PERMISSIONS, ref);
  }

  addPermission(permission: PermissionModel): Promise<void> {
    const permissionId = permission.id; // avoiding error prone scenarios
    return this.permissionsCollection
      .doc(permissionId)
      .ref.get()
      .then(result => {
        if (result.exists) {
          throw new Error(`Permission "${permissionId}" already exists`);
        } else {
          return this.permissionsCollection.doc(permissionId).set(Object.assign({}, permission));
        }
      });
  }

  updatePermission(permission: PermissionModel): Promise<void> {
    return this.permissionsCollection.doc(permission.id).update(Object.assign({}, permission));
  }

  getPermissionsBy(
    userId?: string | null,
    corpId?: string | null,
    hierarchyElementSystemName?: string | null,
    botCode?: string | null,
  ): Observable<PermissionModel[]> {
    const hasAllCorps = userId && this.usersService.isUserAbleAccessAllCompanies(userId);

    const queryFn: QueryFn = ref => {
      let q: firebase.default.firestore.Query = ref;

      if (userId) {
        q = q.where('userId', '==', userId);
      }
      if (corpId && !hasAllCorps) {
        q = q.where('corpId', '==', corpId);
      }
      if (hierarchyElementSystemName) {
        q = q.where('hierarchyElementSystemName', '==', hierarchyElementSystemName);
      }
      if (botCode) {
        q = q.where('botCode', '==', botCode);
      }
      return q;
    };
    const permissionsCollection = this.permissionsCollectionQuery(queryFn);
    return permissionsCollection.valueChanges().pipe(
      map(permissions => {
        return permissions.map(permission => plainToClass(PermissionModel, permission));
      }),
    );
  }

  async deleteAllUserCorpPermission(userId: string, corpId: string): Promise<void> {
    const snapshots = await this.permissionsCollectionQuery(ref =>
      ref.where('userId', '==', userId).where('corpId', '==', corpId),
    )
      .get()
      .toPromise();
    // tslint:disable-next-line:no-non-null-assertion
    snapshots!.forEach(async ({ id }) => {
      await this.removePermission(id);
    });
  }

  removePermission(id: string): Promise<void> {
    return this.permissionsCollection.doc(id).delete();
  }
}
