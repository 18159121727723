<div class="d-flex flex-row pt-2">
  <div class="d-flex flex-column w-50 pr-2 py-3 overflow-auto" [ngClass]="className">
    <div *ngIf="nlpModelFileItems && nlpModelFileItems.length > 0; else noFilesBlock">
      <app-simple-card-list [cardItemList]="nlpModelFileItems"></app-simple-card-list>
    </div>
    <ng-template #noFilesBlock>
      <div class="text-center">No file selected.</div>
    </ng-template>
  </div>
  <div class="d-flex flex-column w-50 pr-2 pl-1 overflow-auto" [ngClass]="className">
    <div *ngFor="let nlpDataset of nlpDataSets">
      <app-nlp-dataset-cards
        [datasetName]="nlpDataset.name"
        [datasetSystemName]="nlpDataset.system_name"
        [hideFiles]="hideNlpModelFiles"
        [lazyLoading]="true"
        [buttonLabel]="'Add'"
        (buttonClicked)="addNlpFileEvent.next($event)"
        [buttonType]="successButtonType"
        [addFileEvent]="addNlpFileEvent"
        [removeFileEvent]="removeNlpFileEvent"
      ></app-nlp-dataset-cards>
    </div>
  </div>
</div>
