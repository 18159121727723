import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { getSidebarItems } from '../utils';
import { CorpsService, FlowTemplatesService, PermissionsService } from 'src/app/services/firestore';
import { FlowTemplateModel } from 'src/app/models/flow-template';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-global-flow-templates',
  templateUrl: './global-flow-templates.component.html',
  styleUrls: ['./global-flow-templates.component.scss'],
})
export class GlobalFlowTemplatesComponent implements OnInit, OnDestroy {
  private crtDataSubscription: Subscription;
  private dataSubscription: Subscription;
  corps: CorpModel[] = [];
  flowTemplates: FlowTemplateModel[] = [];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private authService: AuthService,
    private permissionsService: PermissionsService,
    private flowTemplatesService: FlowTemplatesService,
    private toaster: ToastrService,
    private corpsService: CorpsService,
  ) {}

  async ngOnInit() {
    const user = await this.authService.currentUser;
    if (!user) {
      return;
    }
    this.refreshUI();
    this.crtDataSubscription = combineLatest([
      this.permissionsService.getPermissionsBy(user.uid),
      this.flowTemplatesService.getGlobalFlowTemplates(),
    ]).subscribe(
      ([permissions, flowTemplates]) => {
        const corpsId = permissions
          .map(permission => permission.corpId)
          .filter((value, index, self) => {
            return value && self.indexOf(value) === index;
          });
        const observables = corpsId.map(corpId => this.corpsService.getCorpById(corpId));
        this.dataSubscription = combineLatest(observables).subscribe(
          corps => {
            this.corps = corps.filter(corp => corp != null) as CorpModel[];
          },
          error => {
            this.toaster.error(error);
          },
        );
        this.flowTemplates = flowTemplates;
        this.refreshUI();
      },
      error => {
        this.toaster.error(error);
      },
    );
  }

  refreshUI() {
    this.setBreadcrumb();
    this.setSidebarItems();
    this.headerService.setPageTitle('Global Flow Templates');
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }

  private setBreadcrumb() {
    this.breadcrumbService.set([
      {
        label: 'Global Flow Templates',
        route: '/portal/global-flow-templates',
      },
    ]);
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
