import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { BotsService, CorpsService, VersionService } from 'src/app/services/firestore';
import { SidebarService } from 'src/app/services/sidebar.service';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { BotModel, GlobalVariable, GlobalVariableType } from 'src/app/models/bot';
import { CorpModel } from 'src/app/models/corp';
import { VersionModel } from 'src/app/models/version';
import { getSidebarItems, getBreadcrumbItems } from '../utils';
import { HierarchyElementModel } from 'src/app/models';
import { HierarchyElementsService } from 'src/app/services/firestore';
import { DeployBotModalComponent } from 'src/app/components/modals/deploy-bot-modal/deploy-bot-modal.component';
import { ClientEnvironmentModel, BotEnvironment } from 'src/app/models/client-environment';
// tslint:disable-next-line:max-line-length
import { CompleteBotInformationModalComponent } from 'src/app/components/modals/complete-bot-information-modal/complete-bot-information-modal.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bot-deployments',
  templateUrl: './bot-deployments.component.html',
  styleUrls: ['./bot-deployments.component.scss'],
})
export class BotDeploymentsComponent implements OnInit, OnDestroy {
  clientEnvironment: ClientEnvironmentModel | null;
  loading = false;
  corp: CorpModel;
  hierarchyElement: HierarchyElementModel;
  bot: BotModel | null;
  versions: VersionModel[];
  currentVersionNumber = 0;
  currentVersionHistory = '';
  latestVersionNumber = 0;
  latestVersionHistory = '';
  botExists = true;
  corpId: string;
  hierarchyElementSystemName: string;
  botCode: string;

  private paramMapSubscription: Subscription;
  private envsSubscription: Subscription;
  private onInnerSubscription = new Subject();
  invalidBotVariables: GlobalVariable[] = [];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private botsService: BotsService,
    private corpsService: CorpsService,
    private versionService: VersionService,
    private clientEnvironmentService: ClientEnvironmentService,
    private hierarchyElementsService: HierarchyElementsService,
    private modalService: BsModalService,
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.paramMapSubscription = combineLatest([
      this.route.paramMap,
      this.authService.currentUser,
      this.clientEnvironmentService.items$,
    ]).subscribe(([params, user, envs]) => {
      const corpId = params.get('corp');
      const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
      const botCode = params.get('bot');
      if (!corpId || !hierarchyElementSystemName || !botCode || !user || !envs) {
        return;
      }

      this.corpId = corpId;
      this.hierarchyElementSystemName = hierarchyElementSystemName;
      this.botCode = botCode;

      combineLatest([
        this.corpsService.getCorpById(corpId),
        this.hierarchyElementsService.getHierarchyElement(`${this.corpId}-${this.hierarchyElementSystemName}`),
        this.botsService.getBotBy(this.corpId, this.hierarchyElementSystemName, this.botCode, envs),
        this.versionService.versions$,
        // Develop stage bot always exists, so we fetch it for use when a bot on a different stage does not (i.e no bots on prod env).
        // This is mainly used to help the breadcrumbs and side bar work when a user refreshes the dashboard of an empty env.
        this.botsService.getBotById(`${this.corpId}-${this.hierarchyElementSystemName}-${this.botCode}_development`),
      ])
        .pipe(takeUntil(this.onInnerSubscription))
        .subscribe(async ([corp, hierarchyElement, bot, versions, devStageBot]) => {
          if (!corp || !hierarchyElement || !devStageBot) {
            return;
          }

          if (!bot) {
            this.botExists = false;
            this.loading = false;
          }

          this.corp = corp;
          this.hierarchyElement = hierarchyElement;
          this.bot = bot ? bot : devStageBot;

          this.setEnvironmentDropdownListener();
          if (!versions || versions.length === 0) {
            await this.setVersion();
          } else {
            // Update list if the version was deployed to the env we're currently in.
            if (this.bot.clientEnvironment === VersionModel.getCurrent(versions)?.bot.clientEnvironment) {
              this.versions = versions;
              this.setCurrentVersionNumber(this.bot.clientEnvironment);
            }
          }

          this.invalidBotVariables = this.bot.globalVariables.filter(gv => {
            return gv.type === GlobalVariableType.BOT && !gv.defaultValue;
          });
          this.loading = false;
          this.refreshUI();
        });
    });
  }

  async saveBotVariable() {
    this.invalidBotVariables.forEach(gv => {
      this.bot?.globalVariables.forEach(variable => {
        if (variable.label === gv.label) {
          variable.defaultValue = gv.defaultValue;
        }
      });
    });
    if (this.bot) {
      await this.botsService.updateBot(this.bot);
    }
  }

  refreshUI() {
    if (!this.bot) {
      return;
    }

    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot.code);
    this.headerService.setPageTitle(`${this.bot.label} Deployments`);
  }

  // TODO: Add ability to roll back to version.
  // handleRollbackClick(versionToRollbackTo) {
  //   console.log(versionToRollBackTo);
  // }

  hasInvalidBotVariables() {
    const invalidBotVariables = this.bot?.globalVariables.filter(gv => {
      return gv.type === GlobalVariableType.BOT && !gv.defaultValue;
    });
    return invalidBotVariables?.length && invalidBotVariables.length > 0;
  }

  handleOpenDeployModal() {
    if (this.hasInvalidBotVariables()) {
      // prompt user to complete bot variables
      this.modalService.show(CompleteBotInformationModalComponent, {
        ignoreBackdropClick: true,
        initialState: {
          bot: this.bot,
        },
      });
    } else {
      this.modalService.show(DeployBotModalComponent, {
        ignoreBackdropClick: true,
        initialState: {
          bot: this.bot,
        },
      });
    }
  }

  private async setVersion() {
    if (!this.bot) {
      return;
    }
    const versions = await this.versionService.getBotVersions(this.bot.id);
    if (versions) {
      this.versions = versions;
      this.setCurrentVersionNumber(this.bot.clientEnvironment);
    }
  }

  private setCurrentVersionNumber(botClientEnvironment: BotEnvironment) {
    const latestVersion = VersionModel.getLatest(this.versions);
    const currentVersion = VersionModel.getCurrent(this.versions);
    if (currentVersion) {
      this.currentVersionNumber = currentVersion.versionNumber;
      this.currentVersionHistory = this.getVersionHistory(currentVersion);
    }
    if (latestVersion) {
      this.latestVersionNumber = latestVersion.versionNumber;
      this.latestVersionHistory = this.getVersionHistory(latestVersion);
    }
  }

  getVersionHistory(versionItem: VersionModel) {
    const versionHistory: number[] = versionItem.versionHistory ? versionItem.versionHistory : [];
    return versionHistory.join(' -> ');
  }

  private setEnvironmentDropdownListener(): void {
    this.envsSubscription = this.clientEnvironmentService.items$.subscribe(clientEnv => {
      if (clientEnv) {
        this.handleBotEnvChange(clientEnv);
      }
    });
  }

  private handleBotEnvChange(clientEnv: ClientEnvironmentModel): void {
    this.botsService
      .getBotBy(this.corp.id, this.hierarchyElement.systemNameForUrl, this.botCode, clientEnv)
      .then(bot => {
        if (!bot) {
          this.botExists = false;
        }
        if (bot) {
          this.bot = bot;
          this.botExists = true;
          this.setVersion();
        }
      });
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, bot, 'Deployments', 'deployments'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement, botCode));
  }

  ngOnDestroy() {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.envsSubscription) {
      this.envsSubscription.unsubscribe();
    }
    this.onInnerSubscription.next({});
    this.onInnerSubscription.complete();
  }
}
