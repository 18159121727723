import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  OnChanges,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import Chart from 'chart.js';
import { TimeInterval } from 'src/app/components/analytics/_types/TimeInterval';
import { ToastrService } from 'ngx-toastr';
import { chartOptions, parseOptions } from '../chart-options';
import { CountingPeriod } from '../../_types/CountingPeriod';
import { ChartSettings } from '../_type/ChartSettings';
import { IChartData, IFetchChartDataParameters } from '../types';
import { set } from 'lodash';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements AfterViewInit, OnInit, OnChanges {
  @ViewChild('chart', { static: true }) chartEl: ElementRef;

  @Input()
  chartSettings: ChartSettings;

  @Input()
  useCountingPeriod: boolean;

  @Input()
  loading: boolean;

  @Input()
  chartData: IChartData;

  @Output()
  fetchChartData: EventEmitter<IFetchChartDataParameters>;

  chart: Chart;

  timeFrames: TimeInterval[];
  selectedTimeFrame: TimeInterval;

  countingPeriods?: CountingPeriod[];
  selectedCountingPeriod?: CountingPeriod;

  isChartEmpty: boolean;

  constructor(private toaster: ToastrService) {
    parseOptions(Chart, chartOptions());
    this.fetchChartData = new EventEmitter<IFetchChartDataParameters>();
  }

  ngOnChanges() {
    this.updateChartData(this.chartData);
  }

  ngOnInit() {
    if (!this.chartSettings?.timeIntervals || !this.chartSettings.timeIntervals.length) {
      this.toaster.warning('Time intervals not provided to the OVERVIEW chart!');
      return;
    }

    this.timeFrames = this.chartSettings.timeIntervals.map(tf => new TimeInterval(tf));
    const selectedTimeFrameIndex =
      this.chartSettings?.selectedTimeIntervalIndex &&
      this.chartSettings?.selectedTimeIntervalIndex < this.timeFrames.length
        ? this.chartSettings?.selectedTimeIntervalIndex
        : 0;
    this.selectedTimeFrame = this.timeFrames[selectedTimeFrameIndex];

    if (this.useCountingPeriod) {
      this.initialiseCountingPeriods(this.selectedTimeFrame);
    }

    this.loadChartData();
  }

  private updateChartData(chartData: IChartData) {
    this.isChartEmpty = !chartData.labels.length;
    this.chart.data.labels = chartData.labels;
    set(this.chart, ['data', 'datasets', 0, 'data'], chartData.data); //  Bye bye to type safety :facepalm
    this.chart.update({ easing: 'easeInOutQuart' });
  }

  ngAfterViewInit() {
    this.chart = new Chart(this.chartEl.nativeElement, {});
    this.chart.config = {
      type: this.chartSettings.type,
      data: this.chartSettings.data,
      options: this.chartSettings.options,
    };
    this.chart.options = this.chartSettings.options;

    this.setCustomLabels(this.chart);
    this.updateChartData(this.chartData);
  }

  processTimeFrameChange(timeFrame: TimeInterval) {
    this.selectedTimeFrame = timeFrame;
    if (this.useCountingPeriod) {
      this.initialiseCountingPeriods(timeFrame);
    }

    this.loadChartData();
  }

  processTimePeriodChange(timePeriod: CountingPeriod) {
    this.selectedCountingPeriod = timePeriod;

    this.loadChartData();
  }

  private loadChartData() {
    if (!this.selectedTimeFrame) {
      return;
    }

    this.fetchChartData.emit({
      selectedTimeFrame: this.selectedTimeFrame.getType(),
      countingPeriod: this.selectedCountingPeriod?.getType(),
    });
  }

  private initialiseCountingPeriods(timeFrame: TimeInterval) {
    this.countingPeriods = timeFrame.getCountingPeriods();

    const selectedValidPeriod = this.countingPeriods.find(countingPeriod =>
      countingPeriod.equals(this.selectedCountingPeriod),
    );

    if (selectedValidPeriod) {
      this.selectedCountingPeriod = selectedValidPeriod;
    } else if (this.countingPeriods && this.countingPeriods.length) {
      this.selectedCountingPeriod = this.countingPeriods[0];
    }
  }

  private setCustomLabels(chartParam: Chart) {
    chartParam.options.legend = {
      display: true,
      position: 'bottom',
      labels: {
        generateLabels(chart) {
          const data = chart.data;
          if (data.labels?.length && data.datasets?.length) {
            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const dataSet = data.datasets ? data.datasets[0] : undefined;
              const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
              const arcOpts = chart.options.elements?.arc;
              const fill = getValueAtIndexOrDefault(dataSet?.backgroundColor, i, arcOpts?.backgroundColor);
              const stroke = getValueAtIndexOrDefault(dataSet?.borderColor, i, arcOpts?.borderColor);
              const bw = getValueAtIndexOrDefault(dataSet?.borderWidth, i, arcOpts?.borderWidth);
              const value = dataSet?.data?.[i];

              return {
                text: label + ' : ' + value,
                fillStyle: fill,
                strokeStyle: stroke,
                lineWidth: bw,
                hidden: isNaN(Number(dataSet?.data?.[i])) || meta.data[i].hidden,
                index: i,
              };
            });
          } else {
            return [];
          }
        },
      },
    };
  }
}

/**
 * This register it is used to implement the `showAllTooltips` action.
 * When the `showAllTooltips` is provided to the options, all tooltips will be always shown.
 */
Chart.pluginService.register({
  beforeRender(chart) {
    // @ts-ignore
    if (chart.config.options?.showAllTooltips) {
      // create an array of tooltips
      // we can't use the chart tooltip because there is only one tooltip per chart
      // @ts-ignore
      chart.pluginTooltips = [];
      chart.config.data?.datasets?.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((sector, j) => {
          // @ts-ignore
          chart.pluginTooltips.push(
            // @ts-ignore
            new Chart.Tooltip(
              {
                // @ts-ignore
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: [sector],
              },
              chart,
            ),
          );
        });
      });

      // turn off normal tooltips
      // @ts-ignore
      chart.options.tooltips?.enabled = false;
    }
  },
  afterDraw(chart, easing) {
    // @ts-ignore
    if (chart.config.options?.showAllTooltips) {
      // we don't want the permanent tooltips to animate, so don't do anything till the animation runs at least once
      // @ts-ignore
      if (!chart.allTooltipsOnce) {
        // @ts-ignore
        if (easing !== 1) {
          return;
        }
        // @ts-ignore
        chart.allTooltipsOnce = true;
      }

      // turn on tooltips
      // @ts-ignore
      chart.options.tooltips?.enabled = true;
      // @ts-ignore
      Chart.helpers.each(chart.pluginTooltips, tooltip => {
        tooltip.initialize();
        tooltip.update();
        // we don't actually need this since we are not animating tooltips
        tooltip.pivot();
        tooltip.transition(easing).draw();
      });
      // @ts-ignore
      chart.options.tooltips?.enabled = false;
    }
  },
});
