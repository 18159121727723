export class CustomerSettingsModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  sms: string;
  inactive: boolean;
  channel: any;
  doNotContact: boolean;
  doNotContactInternal: boolean;
  doNotContactSMS: boolean;
  doNotContactInternalSMS: boolean;
  doNotContactFederal: boolean;
  doNotContactLitigator: boolean;
  stopCurrentSeries: boolean;
}
