<ng-container *ngIf="loading">
  <div class="container-fluid">
    <div class="row align-items-center py-4 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>

<ng-container>
  <app-action-progress></app-action-progress>
  <div class="container-fluid bot-flow-template-container">
    <div class="row h-100">
      <div class="col-md-12 h-100 overflow-auto">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 d-inline-block mb-0">Flow Templates</h6>
          </div>
        </div>
        <div class="card p-3 m-2 mb-4">
          <fieldset class="container">
            <div>
              <tabset>
                <tab heading="Export" id="export-flow-tab" data-test-id="export-template-tab">
                  <div class="form-group mb-3 mt-3">
                    <h3>
                      Export To
                      <span *ngIf="!isGlobal">{{ corp.label }}</span>
                      <span *ngIf="isGlobal">Global </span>
                      Flow Template
                    </h3>
                  </div>
                  <form (submit)="saveFlow()" #form="ngForm" novalidate>
                    <div class="form-group">
                      <label class="form-control-label" for="botName"> Flow Name </label>
                      <input
                        data-test-id="export-flow-name-input"
                        class="form-control"
                        name="name"
                        [(ngModel)]="flowTemplate.label"
                        type="text"
                        placeholder="e.g. Intro Flow, Support Flow"
                        required
                      />
                    </div>
                    <div class="form-group" *ngIf="isGlobal">
                      <label class="form-control-label" for="sourceBot"> Select Corp </label>
                      <select
                        data-test-id="export-flow-corp-select"
                        class="form-control"
                        id="selectCorp"
                        name="selectedCorp"
                        [(ngModel)]="selectedCorp"
                        (change)="onCorpSelection()"
                      >
                        <option value="">Select</option>
                        <option *ngFor="let __corp of corps" [ngValue]="__corp.id">{{ __corp.label }}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="botName"> Flow Image </label>
                      <app-editable-image
                        [source]="flowTemplate.image"
                        (fileSelect)="imageFile = $event"
                        (remove)="flowTemplate.image = ''"
                      >
                      </app-editable-image>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="sourceBot"> Select Source Assistant </label>
                      <select
                        data-test-id="export-flow-source-bot-select"
                        [disabled]="!corp"
                        class="form-control"
                        id="sourceBot"
                        name="sourceBot"
                        [(ngModel)]="sourceBot"
                        (change)="getNodesInBot()"
                      >
                        <option value="">Select</option>
                        <option *ngFor="let bot of bots" [ngValue]="bot.id">{{ bot.label }}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="templateType"> Template Type </label>
                      <select
                        data-test-id="export-flow-template-type-select"
                        [disabled]="!corp || !sourceBot"
                        class="form-control"
                        id="templateType"
                        name="templateType"
                        [(ngModel)]="flowTemplate.templateType"
                        (change)="onTemplateTypeChange()"
                      >
                        <option [ngValue]="BOT_TEMPLATE">Assistant Template</option>
                        <option [ngValue]="FLOW_TEMPLATE">Flow Template</option>
                      </select>
                    </div>
                    <div *ngIf="flowTemplate.templateType === 'FLOW_TEMPLATE'">
                      <div class="form-group" *ngIf="!findFlowMethod">
                        <label class="form-control-label" for="selectedFlow"> Select A flow To Export </label>
                        <button
                          data-test-id="export-flow-select-flow-button"
                          *ngIf="selectedFlowLabel !== entireFlowLabel"
                          class="ml-3 btn-sm btn-outline-primary text-right"
                          (click)="findFlowMethod = !findFlowMethod"
                        >
                          Find A Flow Through Node
                        </button>
                        <select
                          data-test-id="export-flow-select-flow-select"
                          [disabled]="!corp"
                          class="form-control"
                          id="selectedFlow"
                          name="selectedFlow.label"
                          (change)="selectAFlow()"
                          [(ngModel)]="selectedFlowLabel"
                        >
                          <option disabled>Select A Flow</option>
                          <option *ngFor="let flow of seperatedFlows" [ngValue]="flow.label">{{ flow.label }}</option>
                        </select>
                      </div>
                      <div class="form-group" *ngIf="findFlowMethod && selectedFlowLabel !== entireFlowLabel">
                        <label class="form-control-label" for="selectedNode">
                          Select A Node To Find Connected Flow
                        </label>
                        <button
                          type="button"
                          data-test-id="export-flow-select-node-button"
                          class="ml-3 btn-sm btn-outline-primary"
                          (click)="findFlowMethod = !findFlowMethod"
                        >
                          Browse Flows
                        </button>
                        <select
                          data-test-id="export-flow-select-node-select"
                          [disabled]="!corp"
                          class="form-control"
                          id="selectedNode"
                          name="selectedNode"
                          [(ngModel)]="selectedNode"
                          (change)="findFlowContainingNode()"
                        >
                          <option value="">Select</option>
                          <option *ngFor="let node of nodes" [ngValue]="node.id">{{ node.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="selectedFlow">
                      <label class="form-control-label">
                        {{ selectedFlow.nodes.length }}
                        {{ selectedFlow.nodes.length == 1 ? 'node is' : 'nodes are' }} to be exported
                      </label>
                      <p
                        class="mb-0"
                        *ngFor="let node of selectedFlow.nodes"
                        [ngClass]="{ 'text-through': node.flowTemplateId }"
                      >
                        {{ node.name }}
                      </p>
                    </div>
                    <button
                      data-test-id="export-flow-save-button"
                      class="btn btn-primary btn-outline"
                      type="submit"
                      [disabled]="!corp || !form.valid || loading"
                    >
                      <span
                        *ngIf="loading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span *ngIf="loading"> Saving...</span>
                      <span *ngIf="!loading">Save Template</span>
                    </button>
                  </form>
                </tab>
                <tab heading="Import" id="import-flow-tab" data-test-id="import-template-tab">
                  <div class="form-group mb-3 mt-3">
                    <h3>
                      Import From
                      <span *ngIf="!isGlobal"> {{ corp.label }}</span>
                      <span *ngIf="isGlobal"> Global </span>
                      Flow Template
                    </h3>
                  </div>
                  <div *ngFor="let item of items">
                    <app-simple-list-item [item]="item"></app-simple-list-item>
                  </div>
                  <div *ngIf="items.length === 0" class="text-center">No Saved Templates</div>
                </tab>
              </tabset>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</ng-container>
