import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import * as firestore from 'firebase/firestore';
import * as firebase from 'firebase/auth';
import {
  HLConversationModel,
  HLConversationStatus,
  IConversationClickEvent,
  IFirebaseFilterCriteria,
  IFilterCriteria,
  HLConversationAgent,
} from 'src/app/models/conversations';
import { AuthService } from 'src/app/services/auth.service';
import { HumanInLoopService } from 'src/app/services/firestore/human-in-loop.service';
// tslint:disable-next-line:max-line-length
import { HlConversationSearchModalComponent } from 'src/app/components/modals/hl-conversation-search-modal/hl-conversation-search-modal.component';
import { TimeInterval } from '../../analytics/_types/TimeInterval';
import { BotModel } from 'src/app/models/bot';

enum SearchTextQueryFieldTypes {
  Email,
  Name,
  ConversationId,
}

@Component({
  selector: 'app-hl-conversation-list',
  templateUrl: './hl-conversation-list.component.html',
  styleUrls: ['./hl-conversation-list.component.scss'],
})
export class HlConversationListComponent implements OnInit, OnDestroy {
  @Output()
  scrollEmitter: EventEmitter<any>;

  @Output()
  conversationClickEmitter: EventEmitter<any>;

  @Output()
  filterConversationEmitter: EventEmitter<any>;

  @Output()
  refreshConversationsEmitter: EventEmitter<any>;

  @Input() selectedConversationId: string;
  @Input() bot: BotModel;
  @Input() preloadedConversation: HLConversationModel;

  isFirstLoad = true;
  loading = true;
  previousConversationDataLength = 0;
  conversationSubscription: Subscription;
  conversationData: HLConversationModel[] = [];
  conversationSearchResult: HLConversationModel[] = [];
  currentUserId: string;
  searchTextChanged: Subject<string> = new Subject<string>();
  searchText = '';
  searchMode = false;

  clicked = false;

  touchedFilter = false;
  assignedToMeFilter = false;
  setReadFlag = false;

  // lastScroll: moment.Moment;

  basicFilters: { [key: string]: boolean } = {
    unread: false,
    assignedToMe: false,
    unassigned: false,
  };

  lastFilterCriteria: IFilterCriteria[] = [];
  filterSubscription: Subscription;

  public intervals: TimeInterval[] = [
    new TimeInterval('1d'),
    new TimeInterval('7d'),
    new TimeInterval('30d'),
    new TimeInterval('MTD'),
    new TimeInterval('YTD'),
    new TimeInterval('MAX'),
  ];

  private _selectedInterval = new TimeInterval('MAX');

  public set selectedInterval(interval: TimeInterval) {
    this._selectedInterval = interval;
    this.filterConversations();
  }

  public get selectedInterval(): TimeInterval {
    return this._selectedInterval;
  }

  private userColors = [
    '#780078',
    '#787800',
    '#007878',
    '#FF7800',
    '#00FF78',
    '#7800FF',
    '#0078FF',
    '#78FF78',
    '#7878FF',
    '#FF7878',
    '#FF00FF',
    '#FFFF00',
    '#00FFFF',
    '#78FF00',
    '#0078FF',
    '#FF0078',
    '#00FF78',
    '#FF78FF',
    '#FFFF78',
    '#78FFFF',
  ];

  private userList: object[] = [];
  constructor(
    public humanInLoopService: HumanInLoopService,
    private authService: AuthService,
    private toasterService: ToastrService,
    private modalService: BsModalService,
  ) {
    this.scrollEmitter = new EventEmitter<any>();
    this.conversationClickEmitter = new EventEmitter<any>();
    this.refreshConversationsEmitter = new EventEmitter<any>();
    this.filterConversationEmitter = new EventEmitter<any>();
  }

  async ngOnInit() {
    let currentUser: firebase.User | undefined;
    if (!this.currentUserId) {
      try {
        currentUser = await this.authService.currentUser;

        if (!currentUser) {
          throw new Error('No user profile found');
        }
        this.currentUserId = currentUser.uid;
      } catch (error) {
        console.error('Error in HL Messages List', error);
      }
    }
    if (this.conversationSubscription) {
      this.conversationSubscription.unsubscribe();
    }
    this.filterConversations();
    this.humanInLoopService.clearData('conversations');
    this.conversationSubscription = this.humanInLoopService.conversationData.subscribe(
      (data: HLConversationModel[]) => {
        if (this.preloadedConversation) {
          const filtered = data.filter(c => c?.firebaseDocumentId !== this.preloadedConversation?.firebaseDocumentId);
          if (filtered.length === data.length) {
            data = [this.preloadedConversation, ...filtered];
          }
        }

        if (!this.clicked && (!this.searchMode || !this.conversationData.length)) {
          //console.log('Get ConversationData');
          this.conversationData = this.filterAndReturnData(data);
          // console.log('Processed ConversationData');
          // if data was previously empty but now has data
          // select the conversation to display
          if (this.previousConversationDataLength === 0) {
            // console.log('Select first conversation.');
            this.selectFirstConversation();
          }

          this.previousConversationDataLength = data.length;

          // if the filtered results were empty, send toast to let user know
          // no results were found, and DO NOT replace conversationData with empty array

          const hasSomeFilterApplied = Object.values(this.basicFilters).some(item => item === true);
          // console.log('hasSomeFilterApplied', hasSomeFilterApplied);
          if (data.length === 0 && hasSomeFilterApplied && !this.isFirstLoad) {
            this.toasterService.warning('No results found');
          }
        }
        this.loading = this.conversationData.length == 0 && this.loading;
      },
    );

    this.searchTextChanged.subscribe(query => {
      this.doSearchConversationsList(query);
      if (this.conversationSearchResult.length > 0) {
        this.conversationData = this.conversationSearchResult;
      }
    });

    this.isFirstLoad = false;
  }
  public timestampToDate(lastMessageDate, lastUpdated): Date {
    if (lastMessageDate === undefined) {
      return lastUpdated.toDate();
    }
    return lastMessageDate.toDate();
  }

  handleScroll(e): void {
    // const last: moment.Moment | undefined = this.lastScroll ? this.lastScroll : undefined;

    // if (!last || moment().diff(last, 'seconds') > 20) {
    // this.lastScroll = moment();
    this.scrollEmitter.emit(e);
    //}
  }

  scrollToConversation(conversationId: string) {
    const conversationDiv = document.getElementById(`conversation-item-${conversationId}`);
    if (conversationDiv) {
      const allConversationsDiv = document.getElementById('all-conversations-list');
      if (allConversationsDiv) {
        allConversationsDiv.scrollTop = conversationDiv.offsetTop - allConversationsDiv.offsetTop;
      }
    }
  }

  saveSessionParameters() {
    try {
      const storage = window.localStorage;
      if (storage) {
        const value = storage.getItem('dashboardParameters') || null;
        if (value !== null) {
          const json = JSON.parse(value);
          if (json && json.conversationId) {
            delete json.conversationId;
            storage.setItem('dashboardParameters', JSON.stringify(json));
          }
        }
      }
    } catch (err) {
      console.log('Failed storing messages', err);
    }
  }
  handleConversationClick(conversation: HLConversationModel) {
    if (conversation.firebaseDocumentId && this.setReadFlag) {
      const timeout = this.preloadedConversation ? 2000 : 500;
      const conversationId: string = conversation.firebaseDocumentId;

      if (conversation.status !== HLConversationStatus.Read) {
        this.clicked = true;
        // console.log('updateReadStatus');
        setTimeout(() => {
          let corpId =
            conversation.botId && conversation.botId.indexOf('-') > 1 ? conversation.botId.split('-')[0] : undefined;
          corpId = corpId || conversation['corporation'];
          corpId = corpId || '';

          this.humanInLoopService.updateConversationReadStatus(corpId, conversationId, true).then(() => {
            conversation.status = HLConversationStatus.Read;
            // console.log('updateReadStatus - Updated');
            this.clicked = false;
          });
          // const json = this.saveSessionParameters();
        }, timeout);
      }
    }
    const data: IConversationClickEvent = {
      conversation,
      isAutoSelectedConversation: false,
    };
    this.setReadFlag = true;
    if (this.searchMode) {
      if (this.preloadedConversation) {
        this.preloadedConversation = conversation;
      }
    }
    this.conversationClickEmitter.emit(data);
  }

  componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }
  rgbToHex(r, g, b) {
    return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  getAvatarColor(agent: HLConversationAgent) {
    if (agent.agentAvatarUrl) {
      return agent.agentAvatarUrl;
    }
    for (const user of this.userList) {
      const key = 'agentId';
      if (agent.agentId === user[key]) {
        const colorKey = 'color';
        return user[colorKey];
      }
    }
    const Red = Math.floor(Math.random() * 210) + 10;
    const Green = Math.floor(Math.random() * 220) + 20;
    const Blue = Math.floor(Math.random() * 200) + 30;

    const userObj = {
      agentId: agent.agentId,
      color: this.userColors.length > 0 ? this.userColors[0] : this.rgbToHex(Red, Green, Blue),
    };
    this.userList.push(userObj);
    if (this.userColors.length) {
      this.userColors.splice(0, 1);
    }
    return userObj.color;
  }
  refreshConversations(filters: IFirebaseFilterCriteria[]): void {
    this.isFirstLoad = true; // set this to true so first conv is selected after refresh
    this.refreshConversationsEmitter.emit(filters);
  }

  toggleBasicFilter(filterType: string): void {
    const currentFilterValue = this.basicFilters[filterType];
    this.basicFilters[filterType] = !currentFilterValue;

    const filters: IFirebaseFilterCriteria[] = [];

    // only apply the filter if it is set to true
    if (this.basicFilters[filterType]) {
      if (filterType === 'unread') {
        filters.push({
          field: 'status',
          operator: '==',
          value: 'unread',
        });
      }

      if (filterType === 'assignedToMe') {
        filters.push({
          field: 'assignmentUserIds',
          operator: 'array-contains',
          value: this.currentUserId,
        });
      }

      if (filterType === 'unassigned') {
        filters.push({
          field: 'assignmentCount',
          operator: '==',
          value: 0,
        });
      }
    }

    this.refreshConversations(filters);
  }

  searchConversationsList($event) {
    this.searchTextChanged.next(this.searchText);
  }

  private isNeedleInHaystack(needle: string) {
    return haystack => {
      return haystack && haystack.toLowerCase().includes(needle.toLowerCase());
    };
  }

  doSearchConversationsList(query: string) {
    if (query.trim().length > 0) {
      const isQueryPresent = this.isNeedleInHaystack(query);

      // search user data
      this.conversationSearchResult = this.conversationData.filter(convo => {
        if (convo.userData) {
          const { fullName } = convo.userData;
          const { toEmailAddresses } = convo;
          return (
            isQueryPresent(fullName) ||
            (toEmailAddresses && toEmailAddresses.length && isQueryPresent(toEmailAddresses[0])) ||
            (toEmailAddresses && toEmailAddresses.length > 1 && isQueryPresent(toEmailAddresses[1]))
          );
        }
        return false;
      });
      if (this.conversationSearchResult.length === 0) {
        // search with channel equals
        this.conversationSearchResult = this.conversationData.filter(c => {
          return c.lastChannel.trim().toLowerCase() === query.trim().toLowerCase();
        });
      }
      if (this.conversationSearchResult.length === 0) {
        // search with id equals
        this.conversationSearchResult = this.conversationData.filter(c => {
          return c.firebaseDocumentId && c.firebaseDocumentId.trim().toLowerCase() === query.trim().toLowerCase();
        });
      }

      if (this.conversationSearchResult.length === 0) {
        // search with id contains
        this.conversationSearchResult = this.conversationData.filter(c => {
          return c.firebaseDocumentId && c.firebaseDocumentId.trim().toLowerCase().includes(query.trim().toLowerCase());
        });
      }
      if (this.conversationSearchResult.length === 0) {
        // search with tag equals
        this.conversationSearchResult = this.conversationData.filter(c => {
          return c.tags && c.tags.some(tag => tag.trim().toLowerCase() === query.trim().toLowerCase());
        });
      }
      if (this.conversationSearchResult.length > 0) {
        this.conversationData = this.conversationSearchResult;
      }
      this.searchMode = true;
      return;
    }
    this.searchMode = false;
    return;
  }

  getUsersName(conversation: HLConversationModel) {
    if (conversation.userData && conversation.userData.fullName && conversation.userData.fullName.indexOf('null') < 0) {
      return conversation.userData.fullName;
    }
    if (conversation.userData?.firstName && conversation.userData?.lastName) {
      return conversation.userData.fullName + ' ' + conversation.userData?.lastName;
    }
    if (conversation.userData?.firstName) {
      return conversation.userData.fullName;
    }
    if (conversation.userData?.lastName) {
      return conversation.userData?.lastName;
    }
    if (conversation.userData?.email) {
      return conversation.userData?.email;
    }
    if (conversation.userData?.phoneNumber) {
      return conversation.userData?.phoneNumber;
    }
    return 'Guest';
  }

  getAgentInitials(name: string) {
    const names = name.split(' ');
    if (names.length <= 1) {
      return name.substring(0, 1).toUpperCase();
    }
    return names[0].substring(0, 1).toUpperCase() + names[1].substring(0, 1).toUpperCase();
  }

  clearFilters() {
    this.lastFilterCriteria = [];
    this.filterConversationEmitter.emit([]);
  }

  openAdvancedFiltersModal(): void {
    const modalRef = this.modalService.show(HlConversationSearchModalComponent, {
      initialState: {
        filters: [...this.lastFilterCriteria],
      },
      ignoreBackdropClick: true,
    });
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
    this.filterSubscription = modalRef.content.filter.subscribe((criteria: IFilterCriteria[]) => {
      modalRef.hide();
      this.lastFilterCriteria = criteria;
      // turn of search
      this.searchText = '';
      this.searchMode = false;
      this.filterConversationEmitter.emit(criteria);
    });
  }

  private filterAndReturnData(data: HLConversationModel[]): HLConversationModel[] {
    let filteredData = data;
    if (this.basicFilters.unread) {
      filteredData = filteredData.filter(item => item.status === HLConversationStatus.Unread);
    }
    if (this.basicFilters.assignedToMe) {
      filteredData = filteredData.filter(
        item =>
          item.assignments?.length && item.assignments.filter(assignment => assignment.agentId === this.currentUserId),
      );
    }
    if (this.basicFilters.unassigned) {
      filteredData = filteredData.filter(item => !item.assignments?.length);
    }

    if (this.touchedFilter) {
      filteredData = filteredData.filter(item => item.touched === false);
    }
    if (this.selectedInterval && this.selectedInterval.getType() !== 'MAX') {
      filteredData = filteredData.filter(
        // tslint:disable-next-line: no-non-null-assertion
        item => item.lastMessageDate >= firestore.Timestamp.fromDate(this.selectedInterval.getPastDate()!),
      );
    }

    return filteredData;
  }

  toggleTouchedFilter() {
    this.filterConversations();
  }

  filterConversations() {
    const filters: IFirebaseFilterCriteria[] = [];
    if (this.touchedFilter) {
      filters.push({
        field: 'touched',
        operator: '==',
        value: !this.touchedFilter,
      });
    }
    if (this.assignedToMeFilter) {
      filters.push({
        field: 'assignmentUserIds',
        operator: 'array-contains',
        value: this.currentUserId,
      });
    }

    let lastUpdated: firestore.Timestamp = firestore.Timestamp.fromDate(new Date(2000, 1, 1));
    if (this.selectedInterval && this.selectedInterval.getType() !== 'MAX') {
      // tslint:disable-next-line: no-non-null-assertion
      lastUpdated = firestore.Timestamp.fromDate(this.selectedInterval.getPastDate()!);
    }
    filters.push({
      field: 'lastUpdated',
      operator: '>',
      value: lastUpdated,
    });

    this.refreshConversations(filters);
  }

  generateNameSearchValueCombination(name: string): string[] {
    const synchronizeNameFirstLetters = this.synchronizeFirstLetters(name);
    return [
      name,
      synchronizeNameFirstLetters(input => input.toUpperCase()),
      synchronizeNameFirstLetters(input => input.toLowerCase()),
    ];
  }

  synchronizeFirstLetters =
    (words: string) =>
    (desiredCase: (input: string) => string): string => {
      return words
        .toLowerCase()
        .split(' ')
        .map(word => desiredCase(word.charAt(0)) + word.substring(1))
        .join(' ');
    };

  getIntendedSearchTextQueryField(searchText: string): SearchTextQueryFieldTypes {
    const conversationIdRegex = /^[0-9a-f]{8}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{12}$/;
    // tslint:disable-next-line:max-line-length
    const emailRegex =
      // tslint:disable-next-line: max-line-length
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [queryPart] = searchText.split('|');

    if (emailRegex.test(queryPart)) {
      return SearchTextQueryFieldTypes.Email;
    }
    if (conversationIdRegex.test(queryPart)) {
      return SearchTextQueryFieldTypes.ConversationId;
    }
    return SearchTextQueryFieldTypes.Name;
  }

  doSearchTextFilter() {
    this.filterConversations();
    this.searchMode = true;
  }

  clearSearchFilter() {
    this.searchText = '';
    this.filterConversations();
    this.searchMode = false;
    this.previousConversationDataLength = 0;
  }

  onSearchTextChange() {
    // Naive clear mechanism
    if (this.searchText.length === 0) {
      this.searchMode = false;
      this.filterConversations();
    }
  }

  private selectFirstConversation(): void {
    if (this.preloadedConversation && this.preloadedConversation.firebaseDocumentId) {
      // select preloaded convo and scroll to it
      this.setReadFlag = true;
      this.handleConversationClick(this.preloadedConversation);
      const conversationId = this.preloadedConversation.firebaseDocumentId;
      setTimeout(() => {
        this.scrollToConversation(conversationId);
      }, 100);
    } else {
      if (this.conversationData.length > 0) {
        // select first conversation on load
        this.setReadFlag = false;
        this.handleConversationClick(this.conversationData[0]);
      }
    }
  }

  ngOnDestroy() {
    this.saveSessionParameters();
    if (this.conversationSubscription) {
      this.conversationSubscription.unsubscribe();
    }
  }
}
