<app-human-in-the-loop-render
  (refreshUI)="refreshUI()"
  [conversationId]="preloadConversationId"
  *ngIf="bot || corpId || hierarchyElementSystemName"
  [bot]="bot"
  [botCode]="botCode"
  [botFullId]="botFullId"
  [mode]="hilMode"
  [corpId]="corpId"
  [envs]="envs"
  [hierarchy]="hierarchyElementSystemName"
></app-human-in-the-loop-render>
