import { MappingNode } from './_types/MappingNode';
import { ToastrService } from 'ngx-toastr';

export function getFilter() {
  const filter: any = {
    bool: {
      should: []
    }
  };

  let shouldConditionsCount = 0;
  if (this.botId) {
    filter.bool.should.push({
      term: {
        'messageReceivedEvent.context.platformPayload.vendorId.keyword': this.botId
      }
    });
    shouldConditionsCount++;
  }

  if (shouldConditionsCount > 0) {
    filter.bool.minimum_should_match = shouldConditionsCount;
  }

  return filter;
}

export function downloadCsvFile(csvData, toaster?: ToastrService) {
  if (toaster) {
    toaster.warning('Downloading the generated file.');
  }

  const fileName = 'data-export-'.concat(new Date().toISOString().split('T')[0]);

  const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  const downloadLink = document.createElement('a');
  const url = URL.createObjectURL(blob);
  const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
  if (isSafariBrowser) {
    // if Safari open in new window to save file with random filename.
    downloadLink.setAttribute('target', '_blank');
  }
  downloadLink.setAttribute('href', url);
  downloadLink.setAttribute('download', fileName + '.csv');
  downloadLink.style.visibility = 'hidden';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function processIndexesMapping(indexesMapping: {}): MappingNode {
  const finalMapping = {};

  if (indexesMapping) {
    Object.keys(indexesMapping).forEach(key => {
      // stop processing this iteration
      if (!key.startsWith('messages-')) {
        return;
      }

      const item = indexesMapping[key];
      processMapping(item, [], finalMapping);
    });
  }

  const mappingNode: MappingNode = createRootNode();
  generateMappingNodes(finalMapping, '', mappingNode);

  return mappingNode;
}

function generateMappingNodes(fieldsMapping: object, fullPathParam: string, mappingNode: MappingNode) {
  const fieldsMappingKeys: string[] = Object.keys(fieldsMapping);

  if (fieldsMappingKeys?.length < 1) {
    return;
  }

  for (const fieldKey of fieldsMappingKeys) {
    const fieldItem = fieldsMapping[fieldKey];
    const newFullPath = fullPathParam === '' ? fieldKey : fullPathParam + '.' + fieldKey;
    const newMappingNode = {
      fullPath: newFullPath,
      name: fieldKey,
      selected: false,
      parent: mappingNode,
      children: []
    };
    mappingNode.children.push(newMappingNode);

    generateMappingNodes(fieldItem, newFullPath, newMappingNode);
  }
}

function processMapping(node: any, fieldsPath: string[], finalMapping: any) {
  const nodeFields: string[] = Object.keys(node);

  if (typeof node === 'object') {
    const TYPE_FIELD = 'type';
    const FIELDS_FIELD = 'fields';

    if (nodeFields.length === 1 && nodeFields.includes(TYPE_FIELD)) {
      if (typeof node[TYPE_FIELD] === 'string') {
        return;
      }
    } else if (nodeFields.length > 1 && nodeFields.includes(TYPE_FIELD) && nodeFields.includes(FIELDS_FIELD)) {
      if (typeof node[TYPE_FIELD] === 'string' && typeof node[FIELDS_FIELD] === 'object') {
        return;
      }
    }
  }

  for (const field of nodeFields) {
    const fieldItem = node[field];
    const newFieldsPath = Object.assign([], fieldsPath);

    if ((field === 'mappings' || field === 'properties') && typeof fieldItem === 'object') {
      // do nothing
    } else {
      addFieldToFinalMapping(fieldsPath, finalMapping, field);
      newFieldsPath.push(field);
    }

    processMapping(fieldItem, newFieldsPath, finalMapping);
  }
}

function addFieldToFinalMapping(fieldsPath: string[], finalMapping: any, newField: string) {
  let finalMappingKeys: string[] = Object.keys(finalMapping);
  let finalMappingVar = finalMapping;

  // iterate up to the last field in the finalMapping
  fieldsPath.forEach(nodePath => {
    finalMappingKeys = Object.keys(finalMappingVar[nodePath]);
    finalMappingVar = finalMappingVar[nodePath];
  });

  // adding the new field to the finalMapping
  if (!finalMappingKeys || !finalMappingKeys.includes(newField)) {
    finalMappingVar[newField] = {};
  }
}

function createRootNode() {
  return {
    fullPath: 'root',
    name: 'root',
    selected: false,
    parent: null,
    children: []
  };
}
