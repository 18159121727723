<form (submit)="save()" #form="ngForm" novalidate>
    <fieldset class="container" [disabled]="!writable">
      <div class="form-group row">
        <h3>Settings</h3>
      </div>
      <div class="form-group row">
        <label class="form-control-label" for="inputValidationName">
          Name
        </label>
        <input
          class="form-control"
          id="inputValidationName"
          name="name"
          [(ngModel)]="inputValidation.name"
          type="text"
          placeholder="e.g. User Zipcode, Address"
          required
        />
      </div>
      <div class="form-group row">
        <label
          class="form-control-label"
          for="chatPosition"
        >
          Type
        </label>
        <select
          class="form-control"
          id="inputValidationType"
          name="inputValidationType"
          [(ngModel)]="inputValidation.type"
          required
        >
          <option value="" disabled selected>Select type</option>
          <option value="regex">Regex</option>
        </select>
      </div>
    </fieldset>
    <button *ngIf="writable" class="btn btn-primary btn-outline" type="submit" [disabled]="!form.valid || loading">
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span *ngIf="loading"> Saving...</span>
      <span *ngIf="!loading">SAVE</span>
    </button>
</form>
