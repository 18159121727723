<div class="modal-header">
  <h4 class="modal-title" [hidden]="createMode">Conversation Templates</h4>
  <h4 class="modal-title" [hidden]="!createMode">Create Conversation Template</h4>

  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<ng-container>
  <div class="modal-body" [hidden]="createMode">
    <ng-container *ngIf="allTemplates.length === 0">
      <small>No templates created yet.</small>
    </ng-container>
    <div class="row" [hidden]="allTemplates.length === 0">
      <div class="input-group input-group-alternative">
        <input class="form-control" #searchInput [(ngModel)]="search" placeholder="Search templates" type="text" />

        <div class="input-group-append">
          <span class="input-group-text">
            <i class="ni ni-zoom-split-in"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="row scrollable-body mt-3" [hidden]="allTemplates.length === 0">
      <ul *ngIf="searchMode">
        <li (click)="useTemplate(result)" *ngFor="let result of searchResult" class="row" style="cursor: pointer">
          <div class="col-lg-12 mb-2">
            <div class="justify-content-between align-items-center">
              <h3 class="mb-0">
                {{ result.name }}

                <span class="delete-template text-muted mr-2">
                  <i
                    (click)="removeTemplate(result, $event); $event.stopPropagation()"
                    class="fa fa-trash text-danger ml-3 cursor-pointer"
                  ></i>
                </span>
              </h3>
            </div>
          </div>

          <tag-input
            [ngModel]="result.tags"
            *ngIf="result.tags"
            [modelAsStrings]="true"
            theme="regular-theme"
            [inputClass]="'convo-tags-input'"
            [editable]="false"
            [disable]="true"
            [hideForm]="true"
          ></tag-input>
        </li>
      </ul>

      <ul *ngIf="!searchMode">
        <li
          (click)="useTemplate(template)"
          *ngFor="let template of allTemplates"
          class="row mb-2"
          style="cursor: pointer"
        >
          <div class="col-lg-12 mb-2">
            <div class="justify-content-between align-items-center">
              <h3 class="mb-0">
                {{ template.name }}

                <span class="delete-template text-muted mr-2">
                  <i
                    (click)="removeTemplate(template, $event); $event.stopPropagation()"
                    class="fa fa-trash text-danger ml-3 cursor-pointer"
                  ></i>
                </span>
              </h3>
            </div>
            <tag-input
              [ngModel]="template.tags"
              *ngIf="template.tags"
              [modelAsStrings]="true"
              theme="regular-theme"
              [inputClass]="'convo-tags-input'"
              [editable]="false"
              [disable]="true"
              [hideForm]="true"
            ></tag-input>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-container>

<ng-container>
  <div class="modal-body" [hidden]="!createMode">
    <div class="form-group">
      <label class="form-control-label" for="templateName">Template Name </label>
      <input
        class="form-control"
        id="templateName"
        name="templateName"
        [(ngModel)]="template.name"
        type="text"
        placeholder="Name for your template"
        required
      />
    </div>
    <div class="form-group">
      <label class="form-control-label" for="channelId">Channel</label>
      <select class="form-control" [(ngModel)]="template.channel" name="channelId">
        <option value="">Select</option>
        <option *ngFor="let channel of channels" [ngValue]="channel.id">{{ channel.name }}</option>
      </select>
    </div>

    <div class="form-group">
      <label class="form-control-label" for="tags">Tags</label>
      <div class="col p-0">
        <select (change)="addTag($event.target.value)" class="col-sm form-control" name="kpiSelect">
          <option value="">Select Tag</option>
          <ng-container *ngFor="let tag of corpTags">
            <option *ngIf="!template.tags.includes(tag)" value="{{ tag }}">{{ tag }}</option>
          </ng-container>
        </select>
      </div>
      <tag-input
        [(ngModel)]="template.tags"
        placeholder="Add a new tag"
        [modelAsStrings]="true"
        theme="regular-theme"
        name="tags"
        id="tags"
        [editable]="false"
        [hideForm]="true"
      >
      </tag-input>
    </div>

    <div class="form-group">
      <label class="form-control-label" for="defaultValue">Content</label>

      <angular-editor [(ngModel)]="template.content" id="message-input" [config]="editorConfig"> </angular-editor>
    </div>
  </div>
</ng-container>

<div class="modal-footer" [hidden]="createMode">
  <button (click)="createMode = true" type="button" class="btn btn-primary btn-sm" aria-label="New Template">
    New Template
  </button>
</div>

<div class="modal-footer" [hidden]="!createMode">
  <button (click)="createTemplate()" type="button" class="btn btn-primary btn-sm" aria-label="Create">Create</button>

  <button (click)="createMode = false" type="button" class="btn btn-sm" aria-label="Cancel">Cancel</button>
</div>
