<div class="modal-header">
<app-action-progress></app-action-progress>
    <h4 class="modal-title">Manage Associations for {{ user.fullName }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class=" table-responsive" *ngFor="let userAssociationsGrouping of userAssociationsGroupings; let userAssociationsGroupingIndex = index">
        <table class=" table align-items-center table-flush">
          <thead class=" thead-light">
            <tr>
              <th scope="col" colspan="3" class="text-center">{{ userAssociationsGrouping.inDepthTitle }}</th>
            </tr>
            <tr>
              <th scope="col">{{ userAssociationsGrouping.singleTitle }}</th>
              <th scope="col" *ngIf="!userAssociationsGrouping.isBot" >Show {{ userAssociationsGrouping.childrenName }}</th>
              <th scope="col">Member</th>
            </tr>
          </thead>
          <tbody class=" list">
            <tr *ngFor="let userAssociation of userAssociationsGrouping.associations; let userAssociationIndex = index">
              <td>
                  {{ userAssociation.label }}
              </td>
              <td *ngIf="!userAssociationsGrouping.isBot">
                <button *ngIf="userAssociation.hasChildren" class="btn btn-primary btn-outline btn-sm" type="button" (click)="showChildren(userAssociation, userAssociationsGroupingIndex)">Show {{ userAssociation.childrenName }} </button>
                <button *ngIf="!userAssociation.hasChildren" (click)="modal.hide()" [routerLink]="['/admin/corps/' + userAssociation.systemName + '/hierarchies']" class="btn btn-secondary btn-outline btn-sm" type="button">Create Hierarchy</button>
            </td>
              <td>
                <div class=" custom-control custom-checkbox" (click)="toggleAssociation(userAssociation)">
                  <input
                    class=" custom-control-input"
                    type="checkbox"
                    [ngModel]="userAssociation.isMember"
                  />
                  <label class=" custom-control-label">
                  </label>
                </div>
              </td>
            </tr>
            <tr *ngIf="userAssociationsGrouping.associations.length === 0">
              <td colspan="3" class="text-center">No {{ userAssociationsGrouping.title }} </td>
            </tr>
          </tbody>
        </table>
      </div>
    <ng-container *ngIf="loading">
      <div class=" container-fluid">
        <div class=" row align-items-center py-4 justify-content-center">
          <app-loading></app-loading>
        </div>
      </div>
    </ng-container>
</div>
<div class="modal-footer">
    <button class="btn btn-primary btn-outline" type="submit" (click)="modal.hide()">CLOSE</button>
</div>