<ng-container>
<div *ngIf="invalidBotVariables.length>0" class=" card-deck flex-column flex-xl-row mt-4">
          <div class=" card">
              <div class="card-header">
                <div class="row">
                  <div class="col-8">
                    <h5 class="h3 mb-0">
                    <i class="fa fa-exclamation-triangle" style="color:#FF8C00;" aria-hidden="true"></i>
                      Required Information
                      <small>
                      <br/>
                      Please complete the required field(s) below in order to publish your bot.
                      </small>
                    </h5>                  
                  </div>
                  <div class="col-4 text-right">
                    <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>                
              </div>
              <div class="card-body p-0 scrollable-card">
                <ul class="list-group list-group-flush" data-toggle="checklist">
                    <li *ngFor="let botVariable of invalidBotVariables"
                      class="checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                      <div>
                        <div class="checklist-info">
                          <h5 class="checklist-title mb-0">
                            {{botVariable.label}}
                          </h5>
                          <small>{{botVariable.description}}</small>
                          <h5>
                            <small>
                              <input [(ngModel)]="botVariable.defaultValue"  class="form-control md-6 lg-6 sm-12"  />
                            </small>
                          </h5>
                        </div>
                      </div>                    
                    </li>
                    <li class="checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                      <button type="button" class="btn btn-outline-primary" (click)="saveBotVariable()">
                      Save
                      </button> 
                    </li>
                </ul>
                                      
              </div>
          </div>
        </div>
</ng-container>