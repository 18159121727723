<form (submit)="save()" #form="ngForm" novalidate>
  <fieldset class="container" [disabled]="!writable">
    <div>
      <tabset>
        <tab heading="Settings" id="tab1">
          <div class="form-group row mb-2 mt-2">
            <h3>Settings</h3>
          </div>

          <!-- Name -->
          <div class="form-group row">
            <label class="form-control-label" for="botName"> Name </label>
            <input
              class="form-control required"
              id="botName"
              name="name"
              [(ngModel)]="bot.label"
              type="text"
              placeholder="e.g. Carla, Bubotti"
              [appUniqueBotName]="botNameList"
              #name="ngModel"
              [class.is-invalid]="!name.valid"
              required
            />
            <div *ngIf="!name.dirty || name.errors?.required" class="invalid-feedback">
              {{ validationMessages.required }}
            </div>
            <div *ngIf="name.errors?.alreadyExists" class="invalid-feedback">
              {{ validationMessages.similarBotName }}
            </div>
          </div>

          <!-- Root Element ID -->
          <div class="form-group row">
            <label class="form-control-label" for="rootElementId"> Root Element ID </label>
            <input
              class="form-control"
              id="rootElementId"
              name="rootElementId"
              [(ngModel)]="bot.rootElementId"
              type="text"
              placeholder="carlabs-root-element"
              [class.is-invalid]="!rootId.valid"
              #rootId="ngModel"
              required
            />
            <div *ngIf="rootId.errors?.required" class="invalid-feedback">
              {{ validationMessages.required }}
            </div>
          </div>

          <!-- Init On Load -->
          <div class="form-group row">
            <div class="col-12 no-gutters p-0">
              <label class="form-control-label" for="initOnLoad"> Init On Load </label>
            </div>
            <div class="col p-0">
              <label class="custom-toggle mr-1">
                <input
                  checked="checked"
                  type="checkbox"
                  id="initOnLoad"
                  name="initOnLoad"
                  [(ngModel)]="bot.initOnLoad"
                />
                <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
              </label>
            </div>
          </div>

          <!-- Error Message -->
          <div class="form-group row">
            <label class="form-control-label" for="errorMessage"> Error Message </label>
            <input
              class="form-control"
              id="errorMessage"
              name="errorMessage"
              [(ngModel)]="bot.errorMessage"
              type="text"
              placeholder="I'm sorry, but I didn't quite get that. Can you rephrase your question?"
              [class.is-invalid]="!errorMessage.valid"
              #errorMessage="ngModel"
              required
            />
            <div *ngIf="errorMessage.errors?.required" class="invalid-feedback">
              {{ validationMessages.required }}
            </div>
          </div>

          <!-- Session Storage Access -->
          <div class="form-group row">
            <label class="form-control-label" for="sessionStorageAccess">
              Session Storage Access
              <div>
                <small>Currently limited to one key.</small>
              </div>
            </label>

            <input
              class="form-control"
              id="rootElementId"
              name="sessionStorageAccess"
              [(ngModel)]="bot.sessionStorageAccess"
              type="text"
              placeholder="myKey"
              [class.is-invalid]="!sessionStorageAccess.valid"
              #sessionStorageAccess="ngModel"
            />
            <div *ngIf="sessionStorageAccess.errors?.required" class="invalid-feedback">
              {{ validationMessages.required }}
            </div>
          </div>

          <div class="form-group row mb-2 mt-2">
            <h3>Assistant Teaser</h3>
          </div>

          <!-- Show bot teaser -->
          <div class="form-group row">
            <div class="col-12 no-gutters p-0">
              <label class="form-control-label" for="showTeaser"> Show Teaser </label>
            </div>
            <div class="col p-0">
              <label class="custom-toggle mr-1">
                <input
                  checked="checked"
                  type="checkbox"
                  id="showTeaser"
                  name="showTeaser"
                  [(ngModel)]="bot.styles.showTeaser"
                />
                <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
              </label>
            </div>
          </div>

          <div *ngIf="bot.styles.showTeaser">
            <!-- Interactive bot teaser -->
            <div class="form-group row">
              <div class="col-12 no-gutters p-0">
                <label class="form-control-label" for="interactiveTeaser"> Interactive Teaser </label>
              </div>
              <div class="col p-0">
                <label class="custom-toggle mr-1">
                  <input
                    checked="checked"
                    type="checkbox"
                    id="interactiveTeaser"
                    name="interactiveTeaser"
                    [(ngModel)]="bot.styles.interactiveTeaser"
                  />
                  <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
                </label>
              </div>
            </div>
          </div>

          <div *ngIf="bot.styles.interactiveTeaser && bot.styles.showTeaser">
            <!-- Show Teaser Background -->
            <div class="form-group row">
              <div class="col-12 no-gutters p-0">
                <label class="form-control-label" for="showTeaserBackground"> Show Teaser Background </label>
              </div>
              <div class="col p-0">
                <label class="custom-toggle mr-1">
                  <input
                    checked="checked"
                    type="checkbox"
                    id="showTeaserBackground"
                    name="showTeaserBackground"
                    [(ngModel)]="bot.styles.teaserStyle.showBackground"
                  />
                  <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
                </label>
              </div>
            </div>

            <!-- Teaser Background Color-->
            <div class="form-group row">
              <label class="form-control-label" for="backgroundColor"> Teaser Background Color </label>
              <input
                class="form-control"
                id="backgroundColor"
                name="backgroundColor"
                [(ngModel)]="bot.styles.teaserStyle.backgroundColor"
                type="color"
              />
            </div>

            <!-- Teaser Text -->
            <div class="form-group row">
              <div class="col-12 no-gutters p-0">
                <label class="form-control-label" for="teaserText"> Teaser Text </label>
              </div>
              <div class="col p-0">
                <tag-input
                  [(ngModel)]="bot.styles.teaserText"
                  placeholder="Add a new teaser text"
                  [modelAsStrings]="true"
                  [disable]="!writable"
                  theme="regular-theme"
                  name="teaserText"
                  id="teaserText"
                >
                </tag-input>
              </div>
            </div>

            <!-- Teaser Buttons -->
            <div class="form-group row">
              <div class="col-12 no-gutters p-0">
                <label class="form-control-label" for="teaserText"> Teaser Buttons </label>
              </div>

              <div class="col p-0">
                <button
                  title="Click to remove"
                  type="button"
                  (click)="removeTeaserQRB(teaser)"
                  *ngFor="let teaser of teasersQRB"
                  class="rounded btn btn-primary p-2"
                >
                  {{ teaser.title }}
                </button>
              </div>

              <div class="col-12 p-0 mt-2">
                <!-- 
                  Custom Teasers are for text chat bubbles and QRBS 
                  -- text chat can be multiple
                  -- qrbs can be multiple 
                  -- qrbs can have title and value. 
                  The value can be a text or pointing to a node (i.e __setnode__ {node_id})
                -->
                <button
                  title="Click to add new teaser button"
                  class="btn btn-secondary"
                  type="button"
                  (click)="addTeaserQRB()"
                >
                  Add QRB
                </button>
              </div>
            </div>

            <!-- Teaser Background Color-->
            <div class="form-group row">
              <label class="form-control-label" for="buttonBackgroundColor"> Teaser Button Background Color </label>
              <input
                class="form-control"
                id="buttonBackgroundColor"
                name="buttonBackgroundColor"
                [(ngModel)]="bot.styles.teaserStyle.buttonBackgroundColor"
                type="color"
              />
            </div>
          </div>

          <div *ngIf="!bot.styles.interactiveTeaser && bot.styles.showTeaser">
            <!-- Non interactive teaser text -->
            <div class="form-group row">
              <div class="col-12 no-gutters p-0">
                <label class="form-control-label" for="nonInteractiveTeaserText"> Non Interactive Teaser Text </label>
              </div>
              <div class="col p-0">
                <input
                  class="form-control"
                  placeholder="Add a new teaser text"
                  id="nonInteractiveTeaserText"
                  name="nonInteractiveTeaserText"
                  [(ngModel)]="bot.styles.nonInteractiveTeaserText"
                  type="text"
                />
              </div>
            </div>
          </div>

          <!-- Open bot on init -->
          <div class="form-group row">
            <div class="col-12 no-gutters p-0">
              <label class="form-control-label" for="openBotOnInit"> Open Assistant on Init </label>
            </div>
            <div class="col p-0">
              <label class="custom-toggle mr-1">
                <input
                  checked="checked"
                  type="checkbox"
                  id="openBotOnInit"
                  name="openBotOnInit"
                  [(ngModel)]="bot.styles.openBotOnInit"
                />
                <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
              </label>
            </div>
          </div>

          <!-- Chat Position -->
          <div class="form-group row">
            <label class="form-control-label" for="chatPosition"> Chat Position </label>
            <select class="form-control" id="chatPosition" name="chatPosition" [(ngModel)]="bot.styles.chatPosition">
              <option value="left">Left</option>
              <option value="right">Right</option>
            </select>
          </div>

          <!-- Fixed Position -->
          <div class="form-group row">
            <div class="col-12 no-gutters p-0">
              <label class="form-control-label" for="fixedPosition"> Fixed Position </label>
            </div>
            <div class="col p-0">
              <label class="custom-toggle mr-1">
                <input
                  checked="checked"
                  type="checkbox"
                  id="fixedPosition"
                  name="fixedPosition"
                  [(ngModel)]="bot.styles.fixedPosition"
                />
                <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
              </label>
            </div>
          </div>

          <!-- Full Screen On Mobile -->
          <div class="form-group row">
            <div class="col-12 no-gutters p-0">
              <label class="form-control-label" for="fullScreenOnMobile"> Full Screen On Mobile </label>
            </div>
            <div class="col p-0">
              <label class="custom-toggle mr-1">
                <input
                  checked="checked"
                  type="checkbox"
                  id="fullScreenOnMobile"
                  name="fullScreenOnMobile"
                  [(ngModel)]="bot.styles.fullScreenOnMobile"
                />
                <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
              </label>
            </div>
          </div>

          <!-- Debug Mode -->
          <div class="form-group row">
            <div class="col-12 no-gutters p-0">
              <label class="form-control-label" for="debugMode"> Debug Mode </label>
            </div>
            <div class="col p-0">
              <label class="custom-toggle mr-1">
                <input checked="checked" type="checkbox" id="debugMode" name="debugMode" [(ngModel)]="bot.debugMode" />
                <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
              </label>
            </div>
          </div>

          <!-- Intro Node -->
          <div class="form-group row">
            <label class="form-control-label" for="initNodeId"> Intro Node </label>
            <select class="form-control" [(ngModel)]="bot.initNodeId" name="initNodeId">
              <option value="">None</option>
              <option *ngFor="let n of nodes" [ngValue]="n.id">{{ n.name }}</option>
            </select>
          </div>

          <!-- Clear Previous Session -->
          <div class="form-group row">
            <div class="col-12 no-gutters p-0">
              <label class="form-control-label" for="clearPreviousSessionOnInit">
                Clear Previous Session on Init
              </label>
            </div>
            <div class="col p-0">
              <label class="custom-toggle mr-1">
                <input
                  checked="checked"
                  type="checkbox"
                  id="clearPreviousSessionOnInit"
                  name="clearPreviousSessionOnInit"
                  [(ngModel)]="bot.clearPreviousSessionOnInit"
                />
                <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
              </label>
            </div>
          </div>
        </tab>

        <!-- Configurations Tab -->
        <tab heading="Configurations" id="configurations-tab">
          <div class="form-group row mt-2">
            <h3>Configurations</h3>
          </div>

          <div class="form-group row mb-2">
            <small>These settings are static to the current assistant environment.</small>
          </div>

          <!-- API ID -->
          <div *ngIf="isAdmin" class="form-group row">
            <label class="form-control-label" for="apiGatewayServiceId"> API ID </label>
            <input
              class="form-control"
              id="apiGatewayServiceId"
              name="apiGatewayServiceId"
              [(ngModel)]="bot.apiGatewayServiceId"
              type="text"
              placeholder="ID"
              #apiId="ngModel"
              [class.is-invalid]="!apiId.valid"
            />
          </div>

          <!-- Google Sheets -->
          <div *ngIf="isAdmin" class="form-group row">
            <label class="form-control-label" for="googleSheetUrl"> Google Sheet URL </label>
            <input
              class="form-control"
              id="googleSheetUrl"
              name="googleSheetUrl"
              [(ngModel)]="bot.config.googleSheetUrl"
              type="url"
              placeholder="URL"
            />
          </div>

          <!-- Locale -->
          <div class="form-group row">
            <label class="form-control-label" for="locale"> Locale </label>

            <select
              class="form-control"
              id="locale"
              name="locale"
              [(ngModel)]="bot.config.locale"
              #locale="ngModel"
              [class.is-invalid]="!locale.valid"
            >
              <option value="" disabled selected>Select Locale</option>
              <option *ngFor="let country of countries" [ngValue]="country.locale">{{ country.locale }}</option>
            </select>
          </div>

          <!-- Region -->
          <div class="form-group row">
            <label class="form-control-label" for="locale">
              Region
              <a
                tooltip="Represents the region where the data will be stored. Please do not change if you don't know what it does!"
                ><i class="fa fa-info-circle text-warning"></i
              ></a>
            </label>

            <select
              class="form-control"
              id="region"
              name="region"
              [(ngModel)]="bot.config.awsRegion"
              #region="ngModel"
              [class.is-invalid]="!region.valid"
              required
            >
              <option value="" disabled selected>Select Region</option>
              <option *ngFor="let country of countries" [ngValue]="country.region">{{ country.region }}</option>
            </select>
            <div *ngIf="!region.dirty || region.errors?.required" class="invalid-feedback">
              {{ validationMessages.required }}
            </div>
          </div>

          <!-- NLP Model -->
          <div *ngIf="isAdmin" class="form-group row">
            <label class="form-control-label" for="nlp-model"> NLP Model </label>
            <select
              class="form-control"
              id="nlp-model"
              name="nlp-model"
              [(ngModel)]="bot.config.nlp.model"
              #nlpModel="ngModel"
              [class.is-invalid]="!nlpModel.valid"
            >
              <option value="" disabled selected>Select NLP Model</option>
              <option *ngFor="let nlpModel of nlpModels" [ngValue]="nlpModel.id">{{ nlpModel.name }}</option>
            </select>
          </div>

          <!-- Handover Configs -->
          <div class="form-group row">
            <div class="col-12 px-0">
              <label class="form-control-label"> Handover Service </label>
            </div>
            <div class="col-12 px-0 my-1 col-md-6 px-md-1">
              <input
                class="form-control"
                name="handover-service-name"
                [(ngModel)]="bot.config.handoverConfig.name"
                type="text"
                placeholder="Name"
              />
            </div>
            <div class="col-12 px-0 my-1 col-md-6 px-md-1">
              <input
                class="form-control"
                name="handover-service-authToken"
                [(ngModel)]="bot.config.handoverConfig.authToken"
                type="text"
                placeholder="Auth Token"
              />
            </div>
            <div class="col-12 px-0 my-1 px-md-1">
              <input
                class="form-control"
                name="handover-service-metaData"
                [(ngModel)]="bot.config.handoverConfig.metaData"
                type="text"
                placeholder="Meta Data"
              />
            </div>
          </div>

          <!-- Facebook Page IDs -->
          <div class="form-group row my-0">
            <label class="form-control-label"> Facebook Page IDs </label>
          </div>
          <div class="card row p-0" *ngFor="let pageConfig of bot.facebookPageConfigs; let i = index">
            <div class="form-group row p-2 m-0">
              <div class="col-sm p-0 m-0">
                <input
                  class="form-control"
                  name="botConfigFacebookPageConfigAppId-{{ i }}"
                  [ngModel]="bot.facebookPageConfigs[i].appId"
                  type="text"
                  placeholder="App ID"
                  disabled
                  required
                />
              </div>
            </div>
            <div class="form-group row p-2 m-0">
              <div class="col-sm p-0 m-0">
                <input
                  class="form-control"
                  name="botConfigFacebookPageConfigAppSecret-{{ i }}"
                  [ngModel]="bot.facebookPageConfigs[i].appSecret"
                  type="text"
                  placeholder="App Secret"
                  disabled
                  required
                />
              </div>
            </div>
            <div class="form-group row p-2 m-0">
              <div class="col-sm p-0 m-0">
                <input
                  class="form-control"
                  name="botConfigFacebookPageConfigPageId-{{ i }}"
                  [ngModel]="bot.facebookPageConfigs[i].pageId"
                  type="text"
                  placeholder="Page ID"
                  disabled
                  required
                />
              </div>
            </div>
            <div class="form-group row p-2 m-0">
              <div class="col-sm p-0 m-0">
                <input
                  class="form-control"
                  name="botConfigFacebookPageConfigPageAccessToken-{{ i }}"
                  [ngModel]="bot.facebookPageConfigs[i].pageAccessToken"
                  type="text"
                  placeholder="Page Access Token"
                  disabled
                  required
                />
              </div>
            </div>
            <div class="form-group row p-2 m-0">
              <div class="col-sm p-0 m-0">
                <input
                  class="form-control"
                  name="botConfigFacebookPageConfigThreadControl-{{ i }}"
                  [ngModel]="bot.facebookPageConfigs[i].passThreadControlTargetAppId"
                  type="text"
                  placeholder="Pass Thread Control Target App Id"
                  disabled
                  required
                />
              </div>
            </div>
            <div class="form-group row p-2 m-0">
              <div class="col-sm p-0 m-0">
                <button
                  class="btn btn-sm btn-block btn-secondary m-0"
                  type="button"
                  (click)="removeFacebookPageConfig(pageConfig.pageId)"
                >
                  Remove Facebook Page ID
                </button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <button class="btn btn-sm btn-primary btn-block" type="button" (click)="addFacebookPageConfig()">
              Add Facebook Page ID
            </button>
          </div>
        </tab>
        <tab heading="Variables" id="variables-tab">
          <app-render-global-variables
            title="Assistant Variables"
            variableType="BOT"
            description="These variables will give the same value through out the flow and are not changeable within the flow"
            (handleChanges)="handleGlobalVariableChanges($event)"
            [items]="botVariableItems"
          >
          </app-render-global-variables>

          <app-render-global-variables
            title="User Variables"
            variableType="USER_INPUT"
            description="These variables are unique for every user and can only be set through user responses"
            (handleChanges)="handleGlobalVariableChanges($event)"
            [items]="userVariableItems"
          >
          </app-render-global-variables>

          <app-render-global-variables
            title="API Variables"
            variableType="API_DATA"
            description="These variables are also unique for every user and can only be set through API responses"
            (handleChanges)="handleGlobalVariableChanges($event)"
            [items]="apiVariableItems"
          >
          </app-render-global-variables>
        </tab>

        <!-- Style tab -->
        <tab heading="Style" id="styles-tab">
          <!-- Header -->
          <div class="form-group row mb-2 mt-2">
            <h3>Chat Header</h3>
          </div>
          <!-- Header Text -->
          <div class="form-group row">
            <label class="form-control-label" for="headerText"> Header Text </label>
            <input
              class="form-control"
              id="headerText"
              name="headerText"
              [(ngModel)]="bot.styles.headerText"
              type="text"
            />
          </div>
          <!-- Header Size -->
          <div class="form-group row">
            <label class="form-control-label" for="headerSize"> Header Size </label>
            <input
              class="form-control"
              id="headerSize"
              name="headerSize"
              type="number"
              [(ngModel)]="bot.styles.headerSize"
            />
          </div>
          <!-- Header Text Color -->
          <div class="form-group row">
            <label class="form-control-label" for="headerTextColor"> Header Text Color </label>
            <input
              class="form-control"
              id="headerTextColor"
              name="headerTextColor"
              [(ngModel)]="bot.styles.headerTextColor"
              type="color"
            />
          </div>
          <!-- Header Text Size -->
          <div class="form-group row">
            <label class="form-control-label" for="headerTextSize"> Header Text Size </label>
            <input
              class="form-control"
              id="headerTextSize"
              name="headerTextSize"
              type="number"
              [(ngModel)]="bot.styles.headerTextSize"
            />
          </div>
          <!-- Header Text Font Family -->
          <div class="form-group row">
            <label class="form-control-label" for="headerTextFontFamily"> Header Text Font Family </label>
            <input
              class="form-control"
              id="headerTextFontFamily"
              name="headerTextFontFamily"
              [(ngModel)]="bot.styles.headerTextFontFamily"
              type="text"
            />
          </div>
          <!-- Header Background Color -->
          <div class="form-group row">
            <label class="form-control-label" for="headerBackgroundColor"> Header Background Color </label>
            <input
              class="form-control"
              id="headerBackgroundColor"
              name="headerBackgroundColor"
              [(ngModel)]="bot.styles.headerBackgroundColor"
              type="color"
            />
          </div>
          <!-- Close Button Size -->
          <div class="form-group row">
            <label class="form-control-label" for="closeButtonSize"> Close Button Size </label>
            <input
              class="form-control"
              id="closeButtonSize"
              name="closeButtonSize"
              type="number"
              [(ngModel)]="bot.styles.closeButtonSize"
            />
          </div>

          <!-- Chat Container -->
          <div class="form-group row mb-2 mt-2">
            <h3>Chat Container</h3>
          </div>
          <!-- Chat Container Width -->
          <div class="form-group row">
            <label class="form-control-label" for="chatContainerWidth"> Chat Container Width </label>
            <input
              class="form-control"
              id="chatContainerWidth"
              name="chatContainerWidth"
              type="number"
              [(ngModel)]="bot.styles.chatContainerWidth"
            />
          </div>
          <!-- Chat Container Height -->
          <div class="form-group row">
            <label class="form-control-label" for="chatContainerHeight"> Chat Container Height </label>
            <input
              class="form-control"
              id="chatContainerHeight"
              name="chatContainerHeight"
              type="number"
              [(ngModel)]="bot.styles.chatContainerHeight"
            />
          </div>
          <!-- Chat Container Border Radius -->
          <div class="form-group row">
            <label class="form-control-label" for="chatContainerBorderRadius"> Chat Container Border Radius </label>
            <input
              class="form-control"
              id="chatContainerBorderRadius"
              name="chatContainerBorderRadius"
              type="number"
              [(ngModel)]="bot.styles.chatContainerBorderRadius"
            />
          </div>
          <!-- Chat Container Background Image URL -->
          <div class="form-group row">
            <label class="form-control-label" for="chatContainerBackgroundImageUrl">
              Chat Container Background Image URL
            </label>
            <input
              class="form-control"
              id="chatContainerBackgroundImageUrl"
              name="chatContainerBackgroundImageUrl"
              [(ngModel)]="bot.styles.chatContainerBackgroundImageUrl"
              type="text"
            />
          </div>
          <!-- Chat Container Background Color -->
          <div class="form-group row">
            <label class="form-control-label" for="chatContainerBackgroundColor">
              Chat Container Background Color
            </label>
            <input
              class="form-control"
              id="chatContainerBackgroundColor"
              name="chatContainerBackgroundColor"
              type="color"
              [(ngModel)]="bot.styles.chatContainerBackgroundColor"
            />
          </div>

          <!-- Text -->
          <div class="form-group row mb-2 mt-2">
            <h3>Text</h3>
          </div>
          <!-- Text Bubble Radius -->
          <div class="form-group row">
            <label class="form-control-label" for="textBubbleRadius"> Text Bubble Radius </label>
            <input
              class="form-control"
              id="textBubbleRadius"
              name="textBubbleRadius"
              type="number"
              [(ngModel)]="bot.styles.textBubbleRadius"
            />
          </div>
          <!-- Distance Between Bubbles -->
          <div class="form-group row">
            <label class="form-control-label" for="distanceBetweenBubbles"> Distance Between Bubbles </label>
            <input
              class="form-control"
              id="distanceBetweenBubbles"
              name="distanceBetweenBubbles"
              type="number"
              [(ngModel)]="bot.styles.distanceBetweenBubbles"
            />
          </div>
          <!-- Text Font Size -->
          <div class="form-group row">
            <label class="form-control-label" for="textFontSize"> Text Font Size </label>
            <input
              class="form-control"
              id="textFontSize"
              name="textFontSize"
              type="number"
              [(ngModel)]="bot.styles.textFontSize"
            />
          </div>
          <!-- Text Font Family -->
          <div class="form-group row">
            <label class="form-control-label" for="textFontFamily"> Text Font Family </label>
            <input
              class="form-control"
              id="textFontFamily"
              name="textFontFamily"
              [(ngModel)]="bot.styles.textFontFamily"
              type="text"
            />
          </div>
          <!-- Text User Color -->
          <div class="form-group row">
            <label class="form-control-label" for="textUserColor"> Text User Color </label>
            <input
              class="form-control"
              id="textUserColor"
              name="textUserColor"
              type="color"
              [(ngModel)]="bot.styles.textUserColor"
            />
          </div>
          <!-- Text Assistant Color -->
          <div class="form-group row">
            <label class="form-control-label" for="textBotColor"> Text Assistant Color </label>
            <input
              class="form-control"
              id="textBotColor"
              name="textBotColor"
              type="color"
              [(ngModel)]="bot.styles.textBotColor"
            />
          </div>
          <!-- Text User Background Color -->
          <div class="form-group row">
            <label class="form-control-label" for="textUserBackgroundColor"> Text User Background Color </label>
            <input
              class="form-control"
              id="textUserBackgroundColor"
              name="textUserBackgroundColor"
              type="color"
              [(ngModel)]="bot.styles.textUserBackgroundColor"
            />
          </div>
          <!-- Text Assistant Background Color -->
          <div class="form-group row">
            <label class="form-control-label" for="textBotBackgroundColor"> Text Assistant Background Color </label>
            <input
              class="form-control"
              id="textBotBackgroundColor"
              name="textBotBackgroundColor"
              type="color"
              [(ngModel)]="bot.styles.textBotBackgroundColor"
            />
          </div>

          <!-- Suggested Actions -->
          <div class="form-group row mb-2 mt-2">
            <h3>Suggested Actions</h3>
          </div>
          <!-- Suggested Actions Background Color -->
          <div class="form-group row">
            <label class="form-control-label" for="suggestedActionsBackgroundColor">
              Suggested Actions Background Color
            </label>
            <input
              class="form-control"
              id="suggestedActionsBackgroundColor"
              name="suggestedActionsBackgroundColor"
              type="color"
              [(ngModel)]="bot.styles.suggestedActionsBackgroundColor"
            />
          </div>
          <!-- Suggested Actions Border-->
          <div class="form-group row">
            <label class="form-control-label" for="suggestedActionsBorder"> Suggested Actions Border </label>
            <input
              class="form-control"
              id="suggestedActionsBorder"
              name="suggestedActionsBorder"
              [(ngModel)]="bot.styles.suggestedActionsBorder"
              type="text"
            />
          </div>
          <!-- Suggested Actions Height -->
          <div class="form-group row">
            <label class="form-control-label" for="suggestedActionsHeight"> Suggested Actions Height </label>
            <input
              class="form-control"
              id="suggestedActionsHeight"
              name="suggestedActionsHeight"
              type="number"
              [(ngModel)]="bot.styles.suggestedActionsHeight"
            />
          </div>
          <!-- Action Buttons Distance -->
          <div class="form-group row">
            <label class="form-control-label" for="actionButtonsDistance"> Action Buttons Distance </label>
            <input
              class="form-control"
              id="actionButtonsDistance"
              name="actionButtonsDistance"
              type="number"
              [(ngModel)]="bot.styles.actionButtonsDistance"
            />
          </div>
          <!-- Action Button Border -->
          <div class="form-group row">
            <label class="form-control-label" for="actionButtonBorder"> Action Button Border </label>
            <input
              class="form-control"
              id="actionButtonBorder"
              name="actionButtonBorder"
              [(ngModel)]="bot.styles.actionButtonBorder"
              type="text"
            />
          </div>
          <!-- Action Button Border Radius -->
          <div class="form-group row">
            <label class="form-control-label" for="actionButtonBorderRadius"> Action Button Border Radius </label>
            <input
              class="form-control"
              id="actionButtonBorderRadius"
              name="actionButtonBorderRadius"
              type="number"
              [(ngModel)]="bot.styles.actionButtonBorderRadius"
            />
          </div>
          <!-- Action Button Color -->
          <div class="form-group row">
            <label class="form-control-label" for="actionButtonColor"> Action Button Color </label>
            <input
              class="form-control"
              id="actionButtonColor"
              name="actionButtonColor"
              type="color"
              [(ngModel)]="bot.styles.actionButtonColor"
            />
          </div>
          <!-- Action Button Background Color -->
          <div class="form-group row">
            <label class="form-control-label" for="actionButtonBackgroundColor"> Action Button Background Color </label>
            <input
              class="form-control"
              id="actionButtonBackgroundColor"
              name="actionButtonBackgroundColor"
              type="color"
              [(ngModel)]="bot.styles.actionButtonBackgroundColor"
            />
          </div>
          <!-- Action Button Font Size -->
          <div class="form-group row">
            <label class="form-control-label" for="actionButtonFontSize"> Action Button Font Size </label>
            <input
              class="form-control"
              id="actionButtonFontSize"
              name="actionButtonFontSize"
              type="number"
              [(ngModel)]="bot.styles.actionButtonFontSize"
            />
          </div>
          <!-- Action Button Font Weight -->
          <div class="form-group row">
            <label class="form-control-label" for="actionButtonFontWeight"> Action Button Font Weight </label>
            <input
              class="form-control"
              id="actionButtonFontWeight"
              name="actionButtonFontWeight"
              type="number"
              [(ngModel)]="bot.styles.actionButtonFontWeight"
            />
          </div>
          <!-- Action Button Font Family -->
          <div class="form-group row">
            <label class="form-control-label" for="actionButtonFontFamily"> Action Button Font Family </label>
            <input
              class="form-control"
              id="actionButtonFontFamily"
              name="actionButtonFontFamily"
              [(ngModel)]="bot.styles.actionButtonFontFamily"
              type="text"
            />
          </div>
          <!-- Suggested Actions Arrow Width -->
          <div class="form-group row">
            <label class="form-control-label" for="suggestedActionsArrowWidth"> Suggested Actions Arrow Width </label>
            <input
              class="form-control"
              id="suggestedActionsArrowWidth"
              name="suggestedActionsArrowWidth"
              type="number"
              [(ngModel)]="bot.styles.suggestedActionsArrowWidth"
            />
          </div>
          <!-- Suggested Actions Arrow Border -->
          <div class="form-group row">
            <label class="form-control-label" for="suggestedActionsArrowBorder"> Suggested Actions Arrow Border </label>
            <input
              class="form-control"
              id="suggestedActionsArrowBorder"
              name="suggestedActionsArrowBorder"
              [(ngModel)]="bot.styles.suggestedActionsArrowBorder"
              type="text"
            />
          </div>
          <!-- Suggested Actions Arrow Background Color -->
          <div class="form-group row">
            <label class="form-control-label" for="suggestedActionsArrowBackgroundColor">
              Suggested Actions Arrow Background Color
            </label>
            <input
              class="form-control"
              id="suggestedActionsArrowBackgroundColor"
              name="suggestedActionsArrowBackgroundColor"
              type="color"
              [(ngModel)]="bot.styles.suggestedActionsArrowBackgroundColor"
            />
          </div>
          <!-- Suggested Actions Arrow Image (Left) URL -->
          <div class="form-group row">
            <label class="form-control-label" for="suggestedActionsArrowImageUrlLeft">
              Suggested Actions Arrow Image (Left) URL
            </label>
            <input
              class="form-control"
              id="suggestedActionsArrowImageUrlLeft"
              name="suggestedActionsArrowImageUrlLeft"
              [(ngModel)]="bot.styles.suggestedActionsArrowImageUrlLeft"
              type="text"
            />
          </div>
          <!-- Suggested Actions Arrow Image (Right) URL -->
          <div class="form-group row">
            <label class="form-control-label" for="suggestedActionsArrowImageUrlRight">
              Suggested Actions Arrow Image (Right) URL
            </label>
            <input
              class="form-control"
              id="suggestedActionsArrowImageUrlRight"
              name="suggestedActionsArrowImageUrlRight"
              [(ngModel)]="bot.styles.suggestedActionsArrowImageUrlRight"
              type="text"
            />
          </div>
          <!-- Suggested Actions Arrow Size -->
          <div class="form-group row">
            <label class="form-control-label" for="suggestedActionsArrowSize"> Suggested Actions Arrow Size </label>
            <input
              class="form-control"
              id="suggestedActionsArrowSize"
              name="suggestedActionsArrowSize"
              type="number"
              [(ngModel)]="bot.styles.suggestedActionsArrowSize"
            />
          </div>

          <!-- Input -->
          <div class="form-group row mb-2 mt-2">
            <h3>Input</h3>
          </div>
          <!-- Input Box Placeholder Text -->
          <div class="form-group row">
            <label class="form-control-label" for="inputBoxPlaceholderText"> Input Box Placeholder Text </label>
            <input
              class="form-control"
              id="inputBoxPlaceholderText"
              name="inputBoxPlaceholderText"
              [(ngModel)]="bot.styles.inputBoxPlaceholderText"
              type="text"
            />
          </div>
          <!-- Input Height -->
          <div class="form-group row">
            <label class="form-control-label" for="inputHeight"> Input Height </label>
            <input
              class="form-control"
              id="inputHeight"
              name="inputHeight"
              type="number"
              [(ngModel)]="bot.styles.inputHeight"
            />
          </div>
          <!-- Input Border -->
          <div class="form-group row">
            <label class="form-control-label" for="inputBorder"> Input Border </label>
            <input
              class="form-control"
              id="inputBorder"
              name="inputBorder"
              [(ngModel)]="bot.styles.inputBorder"
              type="text"
            />
          </div>
          <!-- Show Hamburger -->
          <div class="form-group row">
            <div class="col-12 no-gutters p-0">
              <label class="form-control-label" for="showHamburger"> Show Hamburger </label>
            </div>
            <div class="col p-0">
              <label class="custom-toggle mr-1">
                <input
                  checked="checked"
                  type="checkbox"
                  id="showHamburger"
                  name="showHamburger"
                  [(ngModel)]="bot.styles.showHamburger"
                />
                <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
              </label>
            </div>
          </div>
          <!-- Send Image Size -->
          <div class="form-group row">
            <label class="form-control-label" for="sendImageSize"> Send Image Size </label>
            <input
              class="form-control"
              id="sendImageSize"
              name="sendImageSize"
              type="number"
              [(ngModel)]="bot.styles.sendImageSize"
            />
          </div>

          <!-- Mobile View -->
          <div class="form-group row mb-2 mt-2">
            <h3>Mobile View</h3>
          </div>
          <!-- Mobile Height -->
          <div class="form-group row">
            <label class="form-control-label" for="avatarSize"> Mobile Height: {{ bot.styles.mobileHeight }}% </label>
            <input
              [(ngModel)]="bot.styles.mobileHeight"
              id="mobileHeight"
              name="mobileHeight"
              class="avatar-size-range"
              type="range"
              max="100"
              min="40"
            />
          </div>

          <!-- Avatar -->
          <div class="form-group row mb-2 mt-2">
            <h3>Avatar</h3>
          </div>
          <!-- Show Avatar -->
          <div class="form-group row">
            <div class="col-12 no-gutters p-0">
              <label class="form-control-label" for="showAvatar"> Show Avatar </label>
            </div>
            <div class="col p-0">
              <label class="custom-toggle mr-1">
                <input
                  checked="checked"
                  type="checkbox"
                  id="showAvatar"
                  name="showAvatar"
                  [(ngModel)]="bot.styles.showAvatar"
                />
                <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"> </span>
              </label>
            </div>
          </div>
          <!-- Avatar size -->
          <div class="form-group row">
            <label class="form-control-label" for="avatarSize"> Avatar Size: {{ bot.styles.avatarSize }}% </label>
            <input
              [(ngModel)]="bot.styles.avatarSize"
              id="avatarSize"
              name="avatarSize"
              class="avatar-size-range"
              type="range"
              max="200"
              min="50"
            />
          </div>
          <!-- Avatar Image -->
          <div class="form-group row">
            <div class="col-12 no-gutters p-0">
              <label class="form-control-label"> Avatar Image </label>
            </div>
            <div class="col p-0">
              <app-editable-image
                [avatarWidth]="scaledAvatarSize"
                [avatarHeight]="scaledAvatarSize"
                [source]="bot.styles.avatarImageUrl"
                [editable]="writable"
                (fileSelect)="avatarFile = $event"
                (remove)="bot.styles.avatarImageUrl = undefined"
              >
              </app-editable-image>
            </div>
          </div>
          <!-- Avatar Animation -->
          <div class="form-group row">
            <label class="form-control-label" for="avatarAnimation"> Avatar Animation </label>
            <select
              class="form-control"
              id="avatarAnimation"
              name="avatarAnimation"
              [(ngModel)]="bot.styles.avatarAnimation"
            >
              <option value="bounce">Bounce</option>
              <option value="static">Static</option>
            </select>
          </div>
        </tab>
      </tabset>
    </div>
  </fieldset>
  <button
    data-test-id="save-bot-button"
    *ngIf="writable"
    class="btn btn-primary btn-outline"
    type="submit"
    [disabled]="!form.valid || loading"
  >
    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span *ngIf="loading"> Saving...</span>
    <span *ngIf="!loading">SAVE</span>
  </button>
  <button class="btn btn-primary btn-outline" (click)="canceled.emit()" type="button">CANCEL</button>
</form>
