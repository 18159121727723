<div class="card mt-4">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">Edit profile</h3>
      </div>
    </div>
  </div>
  <div class="card-body">
    <form (submit)="save()" *ngIf="user" #form="ngForm" novalidate>
      <h6 class="heading-small text-muted mb-4">Your information</h6>
      <div class="pl-lg-4">
        <app-editable-image
          [source]="user.avatar"
          (fileSelect)="avatarFile = $event"
          (remove)="user.avatar = undefined"
        >
        </app-editable-image>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="input-email"> Email address </label
              ><input
                class="form-control"
                id="input-email"
                type="email"
                placeholder="Email"
                [(ngModel)]="user.email"
                name="email"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="input-first-name"> First name </label
              ><input
                class="form-control"
                id="input-first-name"
                type="text"
                placeholder="First name"
                [(ngModel)]="user.firstName"
                name="firstName"
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="input-last-name"> Last name </label
              ><input
                class="form-control"
                id="input-last-name"
                type="text"
                placeholder="Last name"
                [(ngModel)]="user.lastName"
                name="lastName"
                required
              />
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label"> Receive Email Notifications </label>
              <br />
              <label class="custom-toggle mr-1">
                <input name="notification-email" type="checkbox" [(ngModel)]="notificationSettings.email" />
                <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
              </label>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label"> Receive In-App Notifications </label>
              <br />
              <label class="custom-toggle mr-1">
                <input name="notification-in-app" type="checkbox" [(ngModel)]="notificationSettings.in_app" />
                <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
              </label>
            </div>
          </div>
        </div>
        <button class="btn btn-primary" type="submit" [disabled]="!form.valid || loading">
          <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="loading"> Saving...</span>
          <span *ngIf="!loading">SAVE</span>
        </button>
        <button class="btn btn-secondary" (click)="canceled.emit()">CANCEL</button>
      </div>
    </form>
  </div>
</div>

<div class="card mt-4">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">Change Password</h3>
      </div>
    </div>
  </div>
  <div class="card-body">
    <form role="form" [formGroup]="changePasswordForm" novalidate>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="input-old-password"> Old Password </label
              ><input
                class="form-control"
                id="input-old-password"
                type="password"
                autocomplete="current-password"
                placeholder="Old Password"
                formControlName="oldPassword"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="input-new-password"> New Password </label
              ><input
                class="form-control"
                id="input-new-password"
                type="password"
                autocomplete="new-password"
                placeholder="New Password"
                formControlName="newPassword"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="input-confirm-new-password"> Confirm New Password </label
              ><input
                class="form-control"
                id="input-confirm-new-password"
                type="password"
                autocomplete="new-password"
                placeholder=" Confirm New Password"
                formControlName="reNewPassword"
              />
            </div>
          </div>
        </div>
        <button
          class="btn btn-primary"
          type="submit"
          (click)="onUpdatePasswordSubmit()"
          [disabled]="!changePasswordForm.valid || makingChangePasswordRequest"
        >
          <span
            *ngIf="makingChangePasswordRequest"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span *ngIf="makingChangePasswordRequest"> Changing...</span>
          <span *ngIf="!makingChangePasswordRequest">Change Password</span>
        </button>
      </div>
    </form>
  </div>
</div>
