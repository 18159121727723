import { SidebarItemVM } from 'src/app/components/layout/sidebar/_types/SidebarItemVM';

export const getSidebarItems = (): SidebarItemVM[] => {
  const items: SidebarItemVM[] = [
    {
      path: `/portal/dashboard`,
      title: 'Dashboard',
      type: 'link',
      icontype: 'ni-world-2 text-primary'
    },
    // { TODO: this will be enabled when will fix the analytics on root level
    //   path: `/portal/analytics`,
    //   title: 'Analytics',
    //   type: 'link',
    //   icontype: 'ni-chart-bar-32 text-danger'
    // },
    {
      path: `/portal/nlp/models`,
      title: 'NLP Models',
      type: 'link',
      icontype: 'ni-collection text-info'
    },
    {
      path: `/portal/nlp/datasets`,
      title: 'NLP Datasets',
      type: 'link',
      icontype: 'ni-collection text-info'
    },
    {
      path: `/portal/global-flow-templates`,
      title: 'Global Flow Templates',
      type: 'link',
      icontype: 'ni-folder-17 text-primary'
    }
  ];

  return items;
};
