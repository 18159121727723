import { Pipe, PipeTransform } from '@angular/core';
import uniqBy from 'lodash/uniqBy';

@Pipe({
  name: 'filterUnique',
  pure: false
})
export class FilterUniquePipe implements PipeTransform {
  transform(value: any, filterKey: string, sortKey?: string, sortOrder?: 'asc' | 'desc'): any {
    if (sortKey && sortOrder) {
      if (sortOrder === 'asc') {
        return uniqBy(value, filterKey).sort((a, b) => (a[sortKey] > b[sortKey] ? 1 : -1));
      }
      return uniqBy(value, filterKey).sort((a, b) => (a[sortKey] < b[sortKey] ? 1 : -1));
    }

    return uniqBy(value, filterKey);
  }
}
