import { Component, OnDestroy, OnInit } from '@angular/core';
import { RoleModel } from 'src/app/models';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { SidebarService } from '../../../services/sidebar.service';
import { RolesService, UsersService } from 'src/app/services/firestore';
import { AddRoleModalComponent } from './_components/add-role-modal/add-role-modal.component';
import { EditRoleModalComponent } from './_components/edit-role-modal/edit-role-modal.component';
import { getSidebarItems } from 'src/app/pages/admin/utils';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-roles-admin',
  templateUrl: './roles-admin.component.html',
  styleUrls: ['./roles-admin.component.scss'],
})
export class RolesAdminComponent implements OnInit, OnDestroy {
  loading: boolean;
  roles: RoleModel[];
  dataSubscription: Subscription;

  constructor(
    private rolesService: RolesService,
    private usersService: UsersService,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private modalService: BsModalService,
    private toaster: ToastrService,
    private sidebarService: SidebarService,
    private authService: AuthService,
  ) {
    this.loading = false;
  }

  ngOnInit() {
    this.setBreadcrumb();
    this.refreshRoles();
    this.headerService.setPageTitle('Roles');
    this.setSidebarItems();
  }

  openAddRoleModal() {
    this.modalService.show(AddRoleModalComponent, { ignoreBackdropClick: true });
  }

  openEditRoleModal(role: RoleModel) {
    this.modalService.show(EditRoleModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        role,
        canWriteRoles: this.canWriteRoles(),
      },
    });
  }

  setBreadcrumb() {
    this.breadcrumbService.set([]);
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  canWriteRoles(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_WRITE_ROLES);
  }

  confirmAndDeleteRole(role: RoleModel) {
    // Test this works as intended
    const modalRef = this.modalService.show(ConfirmationModalComponent, { ignoreBackdropClick: true });
    modalRef.content.title = 'Delete Role';
    modalRef.content.message = `Are you sure you want to delete role ${role.name}?`;
    modalRef.content.confirm.subscribe(() => {
      this.usersService.getUsersByRole(role.systemName).subscribe(
        users => {
          if (users.length) {
            this.toaster.error('This role has users. Please reset the roles for this users before deleting the role.');
            return;
          }
          this.deleteRole(role);
        },
        error => {
          this.toaster.error(error);
        },
      );
    });
  }

  private refreshRoles() {
    this.loading = true;
    this.dataSubscription = this.rolesService.getRoles().subscribe(
      (roles: RoleModel[]) => {
        this.roles = roles;
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.toaster.error(error);
      },
    );
  }

  private async deleteRole(role: RoleModel) {
    try {
      await this.rolesService.removeRole(role.systemName);
      this.toaster.success('Role deleted');
    } catch (error) {
      this.toaster.error(error);
    }
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }
}
