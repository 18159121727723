<div class="modal-header">
  <h4 class="modal-title">Add a New Dataset File</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-nlp-dataset-file-general-info
    [writable]="true"
    [datasetSystemName]="datasetSystemName"
    [nlpDatasetFile]="nlpDatasetFile"
    [shouldAdd]="true"
    (saved)="this.modal.hide()"
  >
  </app-nlp-dataset-file-general-info>
</div>
