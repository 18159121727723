<ng-container *ngIf="loading">
  <div class=" container-fluid">
    <div class=" row align-items-center py-4 justify-content-center">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading">
  <div class=" container-fluid">
    <div class=" row">
      <div *ngIf="bot" class=" col-lg-6 col-sm-12">
        <div class=" card m-4">
          <div class=" card-header">
            <h3>General</h3>
          </div>
          <div class=" card-body">
            <app-bot-general-info
              [writable]="true"
              [bot]="bot"
              (saved)="navigateToParentScreen()"
              (canceled)="navigateToParentScreen()"
            >
            </app-bot-general-info
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
