import { CountryCodeEnum } from './CountryCodeEnum';
import { AwsRegionEnum } from './AwsRegionEnum';
import { LocaleEnum } from './LocaleEnum';

/**
 * This is an enum class.
 */
export class CountryEnum {
  private static VALUES: CountryEnum[] = [];

  public static US = new CountryEnum(CountryCodeEnum.US, LocaleEnum.EN_US, AwsRegionEnum.US_EAST_1);
  public static IN = new CountryEnum(CountryCodeEnum.IN, LocaleEnum.EN_IN, AwsRegionEnum.AP_SOUTH_1);
  public static MX = new CountryEnum(CountryCodeEnum.MX, LocaleEnum.ES_MX, AwsRegionEnum.US_EAST_1);

  public readonly code: CountryCodeEnum;
  public readonly locale: LocaleEnum;
  public readonly region: AwsRegionEnum;

  constructor(code: CountryCodeEnum, locale: LocaleEnum, region: AwsRegionEnum) {
    this.code = code;
    this.locale = locale;
    this.region = region;

    CountryEnum.VALUES.push(this);
  }

  public static values() {
    return CountryEnum.VALUES;
  }

  public static getCountryByLocale(locale: LocaleEnum | string): CountryEnum | undefined {
    const countryEnums = this.values().filter(country => country.locale === locale);
    return countryEnums ? countryEnums[0] : undefined;
  }
}
