import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { integer } from 'aws-sdk/clients/cloudfront';

@Injectable({
  providedIn: 'root',
})
export class BotConfigService {
  private botConfigUrl: string;
  private latestVersion: integer;

  constructor(private http: HttpClient) {
    this.latestVersion = environment.botConfig.latestVersion;
    this.botConfigUrl = environment.botConfig.url;
  }

  async getBotConfigById(botId: string) {
    try {
      const result = await this.http.get(this.botConfigUrl + botId).toPromise();
      return result;
    } catch (e) {
      console.error('Failed to fetch bot', e);
      throw e;
    }
  }

  async isLastestVersion(botId: string) {
    const url = this.botConfigUrl + botId;
    const result = await this.http
      .get(url)
      .toPromise()
      .then(data => {
        return data;
      })
      .catch(data => {
        console.error(data);
      });

    if (result) {
      if (result['version'] !== undefined) {
        return parseInt(result['version']) === this.latestVersion;
      }

      if (result['isV3'] !== undefined) {
        return result['isV3'] === false;
      }
    }
    return false;
  }
}
