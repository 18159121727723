import { Component, OnInit } from '@angular/core';
import { StateVariable } from 'src/app/models/state_variables';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { StateVariablesService } from 'src/app/services/firestore';

@Component({
  selector: 'app-render-conversation-state-variables',
  templateUrl: './render-conversation-state-variables.component.html',
  styleUrls: ['./render-conversation-state-variables.component.scss'],
})
export class RenderConversationStateVariablesComponent implements OnInit {
  items: StateVariable[] = [];
  title = 'State Variables';
  description = `These state variables give you access to the conversation/context data in order to use data about that specific session. 
  For example, each conversation has a userID of the person the bot is talking to.`;

  constructor(private stateVariablesService: StateVariablesService, private toaster: ToastrService) {
    this.stateVariablesService
      .getStateVariables()
      .pipe(take(1))
      .subscribe(items => {
        this.items = items;
      });
  }

  ngOnInit() {}

  processCopySuccess() {
    this.toaster.success('Successfully copied to clipboard');
  }
}
