import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UploadCampaignService } from 'src/app/services/upload.campaign.service';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { getSidebarItems as getBotSidebarItems, getBreadcrumbItems } from 'src/app/pages/portal/bot/utils';
import { SidebarService } from 'src/app/services/sidebar.service';
import { BotsService, CorpsService, HierarchyElementsService } from 'src/app/services/firestore';
import { BotModel, CorpModel, HierarchyElementModel } from 'src/app/models';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { Subscription, combineLatest } from 'rxjs';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { CancelConfirmModalComponent } from '../../modals/cancel-confirm-modal/cancel-confirm-modal.component';
import { HttpError } from 'http-errors';
import { DumbledoreService } from 'src/app/services/dumbledore.service';
import { ManifestConversationType } from 'src/app/models/manifest-conversation-types';
import { moveWeekendDateToFollowingMonday } from 'src/app/utils/date/date-utils';

enum CampaignChoice {
  Recall = 'recallCampaign',
  NewMarketing = 'newMarketingCampaign',
  ExistingMarketing = 'existingMarketingCampaign',
}

enum Steps {
  CampaignType = 0,
  CampaignDetails = 1,
  UploadList = 2,
}

const CONTENT_SLA_DAYS = 7;

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class CreateCampaignComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private uploadCampaignService: UploadCampaignService,
    private dumbledoreService: DumbledoreService,
    private router: Router,
    private toasterService: ToastrService,
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    private hierarchyElementsService: HierarchyElementsService,
    private breadcrumbService: BreadcrumbService,
    private corpsService: CorpsService,
    private botsService: BotsService,
    private clientEnvironmentService: ClientEnvironmentService,
    private dialog: MatDialog,
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {
    // When the page is first navigated to from the 'Create New Campaign' button,
    // we pass the state to the router, when the page is refreshed, we lose the state,
    // so we store in localStorage
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state;
    if (state && 'dealershipId' in state) {
      this.dealershipId = state.dealershipId;
      localStorage.setItem('dealershipId', this.dealershipId);
    } else {
      this.dealershipId = localStorage.getItem('dealershipId');
    }
    if (state && 'userName' in state) {
      this.userName = state.userName;
      localStorage.setItem('userName', this.userName);
    } else {
      this.userName = localStorage.getItem('userName');
    }
  }

  manifestEnabled: boolean = false;

  dealershipId: string | null = null;
  userName: string | null = null;

  selectedCampaign: CampaignChoice = CampaignChoice.Recall;

  newCampaignFormGroup: FormGroup;
  marketingCampaignFormGroup: FormGroup;
  campaignDetailsFormGroup: FormGroup;
  uploadCustomerListFormGroup: FormGroup;

  minDate: Date;
  newMarketingMinDate: Date;
  defaultMinDate: Date;

  manifestOptions: string[] = [];

  fileName: string = '';
  fileId: string = '';
  fileContent: string = '';
  isValidated: boolean = false;
  validationErrorMessage: string = '';
  validating: boolean = false;
  validationLineErrors: any[] | null = null;

  processing: boolean = false;

  corpCode: string | null = null;
  botCode: string | null = null;
  hierarchyElementSystemName: string | null = null;
  hierarchyElement: HierarchyElementModel | null = null;

  bot: BotModel;
  corp: CorpModel;

  crtDataSubscription: Subscription;

  onFileSelected(event: any): void {
    const fileList = event.target.files;
    if (!fileList.length) return;
    const file = fileList[0];
    this.fileName = file.name;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      this.fileContent = fileReader.result?.toString();
    };
  }

  removeSelectedFile(): void {
    this.fileContent = '';
    this.fileName = '';
    this.validationErrorMessage = '';
    this.validationLineErrors = null;
    this.isValidated = false;
    this.fileId = '';
  }

  truncateFileName(fileName: string, limit: number): string {
    if (!fileName) return '';
    return fileName.length > limit ? fileName.substring(0, limit) + '...' : fileName;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CancelConfirmModalComponent, {
      data: {
        title: 'Are you sure you want to cancel Campaign Creation?',
        message: 'You will lose all the information entered for this Campaign',
        cancelButtonText: 'Keep It',
        confirmButtonText: 'Cancel Campaign Creation',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.router.navigate(['../'], {
          relativeTo: this.route,
        });
      } else {
        // Remove focus from cancel button when modal is closed
        const el = this.elRef.nativeElement.querySelector('#cancelBtn');
        this.renderer.removeClass(el, 'cdk-focused');
        this.renderer.removeClass(el, 'cdk-program-focused');
      }
    });
  }

  ngOnInit(): void {
    // Can use snapshot instead of paramMap subscription since component does not contain it's own navigation
    this.corpCode = this.route.snapshot.paramMap.get('corp');
    this.hierarchyElementSystemName = this.route.snapshot.paramMap.get('hierarchyElementSystemName');
    this.botCode = this.route.snapshot.paramMap.get('bot');

    // Necessary for settings sidebar items and breadcrumbs in fashion similar to other components
    this.crtDataSubscription = combineLatest([
      this.corpsService.getCorpById(this.corpCode),
      this.hierarchyElementsService.getHierarchyElement(`${this.corpCode}-${this.hierarchyElementSystemName}`),
      this.clientEnvironmentService.items$,
    ]).subscribe(async ([corp, hierarchyElement, envs]) => {
      if (!corp || !hierarchyElement || !envs) {
        return;
      }

      this.hierarchyElement = hierarchyElement;
      this.sidebarService.set(getBotSidebarItems(this.corpCode, this.hierarchyElement, this.botCode));

      try {
        const bot = await this.botsService.getBotBy(this.corpCode, this.hierarchyElementSystemName, this.botCode, envs);
        if (bot) {
          this.bot = bot;
        }
      } catch (error) {
        throw new Error('Valid bot / bot config required');
      }

      this.corp = corp;

      let items = getBreadcrumbItems(this.corp, this.hierarchyElement, this.bot, 'Dashboard', 'dashboard');
      items.push({
        label: 'Create New Campaign',
        route: `portal/corps/${this.corp.id}/hierarchy-el/${this.hierarchyElement.systemNameForUrl}/bots/${this.bot.code}/dashboard/create-new-campaign`,
      });
      this.breadcrumbService.set(items);

      try {
        const result = await this.dumbledoreService.getManifestOptions(this.dealershipId);
        this.manifestOptions = result;
      } catch (e) {
        this.toasterService.error('Could not fetch Campaign Presets');
      }
    });

    this.defaultMinDate = moveWeekendDateToFollowingMonday(moment().add(1, 'days').toDate());
    this.newMarketingMinDate = moveWeekendDateToFollowingMonday(moment().add(CONTENT_SLA_DAYS, 'days').toDate());

    this.minDate = this.defaultMinDate;

    this.newCampaignFormGroup = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: ['', Validators.required],
      followUpMessages: [
        '',
        [Validators.required, this.integerValidator, this.integerValidatorPositive, this.maxFollowUpMessagesValidator],
      ],
    });

    this.marketingCampaignFormGroup = this.formBuilder.group({
      sheetName: ['', Validators.required],
    });

    this.campaignDetailsFormGroup = this.formBuilder.group({
      startDate: [this.minDate, Validators.required],
      maxMessages: [
        '50',
        [Validators.required, this.integerValidator, this.integerValidatorPositive, this.integerValidatorNotZero],
      ],
    });

    this.uploadCustomerListFormGroup = this.formBuilder.group({
      listName: ['', Validators.required],
      uploadErrorsEmailAddress: ['', [Validators.required, Validators.email]],
    });
  }

  disableNextButton(stepper: MatStepper) {
    switch (stepper.selectedIndex) {
      case Steps.CampaignType:
        switch (this.selectedCampaign) {
          case CampaignChoice.NewMarketing:
            return this.newCampaignFormGroup.invalid;
          case CampaignChoice.ExistingMarketing:
            return this.marketingCampaignFormGroup.invalid;
        }
        break;
      case Steps.CampaignDetails:
        return this.campaignDetailsFormGroup.invalid;
      case Steps.UploadList:
        return this.uploadCustomerListFormGroup.invalid || !this.isValidated;
    }
    return false;
  }

  selectCampaign(campaign: CampaignChoice) {
    this.selectedCampaign = campaign;
    this.updateMinDate();
  }

  // These validators are designed so that only one validation error will be returned since
  // there is only room for a single error message under each form input. For example, integerValidator will not return an error
  // if the input value is an empty string since Validators.required will handle that.
  // Similarly, maxFollowUpMessages will only return an error when the value is an integer, since integerValidator will handle that.

  integerValidator(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    if (!Number.isInteger(Number(value))) return { notInteger: true };
    return null;
  }

  maxFollowUpMessagesValidator(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const numValue = Number(value);
    if (value && Number.isInteger(numValue) && numValue > 5) return { maxFollowUpMessages: true };
    return null;
  }

  integerValidatorPositive(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const numValue = Number(value);
    if (value && Number.isInteger(numValue) && numValue < 0) return { notNegative: true };
    return null;
  }

  integerValidatorNotZero(control: AbstractControl): ValidationErrors | null {
    const { value } = control;
    const numValue = Number(value);
    if (value && Number.isInteger(numValue) && numValue === 0) return { notZero: true };
    return null;
  }

  // Update Date Picker minDate and default value
  updateMinDate() {
    this.minDate =
      this.selectedCampaign === CampaignChoice.NewMarketing ? this.newMarketingMinDate : this.defaultMinDate;
    this.campaignDetailsFormGroup.get('startDate').setValue(this.minDate);
  }

  onCancel() {
    this.openDialog();
  }

  async onValidate() {
    this.validating = true;

    let manifestName;
    switch (this.selectedCampaign) {
      case CampaignChoice.ExistingMarketing:
        manifestName = this.marketingCampaignFormGroup.value.sheetName;
        break;
      case CampaignChoice.NewMarketing:
        manifestName = this.newCampaignFormGroup.value.title;
        break;
      default:
        manifestName = undefined;
        break;
    }

    const validationResult = await this.uploadCampaignService.validateInput(
      this.uploadCustomerListFormGroup.value.listName,
      this.uploadCustomerListFormGroup.value.uploadErrorsEmailAddress,
      this.fileName,
      this.fileContent,
      this.dealershipId,
      this.selectedCampaign === CampaignChoice.Recall
        ? ManifestConversationType.Recall
        : ManifestConversationType.Marketing,
      manifestName,
    );

    this.validating = false;

    if (validationResult.success) {
      this.validationLineErrors = null;
      this.validationErrorMessage = '';
      this.isValidated = true;
      this.fileId = validationResult.fileId;
      this.toasterService.success('File validated successfully');
    } else {
      this.isValidated = false;
      if (validationResult.errorMessage) {
        this.validationErrorMessage = validationResult.errorMessage;
      } else {
        if (validationResult.numberOfErrors === 1) {
          this.validationErrorMessage = 'There is 1 line with problem(s) in the file.';
        } else {
          this.validationErrorMessage = `There are ${validationResult.numberOfErrors} lines with problems in the file.`;
        }
        this.validationLineErrors = validationResult.errorLines;
        this.validationLineErrors =
          this.validationLineErrors.length > 5 ? this.validationLineErrors.slice(0, 5) : this.validationLineErrors;
      }
      this.toasterService.error(this.validationErrorMessage);
    }
  }

  onPrevious(stepper: MatStepper) {
    if (stepper.selected) stepper.selected.completed = false;
    stepper.previous();
  }

  // TODO: Edge case, process succeeds but createNewManifest fails - delete data for processed / validated file because we don't want
  // broken entries in DB
  async onNext(stepper: MatStepper) {
    if (stepper.selectedIndex === Steps.UploadList) {
      this.processing = true;

      let manifestName;
      switch (this.selectedCampaign) {
        case CampaignChoice.ExistingMarketing:
          manifestName = this.marketingCampaignFormGroup.value.sheetName;
          break;
        case CampaignChoice.NewMarketing:
          manifestName = this.newCampaignFormGroup.value.title;
          break;
        default:
          manifestName = undefined;
          break;
      }

      const uploadResult = await this.uploadCampaignService.uploadData(
        this.fileId,
        this.dealershipId,
        this.campaignDetailsFormGroup.value.startDate.toISOString().split('T')[0],
        parseInt(this.campaignDetailsFormGroup.value.maxMessages),
        this.userName,
        this.uploadCustomerListFormGroup.value.listName,
        this.selectedCampaign === CampaignChoice.Recall
          ? ManifestConversationType.Recall
          : ManifestConversationType.Marketing,
        manifestName,
      );

      // TODO: This is a bad way of checking for success, we should test for a 201 code but the service
      // currently returns an empty string as body when successful so not possible without other changes
      if (!uploadResult) {
        this.toasterService.success('File processed successfully');

        if (this.selectedCampaign === CampaignChoice.NewMarketing) {
          try {
            FormGroup;
            await this.uploadCampaignService.createNewManifest(
              this.newCampaignFormGroup.value.title,
              this.newCampaignFormGroup.value.description,
              this.dealershipId,
              this.campaignDetailsFormGroup.value.startDate.toISOString().split('T')[0],
              this.uploadCustomerListFormGroup.value.listName,
              parseInt(this.newCampaignFormGroup.value.followUpMessages),
            );
            this.toasterService.success('New campaign requested');
            // Wait 2 seconds then return to dashboard
            await new Promise<void>(resolve => setTimeout(resolve, 2000));
            this.router.navigate(['../'], {
              relativeTo: this.route,
            });
          } catch (error) {
            // TODO: Remove?
            console.log(error);
            // Force user to re-validate and re-upload file
            this.isValidated = false;
            this.processing = false;
            // Wait 1 second then display error
            await new Promise<void>(resolve => setTimeout(resolve, 1000));
            if (error instanceof HttpError) {
              this.toasterService.error(`${error.statusCode}: ${error.message}`);
            } else {
              this.toasterService.error('An error occurred');
            }
          }
        } else {
          await new Promise<void>(resolve => setTimeout(resolve, 1000));
          this.router.navigate(['../'], {
            relativeTo: this.route,
          });
        }
      } else this.toasterService.error('Error processing file');
    } else {
      if (stepper.selected) stepper.selected.completed = true;
      stepper.next();
    }
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
  }
}
