<ng-container *ngIf="false">
  <div class="container-fluid">
    <div class="row align-items-center py-4 justify-content-center">
      <!--<app-loading></app-loading>-->
    </div>
  </div>
</ng-container>
<div class="mt-3"></div>
<ng-container *ngIf="true">
  <div class="container-fluid bot-settings-container">
    <div class="row h-100">
      <div class="card col-md-12 col-sm-12">
        <div class="card-header">
          <h3 class="mb-0">
            Create a New Assistant from Template: <i>{{ botTemplateLabel }}</i>
          </h3>
        </div>
        <div class="card-body">
          <div>
            <form method="post" #form="ngForm" novalidate>
              <tabset #tab>
                <tab heading="Settings" id="tab1">
                  <div class="form-group row mb-2 mt-2">
                    <h3>Settings</h3>
                  </div>
                  <div class="form-group row">
                    <label class="form-control-label" for="botName"> Name </label>
                    <input
                      class="form-control"
                      id="botName"
                      name="name"
                      [(ngModel)]="bot.label"
                      type="text"
                      placeholder="e.g. Carla, Bubotti"
                      required
                    />
                  </div>
                  <div class="form-group row">
                    <label class="form-control-label" for="rootElementId"> Root Element ID </label>
                    <input
                      class="form-control"
                      id="rootElementId"
                      name="rootElementId"
                      [(ngModel)]="bot.rootElementId"
                      type="text"
                      placeholder="carlabs-root-element"
                      required
                    />
                  </div>

                  <div class="form-group row">
                    <div class="col-12 no-gutters p-0">
                      <label class="form-control-label" for="initOnLoad"> Init On Load </label>
                    </div>
                    <div class="col p-0">
                      <label class="custom-toggle mr-1">
                        <input
                          checked="checked"
                          type="checkbox"
                          id="initOnLoad"
                          name="initOnLoad"
                          [(ngModel)]="bot.initOnLoad"
                        />
                        <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes">
                        </span>
                      </label>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-12 no-gutters p-0">
                      <label class="form-control-label" for="clearPreviousSessionOnInit">
                        Clear Previous Session on Init
                      </label>
                    </div>
                    <div class="col p-0">
                      <label class="custom-toggle mr-1">
                        <input
                          checked="checked"
                          type="checkbox"
                          id="clearPreviousSessionOnInit"
                          name="clearPreviousSessionOnInit"
                          [(ngModel)]="bot.clearPreviousSessionOnInit"
                        />
                        <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes">
                        </span>
                      </label>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="form-control-label" for="headerText"> Header Text </label>
                    <input
                      class="form-control"
                      id="headerText"
                      name="headerText"
                      [(ngModel)]="bot.styles.headerText"
                      type="text"
                      placeholder="Chat with me"
                      required
                    />
                  </div>
                  <div class="form-group row">
                    <label class="form-control-label" for="headerBackgroundColor"> Header Background Color </label>
                    <input
                      class="form-control"
                      id="headerBackgroundColor"
                      name="headerBackgroundColor"
                      [(ngModel)]="bot.styles.headerBackgroundColor"
                      type="color"
                    />
                  </div>

                  <!-- Input Box Placeholder Text -->
                  <div class="form-group row">
                    <label class="form-control-label" for="inputBoxPlaceholderText"> Input Box Placeholder Text </label>
                    <input
                      class="form-control"
                      id="inputBoxPlaceholderText"
                      name="inputBoxPlaceholderText"
                      [(ngModel)]="bot.styles.inputBoxPlaceholderText"
                      type="text"
                      placeholder="Type your message here..."
                      required
                    />
                  </div>

                  <!-- Error Message -->
                  <div class="form-group row">
                    <label class="form-control-label" for="errorMessage"> Error Message </label>
                    <input
                      class="form-control"
                      id="errorMessage"
                      name="errorMessage"
                      [(ngModel)]="bot.errorMessage"
                      type="text"
                      placeholder="I'm sorry, but I didn't quite get that. Can you rephrase your question?"
                      required
                    />
                  </div>

                  <!-- Teaser Text -->
                  <div class="form-group row">
                    <div class="col-12 no-gutters p-0">
                      <label class="form-control-label" for="teaserText"> Teaser Text </label>
                    </div>
                    <div class="col p-0">
                      <tag-input
                        [(ngModel)]="bot.styles.teaserText"
                        placeholder="Add a new teaser text"
                        [modelAsStrings]="true"
                        theme="regular-theme"
                        name="teaserText"
                        id="teaserText"
                      ></tag-input>
                    </div>
                  </div>

                  <!-- Chat Position -->
                  <div class="form-group row">
                    <label class="form-control-label" for="chatPosition"> Chat Position </label>
                    <select
                      class="form-control"
                      id="chatPosition"
                      name="chatPosition"
                      [(ngModel)]="bot.styles.chatPosition"
                    >
                      <option value="left">Left</option>
                      <option value="right">Right</option>
                    </select>
                  </div>

                  <!-- Avatar Animation -->
                  <div class="form-group row">
                    <label class="form-control-label" for="avatarAnimation"> Avatar Animation </label>
                    <select
                      class="form-control"
                      id="avatarAnimation"
                      name="avatarAnimation"
                      [(ngModel)]="bot.styles.avatarAnimation"
                    >
                      <option value="bounce">Bounce</option>
                      <option value="static">Static</option>
                    </select>
                  </div>

                  <!-- Fixed Position -->
                  <div class="form-group row">
                    <div class="col-12 no-gutters p-0">
                      <label class="form-control-label" for="fixedPosition"> Fixed Position </label>
                    </div>
                    <div class="col p-0">
                      <label class="custom-toggle mr-1">
                        <input
                          checked="checked"
                          type="checkbox"
                          id="fixedPosition"
                          name="fixedPosition"
                          [(ngModel)]="bot.styles.fixedPosition"
                        />
                        <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes">
                        </span>
                      </label>
                    </div>
                  </div>
                  <!-- TODO make changes to this template thing -->
                  <!-- Chat Container Width -->
                  <div class="form-group row">
                    <label class="form-control-label" for="chatContainerWidth"> Chat Container Width </label>
                    <input
                      class="form-control"
                      id="chatContainerWidth"
                      name="chatContainerWidth"
                      type="number"
                      [(ngModel)]="bot.styles.chatContainerWidth"
                    />
                  </div>

                  <!-- Chat Container Height -->
                  <div class="form-group row">
                    <label class="form-control-label" for="chatContainerHeight"> Chat Container Height </label>
                    <input
                      class="form-control"
                      id="chatContainerHeight"
                      name="chatContainerHeight"
                      type="number"
                      [(ngModel)]="bot.styles.chatContainerHeight"
                    />
                  </div>

                  <!-- Show Avatar -->
                  <div class="form-group row">
                    <div class="col-12 no-gutters p-0">
                      <label class="form-control-label" for="showAvatar"> Show Avatar </label>
                    </div>
                    <div class="col p-0">
                      <label class="custom-toggle mr-1">
                        <input
                          checked="checked"
                          type="checkbox"
                          id="showAvatar"
                          name="showAvatar"
                          [(ngModel)]="bot.styles.showAvatar"
                        />
                        <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes">
                        </span>
                      </label>
                    </div>
                  </div>

                  <!-- Debug Mode -->
                  <div class="form-group row">
                    <div class="col-12 no-gutters p-0">
                      <label class="form-control-label" for="debugMode"> Debug Mode </label>
                    </div>
                    <div class="col p-0">
                      <label class="custom-toggle mr-1">
                        <input
                          checked="checked"
                          type="checkbox"
                          id="debugMode"
                          name="debugMode"
                          [(ngModel)]="bot.debugMode"
                        />
                        <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes">
                        </span>
                      </label>
                    </div>
                  </div>

                  <!-- Avatar -->
                  <div class="form-group row">
                    <div class="col-12 no-gutters p-0">
                      <label class="form-control-label"> Avatar </label>
                    </div>
                    <div class="col p-0">
                      <app-editable-image
                        [source]="bot.styles.avatarImageUrl"
                        (fileSelect)="avatarFile = $event"
                        (remove)="bot.styles.avatarImageUrl = undefined"
                      >
                      </app-editable-image>
                    </div>
                  </div>
                  <!-- Intro Node -->
                  <div class="form-group row">
                    <label class="form-control-label" for="initNodeId"> Intro Node </label>
                    <select class="form-control" [(ngModel)]="bot.initNodeId" name="initNodeId">
                      <option value="">None</option>
                      <option *ngFor="let n of nodes" [ngValue]="n.id">{{ n.name }}</option>
                    </select>
                  </div>

                  <!-- Script Injection Source URL -->
                  <div class="form-group row">
                    <label class="form-control-label" for="scriptInjectionSource"> Script Injection Source </label>
                    <input
                      class="form-control"
                      [ngClass]="{ 'is-invalid': !scriptInjectionSource.valid && !scriptInjectionSource.pristine }"
                      id="scriptInjectionSource"
                      name="scriptInjectionSource"
                      [(ngModel)]="bot.scriptInjectionSource"
                      pattern="^$|https://.+"
                      #scriptInjectionSource="ngModel"
                      type="url"
                      placeholder="Script Source URL"
                    />
                    <div
                      *ngIf="!scriptInjectionSource.valid && !scriptInjectionSource.pristine"
                      class="invalid-feedback"
                    >
                      Please enter a valid secure URL (HTTPS).
                    </div>
                  </div>

                  <!-- Event Triggers -->
                  <div class="form-group row my-0">
                    <label class="form-control-label"> Event Triggers </label>
                  </div>
                  <div class="card row p-0" *ngFor="let trigger of bot.eventTriggers; let i = index">
                    <div class="form-group row p-2 m-0">
                      <div class="col col-md-4 pl-0 m-0">
                        <input
                          class="form-control"
                          name="botConfigEventTriggerType-{{ i }}"
                          [value]="bot.eventTriggers[i].eventTriggerType == 'page-url' ? 'Page URL' : ''"
                          type="text"
                          placeholder="Trigger Type"
                          disabled
                          required
                        />
                      </div>
                      <div class="col col-md-4 p-0 m-0">
                        <input
                          class="form-control"
                          name="botConfigEventTriggerInput-{{ i }}"
                          [value]="bot.eventTriggers[i].eventTriggerInput"
                          type="text"
                          placeholder="Trigger Input"
                          disabled
                          required
                        />
                      </div>
                      <div class="col col-md-4 pr-0 m-0">
                        <input
                          class="form-control"
                          name="botConfigEventTriggerNodeId-{{ i }}"
                          [value]="bot.eventTriggers[i].eventTriggerNodeName"
                          type="text"
                          placeholder="Trigger Node"
                          disabled
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group row p-2 m-0">
                      <div class="col-sm p-0 m-0">
                        <button
                          class="btn btn-sm btn-block btn-secondary m-0"
                          type="button"
                          (click)="removeBotTrigger(bot.eventTriggers[i].eventTriggerId)"
                        >
                          Remove Trigger
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <button class="btn btn-sm btn-primary btn-block" type="button" (click)="addEventTrigger()">
                      Add Event Trigger
                    </button>
                  </div>
                </tab>
                <tab heading="Configurations">
                  <div class="form-group row mt-2">
                    <h3>Configurations</h3>
                  </div>
                  <div class="form-group row mb-2">
                    <small>These settings are static to the current assistant environment.</small>
                  </div>

                  <!-- API ID -->
                  <div class="form-group row">
                    <label class="form-control-label" for="apiGatewayServiceId"> API ID </label>
                    <input
                      class="form-control"
                      id="apiGatewayServiceId"
                      name="apiGatewayServiceId"
                      [(ngModel)]="bot.apiGatewayServiceId"
                      type="text"
                      placeholder="ID"
                      required
                    />
                  </div>

                  <!-- Google Sheets -->
                  <div *ngIf="isAdmin" class="form-group row">
                    <label class="form-control-label" for="googleSheetUrl"> Google Sheet URL </label>
                    <input
                      class="form-control"
                      id="googleSheetUrl"
                      name="googleSheetUrl"
                      [(ngModel)]="bot.config.googleSheetUrl"
                      type="url"
                      placeholder="URL"
                    />
                  </div>

                  <!-- Locale -->
                  <div class="form-group row">
                    <label class="form-control-label" for="locale"> Locale </label>
                    <select class="form-control" id="locale" name="locale" [(ngModel)]="bot.config.locale" required>
                      <option value="" disabled selected>Select Locale</option>
                      <option value="en-US">en-US</option>
                      <option value="es-MX">es-MX</option>
                    </select>
                  </div>

                  <!-- Region -->
                  <div class="form-group row">
                    <label class="form-control-label" for="locale">
                      Region
                      <a
                        tooltip="Represents the region where the data will be stored. Please do not change if you don't know what it does!"
                        ><i class="fa fa-info-circle text-warning"></i
                      ></a>
                    </label>

                    <select class="form-control" id="region" name="region" [(ngModel)]="bot.config.awsRegion" required>
                      <option value="" disabled selected>Select Region</option>
                      <option *ngFor="let country of countries" [ngValue]="country.region">{{ country.region }}</option>
                    </select>
                  </div>

                  <!-- NLP Model -->
                  <div *ngIf="isAdmin" class="form-group row">
                    <label class="form-control-label" for="nlp-model"> NLP Model </label>
                    <select
                      class="form-control"
                      id="nlp-model"
                      name="nlp-model"
                      [(ngModel)]="bot.config.nlp.model"
                      required
                    >
                      <option value="" disabled selected>Select NLP Model</option>
                      <option *ngFor="let nlpModel of nlpModels" [ngValue]="nlpModel.id">{{ nlpModel.name }}</option>
                    </select>
                  </div>

                  <!-- Handover Configs -->
                  <div class="form-group row">
                    <div class="col-12 px-0">
                      <label class="form-control-label"> Handover Service </label>
                    </div>
                    <div class="col-12 px-0 my-1 col-md-6 px-md-1">
                      <input
                        class="form-control"
                        name="handover-service-name"
                        [(ngModel)]="bot.config.handoverConfig.name"
                        type="text"
                        placeholder="Name"
                      />
                    </div>
                    <div class="col-12 px-0 my-1 col-md-6 px-md-1">
                      <input
                        class="form-control"
                        name="handover-service-authToken"
                        [(ngModel)]="bot.config.handoverConfig.authToken"
                        type="text"
                        placeholder="Auth Token"
                      />
                    </div>
                    <div class="col-12 px-0 my-1 px-md-1">
                      <input
                        class="form-control"
                        name="handover-service-metaData"
                        [(ngModel)]="bot.config.handoverConfig.metaData"
                        type="text"
                        placeholder="Meta Data"
                      />
                    </div>
                  </div>

                  <!-- Facebook Page IDs -->
                  <div class="form-group row my-0">
                    <label class="form-control-label"> Facebook Page IDs </label>
                  </div>
                  <div class="card row p-0" *ngFor="let pageConfig of bot.facebookPageConfigs; let i = index">
                    <div class="form-group row p-2 m-0">
                      <div class="col-sm p-0 m-0">
                        <input
                          class="form-control"
                          name="botConfigFacebookPageConfigAppId-{{ i }}"
                          [ngModel]="bot.facebookPageConfigs[i].appId"
                          type="text"
                          placeholder="App ID"
                          disabled
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group row p-2 m-0">
                      <div class="col-sm p-0 m-0">
                        <input
                          class="form-control"
                          name="botConfigFacebookPageConfigAppSecret-{{ i }}"
                          [ngModel]="bot.facebookPageConfigs[i].appSecret"
                          type="text"
                          placeholder="App Secret"
                          disabled
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group row p-2 m-0">
                      <div class="col-sm p-0 m-0">
                        <input
                          class="form-control"
                          name="botConfigFacebookPageConfigPageId-{{ i }}"
                          [ngModel]="bot.facebookPageConfigs[i].pageId"
                          type="text"
                          placeholder="Page ID"
                          disabled
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group row p-2 m-0">
                      <div class="col-sm p-0 m-0">
                        <input
                          class="form-control"
                          name="botConfigFacebookPageConfigPageAccessToken-{{ i }}"
                          [ngModel]="bot.facebookPageConfigs[i].pageAccessToken"
                          type="text"
                          placeholder="Page Access Token"
                          disabled
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group row p-2 m-0">
                      <div class="col-sm p-0 m-0">
                        <input
                          class="form-control"
                          name="botConfigFacebookPageConfigThreadControl-{{ i }}"
                          [ngModel]="bot.facebookPageConfigs[i].passThreadControlTargetAppId"
                          type="text"
                          placeholder="Pass Thread Control Target App Id"
                          disabled
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group row p-2 m-0">
                      <div class="col-sm p-0 m-0">
                        <button
                          class="btn btn-sm btn-block btn-secondary m-0"
                          type="button"
                          (click)="removeFacebookPageConfig(pageConfig.pageId)"
                        >
                          Remove Facebook Page ID
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <button class="btn btn-sm btn-primary btn-block" type="button" (click)="addFacebookPageConfig()">
                      Add Facebook Page ID
                    </button>
                  </div>
                </tab>

                <tab id="variables_tab" heading="Variables">
                  <ng-container *ngIf="hasGlobalVariables">
                    <div class="form-group row mb-2 mt-2"></div>
                    <div>
                      The field(s) below are required to complete stand up of your bot.
                      <br />If you don’t have the information to fill in these values now, you can still continue and
                      <br />create the assistant but you will be required to fill in this information before you can
                      deploy your assistant live.
                    </div>
                    <br />

                    <div *ngFor="let v of botVariables" class="form-group row">
                      <div class="col-sm-12 col-md-2 col-lg-2">
                        <label class="col-form-label form-control-label" [for]="v.slug">{{ v.label }}</label>
                      </div>

                      <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="col-md-12">
                          <input
                            [id]="v.slug"
                            [name]="v.slug"
                            class="form-control"
                            type="text"
                            [(ngModel)]="v.defaultValue"
                          />
                        </div>
                      </div>

                      <div class="col-sm-12 col-md-3 col-lg-3">
                        <label class="col-form-label form-control-label">{{ v.description }}</label>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!hasGlobalVariables">
                    <div class="form-group row mb-2 mt-2">
                      <h3>Assistant Variables</h3>
                    </div>
                    <div>
                      This assistant template has no required variables. If at any point you need to add variables you
                      can do so after the assistant is created within the <strong>Assistant Settings.</strong>
                    </div>
                  </ng-container>
                </tab>
              </tabset>
            </form>
          </div>

          <ng-container>
            <div class="row row-example">
              <div class="col-sm col-md col-lg">
                <div class="card"></div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="card-footer">
          <a class="btn btn-default go-back" [routerLink]="['../../', 'select-template']">Back to Templates</a>
          <button
            data-test-id="create-bot-from-template-submit-button"
            class="btn btn-primary create-bot"
            (click)="save()"
            [disabled]="!form.valid || loading"
            type="submit"
          >
            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="loading"> Creating...</span>
            <span *ngIf="!loading">Create Assistant</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
