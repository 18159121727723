import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';
import { UsersService } from './firestore/users.service';
import { UserModel } from '../models';
import { RolesService } from './firestore/roles.service';
import { Permissions } from '../utils/permissions/permissions';
import { take } from 'rxjs/operators';
import { LoginInfo } from './types/login.types';
import { LoginMethod } from './types/login.enum';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<firebase.default.User | null>;
  private _currentUserProfile: UserModel | null;
  private _currentUserPermissions: Permissions[];
  public redirectUrl: string;
  constructor(private afAuth: AngularFireAuth, private usersService: UsersService, private rolesService: RolesService) {
    this.user$ = this.afAuth.user;
  }

  async executeLogin(credentials: LoginInfo): Promise<firebase.default.auth.UserCredential> {
    return credentials.method === LoginMethod.LOGIN_WITH_CUSTOM_TOKEN
      ? this.afAuth.signInWithCustomToken(credentials.customToken)
      : this.afAuth.signInWithEmailAndPassword(credentials.user, credentials.password);
  }

  async resolveCustomToken(auth0Token: string): Promise<string> {
    const url = `https://${environment.authSecrets.cloudFunctionUrl}/api/auth`;
    const tokenResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ auth0Token }),
    });

    const jsonReceived = await tokenResponse.json();
    const { customToken } = jsonReceived;

    return customToken;
  }

  async login(credentials: LoginInfo): Promise<any> {
    if (credentials.method === LoginMethod.LOGIN_WITH_CUSTOM_TOKEN) {
      credentials.customToken = await this.resolveCustomToken(credentials.auth0Token);
    }

    return new Promise<any>((resolve, reject) => {
      // changed the persistence from session to local to allow new tabs to be opened and use the same session
      this.afAuth.setPersistence('local').then(() => {
        this.executeLogin(credentials)
          .then(async res => {
            await this.fetchAndSetUserProfileAndPermissions();
            if (this.redirectUrl && this.redirectUrl.length > 0) {
              document.location.href = this.redirectUrl;
            }
            resolve(res);
          })
          .catch(error => {
            const errorCode = error.code;
            const errorMessage = error.message;
            reject(errorMessage + errorCode);
          });
      });
    });
  }

  get currentUser(): Promise<firebase.default.User | null> {
    return this.afAuth.currentUser;
  }

  async getCurrentUserProfile(): Promise<UserModel | null> {
    if (!this._currentUserProfile) {
      await this.fetchAndSetUserProfileAndPermissions();
    }
    return this._currentUserProfile;
  }

  async getCurrentUserPermissions(): Promise<Permissions[]> {
    // For the weird case that the role has no permission then we will making too many calls
    if (!this._currentUserProfile) {
      await this.fetchAndSetUserProfileAndPermissions();
    }
    return this._currentUserPermissions;
  }

  async hasPermission(permission: Permissions): Promise<boolean> {
    const userPermissions = await this.getCurrentUserPermissions();
    if (!userPermissions) {
      return false;
    }
    return userPermissions.includes(permission);
  }

  // For the templates and since the permissions will be set by then
  hasPermissionSync(permission: Permissions): boolean {
    if (!this._currentUserPermissions) {
      return false;
    }
    return this._currentUserPermissions.includes(permission);
  }

  logout() {
    this.reset();
    this._currentUserProfile = null;
    this._currentUserPermissions = [];
    return this.afAuth.signOut();
  }

  private async fetchAndSetUserProfileAndPermissions(): Promise<void> {
    const currentUser = await this.currentUser;
    if (!currentUser) {
      return;
    }
    const user = await this.usersService.getUserById(currentUser?.uid).pipe(take(1)).toPromise();
    if (!user) {
      return;
    }
    this._currentUserProfile = user;

    if (!user.role) {
      // TODO: Alert the user to tell the admin to give him a role
      return;
    }

    const role = await this.rolesService.getRoleById(user.role).pipe(take(1)).toPromise();
    if (!role) {
      // TODO: Alert the user to tell the admin to give him a correct role
      return;
    }

    if (role.permissions) {
      this._currentUserPermissions = role.permissions;
    }
  }

  private reset() {
    localStorage.clear();
  }
}
