<form (submit)="save()" #form="ngForm" novalidate>
  <fieldset class="container" [disabled]="!writable">
    <div class="form-group row">
      <h3>Settings</h3>
    </div>
    <div class="form-group row">
      <label class="form-control-label" for="name">
        Name
      </label>
      <input
        class="form-control"
        id="name"
        name="name"
        [(ngModel)]="nlpDatasetFile.name"
        type="text"
        placeholder="e.g. Request Details, Payments"
        required
      />
    </div>
  </fieldset>
  <button
    *ngIf="writable"
    class="btn btn-primary btn-outline"
    type="submit"
    [disabled]="!form.valid || loading"
  >
    SAVE
  </button>
</form>
