<mat-card>
  <mat-card-content>
    <mat-stepper linear labelPosition="bottom" #stepper [ngClass]="'last-edited-step-' + stepper.selectedIndex">
      <mat-step label="Campaign Type">
        <h2>Select Campaign Type</h2>
        <div class="campaign-selector">
          <app-campaign-option
            campaignType="recallCampaign"
            [selectedCampaign]="selectedCampaign"
            label="Recall Campaign"
            description="Impel will create the text for your recall message"
            imageSrc="assets/img/icons/multi-list/recall_campaign.png"
            (campaignSelected)="selectCampaign($event)"
          ></app-campaign-option>
          <app-campaign-option-expansion-panel [selectedCampaign]="selectedCampaign" campaign="newMarketingCampaign">
            <div header>
              <app-campaign-option
                campaignType="newMarketingCampaign"
                [selectedCampaign]="selectedCampaign"
                label="New Campaign"
                description="You will need to write a brief description of the campaign message"
                imageSrc="assets/img/icons/multi-list/new_campaign.png"
                (campaignSelected)="selectCampaign($event)"
              ></app-campaign-option>
            </div>
            <div body style="margin-top: -12px">
              <form class="new-campaign-form-group" [formGroup]="newCampaignFormGroup">
                <!-- Campaign Title -->
                <div class="form-label-wrapper">
                  <label for="title" class="form-field-label">Campaign Title</label>
                  <mat-form-field floatPlaceholder="never" appearance="outline" style="max-width: 70%">
                    <input
                      autocomplete="off"
                      spellcheck="false"
                      class="formField"
                      matInput
                      id="title"
                      formControlName="title"
                      placeholder="Give your Campaign a Title"
                      maxlength="31"
                    />
                    <mat-error *ngIf="newCampaignFormGroup.get('title').hasError('required')">
                      Campaign Title is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- Description -->
                <div class="form-label-wrapper">
                  <div style="display: flex; flex-direction: row; gap: 12px; align-items: center">
                    <label for="title" class="form-field-label">Description</label>
                    <mat-icon
                      matTooltip="Impel will use the description to generate appropriate messages"
                      matTooltipPosition="above"
                      class="tooltip-icon description"
                      >info_outline</mat-icon
                    >
                  </div>
                  <mat-form-field floatPlaceholder="never" appearance="outline" style="max-width: 70%">
                    <textarea
                      autocomplete="off"
                      class="formField"
                      matInput
                      id="description"
                      formControlName="description"
                      placeholder="Please provide a Description of the type of Campaign you are looking to create"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="4"
                    ></textarea>
                    <mat-error *ngIf="newCampaignFormGroup.get('description').hasError('required')">
                      Description is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- Follow-Up Messages -->
                <div class="form-label-wrapper">
                  <div style="display: flex; flex-direction: row; gap: 12px; align-items: center">
                    <label for="followUpMessages" class="form-field-label">Follow Up Messages</label>
                    <mat-icon
                      matTooltipPosition="above"
                      matTooltip="Indicates how many messages will be sent after the initial message"
                      class="tooltip-icon"
                      >info_outline</mat-icon
                    >
                  </div>
                  <mat-form-field floatPlaceholder="never" appearance="outline" style="max-width: 70%">
                    <input
                      autocomplete="off"
                      spellcheck="false"
                      class="formField"
                      matInput
                      id="followUpMessages"
                      formControlName="followUpMessages"
                      placeholder="Would you like Follow Up Messages? If Yes, how many follow ups?"
                    />
                    <mat-error *ngIf="newCampaignFormGroup.get('followUpMessages').hasError('required')">
                      Follow Up Messages is required
                    </mat-error>
                    <mat-error *ngIf="newCampaignFormGroup.get('followUpMessages').hasError('notInteger')">
                      Follow Up Messages must be a valid integer
                    </mat-error>
                    <mat-error *ngIf="newCampaignFormGroup.get('followUpMessages').hasError('maxFollowUpMessages')">
                      Follow Up Messages must be less than or equal to 5
                    </mat-error>
                    <mat-error *ngIf="newCampaignFormGroup.get('followUpMessages').hasError('notNegative')">
                      Follow Up Messages cannot be a negative number
                    </mat-error>
                  </mat-form-field>
                </div>
              </form>
            </div>
          </app-campaign-option-expansion-panel>
          <app-campaign-option-expansion-panel
            [selectedCampaign]="selectedCampaign"
            campaign="existingMarketingCampaign"
          >
            <div header>
              <app-campaign-option
                campaignType="existingMarketingCampaign"
                [selectedCampaign]="selectedCampaign"
                label="Marketing Campaign"
                description="You will need to select from existing campaigns. Content will be created by Impel AI"
                imageSrc="assets/img/icons/multi-list/marketing_campaign.png"
                (campaignSelected)="selectCampaign($event)"
              ></app-campaign-option>
            </div>
            <div body style="margin-top: -12px">
              <form [formGroup]="marketingCampaignFormGroup" class="marketing-campaign-form-group">
                <!-- Campaign Preset -->
                <div class="form-label-wrapper">
                  <label for="sheetName" class="form-field-label">Campaign Preset</label>
                  <mat-form-field floatPlaceholder="never" appearance="outline" style="max-width: 50%">
                    <mat-select
                      id="sheetName"
                      formControlName="sheetName"
                      placeholder="Select from previously used Campaigns"
                    >
                      <mat-option *ngFor="let option of manifestOptions" [value]="option">{{ option }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="marketingCampaignFormGroup.get('sheetName').hasError('required')">
                      Campaign Preset is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </form>
            </div>
          </app-campaign-option-expansion-panel>
        </div>
      </mat-step>
      <mat-step label="Campaign Details">
        <h2 style="margin-bottom: 0px">Specify Campaign Details</h2>
        <div class="campaign-selector">
          <form
            [formGroup]="campaignDetailsFormGroup"
            style="display: flex; flex-direction: row; gap: 20px; margin-top: 21px"
          >
            <!-- Start Date -->
            <div class="form-label-wrapper">
              <label for="startDate" class="form-field-label">Start Date</label>
              <mat-form-field floatPlaceholder="never" appearance="outline">
                <input
                  type="text"
                  placeholder="Enter Start Date"
                  [minDate]="minDate"
                  bsDatepicker
                  matInput
                  formControlName="startDate"
                />
                <mat-error *ngIf="campaignDetailsFormGroup.get('startDate').hasError('required')">
                  Start Date is required
                </mat-error>
                <mat-error
                  *ngIf="
                    campaignDetailsFormGroup.get('startDate').hasError('bsDate') &&
                    selectedCampaign === 'newMarketingCampaign'
                  "
                >
                  Must be at least 1 week from now
                </mat-error>
                <mat-error
                  *ngIf="
                    campaignDetailsFormGroup.get('startDate').hasError('bsDate') &&
                    selectedCampaign !== 'newMarketingCampaign'
                  "
                >
                  Must be at least 1 day from now
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Max Messages / Day -->
            <div class="form-label-wrapper">
              <label for="maxMessages" class="form-field-label">Max Messages / Day</label>
              <mat-form-field floatPlaceholder="never" appearance="outline">
                <input
                  autocomplete="off"
                  spellcheck="false"
                  class="formField"
                  matInput
                  id="maxMessages"
                  formControlName="maxMessages"
                  placeholder="Max Messages / Day"
                />
                <mat-error *ngIf="campaignDetailsFormGroup.get('maxMessages').hasError('required')">
                  Max Messages / Day is required
                </mat-error>
                <mat-error *ngIf="campaignDetailsFormGroup.get('maxMessages').hasError('notInteger')">
                  Must be a valid integer
                </mat-error>
                <mat-error *ngIf="campaignDetailsFormGroup.get('maxMessages').hasError('notNegative')">
                  Cannot be negative
                </mat-error>
                <mat-error *ngIf="campaignDetailsFormGroup.get('maxMessages').hasError('notZero')">
                  Cannot be zero
                </mat-error>
              </mat-form-field>
            </div>
          </form>
        </div>
      </mat-step>
      <mat-step label="Customer List">
        <h2 style="margin-bottom: 0px">Upload Customer List</h2>
        <div class="campaign-selector">
          <form
            [formGroup]="uploadCustomerListFormGroup"
            style="display: flex; flex-direction: column; gap: 8px; margin-top: 24px"
          >
            <!-- List Name -->
            <div class="form-label-wrapper">
              <label for="listName" class="form-field-label">List Name</label>
              <mat-form-field floatPlaceholder="never" appearance="outline" style="max-width: 70%">
                <input
                  autocomplete="off"
                  spellcheck="false"
                  class="formField"
                  matInput
                  id="listName"
                  formControlName="listName"
                  placeholder="Enter List Name"
                />
                <mat-error *ngIf="uploadCustomerListFormGroup.get('listName').hasError('required')">
                  List Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Upload Errors Email Address -->
            <div class="form-label-wrapper">
              <label for="uploadErrorsEmailAddress" class="form-field-label">Email to send the upload errors</label>
              <mat-form-field floatPlaceholder="never" appearance="outline" style="max-width: 70%">
                <input
                  autocomplete="email"
                  type="email"
                  spellcheck="false"
                  class="formField"
                  matInput
                  id="uploadErrorsEmailAddress"
                  formControlName="uploadErrorsEmailAddress"
                  placeholder="Enter Email Address"
                />
                <mat-error *ngIf="uploadCustomerListFormGroup.get('uploadErrorsEmailAddress').hasError('required')">
                  Email address is required
                </mat-error>
                <mat-error *ngIf="uploadCustomerListFormGroup.get('uploadErrorsEmailAddress').hasError('email')">
                  Must be a valid email
                </mat-error>
              </mat-form-field>
            </div>
            <div style="display: flex; flex-direction: row; gap: 20px">
              <div class="file-input-wrapper" [class.file-exists]="fileContent">
                <input
                  *ngIf="!fileContent"
                  type="file"
                  (change)="onFileSelected($event)"
                  accept=".csv"
                  multiple="false"
                />
                <div
                  *ngIf="fileContent"
                  style="
                    color: #410099;
                    font-size: 14px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 12px;
                  "
                >
                  {{ truncateFileName(fileName, 40) }}
                  <mat-icon class="failure-icon" *ngIf="!isValidatd && validationErrorMessage">error_outline</mat-icon>
                  <mat-icon class="success-icon" *ngIf="isValidated">task_alt</mat-icon>
                  <mat-icon (click)="removeSelectedFile()" *ngIf="!validating && !processing">close</mat-icon>
                </div>
              </div>
              <button
                mat-flat-button
                class="cancel-btn validate-btn"
                (click)="onValidate()"
                [disabled]="!fileContent || uploadCustomerListFormGroup.invalid || isValidated"
                [class.validating-spinner]="validating"
                [class.spinner]="validating"
              >
                Validate CSV
              </button>
            </div>
          </form>
        </div>
      </mat-step>
      <ng-template matStepperIcon="edit">
        <mat-icon>fiber_manual_record</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="number">
        <mat-icon>fiber_manual_record</mat-icon>
      </ng-template>
    </mat-stepper>
    <div class="buttons">
      <!-- TODO: use better class name than cancel-btn -->
      <button
        id="cancelBtn"
        mat-flat-button
        class="cancel-btn button-left"
        *ngIf="stepper.selectedIndex === 0"
        (click)="onCancel()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        class="cancel-btn button-left"
        *ngIf="stepper.selectedIndex > 0"
        (click)="onPrevious(stepper)"
      >
        Back
      </button>
      <div class="spacer"></div>
      <button
        mat-flat-button
        class="next-btn button-right"
        [class.processing-spinner]="processing"
        [class.spinner]="processing"
        (click)="onNext(stepper)"
        [disabled]="disableNextButton(stepper)"
      >
        {{ stepper.selectedIndex === 2 ? 'Create Campaign' : 'Next' }}
      </button>
    </div>
  </mat-card-content>
</mat-card>
