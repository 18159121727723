import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { GlobalVariable, GlobalVariableType } from 'src/app/models/bot';
import { NodeHttpRequest } from 'src/app/models/node';

@Component({
  selector: 'app-upsert-http-request-modal',
  templateUrl: './upsert-http-request-modal.component.html',
  styleUrls: ['./upsert-http-request-modal.component.scss']
})
export class UpsertHttpRequestModalComponent implements OnInit {
  nodeHttpRequest: NodeHttpRequest;
  globalVariables: GlobalVariable[];
  onNodeHttpRequestSave: (nodeHttpRequest: NodeHttpRequest) => void;

  constructor(public modal: BsModalRef, options: ModalOptions) {
    if (!options.initialState) {
      return;
    }
    const { nodeHttpRequest, onNodeHttpRequestSave, globalVariables } = options.initialState as any;
    this.nodeHttpRequest = nodeHttpRequest;
    this.globalVariables = globalVariables;
    this.onNodeHttpRequestSave = onNodeHttpRequestSave;
  }

  async ngOnInit() {}

  onSave() {
    this.onNodeHttpRequestSave(this.nodeHttpRequest);
    this.modal.hide();
  }
}
