/**
 * This is an enum class.
 *
 * !Important!
 * Whenever you add a new value to this enum is important to add also that value
 * to the environments.ts/environments.staging.ts/environments.prod.ts
 */

export enum AwsRegionEnum {
  US_EAST_1 = 'us-east-1',
  AP_SOUTH_1 = 'ap-south-1'
}
