import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CorpHierarchyModel, CorpModel } from 'src/app/models';
import { plainToClass } from 'class-transformer';
import { CorpsService } from './corps.service';

const HIERARCHY_COLLECTION_NAME = 'hierarchies';

@Injectable({
  providedIn: 'root',
})
export class CorpHierarchiesService {
  private corpsCollection: AngularFirestoreCollection<CorpModel>;

  constructor(private corpsService: CorpsService) {
    this.corpsCollection = this.corpsService.corpsCollection;
  }

  async addCorpHierarchy(corpId: string, corpHierarchy: CorpHierarchyModel): Promise<void> {
    const systemName = corpHierarchy.systemName;
    const result = await this.getCorpHierarchyDocument(corpId, systemName).ref.get();
    if (result.exists) {
      throw new Error(`Hierarchy "${systemName}" already exists`);
    } else {
      return this.getCorpHierarchyDocument(corpId, systemName).set({ ...corpHierarchy });
    }
  }

  updateCorpHierarchy(corpId: string, corpHierarchy: CorpHierarchyModel): Promise<void> {
    return this.getCorpHierarchyDocument(corpId, corpHierarchy.systemName).update({ ...corpHierarchy });
  }

  getHighestCorpHierarchy(corpId: string): Observable<CorpHierarchyModel | null> {
    return this.corpsCollection
      .doc(corpId)
      .collection<CorpHierarchyModel>(HIERARCHY_COLLECTION_NAME, ref => ref.where('order', '==', 1))
      .valueChanges()
      .pipe(
        map(corpHierarchies => {
          if (corpHierarchies.length === 0) {
            return null;
          }
          return plainToClass(CorpHierarchyModel, corpHierarchies[0]);
        }),
      );
  }

  getCorpHierarchy(corpId: string, hierarchySystemName: string): Observable<CorpHierarchyModel | null> {
    return this.getCorpHierarchyDocument(corpId, hierarchySystemName)
      .valueChanges()
      .pipe(
        map(corpHierarchy => {
          return corpHierarchy as CorpHierarchyModel;
        }),
      );
  }

  hasChildren(corpId: string, hierarchySystemName: string): Observable<boolean> {
    // TODO implement
    return Observable.create(observer => {
      observer.next(false);
      observer.complete();
    });
  }

  getCorpHierarchies(corpId: string): Observable<CorpHierarchyModel[]> {
    return this.corpsCollection
      .doc(corpId)
      .collection<CorpHierarchyModel>(HIERARCHY_COLLECTION_NAME)
      .valueChanges()
      .pipe(
        map(corpHierarchies$1 => {
          const corpHierarchies = corpHierarchies$1.map(corpHierarchy =>
            plainToClass(CorpHierarchyModel, corpHierarchy),
          );
          corpHierarchies.sort((a, b) => a.order - b.order);
          return corpHierarchies;
        }),
      );
  }

  removeCorpHierarchy(corpId: string, hierarchySystemName: string) {
    return this.getCorpHierarchyDocument(corpId, hierarchySystemName).delete();
  }

  private getCorpHierarchyDocument(
    corpId: string,
    hierarchySystemName: string,
  ): AngularFirestoreDocument<CorpHierarchyModel> {
    return this.corpsCollection
      .doc(corpId)
      .collection<CorpHierarchyModel>(HIERARCHY_COLLECTION_NAME)
      .doc(hierarchySystemName);
  }
}
