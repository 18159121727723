<ng-container>
  <div *ngIf="!backgroundUrl" class="container-fluid bot-preview-container">
    <div class="row">
      <div class="col">
        <img src="assets/img/brand/carlabs_logo.png" class="ml-5 mt-3" alt="Impel Logo" style="max-width: 200px" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h6 class="h2 d-inline-block mb-0 ml-5">Assistant Preview</h6>
      </div>
    </div>
  </div>
</ng-container>
