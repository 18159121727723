import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { AddBotModalComponent } from 'src/app/components/modals/add-bot-modal/add-bot-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { BotModel } from 'src/app/models/bot';
import { CorpModel } from 'src/app/models/corp';
import { PermissionModel } from 'src/app/models/permission';
import { getBreadcrumbItems, getSidebarItems } from '../utils';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { CorpHierarchyModel, HierarchyElementModel } from 'src/app/models';
import {
  BotsService,
  CorpHierarchiesService,
  CorpsService,
  HierarchyElementsService,
  PermissionsService,
  UsersService,
} from 'src/app/services/firestore';
import uniq from 'lodash/fp/uniq';
// tslint:disable-next-line:max-line-length
import { AddHierarchyElementModalComponent } from 'src/app/components/modals/add-hierarchy-element-modal/add-hierarchy-element-modal.component';
import { ClientEnvironmentModel } from 'src/app/models/client-environment';

@Component({
  selector: 'app-hierarchy-element-dashboard',
  templateUrl: './hierarchy-element-dashboard.component.html',
  styleUrls: ['./hierarchy-element-dashboard.component.scss'],
})
export class HierarchyElementDashboardComponent implements OnInit, OnDestroy {
  bots: BotModel[] = [];
  clientEnvironment: ClientEnvironmentModel | null;
  loading: boolean;
  parentSystemName: string;
  parentElements: Array<HierarchyElementModel | null>;
  corp: CorpModel;
  corpHierarchy: CorpHierarchyModel;
  nextDownLevelCorpHierarchy: CorpHierarchyModel | undefined;
  hierarchyElement: HierarchyElementModel;
  hierarchyElements: HierarchyElementModel[] = [];
  permissions: PermissionModel[];
  user: firebase.default.User;
  elementTitle: string;
  canAddBot: boolean;
  userId: string;
  hasAllCorps: boolean;

  private crtDataSubscription: Subscription;
  private paramMapSubscription: Subscription;
  private botsSubscription: Subscription;
  private hierarchyChildrenElementsSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private hierarchyElementsService: HierarchyElementsService,
    private corpHierarchiesService: CorpHierarchiesService,
    private permissionsService: PermissionsService,
    private corpsService: CorpsService,
    private botsService: BotsService,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private router: Router,
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    private modalService: BsModalService,
    private toaster: ToastrService,
    private clientEnvironmentService: ClientEnvironmentService,
    private usersService: UsersService,
  ) {}

  async ngOnInit() {
    this.userId = (await this.authService.currentUser)?.uid || '';
    this.hasAllCorps = this.userId.length > 0 && (await this.usersService.CanAccessAllCompanies(this.userId));
    this.loading = true;
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(
      ([params, user]) => {
        const corpId = params.get('corp');
        const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
        if (!hierarchyElementSystemName || !corpId || !user) {
          return;
        }
        this.parentSystemName = `${corpId}-${hierarchyElementSystemName}`;
        this.user = user;
        this.crtDataSubscription = combineLatest([
          this.corpsService.getCorpById(corpId),
          this.permissionsService.getPermissionsBy(this.user.uid, corpId),
          this.hierarchyElementsService.getHierarchyElementWithParentElements(this.parentSystemName),
          this.hierarchyElementsService.getHierarchyElement(this.parentSystemName),
          this.corpHierarchiesService.getCorpHierarchies(corpId),
          this.clientEnvironmentService.items$,
        ]).subscribe(
          ([corp, permissions, parentElements, hierarchyElement, corpHierarchies, clientEnv]) => {
            if (!corp || !hierarchyElement) {
              return;
            }
            if (clientEnv) {
              this.clientEnvironment = clientEnv;
            }
            if (!permissions) {
              throw new Error('No permissions');
            }

            const corpHierarchy = corpHierarchies.find(
              ({ systemName }) => systemName === hierarchyElement.hierarchyName,
            );

            if (!corpHierarchy) {
              this.toaster.warning(`Hierarchy Doesn't Exist`);
              this.router.navigate(['/portal/dashboard']);
              return;
            }

            this.loading = false;

            this.corp = corp;
            this.parentElements = parentElements;
            this.hierarchyElement = hierarchyElement;
            this.permissions = permissions;
            this.corpHierarchy = corpHierarchy;

            this.elementTitle = this.parentElements.map(parentElement => parentElement?.label).join(' - ');

            this.nextDownLevelCorpHierarchy = corpHierarchies.find(
              ({ order }) => order === this.corpHierarchy.order + 1,
            );

            this.setCanAddBot();
            this.refreshUI();

            if (this.nextDownLevelCorpHierarchy) {
              this.getHierarchyChildrenElements();
            } else {
              this.geBotsInHierarchyElement();
            }
          },
          error => {
            this.toaster.error(error);
            this.loading = false;
          },
        );
      },
    );
  }

  private geBotsInHierarchyElement() {
    const botsIHavePermissionsTo = uniq(
      this.permissions
        .filter(
          permission =>
            this.hasAllCorps ||
            (permission.corpId === this.corp.id &&
              permission.hierarchyElementSystemName === this.parentSystemName &&
              permission.botCode),
        )
        .map(permission => permission.botCode),
    );

    this.botsSubscription = this.botsService.getBotsByHierarchyElement(this.parentSystemName).subscribe(bots => {
      this.bots = bots.filter(
        ({ code, clientEnvironment }) =>
          (botsIHavePermissionsTo.includes(code) || this.hasAllCorps) &&
          clientEnvironment === this.clientEnvironment?.selectedStage.systemName,
      );
    });
  }

  private getHierarchyChildrenElements() {
    const hierarchyElementsIHavePermissionsTo = uniq(
      this.permissions.map(permission => permission.hierarchyElementSystemName),
    );
    this.hierarchyChildrenElementsSubscription = this.hierarchyElementsService
      .getHierarchyChildrenElements(this.parentSystemName)
      .subscribe(hierarchyElements => {
        this.hierarchyElements = hierarchyElements.filter(
          ({ systemName }) => hierarchyElementsIHavePermissionsTo.includes(systemName) || this.hasAllCorps,
        );
      });
  }

  navigateToBot(botId: string) {
    this.breadcrumbService.pop();
    this.router.navigate(['../', 'bots', botId], { relativeTo: this.route });
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.botsSubscription) {
      this.botsSubscription.unsubscribe();
    }
    if (this.hierarchyChildrenElementsSubscription) {
      this.hierarchyChildrenElementsSubscription.unsubscribe();
    }
  }

  refreshUI() {
    this.setBreadcrumb(this.corp, this.hierarchyElement);
    this.setSidebarItems(this.corp.id, this.hierarchyElement);
    this.headerService.setPageTitle(`${this.hierarchyElement.label} Dashboard`);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, 'Dashboard', 'dashbaprd'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement));
  }

  canReadHierarchyAnalyticsCard(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_READ_HIERARCHY_ANALYTICS_CARD);
  }

  setCanAddBot() {
    // TODO: Instead of hiding the button, we disable it and make a note that they can only create bots on dev env
    this.canAddBot = this.authService.hasPermissionSync(Permissions.CAN_ADD_BOT) && this.isDevEnvironment();
  }

  canAddHierarchy(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_ADD_HIERARCHY);
  }

  isDevEnvironment(): boolean {
    const selectedStageSystemName = this.clientEnvironment?.selectedStage.systemName;
    if (selectedStageSystemName) {
      return selectedStageSystemName === 'development';
    }
    // We return true if selectedStage does not exist to support backwards compatibility
    // for clients that do not have environments yet. TODO: Remove this when all clients
    // migrated over to client environments.
    return true;
  }

  showAddBotModal() {
    if (!this.corp || !this.hierarchyElement) {
      return;
    }
    this.modalService.show(AddBotModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        corpId: this.corp.id,
        hierarchyElementId: this.hierarchyElement.id,
        hierarchyElementSystemName: this.hierarchyElement.systemName,
        botNameList: this.bots.map(bot => bot.code),
      },
    });
  }

  showAddHierarchyModal() {
    this.modalService.show(AddHierarchyElementModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        corpHierarchy: this.nextDownLevelCorpHierarchy,
        parentSystemName: this.parentSystemName,
      },
    });
  }
}
