import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NlpDatasetFile } from 'src/app/models/nlp/nlp-dataset/nlp-dataset-file';
import { ToastrService } from 'ngx-toastr';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nlp-dataset-file-general-info',
  templateUrl: './nlp-dataset-file-general-info.component.html',
  styleUrls: ['./nlp-dataset-file-general-info.component.scss']
})
export class NlpDatasetFileGeneralInfoComponent implements OnInit {
  loading: boolean;
  nlpDatasetFileAdded: EventEmitter<NlpDatasetFile>;

  @Input()
  writable: boolean;

  @Input()
  datasetSystemName: string;

  @Input()
  nlpDatasetFile: NlpDatasetFile;

  @Input()
  shouldAdd = false;

  @Output()
  saved: EventEmitter<void>;

  constructor(
    public modal: BsModalRef,
    private nlpDojoService: NlpDojoService,
    private toaster: ToastrService,
    private router: Router
  ) {
    this.saved = new EventEmitter<void>();
    this.nlpDatasetFileAdded = new EventEmitter<NlpDatasetFile>();
  }

  ngOnInit() {}

  save() {
    if (this.shouldAdd) {
      this.add();
    } else {
      this.update();
    }
  }

  private add() {
    NlpDatasetFile.generateFileName(this.nlpDatasetFile);
    this.nlpDojoService
      .createDatasetFile('default', this.datasetSystemName, this.nlpDatasetFile)
      .subscribe(createdNlpDatasetFile => {
        this.modal.hide();
        this.nlpDatasetFileAdded.emit(createdNlpDatasetFile);
        this.toaster.success('Dataset file created successfully');
        this.router.navigate([
          `portal/nlp/datasets/${this.datasetSystemName}/files/${createdNlpDatasetFile.system_name}`
        ]);
      });
  }

  private update() {
    NlpDatasetFile.generateFileName(this.nlpDatasetFile);
    this.nlpDojoService
      .updateDatasetFile('default', this.datasetSystemName, this.nlpDatasetFile.system_name, this.nlpDatasetFile)
      .subscribe(updatedNlpDatasetFile => {
        this.modal.hide();
        this.toaster.success('Dataset file updated successfully');
      });
  }
}
