import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorHandler {
  constructor(public toaster: ToastrService) {}

  handleBrainError(error: HttpErrorResponse) {
    if (error?.error?.message && error?.error?.error) {
      return throwError(error.error.error);
    }
    return throwError('An error occurred. Please try again.');
  }

  handleNlpDojoError(error: HttpErrorResponse) {
    if (error?.error?.error) {
      return throwError(error.error.error);
    }
    return throwError('An error occurred. Please try again.');
  }

  handleApiGateWayAnalyticsError(error: HttpErrorResponse) {
    if (error?.error?.error) {
      return throwError(error.error.error);
    }
    return throwError('An error occurred. Please try again.');
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.toaster.error(`An error occurred: ${error.error.message}`);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.toaster.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('An error occurred. Please try again.');
  }
}
