<ng-container class="graphql-docs" *ngIf="selected">
    <div class="d-flex flex-row py-2 align-items-start">
        <div>
            <h3 class="mr-2">{{ selected['name'] }}</h3>
            <h2 class="mr-2">
                <span class="badge badge-primary text-capitalize">
                    {{ selected['typeLabel'] ? selected['typeLabel']  : 'Documentation' }}
                </span>
            </h2>
        </div>
        <button class="btn btn-secondary btn-sm ml-auto" *ngIf="this.selectedHistory.length >= 2" (click)="back()">
            Back
        </button>
    </div>

    <div class="d-flex align-items-center" *ngFor="let item of selected['args'] | keyvalue">
      <h5 class="c-pointer hov-clr-purple mr-2" (click)="selectType(item.value)">{{item.value['name']}}</h5>
      <h3 class="c-pointer" *ngIf="item.value['typeLabel']" (click)="selectType(item.value)">
        <span class="badge badge-dark text-white" style="text-transform: none;">
          {{item.value['typeLabel']}}
        </span>
      </h3>
    </div>

    <div class="d-flex align-items-center" *ngFor="let item of selected['inputFields'] | keyvalue">
      <h5 class="c-pointer hov-clr-purple mr-2" (click)="selectType(item.value)">{{item.value['name']}}</h5>
      <h3 class="c-pointer" *ngIf="item.value['typeLabel']" (click)="selectType(item.value)">
        <span class="badge badge-dark text-white" style="text-transform: none;">
          {{item.value['typeLabel']}}
        </span>
      </h3>
    </div>

    <hr *ngIf="(selected['args'] || selected['inputFields']) && selected['children']" />
    <ng-container *ngFor="let item of selected['children'] | keyvalue">
        <h5 *ngIf="!hideTypes" class="c-pointer hov-clr-purple" (click)="selectType(item.value)">{{item.value['name']}}</h5>
        <h3 class="c-pointer" *ngIf="item.value['typeLabel']" (click)="selectType(item.value)">
            <span class="badge badge-dark text-white" style="text-transform: none;">
                {{item.value['typeLabel']}}
            </span>
        </h3>
    </ng-container>
</ng-container>
