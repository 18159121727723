import { Timestamp } from 'firebase-tools';
import * as firestore from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export class ApiQueryModel {
  id: string;
  name: string;
  systemName: string;
  corpId?: string;
  botId?: string;
  flowTemplateId?: string;
  query: string;
  queryType: string;
  queryOperation: string;
  exampleData: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;

  static generateDefault(): ApiQueryModel {
    const apiQuery = new ApiQueryModel();
    ApiQueryModel.generateId(apiQuery);
    apiQuery.createdAt = firestore.serverTimestamp();
    apiQuery.updatedAt = firestore.serverTimestamp();
    apiQuery.exampleData = '{}';
    apiQuery.query = '';
    return apiQuery;
  }

  static generateId(apiQuery: ApiQueryModel): string {
    apiQuery.id = uuidv4();
    return apiQuery.id;
  }

  static generateUpdatedAt(apiQuery: ApiQueryModel): string {
    apiQuery.updatedAt = firestore.serverTimestamp();
    return apiQuery.updatedAt;
  }

  static generateSystemName(apiQuery: ApiQueryModel): string {
    apiQuery.systemName = apiQuery.name.toLowerCase().trim().replace(/\s+/g, '-');
    return apiQuery.systemName;
  }
}
