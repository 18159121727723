<div class="container-fluid">
  <!-- General corp data -->
  <div class="row align-items-center py-4">
    <div class="col">
      <h6 class="h2 d-inline-block mb-0">General</h6>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-12">
      <div class="card">
        <div class="card-body">
          <app-corp-general-info
            *ngIf="corp"
            [corpTags]="corpTags"
            [writable]="canEditCorp()"
            [corp]="corp"
            (updateTags)="updateTags($event)"
            (saved)="refreshUI()"
          >
          </app-corp-general-info>
        </div>
      </div>
    </div>
  </div>

  <!-- KPIS -->
  <div class="row align-items-center py-4">
    <div class="col">
      <h6 class="h2 d-inline-block mb-0">Key Performance Indicators</h6>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-12">
      <button class="btn btn-primary btn-outline" type="button" (click)="showAddKpiModal()">Add KPI</button>
    </div>
  </div>
</div>
