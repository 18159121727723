import { prodSecrets } from './secrets.prod.local';

export const environment = {
  production: true,
  useAuth0: true,
  vulcan: {
    url: 'https://o4jtl12unc.execute-api.us-east-1.amazonaws.com/prod/',
  },
  botConfig: {
    url: 'https://28xzbofhza.execute-api.us-east-1.amazonaws.com/prod/bot-configuration/',
    latestVersion: 4,
  },
  bach: {
    url: 'https://opyuc1t6dj.execute-api.us-east-1.amazonaws.com/prod/',
  },
  dumbledore: {
    url: 'https://8sztbymif2.execute-api.us-east-1.amazonaws.com/prod/',
  },
  firebase: {
    apiKey: 'AIzaSyCqVGgeVYjhEy7L4Km0yifwDOJY103nsjU',
    authDomain: 'brain-ui-v1.firebaseapp.com',
    databaseURL: 'https://brain-ui-v1.firebaseio.com',
    projectId: 'brain-ui-v1',
    storageBucket: 'brain-ui-v1.appspot.com',
    messagingSenderId: '92363743524',
    appId: '1:92363743524:web:0add830d7fdf2882c393b1',
    measurementId: 'G-0GBBPJYJK1',
  },
  es: {
    'us-east-1': {
      domain: 'https://logs.es.carlabs.com/',
      accessKeyId: 'AKIATZ3SGK5WRBLWYDFG',
      secretAccessKey: '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',
    },
    'ap-south-1': {
      domain: 'https://logs.es.in.carlabs.com',
      accessKeyId: 'AKIATZ3SGK5WRBLWYDFG',
      secretAccessKey: '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',
    },
  },
  brain: {
    url: 'https://api.brain.carlabs.com',
  },
  apiGatewayAnalytics: {
    url: 'https://olvvzu5yud.execute-api.us-east-1.amazonaws.com/',
  },
  apiGatewayService: {
    url: 'https://api.data.carlabs.com',
  },
  botWebChatUrl: 'https://webchat.carlabs.com/static/js/carlabs-bundle.js',
  nlpDojoUrl: 'https://nlp-dojo.carlabs.com',
  nlpDojoS3Url: 'https://cl-nlp-dojo-prod.s3.amazonaws.com',
  jaiminhoEmailService: {
    url: 'https://g54z63ifcb.execute-api.us-east-1.amazonaws.com/prod/message',
  },
  platformApi: {
    url: 'https://jygfg8wr1h.execute-api.us-east-1.amazonaws.com/prod',
  },
  shopbot: {
    url: 'https://shopbot.carlabs.com',
  },
  domain: 'prod-serviceai.us.auth0.com',
  clientId: 'qOABSjqQyLSuy7CyWhZS7VCXtdYCsk7Z',
  authSecrets: prodSecrets,
};
