import { Timestamp } from 'firebase-tools';
export class UserNotificationSettingsModel {
  email?: boolean;
  in_app?: boolean;
  lastActive?: Timestamp;
  count?: number;
}
export class UserModel {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  avatar?: string;
  role: string;
  notificationSettings: UserNotificationSettingsModel;
  carLabsEmail?: string;
  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
