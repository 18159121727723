export class PermissionModel {
  userId: string;
  corpId: string;
  hierarchyElementSystemName: string | null | undefined;
  botCode: string | null | undefined;

  get id(): string {
    return PermissionModel.buildId(this.userId, this.corpId, this.hierarchyElementSystemName, this.botCode);
  }

  static buildId(
    userId: string,
    corpId: string,
    hierarchyElementSystemName?: string | null,
    botId?: string | null
  ): string {
    return [userId, corpId, hierarchyElementSystemName, botId].filter(i => i != null).join('-');
  }
}
