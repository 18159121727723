import { Component, OnInit } from '@angular/core';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';

@Component({
  selector: 'app-action-progress',
  templateUrl: './action-progress.component.html',
  styleUrls: ['./action-progress.component.scss']
})
export class ActionProgressComponent implements OnInit {
  progressRef: NgProgressRef;

  constructor(private progress: NgProgress) {}

  ngOnInit() {
    this.progressRef = this.progress.ref('myProgress');
  }
  start() {
    this.progressRef.start();
  }

  complete() {
    this.progressRef.complete();
  }
}
