<div class="container-fluid">
  <ng-container *ngIf="loading">
    <div class="container-fluid">
      <div class="row align-items-center py-4 justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading && hierarchyElement">
    <!--Unused component, remove after some months-->
    <!--<app-analytics-cards-topline-stats
      *ngIf="canReadHierarchyAnalyticsCard()"
      [corpsId]="[corp.id]"
      [hierarchyElementId]="hierarchyElement.id"
    ></app-analytics-cards-topline-stats>-->
    <div *ngIf="!nextDownLevelCorpHierarchy">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7 d-flex align-items-center">
          <h2 class="h2 d-inline-block mb-0">Assistants</h2>
          <button data-test-id="add-bot" class="ml-3 btn-sm btn-outline-primary rounded-circle" *ngIf="canAddBot">
            <i class="fa fa-plus" (click)="showAddBotModal()"></i>
          </button>
          <a
            data-test-id="add-bot-from-template"
            [routerLink]="['../', 'select-template']"
            class="ml-3 btn-sm btn-outline-primary"
            *ngIf="canAddBot"
          >
            <i class="fa fa-plus"></i>
            Create from template
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-md-6" *ngFor="let bot of bots">
          <div
            [attr.data-test-id]="'bot-label-' + bot.label"
            data-test-class="bot-item"
            class="card element-card"
            [routerLink]="['../', 'bots', bot.code]"
          >
            <div class="card-body">
              <img
                class="img-center img-fluid rounded-circle"
                [src]="bot.styles.avatarImageUrl"
                onError="this.src='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='"
              />
              <div class="pt-4 text-center">
                <h2 class="title">{{ bot.label }}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="!bots.length">
          <h5>No bots.</h5>
        </div>
      </div>
    </div>

    <div *ngIf="nextDownLevelCorpHierarchy">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7 d-flex align-items-center">
          <h2 class="h2 d-inline-block mb-0">
            {{ nextDownLevelCorpHierarchy.label }}
          </h2>
          <button class="ml-3 btn-sm btn-outline-primary rounded-circle" *ngIf="canAddHierarchy()">
            <i class="fa fa-plus" (click)="showAddHierarchyModal()"></i>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-6" *ngFor="let hierarchyElementItem of hierarchyElements">
          <div class="card element-card" [routerLink]="['../../', hierarchyElementItem.systemNameForUrl]">
            <div class="card-body">
              <img
                class="img-center img-fluid rounded-circle"
                [src]="hierarchyElementItem.logo"
                onError="this.src='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='"
              />
              <div class="pt-4 text-center">
                <h2 class="title">{{ hierarchyElementItem.label }}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="!hierarchyElements.length">
          <h5>No {{ nextDownLevelCorpHierarchy.label }}.</h5>
        </div>
      </div>
    </div>

    <!-- Status Updates -->

    <!--		<div class="row pt-3">-->
    <!--			<div class="col-lg-6 col-sm-12">-->
    <!--				<app-status-udates-card></app-status-udates-card>-->
    <!--			</div>-->
    <!--			<div class="col-lg-6 col-sm-12">-->
    <!--				<app-notifications-card></app-notifications-card>-->
    <!--			</div>-->
    <!--		</div>-->
  </ng-container>
</div>
