<div class="card card-stats">
  <div class="card-body">
    <div class="row">
      <div class="col">
        <h5 class="card-title text-uppercase text-muted mb-0">
          {{ title }}
        </h5>

        <span *ngIf="!loading && data" class="h2 font-weight-bold mb-0">
          {{ data.value | number: '1.0-2' }}
        </span>
      </div>

      <div class="col-auto" *ngIf="icon">
        <div class="icon icon-shape text-white rounded-circle shadow" [ngClass]="icon.bgClasses">
          <i class="ni" [ngClass]="icon.iconClasses"> </i>
        </div>
      </div>
    </div>
    <ng-container *ngIf="loading">
      <div class="container-fluid">
        <div class="row align-items-center py-4 justify-content-center">
          <app-loading></app-loading>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading && !data"><span> No data. </span></ng-container>
    <ng-container *ngIf="!loading && data">
      <p class="mt-3 mb-0 text-sm">
        <span
          class="mr-2"
          [ngClass]="{
            'text-success': data.improved === true,
            'text-warning': data.improved === false
          }"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-arrow-up': data.improved === true,
              'fa-arrow-down': data.improved === false
            }"
          >
          </i>
          {{ data.difference }}
        </span>
        <span class="text-nowrap"> {{ data.explanation }} </span>
      </p>
    </ng-container>
  </div>
</div>
