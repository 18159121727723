import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from 'src/app/services/error-handler/http-error-handler.service';
import { environment } from 'src/environments/environment';
import { map, take, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiGatewayService {
  private baseUrl: string;

  basicTypes = [
    '__Directive',
    '__DirectiveLocation',
    '__EnumValue',
    '__Field',
    '__InputValue',
    '__Schema',
    '__Type',
    '__TypeKind'
  ];

  constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.baseUrl = environment.apiGatewayService.url;
  }

  async schema(id: string): Promise<object> {
    const url = `${this.baseUrl}/${id}/graphql/schema`;
    return this.http
      .get<object>(url)
      .pipe(catchError(error => this.httpErrorHandler.handleBrainError(error)))
      .pipe(take(1))
      .toPromise()
      .then((data) => {
        const ts = data['__schema']['types'].filter((t) => !this.basicTypes.includes(t.name));

        const allTypes = ts.filter((t) => t.name != 'Query' && t.name != 'Mutation');
        const allQueryTypes = ts.filter((t) => t.name == 'Query')[0] ?? null;
        const allMutationTypes = ts.filter((t) => t.name == 'Mutation')[0] ?? null;

        const types = this.generateGraphQLReturnType(allTypes, allTypes);
        const queryTypes = this.generateGraphQLReturnType(allTypes, allQueryTypes['fields']);
        const mutationTypes = this.generateGraphQLReturnType(allTypes, allMutationTypes['fields']);

        return {
          name: 'Documentation',
          children: {
            types: {
              name: 'Types',
              children: types
            },
            query: {
              name: 'Query',
              children: queryTypes
            },
            mutation: {
              name: 'Mutation',
              children: mutationTypes
            }
          }
        }
      });
  }

  async validate(id: string, query: string): Promise<object[]> {
    const url = `${this.baseUrl}/${id}/graphql/validate`;
    const data = { query: query };
    return this.http
      .post<object>(url, data)
      .pipe(catchError(error => this.httpErrorHandler.handleBrainError(error)))
      .pipe(take(1))
      .toPromise()
      .then((data: object[]) => {
        if (data == null){
          return [];
        }
        return data;
      });
  }

  getGraphQLFieldType(field: object): string {
    if (!field['type'] && field['name']) {
      return field['name'];
    }
    const r = (obj: object): string => {
      if (obj['ofType']) {
        return r(obj['ofType']);
      }
      return obj['name'];
    };
    return r(field['type']);
  }

  generateGraphQLFieldName(field: object) {
    if (!field['type'] && field['name']) {
      return field['name'];
    }
    const r = (obj: object, arr: object[] = []): object[] => {
      arr.push(obj);
      if (obj['ofType']) {
        return r(obj['ofType'], arr);
      }
      return arr;
    };

    const types = r(field['type']);
    types.reverse();

    let name = '';

    types.forEach((t: object) => {
      if (t['name']) {
        name = t['name'];
      }
      switch(t['kind']) {
        case 'LIST':
          name = `[${name}]`;
          break;
        case 'OBJECT':
          name = `${name}`;
          break;
        case 'NON_NULL':
          name = `!${name}`;
          break;
      }
    });

    return name;
  }

  generateGraphQLFieldKind(field: object) {
    if (field['type'] && field['type']['kind']) {
      const r = (field: object): string => {
        if (field['kind'] === 'NON_NULL' && field['ofType']) {
          return r(field['ofType']);
        } else {
          return field['kind'];
        }
      };
      return r(field['type']);
    }
    return null;
  }

  generateGraphQLType(types: object[] = [], gqlTypes: object[] = []) {
    const obj = {};
    gqlTypes.forEach((gqlType: object) => {
      if (gqlType['name']) {
        obj[gqlType['name']] = true;
      }
    });
    return obj;
  }

  generateGraphQLReturnType(allTypes: object[] = [], types: object[]): object {
    const typesMap = {};
    allTypes.forEach(t => {
      typesMap[t['name']] = t;
    });

    const r = (types: object, fields: object[], rootObj: object = {}) => {
      fields.forEach(f => {
        const fieldTypeName = this.getGraphQLFieldType(f);
        const foundField = types[fieldTypeName];
        let inputFields: object | null = null;
        let children: object | null = null;
        let args: object | null = null;

        if (rootObj[fieldTypeName]) {
          return;
        }

        if (f['args'] && f['args'].length) {
          args = r(types, f['args']);
        }

        if (!foundField) {
          rootObj[f['name']] = {
            name: f['name'],
            type: fieldTypeName,
            kind: this.generateGraphQLFieldKind(f),
            typeLabel: this.generateGraphQLFieldName(f),
            children: children,
            inputFields: inputFields,
            args: args,
          };
          return;
        }

        if (foundField['inputFields'] && foundField['inputFields'].length) {
          inputFields = r(types, foundField['inputFields']);
        }

        if (foundField['fields'] && foundField['fields'].length) {
          children = r(types, foundField['fields']);
        }

        rootObj[f['name']] = {
          name: f['name'],
          type: fieldTypeName,
          kind: this.generateGraphQLFieldKind(f),
          typeLabel: this.generateGraphQLFieldName(f),
          children: children,
          inputFields: inputFields,
          args: args
        };
      });

      return rootObj;
    };

    return r(typesMap, types);
  }
}
