<div class="modal-header">
  <h4 class="modal-title">Save Global Variable</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (submit)="onSave()" #form="ngForm" novalidate>
  <div class="modal-body">
    <div class=" form-group">
      <label class=" form-control-label" for="label"> Label </label>
      <input data-test-id="global-variable-label-input" class="form-control" id="label" name="label" [(ngModel)]="globalVariable.label" type="text" placeholder="Label for the variable" required />
    </div>
    <div class=" form-group">
      <label class=" form-control-label" for="defaultValue">{{ isDefaultText }} Value </label>
      <input data-test-id="global-variable-default-value-input" class="form-control" id="defaultValue" name="defaultValue" [(ngModel)]="globalVariable.defaultValue" type="text" [placeholder]="isDefaultText + 'Value for the variable'"/>
    </div>
    <div class=" form-group">
      <label class=" form-control-label" for="description"> Description </label>
      <input data-test-id="global-variable-description-input" class="form-control" id="description" name="description" [(ngModel)]="globalVariable.description" type="text" placeholder="Description for the variable" />
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-outline" data-test-id="save-variables-button" type="submit" [disabled]="!form.valid">Save</button>
    <button class="btn btn-primary-outline" data-test-id="close-variables-button" type="button" (click)="modal.hide()">CLOSE</button>
  </div>
</form>
