import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { GlobalVariable, GlobalVariableType } from 'src/app/models/bot';

@Component({
  selector: 'app-upsert-global-variable-modal',
  templateUrl: './upsert-global-variable-modal.component.html',
  styleUrls: ['./upsert-global-variable-modal.component.scss']
})
export class UpsertGlobalVariableModalComponent implements OnInit {
  globalVariable: GlobalVariable;
  isDefaultText = '';
  onGlobalVariableSave: (globalVariable: GlobalVariable) => void;

  constructor(public modal: BsModalRef, options: ModalOptions) {
    if (!options.initialState) {
      return;
    }
    const { globalVariable, onGlobalVariableSave } = options.initialState as any;
    this.globalVariable = globalVariable;
    this.isDefaultText = this.globalVariable.type === GlobalVariableType.BOT ? '' : 'Default ';
    this.onGlobalVariableSave = onGlobalVariableSave;
  }

  async ngOnInit() {}

  onSave() {
    this.onGlobalVariableSave(this.globalVariable);
    this.modal.hide();
  }
}
