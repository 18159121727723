export const COLLECTION_NAMES = {
  USERS: 'users',
  BOTS: 'bots',
  VERSIONS: 'versions',
  NODES: 'nodes',
  ROLES: 'roles',
  CORPS: 'corps',
  ENVIRONMENTS: 'environments',
  NOTIFICATIONS: 'notifications',
  API_QUERIES: 'api_queries',
  PERMISSIONS: 'permissions',
  SEQUENCES: 'sequences',
  STATE_VARIABLES: 'state_variables',
  INPUT_VALIDATIONS: 'input_validations',
  HIERARCHY_ELEMENTS: 'hierarchy_elements',
};
