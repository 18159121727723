import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  HLConversationModel,
  HLTemplateModel,
  IConversationClickEvent,
  IFilterCriteria,
  IFirebaseFilterCriteria,
} from 'src/app/models/conversations';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { ClientEnvironmentModel } from 'src/app/models/client-environment';
import { BotModel, CorpModel, HierarchyElementModel, RoleModel } from 'src/app/models';
import { Subscription, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { HeaderService } from 'src/app/services/header.service';
import { BotsService, CorpsService, HierarchyElementsService, HumanInLoopService } from 'src/app/services/firestore';
import { BreadcrumbNodeVM } from '../../breadcrumb/_types/BreadcrumbNodeVM';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HILMode } from 'src/app/pages/portal/human-in-the-loop/human-in-the-loop.component';
import { getSidebarItems as getBotSidebarItems } from 'src/app/pages/portal/bot/utils';
import { getSidebarItems as getCorpSidebarItems } from 'src/app/pages/portal/corp/utils';
import { getSidebarItems as getHierarchySidebarItems } from 'src/app/pages/portal/hierarchy-element/utils';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-hl-container',
  templateUrl: './hl-container.component.html',
  styleUrls: ['./hl-container.component.scss'],
})
export class HlContainerComponent implements OnInit, OnDestroy {
  private routesSubscription: Subscription;
  private combinedSubscriptions: Subscription;

  conversationsList: HLConversationModel[];
  selectedConversation: HLConversationModel | null;
  selectedConversationId: string;
  corpId: string;
  corp: CorpModel | null;
  hierarchyElement: HierarchyElementModel | null;
  bot: BotModel | null;
  corpTags: string[];
  templates: HLTemplateModel[];
  envs: ClientEnvironmentModel | null;
  hilSupportRoles: RoleModel[];

  hilMode: HILMode = HILMode.None;

  hierarchy: string;
  botCode: string;
  conversationId: string;
  userId: string;
  preloadedConversation: HLConversationModel | null;

  constructor(
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private corpsService: CorpsService,
    private botsService: BotsService,
    private humanInLoopService: HumanInLoopService,
    private clientEnvironmentService: ClientEnvironmentService,
    private hierarchyElementsService: HierarchyElementsService,
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
  ) {}

  ngOnInit() {
    this.routesSubscription = this.route.params.subscribe(async param => {
      if (!param.corp) {
        return;
      }
      const { hierarchyElementSystemName, conversationId, userId, bot: botCode } = param;

      if (hierarchyElementSystemName) {
        this.hierarchy = hierarchyElementSystemName;
      }
      if (botCode) {
        this.botCode = botCode;
      }
      if (conversationId) {
        this.conversationId = conversationId;
      } else {
        const json = this.getSessionParameters();
        if (json && json.conversationId) {
          this.conversationId = json.conversationId;
        }
      }
      if (userId) {
        this.userId = userId;
      }

      this.corpId = param.corp;

      if (botCode) {
        this.hilMode = HILMode.Bot;
      } else if (hierarchyElementSystemName) {
        this.hilMode = HILMode.Hierarchy;
      } else if (this.corpId) {
        this.hilMode = HILMode.Corp;
      }

      if (this.conversationId && this.corpId) {
        const conversation = await this.humanInLoopService.loadInitialConversation(this.corpId, this.conversationId);
        if (conversation) {
          this.preloadedConversation = conversation as HLConversationModel;
        }
      }

      if (this.combinedSubscriptions) {
        this.combinedSubscriptions.unsubscribe();
      }
      this.combinedSubscriptions = combineLatest([
        this.clientEnvironmentService.items$,
        this.humanInLoopService.getCorpTags(this.corpId),
        this.humanInLoopService.getCorpHLTemplates(this.corpId),
        this.humanInLoopService.getHILSupportedRoles(),
        this.corpsService.getCorpById(this.corpId),
        this.hierarchyElementsService.getHierarchyElement(`${this.corpId}-${this.hierarchy}`),
      ]).subscribe(([envs, hlCorp, hlTemplates, hlRoles, corpModel, hierarchyElement]) => {
        if (!envs || !hlCorp || !hlTemplates || !hlRoles) {
          return;
        }

        this.envs = envs;
        this.corpTags = hlCorp.conversation_tags ?? [];
        this.templates = hlTemplates;
        this.hilSupportRoles = hlRoles;
        this.corp = corpModel;
        this.hierarchyElement = hierarchyElement;
        if (this.botCode) {
          this.botsService
            .getBotBy(this.corpId, this.hierarchy, this.botCode, this.envs)
            .then(bot => {
              this.bot = bot;
              this.refreshUI();
            })
            .catch(error => {
              this.refreshUI();
            });
        } else {
          this.refreshUI();
        }
      });
    });
  }

  public refreshUI() {
    if (!this.corp && !this.hierarchyElement && !this.bot) {
      return;
    }
    if (!this.corp) {
      return;
    }
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.botCode);
    this.headerService.setPageTitle(
      `${this.bot?.label || this.hierarchyElement?.label || this.corp?.label} Human In The Loop`,
    );
  }

  getSessionParameters() {
    try {
      const storage = window.localStorage;
      if (storage) {
        const value = storage.getItem('dashboardParameters') || null;
        if (value !== null) {
          return JSON.parse(value);
        }
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel | null, botCode: string) {
    switch (this.hilMode) {
      case HILMode.Bot:
        if (hierarchyElement) {
          this.sidebarService.set(getBotSidebarItems(corpId, hierarchyElement, botCode));
        }
        break;
      case HILMode.Hierarchy:
        if (hierarchyElement) {
          this.sidebarService.set(getHierarchySidebarItems(corpId, hierarchyElement));
        }
        break;
      case HILMode.Corp:
        this.sidebarService.set(getCorpSidebarItems(corpId));
        break;
    }
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel | null, bot: BotModel | null) {
    const title = 'Human In The Loop';
    const url = 'human-in-the-loop';
    const baseCorpUrl = `portal/corps/${this.corpId}`;
    const items: BreadcrumbNodeVM[] = [
      {
        label: corp.label,
        icon: corp.logo,
        route: `portal/corps/${corp.id}`,
        testId: 'bread-crumb-corp',
      },
    ];
    if (hierarchyElement) {
      items.push({
        icon: hierarchyElement.logo,
        label: hierarchyElement.label,
        route: `${baseCorpUrl}/hierarchy-el/${hierarchyElement.systemNameForUrl}`,
        testId: 'bread-crumb-hierarchy',
      });
    }
    if (bot) {
      if (hierarchyElement) {
        items.push({
          label: bot.label,
          icon: bot.styles.avatarImageUrl,
          route: `${baseCorpUrl}/hierarchy-el/${hierarchyElement.systemNameForUrl}/bots/${bot.code}`,
          testId: 'bread-crumb-bot',
        });
      } else {
        items.push({
          label: bot.label,
          icon: bot.styles.avatarImageUrl,
          route: `${baseCorpUrl}/hierarchy-el/${this.hierarchy}/bots/${bot.code}`,
          testId: 'bread-crumb-bot',
        });
      }
    }

    if (bot) {
      items.push({
        label: title,
        route: `${baseCorpUrl}/bots/${bot.code}/${url}`,
      });
    } else if (hierarchyElement) {
      items.push({
        label: title,
        route: `${baseCorpUrl}/hierarchy-el/${hierarchyElement.systemNameForUrl}/${url}`,
      });
    } else if (corp) {
      items.push({
        label: title,
        route: `${baseCorpUrl}/${url}`,
      });
    }
    this.breadcrumbService.set(items);
  }

  conversationScrollListener($event: 'bottom' | 'top') {
    if ($event === 'bottom') {
      // || $event === 'top')
      this.humanInLoopService.loadMoreConversations(this.corpId, this.hierarchy, this.botCode);
    }
  }
  async conversationClickListener($event: IConversationClickEvent) {
    try {
      const { conversation, isAutoSelectedConversation } = $event;
      if (!conversation?.firebaseDocumentId) {
        throw new Error('No firebaseDocumentId found');
      }
      this.selectedConversation = conversation;
      this.selectedConversationId = conversation?.firebaseDocumentId;
    } catch (error) {
      console.error(error);
    }
  }
  clearSelectedConversation() {
    this.selectedConversation = null;
    this.selectedConversationId = '';
  }

  async refreshConversations(filters: IFirebaseFilterCriteria[]) {
    this.clearSelectedConversation();
    const newLimit = await this.humanInLoopService.conversationCount.pipe(take(1)).toPromise();
    this.humanInLoopService.clearData('conversations');
    this.attachConversationListener(this.corpId, filters, newLimit);
  }

  private attachConversationListener(corpId: string, filters: IFirebaseFilterCriteria[], newLimit?: number) {
    // console.log('attachConversationListener -> listenToConversations');
    this.humanInLoopService.listenToConversations(corpId, filters, newLimit, this.hierarchy, this.botCode);
  }

  filterConversations(event: IFilterCriteria[]) {
    const firebaseFilters: IFirebaseFilterCriteria[] = [];
    event.forEach(filter => {
      if (filter.firebaseFilter) {
        firebaseFilters.push(filter.firebaseFilter);
      }
    });
    this.refreshConversations(firebaseFilters);
  }

  ngOnDestroy() {
    if (this.routesSubscription) {
      this.routesSubscription.unsubscribe();
    }
    if (this.combinedSubscriptions) {
      this.combinedSubscriptions.unsubscribe();
    }
  }
}
