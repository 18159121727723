<div class="modal-header">
  <h4 class="modal-title">Conversation Tags</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group row">
    <div class="col p-0">
      <select (change)="addTag($event.target.value)" class="col-sm form-control" name="kpiSelect">
        <option value="">Select Tag</option>
        <ng-container *ngFor="let tag of corpTags">
          <option *ngIf="!tags.includes(tag)" value="{{ tag }}">{{ tag }}</option>
        </ng-container>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <div class="col p-0">
      <tag-input
        [(ngModel)]="tags"
        placeholder="Add a new tag"
        [modelAsStrings]="true"
        theme="regular-theme"
        name="tags"
        id="tags"
        [editable]="true"
        [hideForm]="true"
      >
      </tag-input>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-sm" aria-label="Close" (click)="saveTags()">Save</button>
</div>
