import { Component, Input, Output, EventEmitter, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { HLConversationModel } from 'src/app/models/conversations';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-hl-conversations-list',
  templateUrl: './hl-conversations-list.component.html',
  styleUrls: ['./hl-conversations-list.component.scss'],
})
export class HlConversationsListComponent {
  searchText = '';

  @Input()
  conversations = [];

  @Input()
  conversationsLastMessage = {};

  @Input()
  selectedConversationId;

  @Output()
  conversationSelected: EventEmitter<HLConversationModel>;

  @Output()
  filterConversation: EventEmitter<void>;

  @Output()
  clearFilter: EventEmitter<void>;

  @Output()
  searchConversations: EventEmitter<string>;

  @Output()
  loadMore: EventEmitter<boolean>;

  advancedSearch = false;

  @Input()
  clearSearch = false;

  filterFields = [
    {
      id: 'email',
      name: 'Email',
    },
    {
      id: 'tags',
      name: 'Tags',
    },
    {
      id: 'first_name',
      name: 'First Name',
    },
  ];

  fieldOperators = {
    email: [
      { id: '==', name: 'Equal' },
      { id: 'contains', name: 'Contains' },
    ],
    tags: [{ id: 'contains', name: 'Contains' }],
    first_name: [
      { id: '==', name: 'Equal' },
      { id: 'contains', name: 'Contains' },
    ],
  };

  searchTextChanged: Subject<string> = new Subject<string>();

  loadMoreTopSubject: Subject<boolean> = new Subject<boolean>();
  loadMoreBottomSubject: Subject<boolean> = new Subject<boolean>();

  @ViewChildren('conversationsListDiv') conversationsListDiv: QueryList<ElementRef>;

  @Input()
  loadingTop = true;

  @Input()
  loadingBottom = true;

  constructor() {
    this.conversationSelected = new EventEmitter<HLConversationModel>();
    this.filterConversation = new EventEmitter<void>();
    this.clearFilter = new EventEmitter<void>();
    this.searchConversations = new EventEmitter<string>();
    this.loadMore = new EventEmitter<boolean>();

    this.searchTextChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe(query => {
      this.searchConversations.emit(query);
    });
  }

  onScroll(event: Event) {
    if (event.target) {
      const div: HTMLElement = event.target as HTMLElement;
      if (div.scrollTop === 0) {
        this.loadMore.emit(true);
        return;
      }
      if (div.scrollTop + div.offsetHeight === div.scrollHeight) {
        this.loadMore.emit(false);
      }
    }
  }

  refreshConversation(conversation) {
    this.conversationSelected.emit(conversation);
  }

  showAdvancedSearch() {
    this.filterConversation.emit();
  }

  doClearFilter() {
    this.searchText = '';
    this.clearFilter.emit();
  }

  doSearchConversations(query) {
    this.searchTextChanged.next(this.searchText);
  }

  public scrollToConversation(conversationId: string) {
    const conversationDiv = document.getElementById(`convo-${conversationId}`);
    if (conversationDiv) {
      const allConversationsDiv = document.getElementById('all-conversations-list');
      if (allConversationsDiv) {
        allConversationsDiv.scrollTop = conversationDiv.offsetTop - allConversationsDiv.offsetTop;
      }
    }
  }
}
