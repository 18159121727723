<div class=" card">
  <div class=" card-header">
    <div class=" d-flex flex-row-reverse">
      <div *ngIf="useCountingPeriod" class="pl-2">
        <select [ngModel]="selectedCountingPeriod" (ngModelChange)="processTimePeriodChange($event)">
          <option *ngFor="let allowedPeriodDTO of countingPeriods" [ngValue]="allowedPeriodDTO">
            Counting {{ allowedPeriodDTO.description }}</option>
        </select>
      </div>

      <div>
        <select [ngModel]="selectedTimeFrame" (ngModelChange)="processTimeFrameChange($event)">
          <option *ngFor="let timeFrame of timeFrames" [ngValue]="timeFrame">{{ timeFrame.description }}</option>
        </select>
      </div>
    </div>

    <h6 *ngIf="chartSettings.surtitle" class=" surtitle">{{ chartSettings.surtitle }}</h6>

    <h5 *ngIf="chartSettings.title" class=" h3 mb-0">{{ chartSettings.title }}</h5>
  </div>

  <div class=" card-body mh-398-px" [ngClass]="isChartEmpty ? 'empty-chart' : ''">
    <div class=" container-fluid" *ngIf="loading">
      <div class=" row align-items-center py-4 justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>

    <div *ngIf="isChartEmpty && !loading" class="empty-chart-message">NO DATA</div>
    <div [hidden]="loading" class=" chart" [ngClass]="isChartEmpty ? 'empty-chart-table' : ''">
      <canvas class="chart-canvas" #chart></canvas>
    </div>
  </div>
</div>