import { SidebarItemVM } from 'src/app/components/layout/sidebar/_types/SidebarItemVM';
import { Permissions } from 'src/app/utils/permissions/permissions';

export const getSidebarItems = (corpId: string): SidebarItemVM[] => {
  return [
    {
      path: `/portal/corps/${corpId}/dashboard`,
      title: 'Dashboard',
      type: 'link',
      testId: 'corp-dashboard-link',
      icontype: 'ni-world-2 text-primary',
    },
    {
      path: `/portal/corps/${corpId}/input-validations`,
      title: 'Input Validations',
      type: 'link',
      icontype: 'ni-collection text-warning',
      permission: Permissions.CAN_READ_CORP_INPUT_VALIDATIONS,
    },
    {
      path: `/portal/corps/${corpId}/settings`,
      title: 'Settings',
      type: 'link',
      icontype: 'ni-settings-gear-65 text-grey',
      testId: 'corp-settings-link',
      permission: Permissions.CAN_READ_CORP_SETTINGS,
    },
    // { TODO: this will be enabled when will fix the analytics on corp level
    //   path: `/portal/corps/${corpId}/analytics`,
    //   title: 'Analytics',
    //   type: 'link',
    //   icontype: 'ni-chart-bar-32 text-danger',
    //   permission: Permissions.CAN_READ_CORP_ANALYTICS
    // },
    {
      path: `/portal/corps/${corpId}/flow-templates`,
      title: 'Flow Templates',
      type: 'link',
      icontype: 'ni-folder-17 text-primary',
      testId: 'corp-flow-templates-link',
      permission: Permissions.CAN_READ_FLOW_TEMPLATES,
    },

    {
      path: `/portal/corps/${corpId}/human-in-the-loop`,
      title: 'Human In The Loop',
      type: 'link',
      icontype: 'ni-support-16 text-purple',
      testId: 'corp-flow-templates-link',
      permissions: [Permissions.CAN_HANDLE_BOT_SUPPORT, Permissions.CAN_ADMIN_BOT_HIL],
    },
  ];
};
