<div class="container-fluid">
  <div *ngIf="loading">
    <div class="container-fluid">
      <div class="row align-items-center py-4 justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!loading">
    <!--Unused component, remove after some months-->
    <!--<app-analytics-cards-topline-stats [corpsId]="corpsId"></app-analytics-cards-topline-stats>-->

    <!-- CORPS -->
    <div class="row align-items-center py-4">
      <div class="col-lg-6 col-7">
        <h2 class="h2 d-inline-block mb-0">Organizations</h2>
      </div>
    </div>
    <div class="row" *ngIf="corps">
      <div class="col-xl-3 col-md-6" *ngFor="let corp of corps">
        <div class="card corp-card" [attr.data-test-id]="'corp-' + corp.id" (click)="navigateToCorp(corp.id)">
          <div class="card-body">
            <img
              class="img-center img-fluid rounded-circle"
              [src]="corp.logo"
              onError="this.src='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='"
            />
          </div>
          <p style="margin-left: 15px">{{ corp.label }}</p>
        </div>
      </div>
      <div class="col-12" *ngIf="!corps.length">
        <h5>No Organizations.</h5>
      </div>
    </div>

    <!--<div class="row pt-3">
			<div class="col-lg-6 col-sm-12">
				<div class="card">
					<div class="card-header">
						<h5 class="h3 mb-0">Notifications</h5>
					</div>

					<div class="card-body">
						<div
							class="timeline timeline-one-side"
							data-timeline-axis-style="dashed"
							data-timeline-content="axis"
						>
							<div class="timeline-block">
								<span class="timeline-step badge-success">
									<i class="ni ni-bell-55"> </i>
								</span>

								<div class="timeline-content">
									<div class="d-flex justify-content-between pt-1">
										<div>
											<span class="text-muted text-sm font-weight-bold">
												New login
											</span>
										</div>

										<div class="text-right">
											<small class="text-muted">
												<i class="fas fa-clock mr-1"> </i> 2 hrs ago
											</small>
										</div>
									</div>

									<h6 class="text-sm mt-1 mb-0">
										New Account Created
									</h6>
								</div>
							</div>

							<div class="timeline-block">
								<span class="timeline-step badge-danger">
									<i class="ni ni-html5"> </i>
								</span>

								<div class="timeline-content">
									<div class="d-flex justify-content-between pt-1">
										<div>
											<span class="text-muted text-sm font-weight-bold">
												Content Edited
											</span>
										</div>

										<div class="text-right">
											<small class="text-muted">
												<i class="fas fa-clock mr-1"> </i> 5 hrs ago
											</small>
										</div>
									</div>

									<h6 class="text-sm mt-1 mb-0">
										Digital Assistant content was edited.
									</h6>
								</div>
							</div>

							<div class="timeline-block">
								<span class="timeline-step badge-info">
									<i class="ni ni-like-2"> </i>
								</span>

								<div class="timeline-content">
									<div class="d-flex justify-content-between pt-1">
										<div>
											<span class="text-muted text-sm font-weight-bold">
												New trend!
											</span>
										</div>

										<div class="text-right">
											<small class="text-muted">
												<i class="fas fa-clock mr-1"> </i> 5 hrs ago
											</small>
										</div>
									</div>

									<h6 class="text-sm mt-1 mb-0">
										ABC Assistant is experiencing an uptick in usage for Dec '19
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-sm-12">
				<div class="card">
					<div class="card-header">
						<h5 class="h3 mb-0">Progress Updates</h5>
					</div>

					<div class="card-body p-0">
						<div class="list-group list-group-flush">
							<a
								class="list-group-item list-group-item-action flex-column align-items-start py-4 px-4"
								href="javascript:void(0)"
							>
								<div class="d-flex w-100 justify-content-between">
									<div>
										<div class="d-flex w-100 align-items-center">
											<img
												alt="Image placeholder"
												class="avatar avatar-xs mr-2"
												src="assets/img/theme/team-1.jpg"
											/>

											<h5 class="mb-1">James</h5>
										</div>
									</div>

									<small> 2 hrs</small>
								</div>

								<h4 class="mt-3 mb-1">New Flow Template Applied</h4>

								<p class="text-sm mb-0">
									The ABC Assistant was initially created through the basebot
									template.
								</p>
							</a>

							<a
								class="list-group-item list-group-item-action flex-column align-items-start py-4 px-4"
								href="javascript:void(0)"
							>
								<div class="d-flex w-100 justify-content-between">
									<div>
										<div class="d-flex w-100 align-items-center">
											<img
												alt="Image placeholder"
												class="avatar avatar-xs mr-2"
												src="assets/img/theme/team-1.jpg"
											/>

											<h5 class="mb-1">James</h5>
										</div>
									</div>

									<small> 6 hr </small>
								</div>

								<h4 class="mt-3 mb-1">
									<span class="text-info"> ● </span>NLU Agent Updated
								</h4>

								<p class="text-sm mb-0">
									The FI agent was updated with some new high frequency
									questions as captured from customer questions and synthizised
									into the model.
								</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>-->
  </ng-container>
</div>
