<div class="container-fluid">
  <p *ngFor="let conv of conversationsList">{{ conv.userId }}</p>
  <div class="row mt-3">
    <!-- Conversations List -->
    <div class="col-12 col-md-3">
      <app-hl-conversation-list
        (scrollEmitter)="conversationScrollListener($event)"
        (conversationClickEmitter)="conversationClickListener($event)"
        (refreshConversationsEmitter)="refreshConversations($event)"
        (filterConversationEmitter)="filterConversations($event)"
        [selectedConversationId]="selectedConversationId"
        [preloadedConversation]="preloadedConversation"
        [bot]="bot"
      >
      </app-hl-conversation-list>
    </div>

    <!-- Main Chat Window -->
    <div class="col-12 col-md-6">
      <app-hl-messages-list
        *ngIf="selectedConversation"
        [corpTags]="corpTags"
        [templates]="templates"
        [envs]="envs"
        [supportRoles]="hilSupportRoles"
        [selectedConversationId]="selectedConversationId"
        [selectedConversation]="selectedConversation"
      >
      </app-hl-messages-list>
    </div>

    <!-- Meta Data Side Menu -->
    <div class="col-12 col-md-3">
      <app-hl-meta-data-list
        *ngIf="selectedConversation"
        [selectedConversation]="selectedConversation"
        [corpId]="corpId"
        [envs]="envs"
        [bot]="bot"
        [hierarchyElement]="hierarchyElement"
      >
      </app-hl-meta-data-list>
      <app-hl-activity-list
        *ngIf="selectedConversation"
        [corpId]="corpId"
        [selectedConversationId]="selectedConversationId"
      >
      </app-hl-activity-list>
    </div>
  </div>
</div>
