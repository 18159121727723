import { Component, Input, OnInit } from '@angular/core';
import { SimpleListItem } from './_types/SimpleListItem';
import { SimpleListItemButton } from './_types/SimpleListItemButton';
import { Router } from '@angular/router';

@Component({
  selector: 'app-simple-list-item',
  templateUrl: './simple-list-item.component.html',
  styleUrls: ['./simple-list-item.component.scss']
})
export class SimpleListItemComponent implements OnInit {
  @Input()
  item: SimpleListItem;

  constructor(private router: Router) {}

  ngOnInit() {}

  executeButtonActions(button: SimpleListItemButton) {
    if (button.eventEmitter) {
      button.eventEmitter.emit(this.item);
    }
    if (button.action) {
      button.action();
    }
    if (button.link) {
      this.router.navigate(button.link);
    }
  }
}
