import { DomSanitizer } from '@angular/platform-browser';

export class ShowRecallCampaignsSettings {
  constructor(private domSanitizer: DomSanitizer) {}

  get(itensPerPage: number = 5): any {
    return {
      noDataMessage: 'No records found.',
      hideSubHeader: true,
      attr: {
        class: 'table',
      },
      pager: {
        display: true,
        perPage: itensPerPage,
      },
      actions: {
        add: false,
        edit: false,
        delete: false,
      },
      columns: {
        createdAt: {
          title: 'Created At',
          filter: false,
          valuePrepareFunction: value => {
            if (!value) {
              return '';
            }
            const date = new Date(value);
            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          },
        },
        userName: {
          title: 'Created By',
          filter: false,
          valuePrepareFunction: value => {
            return value;
          },
        },
        startDate: {
          title: 'Start Date',
          filter: false,
          valuePrepareFunction: value => {
            if (!value) {
              return '';
            }
            const date = new Date(value);
            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          },
        },
        maxBatch: {
          title: 'Daily Limit',
          filter: false,
          valuePrepareFunction: value => {
            if (value === 0) {
              return '';
            }
            return value;
          },
        },
        name: {
          title: 'List Name',
          filter: true,
          type: 'html',
          valuePrepareFunction: (value, row) => {
            if (!value) {
              return '';
            }
            if (row.click) {
              return this.domSanitizer.bypassSecurityTrustHtml(
                `<div style="color: blue;"><strong>${value}</strong></div>`,
              );
            } else {
              return this.domSanitizer.bypassSecurityTrustHtml(`<div><strong>${value}</strong></div>`);
            }
          },
        },
        fileName: {
          title: 'File Name',
          filter: true,
          type: 'html',
        },
        customersCount: {
          title: 'Number Of Users',
          filter: false,
          valuePrepareFunction: value => {
            if (value === 0) {
              return '';
            }
            return value;
          },
        },
      },
    };
  }
}
