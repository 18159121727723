import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SidebarService } from 'src/app/services/sidebar.service';
import { HeaderService } from 'src/app/services/header.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NlpModel } from 'src/app/models/nlp/nlp-model/nlp-model';
import { SimpleListItem } from 'src/app/components/lists/simple-list-item/_types/SimpleListItem';
import { FileCardItem } from 'src/app/components/nlp/nlp-dataset-cards/_types/FileCardItem';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { NlpModelTrain } from 'src/app/models/nlp/nlp-model/nlp-model-train';
import { NlpModelTrainFile } from 'src/app/models/nlp/nlp-model/nlp-model-train-file';
import { ButtonType } from 'src/app/components/common/_types/ButtonType';
import { getSidebarItems } from 'src/app/pages/portal/nlp/utils';
import { environment } from 'src/environments/environment';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { NlpService } from 'src/app/services/firestore';
dayjs.extend(relativeTime);

@Component({
  selector: 'app-nlp-model-edit',
  templateUrl: './nlp-model-edit.component.html',
  styleUrls: ['./nlp-model-edit.component.scss'],
})
export class NlpModelEditComponent implements OnInit, OnDestroy {
  loading: boolean;
  modelSystemName: string | null;
  nlpModel: NlpModel;
  nlpDojoS3Url = environment.nlpDojoS3Url;

  nlpModelEvaluateIntentReport: string;
  nlpModelEntities: string;
  nlpModelIntents: string;
  nlpModelIntentsEntities: string;

  parseLoading = false;
  parseResponse = '';
  parseText = '';

  nlpModelItem: SimpleListItem;
  nlpModelFileItems: FileCardItem[];

  saveNlpModelEvent: EventEmitter<any>;

  private saveActionSubscription: Subscription;
  private paramMapSubscription: Subscription;
  private nlpModelOnChangeSubscription: Subscription;
  private modelSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private sidebarService: SidebarService,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService,
    private nlpDojoService: NlpDojoService,
    private nlpService: NlpService,
  ) {
    this.saveNlpModelEvent = new EventEmitter<any>();
    this.nlpModelFileItems = [];
  }

  ngOnInit(): void {
    this.loading = true;
    this.paramMapSubscription = this.route.paramMap.subscribe(params => {
      this.modelSystemName = params.get('model');
      if (!this.modelSystemName) {
        return;
      }

      this.modelSubscription = this.nlpDojoService.getModel('default', this.modelSystemName).subscribe(result => {
        if (!result) {
          return;
        }
        this.nlpModel = result;
        if (this.nlpModel?.status === 'ready') {
          this.nlpModelEvaluateIntentReport = JSON.stringify(this.nlpModel.evaluation.intent_report, null, 2);
          this.nlpModelIntents = JSON.stringify(this.nlpModel.intents, null, 2);
          this.nlpModelEntities = JSON.stringify(this.nlpModel.entities, null, 2);
          this.nlpModelIntentsEntities = JSON.stringify(this.nlpModel.intents_entities, null, 2);
        }
        this.initialiseSubscriptions();
        this.refreshListItem();
        this.refreshUI();
        this.setSidebarItems();
        this.loading = false;
      });
    });
  }

  private initialiseSubscriptions() {
    this.saveActionSubscription = this.saveNlpModelEvent.subscribe(response => {
      if (!this.nlpModelFileItems?.length) {
        this.toaster.error('There must be at least 1 file to train');
        return;
      }
      this.nlpModel.status = 'training';
      this.nlpModel.training_progress = 0;
      this.updateNlpModel();
      this.refreshListItem();
    });

    let count = 0;
    this.nlpModelOnChangeSubscription = this.nlpService.nlpModelOnChange(this.nlpModel.id).subscribe(async results => {
      if (count === 0) {
        count++;
        return;
      }
      if (this.modelSystemName == null) {
        return;
      }
      this.nlpModel = await this.nlpDojoService.getModel('default', this.modelSystemName).pipe(take(1)).toPromise();
      this.refreshListItem();
    });
  }

  private updateNlpModel() {
    this.toaster.success('Model train started');

    const nlpTrainFiles: NlpModelTrainFile[] = this.nlpModelFileItems.map(nlpModelFileItem => {
      return {
        org_id: 'default',
        dataset_id: nlpModelFileItem.datasetId,
        file_id: nlpModelFileItem.id,
      };
    });

    this.nlpDojoService
      .trainModel('default', this.nlpModel.id, new NlpModelTrain(nlpTrainFiles))
      .toPromise()
      .then((updatedNlpModel: NlpModel) => {
        this.nlpModel = updatedNlpModel;
        this.refreshUI();
      });
  }

  private refreshListItem(updateProgress = false) {
    const intentReport = this.nlpModel?.evaluation.intent_report;
    const evaluation = intentReport ? intentReport['weighted avg'] : {};
    // Minus 3 to remove 'weighted avg', 'macro avg', 'micro avg' from intent report.
    const numIntents = intentReport ? Object.keys(intentReport).length - 3 : null;

    let trainingTimeElapsed: string | null = null;
    if (this.nlpModel.status === 'ready') {
      const trainingStarted = dayjs(this.nlpModel.training_started_at);
      const trainingEnded = dayjs(this.nlpModel.training_ended_at);
      trainingTimeElapsed = trainingStarted.to(trainingEnded, true);
    }

    this.nlpModelItem = {
      title: this.nlpModel.name,
      dataPoints: [
        {
          label: 'F1 Score',
          value: evaluation['f1-score']?.toFixed(2)?.toString() ?? '—',
          className: 'simple-list-item-data-point--sm',
        },
        {
          label: 'Precision',
          value: evaluation.precision?.toFixed(2)?.toString() ?? '—',
          className: 'simple-list-item-data-point--sm',
        },
        {
          label: 'Recall',
          value: evaluation.recall?.toFixed(2)?.toString() ?? '—',
          className: 'simple-list-item-data-point--sm',
        },
        {
          label: 'Support',
          value: evaluation.support?.toFixed(2)?.toString() ?? '—',
          className: 'simple-list-item-data-point--sm',
        },
        {
          label: 'Intents',
          value: numIntents?.toString() ?? '—',
          className: 'simple-list-item-data-point--sm',
        },
        {
          label: 'Training Time Elapsed',
          value: trainingTimeElapsed ?? '—',
          className: 'simple-list-item-data-point--sm',
        },
      ],
    };

    if (this.nlpModel.status === 'training' && this.nlpModel.training_progress != null) {
      this.nlpModelItem.progress = {
        value: this.nlpModel.training_progress * 100 || 0,
      };
    } else {
      this.nlpModelItem.buttons = [
        {
          label: 'Train',
          type: ButtonType.primary,
          disabled: this.nlpModel.status === 'training',
          eventEmitter: this.saveNlpModelEvent,
        },
      ];
    }
  }

  private refreshUI() {
    this.setBreadcrumb();
    this.headerService.setPageTitle(`Training - Edit Model`);
  }

  private setBreadcrumb() {
    this.breadcrumbService.set([
      {
        label: 'Training Models',
        route: `/portal/nlp/models`,
      },
      {
        label: `${this.nlpModel.name}`,
        route: `/portal/nlp/models/${this.modelSystemName}`,
      },
    ]);
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }

  async parse() {
    this.parseLoading = true;
    const response = await this.nlpDojoService.parseModel('default', this.nlpModel.id, this.parseText);
    this.parseResponse = JSON.stringify(response, null, 2);
    this.parseLoading = false;
  }

  ngOnDestroy(): void {
    if (this.saveActionSubscription) {
      this.saveActionSubscription.unsubscribe();
    }
    if (this.nlpModelOnChangeSubscription) {
      this.nlpModelOnChangeSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.modelSubscription) {
      this.modelSubscription.unsubscribe();
    }
  }
}
