<nav
  class="navbar navbar-top navbar-expand border-bottom" id="navbar-main">
  <div class="container-fluid">
    <app-breadcrumb></app-breadcrumb>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Navbar links -->
      <ul class="navbar-nav align-items-center ml-md-auto">
        <li class="nav-item d-xl-none">
          <!-- Sidenav toggler -->
          <div
            *ngIf="sidebarItemsCount"
            class="pr-3 sidenav-toggler sidenav-toggler-dark"
            data-action="sidenav-pin"
            data-target="#sidenav-main"
            (click)="openSidebar()"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
      </ul>

      <!-- Client Environments -->
      <ul
        *ngIf="isOnDashboard && isOnHierarchyEl && clientEnvironment && isAdmin"
        class="navbar-nav align-items-center ml-auto ml-md-0 c-pointer mr-3"
      >
        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <a data-test-id="select-environment" class="nav-link pr-0 dropdown-toggle" role="button" dropdownToggle>
            <div class="media align-items-center">
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm mr-1">{{ clientEnvironment.selectedStage.name }}</span>
                <i class="fas fa-caret-down"></i>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right" *dropdownMenu>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Your Environments</h6>
            </div>
            <a
              [attr.data-test-id]="'select-environment-' + stage.name"
              *ngFor="let stage of clientEnvironment?.stages"
              (click)="setStage(stage)"
              class="dropdown-item"
            >
              <span>{{ stage.name }}</span>
            </a>
            <div class="dropdown-divider"></div>
            <a href="javascript:void(0)" class="dropdown-item" (click)="openEnvironmentManagementModal()">
              Add/Remove Environments
            </a>
          </div>
        </li>
      </ul>

      <!-- User -->
      <ul class="navbar-nav align-items-center ml-auto ml-md-0 c-pointer">
        <li (click)="handleNotificationClick()" class="nav-item dropdown" dropdown placement="bottom-right">
          <a
            class="nav-link dropdown-toggle notifications-anchor"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            dropdownToggle
          >
            <i class="ni ni-bell-55"></i>
            <span *ngIf="unseenNotificationCount" class="badge badge-pill badge-danger">{{
              unseenNotificationCount
            }}</span>
          </a>

          <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden" *dropdownMenu>
            <div class="px-3 py-3">
              <h6 class="text-sm text-muted m-0">
                You have <strong class="text-primary">{{ unreadNotificationCount }}</strong> unread notifications.
              </h6>
            </div>
            <div class="list-group list-group-flush notifications-list">
              <div
                (click)="clickedNotification(notification)"
                *ngFor="let notification of notifications"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col-auto"></div>
                  <div class="col ml--2">
                    <p class="text-sm mb-0">{{ notification.message }}</p>
                    <div class="text-right text-muted">
                      <small>{{ notification.timeAgo }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="dropdown-item text-center text-primary font-weight-bold py-3" routerLink="/portal/notifications">
              View all
            </a>
          </div>
        </li>

        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <a class="nav-link pr-0 dropdown-toggle" role="button" dropdownToggle>
            <div class="media align-items-center">
              <span class="
               avatar-sm overflow-hidden rounded-circle">
                <img *ngIf="user && user?.avatar" alt="User Icon" [src]="user.avatar" />
                <img class="avatar-img"
                  *ngIf="!user || !user?.avatar"
                  alt="User Icon"
                  src="assets/img/brand/undraw_profile_pic_ic5t.png"
                />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm" *ngIf="user">{{ user.fullName }}</span>
              </div>
            </div>
          </a>

          <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right" *dropdownMenu>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Your Account</h6>
            </div>

            <a
              *ngFor="let navbarItem of userNavbarItems"
              routerLinkActive="active"
              [routerLink]="[navbarItem.route]"
              class="dropdown-item"
            >
              <i class="ni" [ngClass]="navbarItem.icon ? 'ni-' + navbarItem.icon : ''"></i>
              <span>{{ navbarItem.label }}</span>
            </a>

            <div class="dropdown-divider"></div>
            <a href="javascript:void()" class="dropdown-item" (click)="logout()">
              <i class="ni ni-user-run"></i> <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
