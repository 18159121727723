import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ConversationMessageModel } from '../models/conversations';
@Pipe({
  name: 'uniqueMessages',
})
export class UniqueMessagesPipe implements PipeTransform {
  transform(messages: ConversationMessageModel[]): ConversationMessageModel[] {
    if (messages !== undefined && messages !== null) {
      messages = _.uniqBy(messages, 'firebaseDocumentId');

      // TODO This is a hack! Remove once duplicated messages solved
      const unique_messages = _.uniqBy(messages, 'message');
      return unique_messages;
    }
    return messages;
  }
}
