import { RoleModel } from 'src/app/models';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { makeSystemNameUserFriendly } from 'src/app/utils/strings/string.utils';

interface IMultiSelectOptions {
  id: string;
  text: string;
}

export abstract class ManageRoleModel {
  role: RoleModel;
  permissions: IMultiSelectOptions[];
  selectedPermissions: IMultiSelectOptions[] = [];

  constructor() {
    this.permissions = Object.values(Permissions).map(this.mapPermissionStringToSelectOption);
    this.selectedPermissions = [];
  }

  permissionsSearchFunction(term: string, item: string) {
    return item.toLowerCase().indexOf(term.toLowerCase()) > -1;
  }

  mapPermissionStringToSelectOption(permission: string): IMultiSelectOptions {
    return {
      id: permission,
      text: makeSystemNameUserFriendly(permission)
    };
  }
}
