<ng-container *ngFor="let templatesGroup of presentationTemplatesGroups">
    <div class="row row-example">
        <ng-container *ngFor="let template of templatesGroup" [ngSwitch]="template == null" >
            <ng-container *ngSwitchCase="false">
            <div class="col-sm col-md col-lg">
                <div class=" card">
                    <img
                    *ngIf="template.image != null"
                    alt="Image placeholder"
                    class="card-img-top"
                    src="{{template.image}}" />
                    <img
                    *ngIf="template.image == null"
                    alt="Image placeholder"
                    class="card-img-top"
                    src="https://firebasestorage.googleapis.com/v0/b/brain-ui-v1-dev.appspot.com/o/bots%2F_default%2Favatar.png?alt=media&token=4e2cf240-05ce-4b38-98ea-bc033e168854"
                    />
                    <div class="card-body">
                        <h5 class=" h2 card-title mb-0">{{template.label}}</h5>
                        <small class="text-muted">Last updated on {{formateDate(template.updatedAt)}} </small>
                        <p class="card-text mt-4"></p>
                        <a class="btn btn-primary w-80" 
                        [attr.data-test-id]="'Clone-'+template.label"
                        [routerLink]="['../', 'use-template', template.systemName]" >Clone</a>
                    </div>
                </div>
            </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <div class="col-sm col-md col-lg">
                    <div class=" card">
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>