<div class="simple-list container-fluid w-100">
  <div class="row pt-4">
    <div class="col-lg-6 col-7 d-flex align-items-center">
      <h2 class="d-inline-block mb-0">{{title}}</h2>
      <button class="ml-3 btn-sm btn-outline-primary rounded-circle" *ngIf="canCreate" (click)="onClickCreate()">
        <i class="fas fa-plus"></i>
      </button>
    </div>
  </div>
  <div class="row pt-4">
    <div class="col">
      <div *ngFor="let item of items">
        <app-simple-list-item [item]="item"></app-simple-list-item>
      </div>
    </div>
  </div>
</div>
