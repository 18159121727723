<div
  *ngIf="activeTab != null"
  class="simple-editor simple-editor--light"
  [ngClass]="theme.containerClass"
>
  <div class="simple-editor-toolbar">
    <div class="simple-editor-toolbar-left">
      <div class="simple-editor-tabs">
        <button
          [attr.data-test-id]="'editor-tab-'+tab.id"
          *ngFor="let tab of tabs"
          class="simple-editor-tab"
          [ngClass]="activeTab.id == tab.id ? 'simple-editor-tab--active': ''"
          (click)="onChangeTab(tab)"
        >
          {{tab.label}}
        </button>
      </div>
    </div>
    <div class="simple-editor-toolbar-right">
      <div class="dropdown">
        <button class="btn btn-primary btn-sm dropdown-toggle"
          type="button"
          id="dropdownMenu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          (click)="processDropDownThemeClick()"
        >
          {{theme.label}}
        </button>
        <div
          class="dropdown-menu"
          aria-labelledby="dropdownMenu"
          [ngClass]="dropdownThemeShow ? 'show' : 'hide'"
        >
          <button *ngFor="let theme of themes"
            class="dropdown-item"
            type="button"
            (click)="processDropDownThemeItemClick(theme)"
          >{{theme.label}}</button>
        </div>
      </div>
      <button
        data-test-id="editor-save-button"
        type="button"
        class="btn btn-primary btn-sm"
        *ngIf="canSave"
        (click)="processSaveClick()"
      >Save</button>
      <button
        type="button"
        class="btn btn-primary btn-sm"
        *ngIf="canRename"
        (click)="processRenameFile()"
      >Rename File</button>
    </div>
  </div>
  <div class="monaco-editor-container editor-wrapper">
  <!-- Remove template UI for now -->
    <!--
    <app-visual-template-editor
      [data]="activeTab.data"
      (dataChanged)="processVisualData($event)"
      *ngIf="showVisualTemplateEditor"
    >
    </app-visual-template-editor>
    -->
    <ngx-monaco-editor
      data-test-id="editor-pane"
      class="monaco-editor"
      [options]="editorOptions"
      [(ngModel)]="activeTab.data"
      (onInit)="onEditorInit($event)"
    >
    </ngx-monaco-editor>
  </div>
  <div class="simple-editor-logs" *ngIf="activeTab?.logs.length">
    <div class="simple-editor-logs-scroll">
      <h4
        class="simple-editor-log"
        *ngFor="let log of activeTab.logs"
        [ngClass]="log.className"
      >{{log.text}}</h4>
    </div>
  </div>
</div>
