import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ShowRecallCampaignsSettings } from './settings/recall-campaigns-settings';
import { ShowRecallCampaignsUsers } from './settings/recall-campaigns-users';
import { DomSanitizer } from '@angular/platform-browser';
import { BotConfigService } from '../../../../services/bot-config.service';
import { UploadCampaignService } from '../../../../services/upload.campaign.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';

const ITEMS_PER_PAGE = 5;

@Component({
  selector: 'app-bot-upload-campaigns',
  templateUrl: './bot-upload-campaigns.component.html',
  styleUrls: ['./bot-upload-campaigns.component.scss'],
})
export class BotUploadCampaignsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public isLoading = true;

  public currentStep: string;
  private botConfig: any;
  public recallCampaigns: MatTableDataSource<any>;
  @Input() userEmail: string;

  @Input() userName: string;

  @Input() botId: string;

  displayedColumns = ['createdAt', 'userName', 'startDate', 'maxBatch', 'fileName', 'customersCount', 'numberOfUsers'];

  constructor(
    private domSanitizer: DomSanitizer,
    private botConfigService: BotConfigService,
    private uploadCampaign: UploadCampaignService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.currentStep = 'SHOW_RECALL_CAMPAIGNS';
  }

  public showRecallCampaignsSettings: any;
  public showRecallCampaignUsers: any;
  public dealershipId = '';
  async ngOnInit() {
    this.showRecallCampaignsSettings = new ShowRecallCampaignsSettings(this.domSanitizer).get(ITEMS_PER_PAGE);
    this.showRecallCampaignUsers = new ShowRecallCampaignsUsers(this.domSanitizer).get();
    this.botConfig = await this.botConfigService.getBotConfigById(this.botId);
    this.dealershipId = this.botConfig.code;

    const campaignsFromDataSource = await this.uploadCampaign.getConversations(this.dealershipId);
    for (let x = 0; x < campaignsFromDataSource.length % ITEMS_PER_PAGE; x += 1) {
      campaignsFromDataSource.push({
        customersCount: 0,
        dealershipCode: '',
        fileId: '',
        fileName: '',
        id: '',
        maxBatch: 0,
        startDate: '',
        userName: '',
      });
    }
    this.recallCampaigns = new MatTableDataSource<any>(campaignsFromDataSource);
    setTimeout(() => {
      this.recallCampaigns.paginator = this.paginator;
      this.recallCampaigns.sort = this.sort;
    }, 100);
    this.isLoading = false;
  }
  selectRowRecallCampaign(e): any {}

  addNewCampaign(e): any {
    this.router.navigate(['create-new-campaign'], {
      relativeTo: this.route,
      state: {
        dealershipId: this.dealershipId,
        userName: this.userName,
      },
    });
  }

  goBackCampaign(e): any {
    this.currentStep = 'SHOW_RECALL_CAMPAIGNS';
  }
}
