<div class=" card">
  <div class=" card-header border-0">
    <div class=" row">
      <div class=" col-6">
        <h3 class=" mb-0">Kpi Performance</h3>
      </div>
      <div class=" col-6 text-right">
        <div>
          <select [ngModel]="selectedTimeFrame" (ngModelChange)="processTimeFrameChange($event)">
            <option *ngFor="let timeFrame of timeFrames" [ngValue]="timeFrame">{{ timeFrame.description }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class=" table-responsive">
    <table class=" table align-items-center table-flush">
      <thead class=" thead-light">
        <tr class="d-flex">
          <th class="col-3">Kpi</th>
          <th class="col-3" data-sort="frequency" (click)="sortByField('frequency')">Frequency</th>
          <th class="col-3" data-sort="percentageOfKpiHits" (click)="sortByField('percentageOfKpiHits')">
            % of KPI hits
          </th>
          <th class="col-3" data-sort="percentageOfTotalSessions" (click)="sortByField('percentageOfTotalSessions')">
            % of total sessions
          </th>
        </tr>
      </thead>

      <tbody>
        <div class=" container-fluid" *ngIf="loading">
          <div class=" row align-items-center py-4 justify-content-center">
            <app-loading></app-loading>
          </div>
        </div>
        <ng-container *ngIf="!loading">
          <ng-container *ngIf="kpiPerformance.length > 0; else noKpiData">
            <tr *ngFor="let kpiPerformanceRow of kpiPerformance" class="d-flex">
              <td class="text-capitalize col-3">
                <b> {{ kpiPerformanceRow.name }} </b>
              </td>
              <td class="col-3">
                <span class="text-muted">
                  {{ kpiPerformanceRow.frequency }}
                </span>
              </td>
              <td class="col-3">
                <span class="text-muted"> {{ kpiPerformanceRow.percentageOfKpiHits | number: '.2-2' }}% </span>
              </td>
              <td class="col-3">
                <span class="text-muted"> {{ kpiPerformanceRow.percentageOfTotalSessions | number: '1.0-2' }}% </span>
              </td>
              <!-- <td class="col-2 py-1"> TODO Work on this another day
                <app-sparkline-chart [sparklineData]="kpiPerformanceRow.frequencyTrend"></app-sparkline-chart>
              </td> -->
            </tr>
          </ng-container>
          <ng-template #noKpiData>
            <td class="col-12 text-center">
              NO DATA
            </td>
          </ng-template>
        </ng-container>
      </tbody>

      <tfoot *ngIf="kpiPerformance.length > 0 && !loading">
        <tr class="d-flex">
          <td class="col-3">
            <b>TOTAL</b>
          </td>
          <td class="col-3">
            {{ totalKpisCount }}
          </td>
          <td class="col-3">
            100%
          </td>
          <td class="col-3">{{ totalPercentageOfTotalSessions | number: '1.0-2' }}%</td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
