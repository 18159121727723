import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NlpModelCreateModalComponent } from 'src/app/components/modals/nlp-model-create-modal/nlp-model-create-modal.component';
import { SidebarService } from 'src/app/services/sidebar.service';
import { HeaderService } from 'src/app/services/header.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { getSidebarItems } from 'src/app/pages/portal/nlp/utils';

@Component({
  selector: 'app-nlp-models',
  templateUrl: './nlp-models.component.html',
  styleUrls: ['./nlp-models.component.scss']
})
export class NlpModelsComponent implements OnInit {
  corpId: string;

  constructor(
    private modalService: BsModalService,
    private sidebarService: SidebarService,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService,
  ) {}

  async ngOnInit() {
    // we are using only the `default` corporation for training models.
    this.corpId = 'default';

    this.refreshUI();
    this.setSidebarItems();
  }

  showAddNlpModel() {
    if (!this.corpId) {
      return;
    }
    this.modalService.show(NlpModelCreateModalComponent, {
      ignoreBackdropClick: true,
      initialState: { corpId: this.corpId },
      class: 'modal-lg'
    });
  }

  refreshUI() {
    if (this.corpId) {
      this.setBreadcrumb();
      this.headerService.setPageTitle(`Training Models`);
    }
  }

  setBreadcrumb() {
    this.breadcrumbService.set([
      {
        label: 'Training Models',
        route: `/portal/nlp/models`
      }
    ]);
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }
}
