<ng-container *ngIf="loading">
    <div class="container-fluid">
      <div class=" row align-items-center py-4 justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!loading" class="api-queries-container">
    <app-simple-list [items]="items" [title]="'API Queries'" (onCreate)="showAddModal()"
      [canCreate]="canWriteBotApiQueries()">
    </app-simple-list>
  </div>