<div class="row align-items-center py-4">
  <div class="col-12">
    <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4-">
      <ol class="breadcrumb breadcrumb-links breadcrumb-dark-">
        <li class="breadcrumb-item" (click)="routeToRoot()">
          <a href="javascript:void(0)">
            <i class="fas" [ngClass]="'fa-' + root.icon"></i>
          </a>
        </li>
        <li
          [attr.data-test-id]="node.testId"
          class="breadcrumb-item"
          *ngFor="let node of nodes; let level = index"
          (click)="routeToLevel(level + 1)"
        >
          <a href="javascript:void(0)">
            <!-- <img class="breadcrumb-item-icon rounded-circle" *ngIf="node.icon" [src]="node.icon" /> -->
            {{ node.label }}
          </a>
        </li>
      </ol>
    </nav>
  </div>
</div>
