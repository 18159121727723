<ng-container *ngIf="conversationSummary">
  <div
    class=" card message-card m-1 mb-lg-2"
    (click)="conversationSelected.emit()"
    [ngClass]="{ 'bg-light': conversationSummary.conversationId === selectedConversationId }"
  >
    <div class="card-body py-2">
      <div class=" row">
        <div class=" col-12">
          <div class="row">
            <div class="col-6 pr-0">
              <h5 class="card-title text-uppercase text-muted mb-0">User Id</h5>
            </div>
            <div class="col-6 pr-0">
              <h5 class="card-title mb-0">{{ conversationSummary.userId }}</h5>
            </div>
          </div>

          <div class="row">
            <div class="col-6 pr-0">
              <h5 class="card-title text-uppercase text-muted mb-0">Conversation Date</h5>
            </div>
            <div class="col-6 pr-0">
              <h5 class="card-title mb-0">{{ conversationSummary.conversationStartDate | date: 'dd MMM yyyy H:mm a' }}</h5>
            </div>
          </div>

          <div class="row">
            <div class="col-6 pr-0">
              <h5 class="card-title text-uppercase text-muted mb-0">Begin Intent</h5>
            </div>
            <div class="col-6 pr-0">
              <h5 class="card-title mb-0">{{ conversationSummary.beginIntent }}</h5>
            </div>
          </div>

          <div class="row">
            <div class="col-6 pr-0">
              <h5 class="card-title text-uppercase text-muted mb-0">Last Intent</h5>
            </div>
            <div class="col-6 pr-0">
              <h5 class="card-title mb-0">{{ conversationSummary.lastIntent }}</h5>
            </div>
          </div>

          <div class="row">
            <div class="col-6 pr-0">
              <h5 class="card-title text-uppercase text-muted mb-0">No Of TURNS</h5>
            </div>
            <div class="col-6 pr-0">
              <h5 class="card-title mb-0">{{ conversationSummary.noOfTurns }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>
