import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, tap, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NlpModel } from 'src/app/models/nlp/nlp-model/nlp-model';
import { HttpErrorHandler } from 'src/app/services/error-handler/http-error-handler.service';
import { environment } from 'src/environments/environment';
import { NlpModelTrain } from 'src/app/models/nlp/nlp-model/nlp-model-train';
import { NlpDataset } from 'src/app/models/nlp/nlp-dataset/nlp-dataset';
import { NlpDatasetFile } from 'src/app/models/nlp/nlp-dataset/nlp-dataset-file';

@Injectable({
  providedIn: 'root'
})
export class NlpDojoService {
  // Uses proxy.conf.json
  private nlpDojoUrl = `${environment.nlpDojoUrl}/orgs`;
  public nlpModelUpdates: Subject<void>;
  public nlpDatasetUpdates: Subject<void>;
  public nlpDatasetFileUpdates: Subject<void>;

  constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.nlpModelUpdates = new Subject<void>();
    this.nlpDatasetUpdates = new Subject<void>();
    this.nlpDatasetFileUpdates = new Subject<void>();
  }

  //
  // Train
  //

  trainModel(orgId: string, modelId: string, nlpModelTrain: NlpModelTrain): Observable<NlpModel> {
    const trainModelUrl = `${this.nlpDojoUrl}/${orgId}/models/${modelId}/train`;

    return this.http
      .post<NlpModel>(trainModelUrl, nlpModelTrain)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)));
  }

  //
  // Parse
  //

  async parseModel(orgId: string, modelId: string, text: string): Promise<object> {
    const parseModelUrl = `${this.nlpDojoUrl}/${orgId}/models/${modelId}/parse`;
    const data = {
      text
    };
    return await this.http.post<object>(parseModelUrl, data)
      .pipe(take(1)).toPromise();
  }

  //
  // Models
  //

  getModels(orgId: string): Observable<NlpModel[]> {
    const getModelsUrl = `${this.nlpDojoUrl}/${orgId}/models`;
    return this.http
      .get<NlpModel[]>(getModelsUrl)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)));
  }

  createModel(orgId: string, name: string): Observable<NlpModel> {
    const nlpModel = new NlpModel(name);
    const createModelUrl = `${this.nlpDojoUrl}/${orgId}/models`;

    return this.http
      .post<NlpModel>(createModelUrl, nlpModel)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)))
      .pipe(tap(() => this.nlpModelUpdates.next()));
  }

  getModel(orgId: string, modelSystemName: string): Observable<NlpModel> {
    const getModelUrl = `${this.nlpDojoUrl}/${orgId}/models/${modelSystemName}`;

    return this.http
      .get<NlpModel>(getModelUrl)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)));
  }

  updateModel(orgId: string, modelId: string, name: string): Observable<NlpModel> {
    const nlpModel = new NlpModel(name);
    const updateModelUrl = `${this.nlpDojoUrl}/${orgId}/models/${modelId}`;

    return this.http
      .put<NlpModel>(updateModelUrl, nlpModel)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)))
      .pipe(tap(() => this.nlpModelUpdates.next()));
  }

  deleteModel(orgId: string, modelId: string): Observable<NlpModel> {
    const deleteModelUrl = `${this.nlpDojoUrl}/${orgId}/models/${modelId}`;

    return this.http
      .delete<NlpModel>(deleteModelUrl)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)))
      .pipe(tap(() => this.nlpModelUpdates.next()));
  }

  //
  // Datasets
  //

  getDatasets(orgId: string): Observable<NlpDataset[]> {
    const getDatasetUrl = `${this.nlpDojoUrl}/${orgId}/datasets`;

    return this.http
      .get<NlpDataset[]>(getDatasetUrl)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)));
  }

  createDataset(orgId: string, name: string): Observable<NlpDataset> {
    const nlpDataset = new NlpDataset(name);
    const createDatasetUrl = `${this.nlpDojoUrl}/${orgId}/datasets`;

    return this.http
      .post<NlpDataset>(createDatasetUrl, nlpDataset)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)))
      .pipe(tap(() => this.nlpDatasetUpdates.next()));
  }

  getDataset(orgId: string, datasetSystemName: string): Observable<NlpDataset> {
    const getDatasetUrl = `${this.nlpDojoUrl}/${orgId}/datasets/${datasetSystemName}`;

    return this.http
      .get<NlpDataset>(getDatasetUrl)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)));
  }

  updateDataset(orgId: string, datasetId: string, name: string): Observable<NlpDataset> {
    const nlpDataset = new NlpDataset(name);
    const updateDatasetUrl = `${this.nlpDojoUrl}/${orgId}/datasets/${datasetId}`;

    return this.http
      .put<NlpDataset>(updateDatasetUrl, nlpDataset)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)))
      .pipe(tap(() => this.nlpDatasetUpdates.next()));
  }

  deleteDataset(orgId: string, datasetId: string): Observable<NlpDataset> {
    const deleteDatasetUrl = `${this.nlpDojoUrl}/${orgId}/datasets/${datasetId}`;

    return this.http
      .delete<NlpDataset>(deleteDatasetUrl)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)))
      .pipe(tap(() => this.nlpDatasetUpdates.next()));
  }

  //
  // Dataset Files
  //

  createDatasetFile(
    orgId: string,
    datasetSystemName: string,
    nlpDatasetFile: NlpDatasetFile
  ): Observable<NlpDatasetFile> {
    const createDatasetFileUrl = `${this.nlpDojoUrl}/${orgId}/datasets/${datasetSystemName}/files`;

    return this.http
      .post<NlpDatasetFile>(createDatasetFileUrl, nlpDatasetFile)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)))
      .pipe(tap(() => this.nlpDatasetFileUpdates.next()));
  }

  getDatasetFile(orgId: string, datasetSystemName: string, fileSystemName: string): Observable<NlpDatasetFile> {
    const getDatasetFileUrl = `${this.nlpDojoUrl}/${orgId}/datasets/${datasetSystemName}/files/${fileSystemName}`;

    return this.http
      .get<NlpDatasetFile>(getDatasetFileUrl)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)));
  }

  updateDatasetFile(
    orgId: string,
    datasetSystemName: string,
    fileSystemName: string,
    nlpDatasetFile: NlpDatasetFile
  ): Observable<NlpDatasetFile> {
    const updateDatasetFileUrl = `${this.nlpDojoUrl}/${orgId}/datasets/${datasetSystemName}/files/${fileSystemName}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    return this.http
      .put<NlpDatasetFile>(updateDatasetFileUrl, nlpDatasetFile, httpOptions)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)));
  }

  deleteDatasetFile(orgId: string, datasetId: string, fileId: string): Observable<NlpDatasetFile> {
    const deleteDatasetFileUrl = `${this.nlpDojoUrl}/${orgId}/datasets/${datasetId}/files/${fileId}`;

    return this.http
      .delete<NlpDatasetFile>(deleteDatasetFileUrl)
      .pipe(catchError(error => this.httpErrorHandler.handleNlpDojoError(error)))
      .pipe(tap(() => this.nlpDatasetFileUpdates.next()));
  }
}
