import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/services/auth.service';
import { BotModel } from 'src/app/models/bot';

@Component({
  selector: 'app-add-bot-modal',
  templateUrl: './add-bot-modal.component.html',
  styleUrls: ['./add-bot-modal.component.scss']
})
export class AddBotModalComponent implements OnInit {
  bot: BotModel;
  loading: boolean;
  botAdded: EventEmitter<BotModel>;
  canSaveChanges = true; // Modal can only be opened in dev, so this defaults to true
  botNameList: string[];

  constructor(public modal: BsModalRef, options: ModalOptions, private authService: AuthService) {
    this.botAdded = new EventEmitter<BotModel>();
    if (!options.initialState) {
      return;
    }
    const { corpId, hierarchyElementSystemName, hierarchyElementId, botNameList } = options.initialState as any;
    this.bot = BotModel.generateDefaultBot();
    this.bot.corpId = corpId;
    this.bot.hierarchyElementId = hierarchyElementId;
    this.bot.hierarchyElementSystemName = hierarchyElementSystemName;
    this.loading = false;
    this.botNameList = botNameList;
  }

  async ngOnInit() {
    const currentUser = await this.authService.currentUser;
    if (!currentUser) {
      return;
    }
    this.bot.createdBy = currentUser.uid;
  }
}
