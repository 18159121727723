<ng-container #chatContainer>
  <div class="card" *ngIf="loadingMessages">
    <div class="card-body text-center">Loading conversation ...</div>
  </div>
  <div *ngIf="!loadingMessages">
    <div class="card" style="margin-bottom: 20px">
      <div *ngIf="selectedConversationId" class="card-body conversation-header">
        <div class="row">
          <div class="col-9 col-md-10">
            <div class="media align-items-center">
              <div class="media-body">
                <h6 class="mb-0 d-block user-name">
                  {{
                    conversation.userData && conversation.userData.fullName ? conversation.userData.fullName : 'Guest'
                  }}
                </h6>
                <small *ngIf="conversation.lastUpdatedString" class="text-muted last-seen">
                  Last seen {{ conversation.lastUpdatedString }}
                </small>
              </div>
            </div>
          </div>
          <div class="col-3 col-md-2">
            <div class="dropdown float-right" dropdown>
              <button
                class="btn btn-link btn-sm text-primary float-right fixed-margin-right"
                data-toggle="dropdown"
                dropdownToggle
                type="button"
              >
                <i class="fas fa-ellipsis-v fa-lg"> </i>
              </button>
              <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                <a (click)="showConversationTags()" class="dropdown-item" href="javascript:;">
                  <i class="fas fa-tags fa-lg tools-icon"> </i> Tag Conversation
                </a>

                <a (click)="assignConversationToUser()" class="dropdown-item" href="javascript:;">
                  <i class="fas fa-user-plus fa-lg tools-icon"> </i> Assign To User
                </a>

                <a (click)="doSelectNode()" class="dropdown-item" href="javascript:;">
                  <i class="fas fa-random fa-lg tools-icon"> </i> Select Node
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body" *ngIf="!selectedConversationId">Select conversation to see messages</div>

        <div
          *ngIf="selectedConversationId && !loadingMessages"
          class="card-body p-0 scrollable-card conversation-messages-list"
        >
          <div *ngIf="loadingMoreTop">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              style="margin: auto; background: transparent; display: block"
              width="25px"
              height="25px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#07abcc"
                stroke-width="10"
                r="35"
                stroke-dasharray="164.93361431346415 56.97787143782138"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="1s"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                ></animateTransform>
              </circle>
            </svg>
          </div>

          <div *ngIf="!loadingMessages && currentMessages.length === 0" class="mt-2">
            <small>No messages</small>
          </div>
          <div
            *ngFor="let message of currentMessages"
            class="row"
            [ngClass]="
              message.userType && message.userType.toString() === 'customer'
                ? ' justify-content-start'
                : 'justify-content-end text-right'
            "
          >
            <div
              class="col-auto"
              *ngIf="!message.messageType || (message.messageType && message.messageType !== 'handoverStatus')"
            >
              <div class="card" [ngClass]="{ 'bg-default text-white': message.userType.toString() === 'customer' }">
                <div class="card-body p-2">
                  <p class="mb-1" [innerHTML]="message.message"></p>
                  <div>
                    <small class="opacity-60">
                      <i class="far fa-chart-bar c-pointer" (click)="showKpisModal(message)"></i>
                      |
                      <i *ngIf="message.messageType === 'email'" tooltip="Email" class="far fa-envelope"></i>
                      <i *ngIf="message.messageType === 'webchat'" tooltip="Web" class="far fa-comment-dots"></i>
                      <i *ngIf="message.messageType === 'facebook'" tooltip="Facebook" class="fab fa-facebook-f"></i>
                      |
                      <i class="far fa-clock"> </i>
                      {{ message.createdAt.toDate() | date: 'HH:mm' }}
                      <span *ngIf="message.userType && message.userType.toString() !== 'customer'">
                        | {{ message.userType.toString() === 'bot' ? 'assistant' : message.userType.toString() }}</span
                      >
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="message.messageType && message.messageType == 'handoverStatus'">
              <h3 class="handover-message"><span>Handover started</span></h3>
            </div>
          </div>

          <div *ngIf="loadingMoreBottom">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              style="margin: auto; background: transparent; display: block"
              width="25px"
              height="25px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#07abcc"
                stroke-width="10"
                r="35"
                stroke-dasharray="164.93361431346415 56.97787143782138"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="1s"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                ></animateTransform>
              </circle>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="quick-menu mt-0 mb-0">
      <div class="mr-1 dropdown badge badge-default font-white clearfix float-right channel-name c-pointer" dropdown>
        <span *ngIf="channelName" class="dropdown-toggle" id="dropdownMenuButton" dropdownToggle>
          {{ channelName }}
        </span>
        <div aria-labelledby="dropdownMenuButton" *dropdownMenu class="dropdown-menu">
          <a class="dropdown-item" style="color: #172b4d" href="javascript:void(0)">Email</a>
          <a class="dropdown-item disabled" style="color: #172b4d" href="javascript:void(0)">Web</a>
          <a class="dropdown-item disabled" style="color: #172b4d" href="javascript:void(0)">Facebook</a>
        </div>
      </div>
      <ul class="quick-menu-list" style="display: inline-block; margin-left: auto; margin-right: auto">
        <li class="mr-1 ml-1">
          <i tooltip="Templates" (click)="showTemplates()" class="fas fa-copy fa-lg tools-icon"></i>
        </li>
        <li class="mr-1 ml-1">
          <i tooltip="Tags" (click)="showConversationTags()" class="fa fa-tags fa-lg tools-icon"></i>
        </li>

        <!-- <li class="mr-1 ml-1" *ngIf="!conversation.handover">
          <i tooltip="Halt Bot" (click)="stopBotMessages($event)" class="fas red fa-exclamation-triangle fa-lg"></i>
        </li>

        <li class="mr-1 ml-1" *ngIf="conversation.handover">
          <i
            tooltip="End Handover"
            (click)="endBotHandover($event)"
            style="color: red"
            class="fas red fa-exclamation-triangle fa-lg"
          ></i>
        </li> -->

        <li class="mr-1 ml-1">
          <i tooltip="Assign" (click)="assignConversationToUser()" class="fas fa-user-plus fa-lg tools-icon"></i>
        </li>
        <li class="mr-1 ml-1">
          <i tooltip="Select Node" (click)="doSelectNode()" class="fas fa-random fa-lg tools-icon"></i>
        </li>
      </ul>
    </div>
    <div class="card">
      <div *ngIf="selectedConversationId" class="card-body conversation-footer d-block">
        <div class="form-group row mb-1">
          <div
            class="input-group col-lg-12"
            *ngIf="
              lastMessage && lastMessage.messageType && lastMessage.messageType === 'email';
              else normalMessageInput
            "
          >
            <angular-editor [(ngModel)]="inputMessage" id="message-input" [config]="editorConfig"> </angular-editor>
          </div>

          <ng-template #normalMessageInput>
            <div class="input-group col-lg-12">
              <textarea
                placeholder="Enter your response here..."
                class="message-box form-control"
                [(ngModel)]="inputMessage"
              ></textarea>
            </div>
          </ng-template>
        </div>
        <button class="btn btn-primary float-right" (click)="sendMessage($event)">
          <i class="fas fa-paper-plane fa-lg"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>
