import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { getSidebarItems } from '../utils';
import { CorpsService, FlowTemplatesService } from 'src/app/services/firestore';
import { FlowTemplateModel } from 'src/app/models/flow-template';

@Component({
  selector: 'app-corp-flow-templates',
  templateUrl: './corp-flow-templates.component.html',
  styleUrls: ['./corp-flow-templates.component.scss'],
})
export class CorpFlowTemplatesComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  corp: CorpModel;
  flowTemplates: FlowTemplateModel[] = [];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private flowTemplatesService: FlowTemplatesService,
    private toaster: ToastrService,
    private corpsService: CorpsService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap]).subscribe(([params]) => {
      const corpId = params.get('corp');
      if (!corpId) {
        return;
      }
      this.crtDataSubscription = combineLatest([
        this.corpsService.getCorpById(corpId),
        this.flowTemplatesService.getCorpFlowTemplates(corpId),
      ]).subscribe(
        ([corp, flowTemplates]) => {
          if (!corp) {
            return;
          }
          this.corp = corp;
          this.flowTemplates = flowTemplates;
          this.refreshUI();
        },
        error => {
          this.toaster.error(error);
        },
      );
    });
  }

  refreshUI() {
    this.setBreadcrumb(this.corp);
    this.setSidebarItems(this.corp.id);
    this.headerService.setPageTitle(`${this.corp.label} Flow Templates`);
  }

  private setBreadcrumb(corp: CorpModel) {
    this.breadcrumbService.set([
      {
        label: corp.label,
        icon: corp.logo,
        route: `corps/${corp.id}`,
        testId: 'bread-crumb-corp',
      },
      {
        label: 'Flow Templates',
        route: `corps/${corp.id}/flow-templates`,
      },
    ]);
  }

  private setSidebarItems(corpId: string) {
    this.sidebarService.set(getSidebarItems(corpId));
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
