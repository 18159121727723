import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NlpDatasetCreateModalComponent } from 'src/app/components/modals/nlp-dataset-create-modal/nlp-dataset-create-modal.component';
import { SidebarService } from 'src/app/services/sidebar.service';
import { HeaderService } from 'src/app/services/header.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SimpleListItem } from 'src/app/components/lists/simple-list-item/_types/SimpleListItem';
import { NlpDojoService } from 'src/app/services/nlp-dojo.service';
import { ButtonType } from 'src/app/components/common/_types/ButtonType';
import { getSidebarItems } from 'src/app/pages/portal/nlp/utils';
import { ActionProgressComponent } from 'src/app/components/action-progress/action-progress.component';
// tslint:disable-next-line:max-line-length
import { SingleItemDeleteConfirmationModalComponent } from 'src/app/components/modals/single-item-delete-confirmation-modal/single-item-delete-confirmation-modal.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-nlp-datasets',
  templateUrl: './nlp-datasets.component.html',
  styleUrls: ['./nlp-datasets.component.scss']
})
export class NlpDatasetsComponent implements OnInit, OnDestroy, AfterViewInit {
  corpId: string;
  items: SimpleListItem[] = [];

  private nlpDatasetUpdatesSubscription: Subscription;
  private datasetsSubscription: Subscription;

  @ViewChild(ActionProgressComponent) actionProgress: ActionProgressComponent;
  constructor(
    private modalService: BsModalService,
    private sidebarService: SidebarService,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService,
    private nlpDojoService: NlpDojoService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    // we are using only the `default` corporation for training models.
    this.corpId = 'default';

    this.refreshUI();
    this.setSidebarItems();

    this.nlpDatasetUpdatesSubscription = this.nlpDojoService.nlpDatasetUpdates.subscribe(() => {
      this.reloadNlpDatasets();
    });
  }

  ngAfterViewInit() {
    this.reloadNlpDatasets();
  }

  showAddNlpDataset() {
    if (!this.corpId) {
      return;
    }
    this.modalService.show(NlpDatasetCreateModalComponent, {
      ignoreBackdropClick: true,
      initialState: { corpId: this.corpId }
    });
  }

  refreshUI() {
    if (this.corpId) {
      this.setBreadcrumb();
      this.headerService.setPageTitle(`Training Datasets`);
    }
  }

  private reloadNlpDatasets() {
    this.actionProgress.start();
    this.datasetsSubscription = this.nlpDojoService.getDatasets(this.corpId).subscribe(nlpDatasets => {
      this.actionProgress.complete();
      this.items = nlpDatasets.map(nlpDataset => ({
        title: nlpDataset.name,
        dataPoints: [
          {
            label: 'Files Count',
            value: nlpDataset.files ? `${nlpDataset.files.length}` : '0',
            className: 'simple-list-item-data-point--sm'
          }
        ],
        buttons: [
          {
            label: 'Files',
            link: ['/portal/nlp/datasets/', nlpDataset.system_name]
          },
          {
            label: 'Delete',
            type: ButtonType.danger,
            action: () => this.confirmAndDeleteDataset(nlpDataset.id, nlpDataset.name)
          }
        ]
      }));
    });
  }

  private confirmAndDeleteDataset(datasetId: string, datasetName: string) {
    const modalRef = this.modalService.show(SingleItemDeleteConfirmationModalComponent, { ignoreBackdropClick: true });
    modalRef.content.typeOfEntity = 'Dataset';
    modalRef.content.entityValue = datasetName;
    modalRef.content.onDeleteConfirmed.subscribe(async () => {
      await this.nlpDojoService
        .deleteDataset(this.corpId, datasetId)
        .pipe(take(1))
        .toPromise();
      this.toaster.success('Dataset deleted');
    });
  }

  ngOnDestroy(): void {
    if (this.nlpDatasetUpdatesSubscription) {
      this.nlpDatasetUpdatesSubscription.unsubscribe();
    }
    if (this.datasetsSubscription) {
      this.datasetsSubscription.unsubscribe();
    }
  }

  private setBreadcrumb() {
    this.breadcrumbService.set([
      {
        label: 'Training Datasets',
        route: `/portal/nlp/datasets`
      }
    ]);
  }

  private setSidebarItems() {
    this.sidebarService.set(getSidebarItems());
  }
}
