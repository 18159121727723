import { Component, OnDestroy, OnInit } from '@angular/core';
import { CorpModel } from 'src/app/models/corp';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { HeaderService } from 'src/app/services/header.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ToastrService } from 'ngx-toastr';
import { SidebarService } from '../../../../services/sidebar.service';
import { CorpHierarchyModel } from 'src/app/models';
import { CorpHierarchiesService, CorpsService } from 'src/app/services/firestore';
import { HumanInLoopService } from 'src/app/services/firestore/human-in-loop.service';
import { HLCorp } from 'src/app/models/conversations';

@Component({
  selector: 'app-corp-settings-admin',
  templateUrl: './corp-settings-admin.component.html',
  styleUrls: ['./corp-settings-admin.component.scss'],
})
export class CorpSettingsAdminComponent implements OnInit, OnDestroy {
  loading: boolean;
  corp: CorpModel | null;
  highestCorphierarchy: CorpHierarchyModel | null;

  private paramMapSubscription: Subscription;
  private dataSubscription: Subscription;
  private tagSubscription: Subscription;
  hlCorp: HLCorp;
  corpTags: string[];

  constructor(
    private corpsService: CorpsService,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService,
    private toaster: ToastrService,
    private sidebarService: SidebarService,
    private corpHierarchiesService: CorpHierarchiesService,
    private humanInLoopService: HumanInLoopService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = this.route.paramMap.subscribe(params => {
      const corpId = params.get('corp');
      if (!corpId) {
        return;
      }
      this.loading = true;
      this.dataSubscription = combineLatest([
        this.corpsService.getCorpById(corpId),
        this.corpHierarchiesService.getHighestCorpHierarchy(corpId),
      ]).subscribe(
        ([corp, highestCorphierarchy]) => {
          this.corp = corp;
          this.highestCorphierarchy = highestCorphierarchy;

          this.refreshUI();
          this.setSidebarItems();
          this.loading = false;
        },
        error => {
          this.toaster.error(error);
          this.loading = false;
        },
      );

      this.tagSubscription = this.humanInLoopService.getCorpTags(corpId).subscribe(doc => {
        if (doc) {
          this.hlCorp = doc;
          if (this.hlCorp) {
            this.corpTags = this.hlCorp.conversation_tags;
          }
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.tagSubscription) {
      this.tagSubscription.unsubscribe();
    }
  }

  refreshUI() {
    if (this.corp) {
      this.setBreadcrumb(this.corp);
      this.headerService.setPageTitle(`${this.corp.label} Settings`);
    }
  }

  setBreadcrumb(corp: CorpModel) {
    this.breadcrumbService.set([
      {
        label: 'Corporations',
        route: `admin/corps`,
      },
      {
        label: corp.label,
        icon: corp.logo,
        route: `admin/corps/${corp.id}`,
      },
    ]);
  }

  updateTags(tags: string[]) {
    if (this.corp) {
      this.hlCorp.conversation_tags = tags;
      this.humanInLoopService.saveCorpTags(this.corp.id, this.hlCorp);
    }
  }

  private setSidebarItems() {
    const sidebar = [
      {
        path: `/admin/corps/${this.corp?.id}/hierarchies`,
        title: 'Hierarchies',
        type: 'link',
        icontype: 'fa fa-sitemap text-success',
      },
      {
        path: `/admin/corps/${this.corp?.id}/settings`,
        title: 'Settings',
        type: 'link',
        icontype: 'ni-settings-gear-65 text-success',
      },
    ];
    if (this.highestCorphierarchy) {
      sidebar.splice(1, 0, {
        path: `/admin/corps/${this.corp?.id}/hierarchies/${this.highestCorphierarchy.systemName}`,
        title: this.highestCorphierarchy.label,
        type: 'link',
        icontype: 'fas fa-arrow-circle-right text-gray',
      });
    }
    this.sidebarService.set(sidebar);
  }
}
