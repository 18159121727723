import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/firestore';
import { UserModel } from 'src/app/models';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserNotificationsService } from 'src/app/services/firestore/user-notifications.service';
import { NotificationType, UserNotificationModel } from 'src/app/models/notifications';
import moment from 'moment';
@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss'],
})
export class UserNotificationsComponent implements OnInit, OnDestroy {
  // https://demos.creative-tim.com/argon-dashboard-pro-angular/

  private currentUser: UserModel | null;
  private notificationsSubscription: Subscription;
  notifications: UserNotificationModel[];

  constructor(
    private router: Router,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private authService: AuthService,
    private usersService: UsersService,
    private toaster: ToastrService,
    private notificationService: UserNotificationsService,
  ) {}

  async ngOnInit() {
    this.headerService.setPageTitle('User Notifications');
    this.setBreadcrumb();
    this.setSidebarItems();

    this.currentUser = await this.authService.getCurrentUserProfile();
    if (this.currentUser) {
      this.notificationsSubscription = this.notificationService
        .getUserNotifications(this.currentUser.id)
        .subscribe(notifications => {
          this.notifications = notifications
            .map(n => {
              Object.defineProperty(n, 'timeAgo', {
                get() {
                  return moment(n.createdAt.toDate()).fromNow();
                },
                set() {
                  //
                },
              });

              return n;
            })
            .sort((a, b) => {
              return b.createdAt.toDate() - a.createdAt.toDate();
            });
        });
    }
  }

  clickedNotification(notification: UserNotificationModel) {
    if (notification.notificationType === NotificationType.ConversationAssigned) {
      // open conversation
      const { url } = notification.notificationData as any;

      if (url) {
        const urlString = `${url}`;
        if (urlString.startsWith('http')) {
          try {
            const u = new URL(urlString);
            this.router.navigateByUrl(u.pathname);
            return;
          } catch {
            //
          }
        }
        this.router.navigateByUrl(urlString);
      }
    }
  }
  private setBreadcrumb() {
    this.breadcrumbService.set([
      {
        label: 'User Notifications',
        route: '/portal/notifications',
      },
    ]);
  }

  private setSidebarItems() {
    this.sidebarService.set([
      {
        path: `/portal/dashboard`,
        title: 'Dashboard',
        type: 'link',
        icontype: 'ni-world-2 text-primary',
      },
    ]);
  }

  ngOnDestroy() {}
}
