import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataExportFilter } from '../_types/DataExportFilter';
import { executeCount, getIndexes, getMapping, index$, setFilter } from '../../../utils/es-reactive/es-reactive';
import { AwsRegionEnum } from '../../../models/countries/AwsRegionEnum';
import { EsClientService } from '../../../services/es-client.service';
import { getFilter, processIndexesMapping } from '../utils';
import { ToastrService } from 'ngx-toastr';
import { MappingNode } from '../_types/MappingNode';
import { getDatesAsString } from '../../../utils/date/date-utils';
import { FieldsSelectionService } from './service/fields-selection.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-data-export-search',
  templateUrl: './data-export-search.component.html',
  styleUrls: ['./data-export-search.component.scss']
})
export class DataExportSearchComponent implements OnInit {
  loading: boolean;
  dateOfToday: Date = new Date();

  @Input()
  botId: string;

  /**
   * If not provided, will be used the default defined region {@link AwsRegionEnum.US_EAST_1}
   */
  @Input()
  awsRegion: AwsRegionEnum;

  options = {};
  mappingNodes: MappingNode[];
  selectedNode: MappingNode;
  allFieldsSelected = false;

  @Output()
  filterUpdated: EventEmitter<DataExportFilter>;
  filter: DataExportFilter;

  constructor(
    private esClientService: EsClientService,
    private toaster: ToastrService,
    private fieldsSelectionService: FieldsSelectionService
  ) {
    this.filter = new DataExportFilter();
    this.filterUpdated = new EventEmitter<DataExportFilter>();
    this.mappingNodes = [];
  }

  ngOnInit() {
    this.getIndexesMapping();
  }

  private getIndexesMapping() {
    this.loading = true;
    this.mappingNodes = [];

    const indexes: string | string[] =
      this.filter && this.filter.dateRange
        ? getIndexes(getDatesAsString(this.filter.dateRange[0], this.filter.dateRange[1]))
        : 'messages-*';

    this.getLogsCountForExport(indexes).subscribe(count => {
      this.filter.messagesCount = count;
      if (count < 1) {
        this.loading = false;
        return;
      }

      index$(indexes)
        .pipe(getMapping(this.esClientService.getClient(this.awsRegion)))
        .subscribe(
          response => {
            this.allFieldsSelected = false;
            this.filter.includedFields = [];
            this.filter.includedFieldToNodeMap = new Map<string, MappingNode>();
            this.filter.excludedFields = [];
            this.mappingNodes = processIndexesMapping(response).children;
            this.emitFilterUpdated();

            this.loading = false;
          },
          error => {
            this.toaster.error(error);
            this.loading = false;
          }
        );
    });
  }

  onSortOrderChange() {
    this.emitFilterUpdated();
  }

  onDateIntervalChange(newDates: Date[]) {
    if (
      !this.filter.dateRange ||
      this.filter.dateRange[0] !== newDates[0] ||
      this.filter.dateRange[1] !== newDates[1]
    ) {
      this.filter.dateRange = newDates;
      this.getIndexesMapping();
    }
  }

  toggleNode(event) {
    const node = event.node.data as MappingNode;
    this.filter = this.fieldsSelectionService.toggleNode(this.filter, node);

    this.emitFilterUpdated();
  }

  toggleAllFields() {
    this.allFieldsSelected = !this.allFieldsSelected;
    this.filter = this.fieldsSelectionService.toggleAllFields(this.filter, this.mappingNodes, this.allFieldsSelected);

    this.emitFilterUpdated();
  }

  private emitFilterUpdated() {
    this.filterUpdated.emit(this.filter);
  }

  private getLogsCountForExport(indexes: string | string[]) {
    return index$(indexes)
      .pipe(setFilter(getFilter.bind(this)()), executeCount(this.esClientService.getClient(this.awsRegion)))
      .pipe(map(countResponse => countResponse?.count));
  }
}
