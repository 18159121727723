/**
 * This method is returning the possible dates as string, for a given interval,
 * using the following formats:
 *    yyyy-MM-dd - representing one day
 *    yyyy-MM-*  - representing the whole month
 */
import { Timestamp } from 'firebase-tools';
import dayjs from 'dayjs';
import moment from 'moment';

enum MomentDays {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

function getDatePlusOneMonth(date: Date): Date {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + 1);
  return newDate;
}

function getMonthAsString(month: number): string {
  return month < 10 ? `0${month}` : month.toString();
}

export function getDatesAsString(pastDate: Date | null, toDate?: Date): string[] | null {
  if (!pastDate) {
    return null;
  }

  if (!toDate) {
    toDate = new Date(Date.now());
  }

  const days: string[] = [];
  let loopDate = new Date(pastDate);
  while (loopDate <= toDate) {
    if (loopDate.getDate() === 1 && getDatePlusOneMonth(loopDate) < toDate) {
      days.push(`${loopDate.getFullYear()}-${getMonthAsString(loopDate.getMonth() + 1)}-*`);

      loopDate = getDatePlusOneMonth(loopDate);
    } else {
      days.push(loopDate.toISOString().split('T')[0]);

      const nextDate = loopDate.setDate(loopDate.getDate() + 1);
      loopDate = new Date(nextDate);
    }
  }

  return days;
}

export const makeObjectFirebaseTimestampHumanReadable = (model: {
  createdAt: Timestamp;
  updatedAt: Timestamp;
}): void => {
  model.createdAt = dayjs.unix(model.createdAt.seconds).format('MM-DD-YYYY hh:mm A');
  model.updatedAt = dayjs.unix(model.updatedAt.seconds).format('MM-DD-YYYY hh:mm A');
};

export function moveWeekendDateToFollowingMonday(date) {
  let dayOfWeek = moment(date).day();
  switch (dayOfWeek) {
    case MomentDays.Saturday:
      return moment(date).add(2, 'days').toDate();
    case MomentDays.Sunday:
      return moment(date).add(1, 'days').toDate();
    default:
      return date;
  }
}
