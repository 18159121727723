<div class="card-body wrapper">
  <h5 class="mb-0">Conversation ID</h5>
  <small class="text-muted">{{ selectedConversationId }}</small>
  <br />
  <br />
  <!-- <tag-input
    [ngModel]="tags"
    *ngIf="selectedConversationId"
    [modelAsStrings]="true"
    class="conversation-tags"
    theme="regular-theme"
    [inputClass]="'convo-tags-input'"
    [editable]="false"
    [disable]="true"
    [hideForm]="true"
  ></tag-input> -->
  <h5 class="mb-0">Last Updated</h5>
  <small class="text-muted">{{ updatedAt }}</small>
  <br />
  <br />
  <h5 class="mb-0">Service Reps</h5>
  <ng-container *ngIf="serviceReps">
    <small class="text-muted" *ngFor="let s of serviceReps"> {{ s.firstName }} {{ s.lastName }}<br /></small>
  </ng-container>
  <ng-container *ngIf="!serviceReps || serviceReps.length === 0">
    <small class="text-muted">No users assigned</small>
  </ng-container>
  <br />
  <br />
  <div *ngIf="conversation && conversation.botName">
    <h5 class="mb-0">Assistant</h5>
    <a *ngIf="botUrl" class="bot-link" [routerLink]="botUrl">{{ conversation.botName }}</a>
    <small class="text-muted" *ngIf="!botUrl">{{ conversation.botName }}</small>
  </div>
  <br />
  <div *ngIf="hierarchyLabel">
    <h5 class="mb-0">Location</h5>
    <small class="text-muted">{{ hierarchyLabel }}</small>
  </div>
</div>
